import React, { useCallback, SyntheticEvent } from 'react';
import { useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Account from 'models/canvas/account';
import Course from 'models/canvas/course';

import { useSelectorWithProps } from 'hooks/useSelectorWithProps';

import { triggerDelete } from 'store/accounts/composite-actions';
import * as courseSelectors from 'store/courses/selectors';
import * as accountSelectors from 'store/accounts/selectors';

import Button from 'components/elements/Button';
import PopoverButton from 'components/elements/PopoverButton';
import { Store } from 'redux';
import { AppState } from 'store';

export interface IAccountItemActionsProps {
  accountId: Account['id'];
  locked: boolean;
}

export const accountItemActionsClassName = 'account-item-actions';

const AccountItemActions: React.FC<IAccountItemActionsProps> = ({ accountId, locked }) => {
  const store: Store<AppState> = useStore();
  const { t } = useTranslation();
  const descendentCoursesIds: Course['id'][] = useSelectorWithProps(
    courseSelectors.getDescendentCoursesIdsOfAccount,
    { accountId },
  );

  const descendentAccountIds: Account['id'][] = useSelectorWithProps(
    accountSelectors.getDescendentAccountsIdsOfAccount,
    { accountId }
  );

  const accountUrl: string = useSelectorWithProps(
    accountSelectors.getAccountUrl,
    { accountId }
  );

  // Deletes the current account as well as its descendent courses and accounts
  const handleDeleteClick = useCallback((e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    triggerDelete(accountId, descendentCoursesIds, descendentAccountIds, t, store.getState())(store.dispatch);
  }, [store, accountId, descendentAccountIds, descendentCoursesIds, t]);

  return (
    <div className={accountItemActionsClassName}>
      <PopoverButton
        testId={`account-${accountId}-menu-button`}
        preferredPosition={['top']}
        isIconButton
        aria-label='Account Menu.'
        popoverContent={(
          <ul data-testid={`account-${accountId}-menu-content`} role='menu'>
            <li>
              <Button
                className='account-link'
                as={locked ? 'a' : 'button'}
                disabled={!locked}
                target='_blank'
                href={accountUrl}
                variant='link'
                color='white'
                size='big'
              >
                <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                {t('subAccountGoTo')}
              </Button>
            </li>

            <li>
              <Button
                className='delete-account-button'
                variant='link'
                color='white'
                size='big'
                onClick={handleDeleteClick}
                disabled={locked}
                ariaLabel='Delete'
              >
                <FontAwesomeIcon icon={['fas', 'minus-circle']} />
                {t('delete')}
              </Button>
            </li>
          </ul>
        )}
      >
        <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
      </PopoverButton>
    </div>
  );
};

export default React.memo(AccountItemActions);
