import React, { FC, useCallback, useMemo } from 'react';
import { map, get } from 'lodash';
import CreatableSelect, { Props } from 'react-select/creatable';
import { ActionMeta, Styles, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { customSelectStyles } from '../shared-style';
import { useTheme } from 'styled-components';
import ITheme from 'styles/interfaces/theme';

interface ITagsInputOption {
  label: string,
  value: string
}

interface ITagsInput {
  tags: string[];
  onChange: (tags: string[]) => void;
  value: string[];
  disabled?: boolean;
}

const tagToOption = (tag: string): ITagsInputOption => ({ label: tag, value: tag });
const optionToTag = (option: ITagsInputOption): string => get(option, 'value');

export const TagsInput: FC<ITagsInput & Omit<Props<string[], boolean>, 'value'>> = ({ tags, onChange, value, disabled, ...otherProps }) => {
  const { t } = useTranslation();
  const theme = useTheme() as ITheme;

  const options = useMemo((): ITagsInputOption[] => {
    return map(tags, tagToOption);
  }, [tags]);

  const handleChange = useCallback((newValue: ValueType<ITagsInputOption, boolean>, action: ActionMeta<ITagsInputOption>) => {
    const newTags: string[] = map(newValue, optionToTag);
    onChange(newTags);
  }, [onChange]);

  const valueAsOptions: ITagsInputOption[] = useMemo(() => {
    return map(value, tagToOption);
  }, [value]);

  const isDisabled: boolean = useMemo(() => {
    return !!disabled;
  }, [disabled]);

  const customStyles: Partial<Styles<any, any>> = {
    ...customSelectStyles({ size: 'big', theme })
  };

  return (
    <CreatableSelect
      className='tags-select-input'
      isMulti
      onChange={handleChange}
      options={options}
      placeholder={t('selectOrStartTyping')}
      value={valueAsOptions}
      menuPortalTarget={document.body}
      styles={customStyles}
      isDisabled={!!isDisabled}
      {...otherProps}
    />
  );
};
