import { createSelector } from 'reselect';
import { every } from 'lodash';
import { AppState } from 'store';
import { FunctionBarFeatureState } from './reducer';
import { getCourseExistsInLmsAlready, getSelectedCourses } from 'store/courses/selectors';
import Course from 'models/canvas/course';
import { PerMatchProps } from 'store/router/selectors';
import { RoutePathnames } from 'config/routePathnames';
import { getAccountLockedMap, getCourseLockedMap } from 'store/general/cross-selectors';
import { getActiveAccount } from 'store/account-tree/selectors';
import { COURSES_LIST_TABLE_ID } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable';
import Account from 'models/canvas/account';
import { memoizeSelectorFactory } from 'store/selector-helpers';
import { filter } from 'lodash';

export const getFunctionBarFeature = (appState: AppState): FunctionBarFeatureState => appState?.functionBar;

export const getFunctionBarExpanded = createSelector(
  getFunctionBarFeature,
  (featureState: FunctionBarFeatureState): boolean => !!featureState?.expanded
);

export const getAddAccountIsDisabled = createSelector(
  getActiveAccount,
  (activeAccount: Account): boolean => !activeAccount
);

export const getAddCoursesIsDisabled = createSelector(
  getActiveAccount,
  (activeAccount: Account): boolean => !activeAccount
);

export const getApplyTemplatesIsDisabled = createSelector(
  getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID }),
  (courses: Course[]): boolean => (courses?.length || 0) < 1
);

export const getClearSelectionsIsDisabled = createSelector(
  getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID }),
  (courses: Course[]): boolean => (courses?.length || 0) < 1
);

export const getBulkSettingsIsDisabled = createSelector(
  getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID }),
  (courses: Course[]): boolean => (courses?.length || 0) < 2
);

export const getDeleteUnlockedIsDisabled = memoizeSelectorFactory((props: PerMatchProps) => createSelector(
  getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID }),
  getActiveAccount,
  getAccountLockedMap,
  getCourseLockedMap,
  (
    selectedCourses: Course[],
    activeAccount: Account,
    accountLockedMap: { [accountId: string]: boolean },
    courseLockedMap: { [courseId: string]: boolean },
  ): boolean => {
    const matchPath = props?.match?.path;
    if (matchPath === RoutePathnames.ACCOUNTS) {
      return !activeAccount || !!accountLockedMap?.[activeAccount?.id];
    }

    if (matchPath === RoutePathnames.ACCOUNT_COURSES) {
      const allLocked = every(selectedCourses, (c: Course) => {
        return !!courseLockedMap?.[c?.id];
      });
      const noCoursesSelected = (selectedCourses?.length || 0) < 1;
      return noCoursesSelected || allLocked;
    }
  }
));

export const getMoveItemIsDisabled = memoizeSelectorFactory((props: PerMatchProps) => createSelector(
  getDeleteUnlockedIsDisabled(props),
  (deleteUnlockedDisabled: boolean): boolean => deleteUnlockedDisabled
));

export const getImportCourseIsDisabled = createSelector(
  getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID }),
  (courses: Course[]): boolean => (courses?.length || 0) < 1
);

export const getDuplicateButtonIsDisabled = createSelector(
  [
    (state: AppState) => state,
    getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID }),
  ],
  (state, selectedCourses) => {
    const newCourses: boolean[] = filter(selectedCourses, (c: Course) => {
      return !getCourseExistsInLmsAlready({ courseId: c?.id })(state);
    });
    return newCourses.length === selectedCourses.length;
  }
);