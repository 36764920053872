import { InstallationConfigKey } from 'constants/index';

export default class CourseBuilderInstallationConfig {
  navSettingsFeature?: boolean;
  authSettingsFeature?: boolean;
  pageRatingSettingsFeature?: boolean;
  createTermFeature?: boolean;

  constructor(json: any) {
    this[InstallationConfigKey.NAV_SETTINGS_FEATURE] = !!json?.[InstallationConfigKey.NAV_SETTINGS_FEATURE];
    this[InstallationConfigKey.AUTHORING_FEATURE] = !!json?.[InstallationConfigKey.AUTHORING_FEATURE];
    this[InstallationConfigKey.PAGE_RATING_FEATURE] = !!json?.[InstallationConfigKey.PAGE_RATING_FEATURE];
    this[InstallationConfigKey.CREATE_TERM_FEATURE] = !!json?.[InstallationConfigKey.CREATE_TERM_FEATURE];
  }
}
