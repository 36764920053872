import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCoursesFetchNumPagesFetched, getCoursesFetchTotalPages, getCoursesFetchProgressPercent } from 'store/courses/selectors';
import { ProgressBar } from 'components/elements';
import { Wrapper, OuterWrapper } from './style';

export interface ICoursesFetchStatusProps {}

const CoursesFetchStatus: React.FC<ICoursesFetchStatusProps> = (props) => {
  const { t } = useTranslation();
  const numPagesFetched: number = useSelector(getCoursesFetchNumPagesFetched);
  const totalPages: number = useSelector(getCoursesFetchTotalPages);
  const progressPercent: number = useSelector(getCoursesFetchProgressPercent);

  return (
    <OuterWrapper data-testid='courses-fetch-status'>
      <Wrapper>
        <p>
          <span>{t('courseLoading')}:</span> ({progressPercent}%)
        </p>
        <ProgressBar
          success={numPagesFetched}
          total={totalPages}
        />
      </Wrapper>
    </OuterWrapper>
  );
};

export default React.memo(CoursesFetchStatus);
