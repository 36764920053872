import { createSelector } from 'reselect';
import { get } from 'lodash';
import { AppState } from 'store';
import { RouterState } from 'connected-react-router';
import { RoutePathnames } from 'config/routePathnames';

export type RouteMatch = { path: string, params?: { [key: string]: any } };
export type PerMatchProps = { match: RouteMatch };

export const getRouter = (appState: AppState): RouterState => get(appState, 'router');

export const getRouterLocation = createSelector(
  getRouter,
  (routerState: RouterState): RouterState['location'] => get(routerState, 'location')
);

export const getPathname = createSelector(
  getRouterLocation,
  (location: RouterState['location']): string => get(location, 'pathname')
);

export const getIsAccountsView = createSelector(
  getPathname,
  (pathname: string): boolean => pathname === RoutePathnames.ACCOUNTS
);
