import { Blob } from 'blob-polyfill';

/**
 * Contains the filename and Blob in csv format to be used with PageRatingReportWidget
 */
export default class PageRatingReport {
  /** Filename extracted from Content-Disposition header in backend download response */
  filename: string;
  /** Data streamed from backend download response */
  reportContent: Blob;

  constructor(filename: string, data: string ) {
    this.filename = filename;
    this.reportContent = new Blob([data], {type:'text/csv;charset=utf-8'});
  }
}
