import styled, { css } from 'styled-components';

interface ICustomField {
  inlineCheck?: boolean;
}

export const Field = styled.div<ICustomField>`
  ${({ inlineCheck }) => !inlineCheck && css`
    display: block;
  `};

  ${({ inlineCheck }) => inlineCheck && css`
    display: flex;
    flex-direction: row-reverse;
  `};
  margin-bottom: 16px;
  width: 180px;
`;

export const Input = styled.input`
  background: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.gray6};
  color: ${({ theme }) => theme.color.font};
  display: block;
  font: 1.4rem/2.5rem ${({ theme }) => theme.font.main};
  height: 30px;
  padding: 0 8px;
  width: 100%;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.color.font};
  display: block;
  font: 1.6rem/1.9rem ${({ theme }) => theme.font.main};
  margin-bottom: 8px;
  text-align: left;
  width: 100%;
`;

export const Fieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
`;

export const Legend = styled.legend`
  color: ${({ theme }) => theme.color.font};
  display: block;
  font: 1.6rem/1.9rem ${({ theme }) => theme.font.main};
  margin-bottom: 8px;
  text-align: left;
  width: 100%;
`;
