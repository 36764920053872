import { DataTableConfig, ColumnDef, ColumnSortConfig, ColumnFilterConfig, DataTableSorts, DataTableFilters, DataTableColumnVisibility, DataTableSelections } from 'modules/data-table/types';
import { get, reduce, mapValues, includes, map } from 'lodash';
import { DataTableAction, DataTableActionTypes } from './actions';
import { defaultDataTableConfig, defaultColumnDef } from 'modules/data-table/defaults';

// The state for the whole DataTable feature; all registered tables
export interface DataTableFeatureState {
  tables: { [tableId: string]: DataTableState };
  data: { [tableId: string]: any[] };
}

// A single DataTable
export interface DataTableState {
  tableId: string;
  config: DataTableConfig;
  sorts: DataTableSorts;
  filters: DataTableFilters;
  columnVisibility: DataTableColumnVisibility;
  selections: DataTableSelections;
}

export const defaultDataTableFeatureState: DataTableFeatureState = {
  tables: {},
  data: {}
};

export const defaultDataTableState: DataTableState = {
  tableId: null,
  config: { ...defaultDataTableConfig },
  sorts: {},
  filters: {},
  columnVisibility: {},
  selections: {}
};

function singleTableStateReducer(
  state: DataTableState,
  action: DataTableAction
): DataTableState {
  switch (action.type) {
    case DataTableActionTypes.REGISTER_TABLE: {
      const defaultColumnVisibility = reduce(action.payload.config.columnDefs, (acc: any, colDef: ColumnDef) => {
        return { ...acc, [colDef.columnId]: !colDef.defaultHidden };
      }, {});
      const configWithDefaults = {
        ...action.payload.config,
        columnDefs: map(action.payload.config.columnDefs, (c: ColumnDef) => ({ ...defaultColumnDef, ...c }))
      };
      return {
        ...state,
        tableId: action.payload.tableId,
        config: {
          ...configWithDefaults
        },
        columnVisibility: {
          ...defaultColumnVisibility,
          ...state.columnVisibility
        }
      };
    }
    case DataTableActionTypes.SET_COLUMNS_VISIBLE: {
      const updateObj = reduce(action.payload.columnIds, (acc: Object, colId: string) => {
        return { ...acc, [colId]: action.payload.visible };
      }, {});
      return {
        ...state,
        columnVisibility: action.payload.replace
          ? updateObj
          : { ...state.columnVisibility, ...updateObj }
      };
    }
    case DataTableActionTypes.SET_COLUMN_SORT: {
      return {
        ...state,
        sorts: {
          // TODO: determine whether to allow multiple sorts by uncommenting this spread. (should be dataTable option?)
          // ...state.sorts,
          [action.payload.columnId]: action.payload.sort
        }
      };
    }
    case DataTableActionTypes.SET_COLUMN_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.columnId]: action.payload.filter
        }
      };
    }
    case DataTableActionTypes.SET_ROWS_SELECTED: {
      const updateObj: { [rowId: string]: boolean } = {};
      // forEach is faster than using reduce over a potentially large rowIds array
      action.payload.rowIds.forEach((rowId: string) => {
        updateObj[rowId] = action.payload.selected;
      });
      return {
        ...state,
        selections: action.payload.replace
          ? updateObj
          : { ...state.selections, ...updateObj }
      };
    }
    case DataTableActionTypes.CLEAR_ALL_SORTS: {
      return {
        ...state,
        sorts: {}
      };
    }
    case DataTableActionTypes.CLEAR_ALL_FILTERS: {
      return {
        ...state,
        filters: {}
      };
    }
    case DataTableActionTypes.CLEAR_COLUMNS_SORTS_FILTERS: {
      return {
        ...state,
        sorts: mapValues(
          state.sorts,
          (v: ColumnSortConfig, k: string) => (includes(action.payload.columnIds, k) ? null : state.sorts[k])
        ),
        filters: mapValues(
          state.filters,
          (v: ColumnFilterConfig, k: string) => (includes(action.payload.columnIds, k) ? null : state.filters[k])
        ),
      };
    }
    default:
      return state;
  }
}

export function dataTableReducer(
  state: DataTableFeatureState = defaultDataTableFeatureState,
  action: DataTableAction
): DataTableFeatureState {
  const tableId = get(action, 'payload.tableId');
  const currentTableState: DataTableState = get(state, `tables.${tableId}`, defaultDataTableState);

  switch (action.type) {
    case DataTableActionTypes.REGISTER_TABLE:
    case DataTableActionTypes.SET_COLUMNS_VISIBLE:
    case DataTableActionTypes.SET_COLUMN_SORT:
    case DataTableActionTypes.SET_COLUMN_FILTER:
    case DataTableActionTypes.SET_ROWS_SELECTED:
    case DataTableActionTypes.CLEAR_ALL_SORTS:
    case DataTableActionTypes.CLEAR_ALL_FILTERS:
    case DataTableActionTypes.CLEAR_COLUMNS_SORTS_FILTERS: {
      return {
        ...state,
        tables: {
          ...state.tables,
          [tableId]: singleTableStateReducer(currentTableState, action)
        }
      };
    }
    case DataTableActionTypes.SET_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          [tableId]: action.payload.data
        }
      };
    }
    default:
      return state;
  }
}
