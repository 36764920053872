import { AppState } from 'store';
import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

/**
 * Observe a value computed by a selector that has a selector factory, which is a technique that allows for proper
 *   selector memoization in cases where the selector requires parameterization (via selector props argument).
 *    - NOTE: for stronger type checking and communication, you can provide the optional ResultType parameter,
 *      but this can be omitted if the result type is defined in the selector definition itself
 *        - ex: `useSelectorWithProps<MyClass[]>`
 * @see https://react-redux.js.org/api/hooks#using-memoizing-selectors (final example in 'Using memoizing selectors' section)
 * @param selectorFactory - the function that returns a selector
 * @param selectorProps - the props argument used in the selector's projector function
 */
export function useSelectorWithProps<ResultType = any>(
  selectorFactory: (selectorProps: any) => (appState: AppState) => ResultType,
  selectorProps: { [key: string]: any },
  equalityFn?: (val1: any, val2: any) => boolean
): ResultType {
  const selector = useMemo(
    () => selectorFactory(selectorProps),
    [selectorProps, selectorFactory]
  );

  const resultValue: ResultType = useSelector(
    selector,
    equalityFn || shallowEqual
  );

  return resultValue;
}
