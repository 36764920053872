import { useCallback, useEffect, useState } from 'react';

/*
  Executes 'callback' if 'valueIsActive(currentValue)' returns `true`
  for 'activeDurationMs' consecutive milliseconds.
  If the value remains active, the callback will be executed again after the duration if 'repeat' is true.

  **EXAMPLE usage: Dragging an item over a folder for 1 second should expand the folder to reveal sub-folders.**

  const isDraggingOver = someLibrary.dragIsOver();

  const myCallback = useCallback((draggingOver: boolean) => {
    setExpanded(true);
  }, [setExpanded]);

  useStateDuration(
    isDraggingOver,
    (draggingOver: boolean) => draggingOver === true,
    1000,
    myCallback,
    false
  );

*/
export const useStateDurationEffect = <T>(
  currentValue: T,
  valueIsActive: (value: T) => boolean,
  activeDurationMs: number,
  callback: (value: T) => void,
  repeat?: boolean
) => {
  const [invocationTimeout, setInvocationTimeout] = useState<number>(null);
  const [isActive, setIsActive] = useState(false);

  const invokeCallback = useCallback(() => {
    callback(currentValue);
    if (repeat) { setInvocationTimeout(null); }
  }, [callback, currentValue, setInvocationTimeout, repeat]);

  useEffect(() => {
    setIsActive(!!valueIsActive(currentValue));
  }, [currentValue, valueIsActive, setIsActive]);

  useEffect(() => {
    if (isActive && !invocationTimeout) {
      const newTimeout = window.setTimeout(invokeCallback, activeDurationMs);
      setInvocationTimeout(newTimeout);
    } else if (!isActive && invocationTimeout) {
      // no longer active, cancel the timeout
      clearTimeout(invocationTimeout);
      setInvocationTimeout(null);
    }
    return () => clearTimeout(invocationTimeout);
  }, [isActive, activeDurationMs, invokeCallback, invocationTimeout, setInvocationTimeout]);
};
