import React, { FC, memo } from 'react';
import { FooterActionsContainer } from './style';

interface IFooterActions {
  children?: any;
  hidden?: boolean;
}

export const FooterActions: FC<IFooterActions> = memo(({
  children,
  hidden
}) => {
  if (!children || (Array.isArray(children) && !children.length)) {
    return;
  }

  return (
    <FooterActionsContainer className='footer-actions-container' hidden={hidden}>
      <div className='footer-actions-inner-wrapper'>
        {children}
      </div>
    </FooterActionsContainer>
  );
});
