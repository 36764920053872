import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get, includes, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SyncOrderDto from 'models/dto/syncOrderDto';

import { getLmsBaseUrl } from 'store/installation/selectors';
import { buildAccountUrl, getAccountItemsMap } from 'store/accounts/selectors';
import { buildCourseUrl, buildCourseContentMigrationsUrl, getCourseItems } from 'store/courses/selectors';
import { syncItemIsAccount, getAllActiveSyncOrderIds, syncItemIsCourse,
  getSyncItemTypeData, getSyncOrderDto, getAllItemsForSyncOrder } from 'store/syncOrders/selectors';

import { SyncItemDetail } from './syncItemDetail';
import { SyncItemStatus } from './syncItemStatus';
import BaseSyncItemEntity from 'models/syncOrder/baseSyncItemEntity';
import { ISyncTypeData } from 'constants/index';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { CoursesFeatureState } from 'store/courses/reducer';
import { AccountsFeatureState } from 'store/accounts/reducer';

interface ISyncOrderDetails {
  syncOrderId: string,
}
export const SyncOrderDetails: React.FC<ISyncOrderDetails> = React.memo(({ syncOrderId }) => {
  const { t } = useTranslation();
  const lmsBaseUrl = useSelector(getLmsBaseUrl);
  const syncOrderDto: SyncOrderDto = useSelectorWithProps(getSyncOrderDto, { syncOrderId });
  const allItems: BaseSyncItemEntity[] = useSelectorWithProps(getAllItemsForSyncOrder, { syncOrderId });
  const courseItems: CoursesFeatureState['items'] = useSelector(getCourseItems);
  const accountItems: AccountsFeatureState['items'] = useSelector(getAccountItemsMap);

  const activeSyncOrderIds = useSelector(getAllActiveSyncOrderIds);
  const isActiveSyncOrder: boolean = useMemo(() => {
    return includes(activeSyncOrderIds, syncOrderDto?.syncOrder?.syncOrderId);
  }, [activeSyncOrderIds, syncOrderDto]);

  const shouldShowStatusLink = (syncItem: BaseSyncItemEntity): boolean => {
    const hasMigration = !!get(syncItem, 'courseTemplateId') || !!get(syncItem, 'sourceCourseId');
    return syncItemIsCourse(syncItem) && get(syncItem, 'courseId') && hasMigration;
  };

  const getItemName = (syncItem: BaseSyncItemEntity): string => {
    let name: string = get(syncItem, 'name');
    if (name) { return name; }

    const syncItemTypeData: ISyncTypeData = getSyncItemTypeData(syncItem);
    if (syncItemIsAccount(syncItem)) {
      const accountId = get(syncItem, syncItemTypeData?.lmsItemIdProperty);
      name = get(accountItems, `${accountId}.name`);
    } else if (syncItemIsCourse(syncItem)) {
      const courseId = get(syncItem, syncItemTypeData?.lmsItemIdProperty);
      name = get(courseItems, `${courseId}.name`);
    }
    return name;
  };

  return (
    <table className='sync-job-details'>
      <thead>
        <tr>
          <th>{ t('description') }</th>
          <th>{ t('status') }</th>
          <th>{ t('details') }</th>
          <th>{ t('migrationStatus') }</th>
        </tr>
      </thead>
      <tbody>
        {
          map(allItems, (syncItem: BaseSyncItemEntity) => {
            const { lmsItemIdProperty, idProperty, iconProp }: ISyncTypeData = getSyncItemTypeData(syncItem);
            const isAccount = syncItemIsAccount(syncItem);
            const isCourse = syncItemIsCourse(syncItem);
            const lmsItemId = get(syncItem, lmsItemIdProperty);
            const isCreated = !!lmsItemId;
            let itemUrl = null;
            if (isAccount) {
              itemUrl = buildAccountUrl(lmsBaseUrl, lmsItemId);
            } else if (isCourse) {
              itemUrl = buildCourseUrl(lmsBaseUrl, lmsItemId);
            }

            const shouldLinkToItem = isCreated && itemUrl;
            const statusUrl = shouldShowStatusLink(syncItem) ? buildCourseContentMigrationsUrl(lmsBaseUrl, lmsItemId) : null;

            return (
              <tr key={`sync-order-details-${(syncItem as any)?.[idProperty]}`} className='sync-job-details-item'>
                <td className='sync-details-name'>
                  <FontAwesomeIcon icon={iconProp} fixedWidth className='sync-details-icon' />
                  {
                    shouldLinkToItem
                    ? (
                      <a className='course-external-link' href={itemUrl} target='_blank' rel='noopener noreferrer' title={t('openInNewTab')}>
                        {getItemName(syncItem)}
                      </a>
                    )
                    : getItemName(syncItem)
                  }
                </td>
                <td>
                  <SyncItemStatus
                    syncItem={syncItem}
                    belongToActiveSyncOrder={isActiveSyncOrder}
                  />
                </td>
                <td>
                  <SyncItemDetail
                    syncItem={syncItem}
                  />
                </td>
                <td>
                  {
                    statusUrl
                    ? (
                      <a className='course-external-link' href={statusUrl} target='_blank' rel='noopener noreferrer' title={t('openInNewTab')}>
                        { t('status') }
                      </a>
                    )
                    : '-'
                  }
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
});
