import { AxiosResponse } from 'axios';
import api from 'api';
import mockApi from 'api/mock';

import { API } from 'config';
import CanvasPageable from 'models/canvasPageable';
import CourseDto from 'models/dto/courseDto';

const customApi = API.useMock ? mockApi() : api();

export const paginatedCoursesEndpoint = (accountId: string, page: number) => (
  `/api/accounts/${accountId}/page/courses?include%5B%5D=term&page=${page}`
);

export const courseEndpoint = (courseId: string) => (
  `/api/courses/${courseId}?include%5B%5D=term`
);

// Back-end forwards the queryParams to the Canvas API accounts/:id/courses fetch
export async function getPaginatedCoursesForAccount(
  accountId: string,
  page: number
): Promise<CanvasPageable<CourseDto>> {
  return customApi.get(paginatedCoursesEndpoint(accountId, page))
    .then((resp: AxiosResponse<CanvasPageable<CourseDto>>) => {
      const resultPage = new CanvasPageable<CourseDto>(resp.data, CourseDto);
      return resultPage;
    });
}

export async function getCourse(courseId: string): Promise<CourseDto> {
  return customApi.get(courseEndpoint(courseId))
    .then((resp: AxiosResponse<CourseDto>) => new CourseDto(resp.data));
}
