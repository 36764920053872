import React, { FC, useMemo } from 'react';
import Course from 'models/canvas/course';
import { DataTable } from 'modules/data-table/components/DataTable';
import { ColumnDef } from 'modules/data-table/types';
import { useTranslation } from 'react-i18next';
import { getAccountItemsMap } from 'store/accounts/selectors';
import { selectCourseTableColumns } from 'config/table';
import { useSelector } from 'react-redux';

interface ISelectCourseTable {
  // The courses to use as the full table data set
  courses: Course[];
  // A Fn to determine whether the select button should be disabled for a given course
  courseIsDisabled?: (course: Course) => boolean;
  tableId: string;
}

// A component that displays the provided list of courses in a table
// with a 'select' button next to each course that invokes the onSelect handler
export const SelectCourseTable: FC<ISelectCourseTable> = ({
  courses,
  tableId,
  courseIsDisabled,
}) => {
  const { t } = useTranslation();
  const accountItems = useSelector(getAccountItemsMap);

  const columnDefs: ColumnDef[] = useMemo(() => {
    return selectCourseTableColumns(t, accountItems, courseIsDisabled);
  }, [t, accountItems, courseIsDisabled]);

  return (
    <div className='select-course-table-wrapper' style={{ height: '100%' }}>
      <DataTable
        data={courses}
        defaultRowHeight={40}
        columnDefs={columnDefs}
        tableId={tableId}
        rowIdProperty='id'
        keyboardInstructions={false}
        columnsMenu={false}
      />
    </div>
  );
};
