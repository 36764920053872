import React, { FC } from 'react';
import { ColumnSelect } from './ColumnSelect';
import { DataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';

interface ITableControls {
  tableId: string;
  api: DataTableObservables;
  columnsMenu?: boolean;
}

export const TableControls: FC<ITableControls> = ({
  tableId,
  columnsMenu
}) => {
  return (
    <div className='table-controls-container' data-testid='table-controls-container'>
      {columnsMenu && (
        <ColumnSelect tableId={tableId} />
      )}
    </div>
  );
};
