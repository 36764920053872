import { Button } from 'components/elements';
import { isFunction } from 'lodash';
import { toast } from 'helpers/messages';
import React, { FC, ReactNode, ReactText, useCallback } from 'react';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import { ToastOptions } from 'react-toastify';
import { CustomToastContentWrapper } from '../style';

interface IConfirmToast {
  title?: string;
  children: ReactNode;
  onConfirm: (testId?: ReactText) => void;
  onCancel?: (testId?: ReactText) => void;
  config: ToastOptions;
  ok?: string;
  cancel?: string;
  cancelVisible?: boolean;
}

export const ConfirmToast: FC<IConfirmToast> = ({
  title,
  children,
  onConfirm,
  onCancel,
  config,
  ok,
  cancel,
  cancelVisible = true,
}) => {
  const { t } = useTranslation();
  const { toastId } = config;

  const cancelCallback = useCallback(() => {
    toast.dismiss(toastId);
    document.getElementById('toast-curtain')?.classList.remove('show');
    isFunction(onCancel) && onCancel(toastId);
  }, [onCancel, toastId]);

  const confirmCallback = useCallback(() => {
    toast.dismiss(toastId);
    document.getElementById('toast-curtain')?.classList.remove('show');
    onConfirm(toastId);
  }, [onConfirm, toastId]);

  return (
    <CustomToastContentWrapper className='custom-toast-content confirm-toast-content'>
      <FocusLock returnFocus>
        <div className='content-container'>
          <h3 className='toast-heading'>{title ? title : t('confirm')}</h3>
          {children}
        </div>
        <div className='buttons-container'>
          {cancelVisible && (
            <Button
              className='custom-toast-button'
              color='secondary'
              onClick={cancelCallback}
              size='big'
              testId='toast-confirm-cancel-button'
            >
              {cancel || t('cancel')}
            </Button>
          )}
          <Button
            className='custom-toast-button'
            onClick={confirmCallback}
            size='big'
            testId='toast-confirm-ok-button'
          >
            {ok || t('ok')}
          </Button>
        </div>
      </FocusLock>
    </CustomToastContentWrapper>
  );
};
