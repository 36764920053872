import styled from 'styled-components';

export const CoursesListTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1rem;

  .--data-table-container {
    .table-container-controlbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 1.5rem;
    }
    .course-menu-table-cell {
      padding: 10px 2px;
    }

    .--data-table-row {
      &.selected, &.odd.selected {
        background: ${({ theme }) => theme.color.tableSelectedRow};
      }
    }
  }
`;
