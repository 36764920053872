import React, { FC, memo } from 'react';
import { AppMenuContainer } from './style';
import { AppMenuDropdownItem } from '../AppMenuDropdownItem';
import AppMenuDropdownProvider from '../context/AppMenuDropdownProvider';
import AppMenuDropdownConfig from 'models/appMenuDropdownConfig';
import { appMenuItems } from 'config/app-menu';

interface IAppMenu {
}

export const AppMenu: FC<IAppMenu> = memo(() => {

  const appMenuConfigs = appMenuItems.map(item => new AppMenuDropdownConfig(item));

  return (
    <AppMenuContainer className='app-menu-container'>
      <AppMenuDropdownProvider appMenuConfigs={appMenuConfigs} >
      <ul>
        {appMenuConfigs.map((menuItem, index) => {
          return (
            <AppMenuDropdownItem 
              id={menuItem.id}
              isMainNav={true}
              key={index}
            />
          );
        })}
      </ul>
      </AppMenuDropdownProvider>
    </AppMenuContainer>
  );
});
