import Account from 'models/canvas/account';
import { forEach } from 'lodash';
import { AccountTreeAction, AccountTreeActionTypes } from './actions';

export interface AccountTreeFeatureState {
  expandedAccounts: Record<Account['id'], boolean>;
  activeAccountId: Account['id'];
}

export const defaultAccountTreeState: AccountTreeFeatureState = {
  expandedAccounts: {},
  activeAccountId: null
};

export function accountTreeReducer(
  state: AccountTreeFeatureState = defaultAccountTreeState,
  action: AccountTreeAction
): AccountTreeFeatureState {
  switch (action.type) {
    case AccountTreeActionTypes.SET_ACCOUNTS_EXPANDED: {
      const updateObj: { [accountId: string]: boolean } = {};
      forEach(action.payload.accountIds, (accountId: string) => {
        updateObj[accountId] = !!action.payload.expanded;
      });
      return {
        ...state,
        expandedAccounts: {
          ...state.expandedAccounts,
          ...updateObj
        }
      };
    }
    case AccountTreeActionTypes.SET_ACTIVE_ACCOUNT: {
      return {
        ...state,
        activeAccountId: action.payload.accountId
      };
    }
    default:
      return state;
  }
}
