import React, { FC, memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { RoutePathnames } from 'config/routePathnames';
import { getActiveAccountId } from 'store/account-tree/selectors';
import { setActiveAccount } from 'store/account-tree/actions';
import { getHasChangesToSync } from 'store/general/cross-selectors';
import { SplitResizableLayout } from 'components/SplitResizableLayout';
import { ResizableColumn } from 'components/SplitResizableLayout/ResizableColumn';
import { FillColumn } from 'components/SplitResizableLayout/FillColumn';
import { FooterActions } from 'components/FooterActions';
import { FooterAction } from 'components/FooterActions/style';
import { SyncChangesButton } from 'components/elements/SyncChangesButton';

import { AccountTree } from './AccountTree';
import { CoursesList } from './CoursesList';
import { AccountsCoursesContainer } from './style';

interface IAccountsCoursesManager {
  match: { path: string, params: {}};
}

/**
 * A view shared by 2 routes: ACCOUNTS and ACCOUNT_COURSES (see RoutePathnames)
 */
export const AccountsCoursesManager: FC<IAccountsCoursesManager> = memo(({
  match: { path, params }
}) => {
  const dispatch = useDispatch();
  const [full, setFull] = useState(false);
  const isAccountsRoute = path === RoutePathnames.ACCOUNTS;
  const isAccountCoursesRoute = path === RoutePathnames.ACCOUNT_COURSES;
  const hasChangesToSync = useSelector(getHasChangesToSync);
  const footerHidden = !hasChangesToSync;

  const routeAccountId = get(params, 'accountId');
  const activeAccountId = useSelector(getActiveAccountId);

  // Ensure active accountId matches the route param accountId when navigated to ACCOUNT_COURSES route
  useEffect(() => {
    if (isAccountCoursesRoute && activeAccountId !== routeAccountId) {
      dispatch(setActiveAccount(routeAccountId));
    }
  }, [dispatch, routeAccountId, activeAccountId, isAccountCoursesRoute]);

  // Switch to full accounts view when in ACCOUNTS route
  useEffect(() => {
    setFull(isAccountsRoute);
  }, [isAccountsRoute, setFull]);

  return (
    <AccountsCoursesContainer className='accounts-courses-manager-container'>
      <SplitResizableLayout>
        <ResizableColumn defaultWidth='20%' forceFull={full}>
          <AccountTree />
        </ResizableColumn>
        <FillColumn>
          <CoursesList accountId={activeAccountId} isAccountsRoute={isAccountsRoute} />
        </FillColumn>
      </SplitResizableLayout>
      <FooterActions hidden={footerHidden}>
        {/* Keep as an example until we have something using left-aligned buttons: */}
        {/*
          <FooterActionsLeft>
            <FooterAction>
              Something left
            </FooterAction>
          </FooterActionsLeft>
        */}
        <FooterAction>
          <SyncChangesButton />
        </FooterAction>
      </FooterActions>
    </AccountsCoursesContainer>
  );
});
