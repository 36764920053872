import { RequestAction, RequestsActionTypes } from './actions';

export interface RequestState<ResponseBodyType = any> {
  completedAt?: string;
  error?: Error;
  inProgress?: boolean;
  label?: string;
  requestUuid: string;
  responseBody?: ResponseBodyType;
  startedAt: string;
}

export type RequestsFeatureState = Record<RequestState['requestUuid'], RequestState>;

export const defaultRequestsFeatureState: RequestsFeatureState = {};
export const defaultRequestState: RequestState = {} as RequestState;

function getCurrentRequestState(
  state: RequestsFeatureState,
  action: RequestAction
): RequestState {
  const requestUuid = action?.payload?.requestUuid;
  return state?.[requestUuid] || defaultRequestState;
}

export default function requestsReducer(
  state: RequestsFeatureState = defaultRequestsFeatureState,
  action: RequestAction
): RequestsFeatureState {
  switch (action.type) {
    case RequestsActionTypes.REQUEST_START:
      return {
        ...state,
        [action.payload.requestUuid]: {
          ...getCurrentRequestState(state, action),
          requestUuid: action.payload.requestUuid,
          startedAt: action.payload.startedAt,
          label: action.payload.label,
          inProgress: true
        }
      };
    case RequestsActionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        [action.payload.requestUuid]: {
          ...getCurrentRequestState(state, action),
          completedAt: action.payload.completedAt,
          responseBody: action.payload.responseBody,
          inProgress: false
        }
      };
    case RequestsActionTypes.REQUEST_ERROR:
      return {
        ...state,
        [action.payload.requestUuid]: {
          ...getCurrentRequestState(state, action),
          completedAt: action.payload.completedAt,
          error: action.payload.error,
          inProgress: false
        }
      };
    default:
      return state;
  }
}
