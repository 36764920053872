import { ofType, StateObservable } from 'redux-observable';
import { EMPTY, Observable } from 'rxjs';
import { mergeMapTo, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'store';
import { RequestErrorAction, RequestsActionTypes } from './actions';

export const requestErrorEpic$ = (actions$: Observable<any>, state$: StateObservable<AppState>) => actions$.pipe(
  ofType(RequestsActionTypes.REQUEST_ERROR),
  withLatestFrom(state$),
  tap(([action, appState]: [RequestErrorAction, AppState]) => {
    /** EV-1309 hotfix: remove request-error toasts: */
  }),
  mergeMapTo(EMPTY)
);
