import Course from 'models/canvas/course';
import React, { FC } from 'react';
import { map, slice } from 'lodash';
import { TagPill, TagsCellWrapper } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/elements';
import { useTranslation } from 'react-i18next';
import CourseMeta from 'models/courseMeta';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getCourseIsSyncing } from 'store/general/cross-selectors';
import ReactTooltip from 'react-tooltip';
import { EditSingleValueButton } from 'components/elements/EditSingleValueButton';
import { updateCourseMeta } from 'store/courses/actions';
import { getAllCourseTags } from 'store/courses/selectors';

interface ITagsCell {
  row: Course;
  value: CourseMeta['tags'];
  allowEdit?: boolean;
}

export const TagsCell: FC<ITagsCell> = ({
  row,
  value,
  allowEdit = true
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allCourseTags = useSelector(getAllCourseTags);

  const sortedTags = value;
  const courseId = row?.id;
  const isSyncing = useSelectorWithProps(getCourseIsSyncing, { courseId });

  // Render the first 3 tags, and provide a tooltip if there are more than 3
  const tagsToRender = slice(sortedTags, 0, 3);
  const additionalTags = slice(sortedTags, 3);
  const hasAdditionalTags = (additionalTags?.length || 0) > 0;

  const canEdit = allowEdit && !isSyncing;

  return (
    <TagsCellWrapper className='tags-cell-wrapper'>
      <EditSingleValueButton
        canEdit={canEdit}
        componentProps={{ tags: allCourseTags }}
        currentValue={value}
        labelText={t('tags')}
        onUpdate={(newTags: string[]) => {
          dispatch(updateCourseMeta(courseId, { tags: newTags }));
        }}
        type='tags'
      >
        {map(tagsToRender, (tag: string) => (
          <TagPill key={`${courseId}-${tag}`} className='tag-pill'>
            {tag}
          </TagPill>
        ))}
        {hasAdditionalTags && (
          <>
            <ReactTooltip
              aria-haspopup='true'
              id={`course-${courseId}-tags`}
              event='focus mouseover'
              eventOff='blur mouseout'
              multiline
            >
              <ul className='extra-tags'>
                {map(additionalTags, (tag: string) => (
                  <li key={`${courseId}-${tag}`} style={{ listStyle: 'disc' }}>
                    {tag}
                  </li>
                ))}
              </ul>
            </ReactTooltip>
            <Button
              className='extra-tags-tooltip-trigger'
              data-tip
              data-for={`course-${courseId}-tags`}
              size='small'
              variant='link'
              underline
            >
              + {t('tagsWithCount', { count: additionalTags.length })}
            </Button>
          </>
        )}
      </EditSingleValueButton>
    </TagsCellWrapper>
  );
};
