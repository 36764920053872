import { createSelector } from 'reselect';

import { AppState } from 'store';
import { PageRatingReportFeatureState } from './reducer';
import PageRatingJobStatus from 'models/pageRatingReport/pageRatingJobStatus';
import PageRatingReport from 'models/pageRatingReport/pageRatingReport';

export const getPageRatingReportFeature = (appState: AppState): PageRatingReportFeatureState => appState?.pageRatingReport;

export const getPageRatingReportStatus = createSelector(
  getPageRatingReportFeature,
  (featureState: PageRatingReportFeatureState): PageRatingJobStatus => featureState?.reportStatus
);

export const getPageRatingReport = createSelector(
  getPageRatingReportFeature,
  (featureState: PageRatingReportFeatureState): PageRatingReport => featureState?.report
);
