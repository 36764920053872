import styled, { css } from 'styled-components';

interface ISyncStatusManagerContainer {}

export const SyncStatusManagerContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    width: 100%;

    .sync-status-heading {
      display: flex;
      margin: 24px 0 8px;

      h3 {
        font: 400 2.4rem/2.5rem "Lato", Helvetica, Roboto;
        margin: 0;
      }
    }
  `}
`;

export const SyncOrdersList = styled.div`
  ${({ theme }) => css`
    height: 100%;
    overflow: auto scroll;
    padding: 20px;
    transition: padding .4s ease;
  `}
`;

export const SyncOrdersContainer = styled.section`
  &&.active-sync-orders-container {
    margin-bottom: 24px;
  }

  .sync-status-heading {
    margin-top: 0;
  }

  .global-progress-bar {
    margin-bottom: 24px;

    p {
      font: 400 1.6rem/2.5rem "Lato", Helvetica, Roboto;
      margin: 0;
    }
  }
`;
