import React, { FC, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EnhancedNavSettings from '../../../elements/EnhancedNavSettings';
import EnhancedAuthoringSettings from '../../../elements/EnhancedAuthoringSettings';
import EnhancedPageRatingSettings from '../../../elements/EnhancedPageRatingSettings';

import { useTranslation } from 'react-i18next';

import AccountMeta from 'models/accountMeta';

import { receiveAccounts, updateAccountMeta } from 'store/accounts/actions';
import { getAccountsFeature } from 'store/accounts/selectors';

import { useInstallationConfig } from 'hooks/useInstallationConfig';
import { InstallationConfigKey } from 'constants/index';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import Account from '../../../../models/canvas/account';
import CourseNavSettings from '../../../../models/courseNavSettings';
import CourseAuthoringSettings from '../../../../models/courseAuthoringSettings';
import CoursePageRatingSettings from '../../../../models/coursePageRatingSettings';
import DefaultBulkCourseSettings from 'models/defaultBulkCourseSettings';

interface DdInterface {
  account: Account;
  displayOptions: boolean;
  depthLevel: any;
  showSubAccounts?: boolean;
}
interface IAccountBulkSettingsValues {
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
}

const AccountDropDownItem: FC<DdInterface> = ({ account, displayOptions, depthLevel, showSubAccounts }) => {
  const schema = yup
    .object()
    .shape({
      courseNavSettings: yup.object().shape({
        enabled: yup.boolean(),
        useHomeMenu: yup.boolean()
      }),
      courseAuthoringSettings: yup.object().shape({
        enabled: yup.boolean()
      }),
      coursePageRatingSettings: yup.object().shape({
        enabled: yup.boolean()
      })
   },   
  );  

  const accountFeatureState = useSelector(getAccountsFeature);
  const dispatcher = useDispatch();
  const [settings, setSettings] = useState(new DefaultBulkCourseSettings(
    {
      courseAuthoringSettings: {
        enabled: false
      },
      courseNavSettings: {
        enabled: false,
        useHomeMenu: false
      },
      coursePageRatingSettings: {
        enabled: false
      }
    }
  ));

  const { control, watch, getValues } = useForm<IAccountBulkSettingsValues>({
     resolver: yupResolver(schema)
   });
  const hasAuthoringFeature: boolean = useInstallationConfig(InstallationConfigKey.AUTHORING_FEATURE);
  const hasNavigationFeature: boolean = useInstallationConfig(InstallationConfigKey.NAV_SETTINGS_FEATURE);
  const hasPageRatingFeature: boolean = useInstallationConfig(InstallationConfigKey.PAGE_RATING_FEATURE);
 
  const courseAuthoringSettings: boolean = accountFeatureState.items[account.id]._meta?.defaultCourseBulkSettings?.courseAuthoringSettings?.enabled;
  const courseNavSettings: CourseNavSettings = accountFeatureState.items[account.id]._meta?.defaultCourseBulkSettings?.courseNavSettings;
  const coursepageRatingSettings: boolean = accountFeatureState.items[account.id]._meta.defaultCourseBulkSettings?.coursePageRatingSettings?.enabled;

  const navIsChecked: boolean = courseNavSettings?.enabled;
  const { t } = useTranslation();
 
  useEffect(()=> {
    if (accountFeatureState.items[account.id]._meta.defaultCourseBulkSettings){
    setSettings({...settings,
      courseAuthoringSettings: {
        enabled: courseAuthoringSettings
      },
      courseNavSettings: courseNavSettings,
      coursePageRatingSettings: {
        enabled: coursepageRatingSettings
      }});
    }
  }, []);

  const handleChange = () => {
    const updatedValues = getValues();
    const newSettings: DefaultBulkCourseSettings = {...settings,
      courseAuthoringSettings: {
        enabled: updatedValues.courseAuthoringSettings.enabled
      },
      courseNavSettings: {
        enabled: updatedValues.courseNavSettings.enabled,
        useHomeMenu: updatedValues.courseNavSettings.enabled ? updatedValues.courseNavSettings.useHomeMenu : false
      },
      coursePageRatingSettings: {
        enabled: updatedValues.coursePageRatingSettings.enabled
      }};
    setSettings(newSettings);
    const updatedMeta: AccountMeta = !!account._meta ?
    {...account._meta, defaultCourseBulkSettings: newSettings} :
    new AccountMeta({accountId: account.id, defaultCourseBulkSettings: newSettings});
    dispatcher(updateAccountMeta(account.id, updatedMeta));
  };

  return <Fragment>
    {displayOptions && showSubAccounts &&     
      <div className='collapsibleSubAccounts' style={{ marginLeft: `calc(${depthLevel}em + 33px)` }} >
        <div className='TechnologyPresets'>{t('TechnologyPresets')}</div>
        <div className='accountDescription'>{t('AccountDescription')}</div>
        <form >
          <EnhancedAuthoringSettings
            show={hasAuthoringFeature}
            control={control}
            id={'courseAuthoringSettings'} 
            defaultValue={courseAuthoringSettings}
            isToggle={true}       
            handleSwitch={handleChange}
          />

          <EnhancedNavSettings
            show={hasNavigationFeature}
            control={control}
            id={'courseNavSettings'}
            defaultValue={courseNavSettings}
            readOnlyHomeMenu={!navIsChecked}    
            isToggle={true}
            handleSwitch={handleChange}
          />

          <EnhancedPageRatingSettings
            show={hasPageRatingFeature}
            control={control}
            id={'coursepageRatingSettings'}
            defaultValue={coursepageRatingSettings}
            isToggle={true}          
            handleSwitch={handleChange} 
          />
        </form>
      </div>
    }
  </Fragment>;
};

export default React.memo(AccountDropDownItem);
