import { useRef, useEffect } from 'react';
import { getDataTable, getDataTableData, getFilteredRows, getSortedFilteredRows, getColumnDefs,
  getPinnedLeftColumnDefs, getCenterColumnDefs, getColumnIndices, getDataTableSorts,
  getDataTableFilters, getRowIdProperty, getDataTableSelections, getDataTableConfig,
  getDefaultRowHeight, getDataTableColumnVisibility, getVisibleColumnDefs, getVisibleLeftColumnDefs,
  getVisibleCenterColumnDefs, getTotalPinnedLeftWidth, getTotalCenterWidth } from '../store/data-table/selectors';
import { DataTableState } from '../store/data-table/reducer';
import { DataTableConfig, ColumnDef, DataTableSorts, DataTableFilters, DataTableSelections,
  DataTableColumnVisibility, RowHeights, RowPositions, RowHeightUpdate, ScrollPosition,
  ElementDimensions, DataTableColumnIndices, RowsToRenderResult } from '../types';
import * as utils from '../util';
import { BehaviorSubject, Observable, combineLatest, Subject } from 'rxjs';
import { map, filter, bufferTime, tap, withLatestFrom, debounceTime, share } from 'rxjs/operators';
import { useSelectorWithPropsObservable } from './useSelectorWithPropsObservable.ts';
import { setRowsSelected } from '../store/data-table/actions';
import { useDispatch } from 'react-redux';

export interface DataTableObservables {
  table$: Observable<DataTableState>,
  data$: Observable<any[]>,
  config$: Observable<DataTableConfig>,
  columnDefs$: Observable<ColumnDef[]>,
  sortedFilteredRows$: Observable<any[]>,
  rowIdProperty$: Observable<string>,
  sorts$: Observable<DataTableSorts>,
  filters$: Observable<DataTableFilters>,
  selections$: Observable<DataTableSelections>,
  columnIndices$: Observable<DataTableColumnIndices>,
  columnVisibility$: Observable<DataTableColumnVisibility>,
  visibleColumnDefs$: Observable<ColumnDef[]>,
  visibleLeftColumnDefs$: Observable<ColumnDef[]>,
  visibleCenterColumnDefs$: Observable<ColumnDef[]>,
  centerColumnDefs$: Observable<ColumnDef[]>,
  pinnedLeftColumnDefs$: Observable<ColumnDef[]>,
  bodyViewportScrollPosition$: BehaviorSubject<ScrollPosition>,
  centerColumnsScrollPosition$: BehaviorSubject<ScrollPosition>,
  viewboxDimensions$: BehaviorSubject<ElementDimensions>,
  defaultRowHeight$: Observable<number>,
  rowHeights$: BehaviorSubject<RowHeights>,
  rowHeightUpdate$: Subject<RowHeightUpdate>,
  rowHeightsReset$: Subject<1>,
  totalHeight$: Observable<number>,
  totalPinnedLeftWidth$: Observable<number>,
  totalCenterWidth$: Observable<number>,
  rowPositions$: Observable<RowPositions>,
  rowsToRender$: Observable<any[]>,
  focusedHeaderCell$: Subject<HTMLDivElement>,
}

export const useDataTableObservables = (tableId: string): DataTableObservables => {
  const dispatch = useDispatch();

  // Observables based off of Redux state selectors
  const table$: Observable<DataTableState> = useSelectorWithPropsObservable(getDataTable, { tableId });
  const data$: Observable<any[]> = useSelectorWithPropsObservable(getDataTableData, { tableId });
  const config$: Observable<DataTableConfig> = useSelectorWithPropsObservable(getDataTableConfig, { tableId });
  const sorts$: Observable<DataTableSorts> = useSelectorWithPropsObservable(getDataTableSorts, { tableId });
  const filters$: Observable<DataTableFilters> = useSelectorWithPropsObservable(getDataTableFilters, { tableId });
  const filteredRows$: Observable<any[]> = useSelectorWithPropsObservable(getFilteredRows, { tableId });
  const sortedFilteredRows$: Observable<any[]> = useSelectorWithPropsObservable(getSortedFilteredRows, { tableId });
  const columnDefs$: Observable<any[]> = useSelectorWithPropsObservable(getColumnDefs, { tableId });
  const pinnedLeftColumnDefs$: Observable<ColumnDef[]> = useSelectorWithPropsObservable(getPinnedLeftColumnDefs, { tableId });
  const centerColumnDefs$: Observable<ColumnDef[]> = useSelectorWithPropsObservable(getCenterColumnDefs, { tableId });
  const columnIndices$: Observable<DataTableColumnIndices> = useSelectorWithPropsObservable(getColumnIndices, { tableId });
  const rowIdProperty$: Observable<string> = useSelectorWithPropsObservable(getRowIdProperty, { tableId });
  const selections$: Observable<DataTableSelections> = useSelectorWithPropsObservable(getDataTableSelections, { tableId });
  const defaultRowHeight$: Observable<number> = useSelectorWithPropsObservable(getDefaultRowHeight, { tableId });
  const columnVisibility$: Observable<DataTableColumnVisibility> = useSelectorWithPropsObservable(getDataTableColumnVisibility, { tableId });
  const visibleColumnDefs$: Observable<ColumnDef[]> = useSelectorWithPropsObservable(getVisibleColumnDefs, { tableId });
  const visibleLeftColumnDefs$: Observable<ColumnDef[]> = useSelectorWithPropsObservable(getVisibleLeftColumnDefs, { tableId });
  const visibleCenterColumnDefs$: Observable<ColumnDef[]> = useSelectorWithPropsObservable(getVisibleCenterColumnDefs, { tableId });
  const totalPinnedLeftWidth$: Observable<number> = useSelectorWithPropsObservable(getTotalPinnedLeftWidth, { tableId });
  const totalCenterWidth$: Observable<number> = useSelectorWithPropsObservable(getTotalCenterWidth, { tableId });

  // Subjects for render-related dimensions / positions
  const bodyViewportScrollPosition$ = useRef(new BehaviorSubject({ top: 0, left: 0 })).current;
  const centerColumnsScrollPosition$ = useRef(new BehaviorSubject({ top: 0, left: 0 })).current;
  const viewboxDimensions$ = useRef(new BehaviorSubject({ height: 0, width: 0 })).current;
  const rowHeightUpdate$ = useRef(new Subject<RowHeightUpdate>()).current;
  const rowHeights$ = useRef(new BehaviorSubject<RowHeights>({})).current;
  const rowHeightsReset$ = useRef(new Subject<1>()).current;
  const focusedHeaderCell$ = useRef(new Subject<HTMLDivElement>()).current;
  // const lastRenderedIndex$ = useRef(new Subject<number>()).current;

  const rowPositions$: Observable<RowPositions> = useRef(combineLatest([sortedFilteredRows$, rowHeights$, rowIdProperty$, defaultRowHeight$]).pipe(
    map(([rows, rowHeights, rowIdProperty, defaultRowHeight]: [any[], any, string, number]) => {
      const positions = utils.getRowPositions(rows, rowHeights, rowIdProperty, defaultRowHeight);
      return positions;
    }),
    share()
  )).current;

  const totalHeight$: Observable<number> = useRef(combineLatest([rowPositions$, defaultRowHeight$]).pipe(
    map(([rowPositions, defaultRowHeight]: [any, number]) => {
      return utils.getTotalHeight(rowPositions, defaultRowHeight);
    }),
    share()
  )).current;

  const rowsToRender$: Observable<any[]> = useRef(combineLatest([sortedFilteredRows$, bodyViewportScrollPosition$, viewboxDimensions$, rowPositions$, rowIdProperty$, defaultRowHeight$]).pipe(
    map(([rows, bodyViewportScrollPosition, viewboxDimensions, rowPositions, rowIdProperty, defaultRowHeight]: any) => {
      return utils.getRowsToRender(rows, rowPositions, rowIdProperty, bodyViewportScrollPosition.top, viewboxDimensions, defaultRowHeight);
    }),
    // tap((result: RowsToRenderResult) => lastRenderedIndex$.next(result.lastIndexToRender)),
    map((result: RowsToRenderResult) => result.rowsToRender)
  )).current;

  // Update rowHeights when rowHeightUpdate emits a new update
  useEffect(() => {
    const sub = rowHeightUpdate$.pipe(
      bufferTime(100),
      filter(x => x.length > 0),
      withLatestFrom(rowHeights$),
      tap(([updates, rowHeights]: [RowHeightUpdate[], RowHeights]) => {
        let hasChange = false;
        updates.forEach((update: RowHeightUpdate) => {
          if (!rowHeights[update.rowId]) {
            rowHeights[update.rowId] = { left: undefined, center: undefined };
          }
          if (rowHeights[update.rowId][update.container] !== update.height) {
            rowHeights[update.rowId][update.container] = update.height;
            hasChange = true;
          }
        });
        if (hasChange) {
          rowHeights$.next(rowHeights);
        }
      })
    ).subscribe();
    return () => sub.unsubscribe();
  }, [rowHeightUpdate$, rowHeights$]);

  // Clear the rowHeights when rowHeightsReset emits
  useEffect(() => {
    const sub = rowHeightsReset$.pipe(
      tap(() => rowHeights$.next({}))
    ).subscribe();
    return () => sub.unsubscribe();
  }, [rowHeightsReset$, rowHeights$]);

  // Reset rowHeights when the columnVisibility changes
  useEffect(() => {
    const sub = combineLatest([columnVisibility$, sorts$]).pipe(
      tap(() => rowHeightsReset$.next(1))
    ).subscribe();
    return () => sub.unsubscribe();
  }, [columnVisibility$, rowHeightsReset$, sorts$]);

  // Remove selections on any rows that have been filtered out
  useEffect(() => {
    const sub = combineLatest([filteredRows$, rowIdProperty$, selections$]).pipe(
      debounceTime(300),
      tap(([filteredRows, rowIdProperty, selections]: [any[], string, DataTableSelections]) => {
        const rowIdsToDeselect = utils.getSelectedRowIdsNotInFilter(filteredRows, selections, rowIdProperty);
        if (rowIdsToDeselect.length > 0) {
          dispatch(setRowsSelected(tableId, rowIdsToDeselect, false, false));
        }
      })
    ).subscribe();
    return () => sub.unsubscribe();
  }, [filteredRows$, selections$, rowIdProperty$, dispatch, tableId]);

  const api = {
    table$,
    data$,
    config$,
    columnDefs$,
    sortedFilteredRows$,
    rowIdProperty$,
    sorts$,
    filters$,
    selections$,
    columnIndices$,
    columnVisibility$,
    visibleColumnDefs$,
    visibleLeftColumnDefs$,
    visibleCenterColumnDefs$,
    centerColumnDefs$,
    pinnedLeftColumnDefs$,
    bodyViewportScrollPosition$,
    centerColumnsScrollPosition$,
    viewboxDimensions$,
    defaultRowHeight$,
    rowHeights$,
    rowHeightUpdate$,
    rowHeightsReset$,
    totalHeight$,
    totalPinnedLeftWidth$,
    totalCenterWidth$,
    rowPositions$,
    rowsToRender$,
    focusedHeaderCell$,
  };

  return api;
};
