import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { every, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ColumnDef } from 'modules/data-table/types';
import { setColumnsVisible } from 'modules/data-table/store/data-table/actions';
import { getToggleableColumnDefs, getDataTableColumnVisibility } from 'modules/data-table/store/data-table/selectors';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';

interface IColumnSelect {
  tableId: string;
}

export const ColumnSelect: FC<IColumnSelect> = ({
  tableId
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const toggleableColumnDefs = useSelectorWithProps(getToggleableColumnDefs, { tableId });
  const columnVisibility = useSelectorWithProps(getDataTableColumnVisibility, { tableId });

  const allAreVisible = useMemo(() => {
    return every(toggleableColumnDefs, (c: ColumnDef) => !!columnVisibility[c.columnId]);
  }, [toggleableColumnDefs, columnVisibility]);

  const handleDropdownToggleClick = useCallback((shouldOpen: boolean, e: any, metadata: { source: string }) => {
    if (metadata?.source === 'select') {
      // keep dropdown open if the toggle command source is from an item within the dropdown being clicked
      setOpen(true);
    } else {
      setOpen(shouldOpen);
    }
  }, [setOpen]);

  const handleColumnToggleClick = useCallback((columnId: string) => {
    const currentlyVisible = !!columnVisibility[columnId];
    dispatch(setColumnsVisible(tableId, [columnId], !currentlyVisible, false));
  }, [dispatch, columnVisibility, tableId]);

  const handleAllClick = useCallback(() => {
    const allIds = map(toggleableColumnDefs, 'columnId');
    // If all columns are visible the all columns toggle should hide all instead of show all
    dispatch(setColumnsVisible(tableId, allIds, !allAreVisible, false));
  }, [dispatch, toggleableColumnDefs, tableId, allAreVisible]);

  return (
    <div className='column-select-container'>
      <Dropdown className='nav-item' show={open} focusFirstItemOnShow='keyboard' onToggle={handleDropdownToggleClick} alignRight>
        <Dropdown.Toggle id={`${tableId}-column-select`} aria-label='Show or hide columns'>
          { t('columns') }
        </Dropdown.Toggle>
        <Dropdown.Menu role='menu'>
          <Dropdown.Item
            key='changeAll'
            as='button'
            role='menuitemcheckbox'
            className='column-select-button all-columns-button'
            aria-checked={allAreVisible}
            aria-label={`${allAreVisible ? t('hideAll') : t('showAll')} Columns`}
            onClick={handleAllClick}
          >
            <span className='column-select-icon-wrapper'>
              {allAreVisible ? <FontAwesomeIcon icon={['fas', 'check-square']} className='column-checked' /> : <FontAwesomeIcon icon={['far', 'square']} className='column-unchecked' />}
            </span>
            { allAreVisible ? t('hideAll') : t('showAll') }
          </Dropdown.Item>
          {toggleableColumnDefs.map((c: ColumnDef) => {
            const visible = !!columnVisibility[c.columnId];
            return (
              <Dropdown.Item
                key={c.columnId}
                as='button'
                role='menuitemcheckbox'
                aria-checked={visible}
                className='column-select-button'
                onClick={() => handleColumnToggleClick(c.columnId)}
                aria-label={`${visible ? 'Hide' : 'Show'} column: ${c.headerName || c.columnId}`}
              >
                <span className='column-select-icon-wrapper'>
                  {visible ? <FontAwesomeIcon icon={['fas', 'check-square']} className='column-checked' /> : <FontAwesomeIcon icon={['far', 'square']} className='column-unchecked' />}
                </span>
                {c.headerName || c.columnId}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
