import React, { RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { TFunction } from 'i18next';

interface IDragResizeHandle {
  onClick: (e: MouseEvent) => void;
  onDrag: (e: MouseEvent) => void;
  onArrowLeft: () => void;
  onArrowRight: () => void;
  width: number;
  t: TFunction;
}

// exported in case it must be focused programatically
export const DRAG_RESIZE_HANDLE_CLASSNAME = 'drag-resize-handle';

export class DragResizeHandle extends React.Component<IDragResizeHandle, any> {
  buttonRef: RefObject<HTMLButtonElement>;

  constructor(props: IDragResizeHandle) {
    super(props);
    this.state = {
      mouseDown: false,
      focused: false
    };
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.buttonRef = React.createRef<HTMLButtonElement>();
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('keydown', this.handleKeyDown);
    this.buttonRef.current?.addEventListener('focus', this.handleFocus);
    this.buttonRef.current?.addEventListener('blur', this.handleBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('keydown', this.handleKeyDown);
    this.buttonRef.current?.removeEventListener('focus', this.handleFocus);
    this.buttonRef.current?.removeEventListener('blur', this.handleBlur);
  }

  handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { onClick } = this.props;
    if (!onClick) {
      return;
    }
    onClick(e.nativeEvent);
  }

  handleMouseMove(e: MouseEvent) {
    const { mouseDown } = this.state;
    const { onDrag } = this.props;

    if (!mouseDown) {
      return;
    }
    onDrag(e);
  }

  handleMouseUp() {
    this.setState(() => ({
      mouseDown: false
    }));
    document.body.style.cursor = 'default';
  }

  handleMouseDown() {
    this.setState(() => ({
      mouseDown: true
    }));
    document.body.style.cursor = 'col-resize';
  }

  handleFocus() {
    this.setState(() => ({
      focused: true
    }));
  }

  handleBlur() {
    this.setState(() => ({
      focused: false
    }));
  }

  handleKeyDown(e: KeyboardEvent) {
    const { focused } = this.state;
    const { onArrowLeft, onArrowRight } = this.props;

    if (!focused) {
      return;
    }
    if (e.key === 'ArrowLeft') {
      onArrowLeft();
    }
    if (e.key === 'ArrowRight') {
      onArrowRight();
    }
  }

  render() {
    const { mouseDown } = this.state;
    const { width, t } = this.props;

    return (
      <button
        ref={this.buttonRef}
        className={cn(DRAG_RESIZE_HANDLE_CLASSNAME, { 'mouse-down': mouseDown })}
        onMouseDown={this.handleMouseDown}
        onClick={this.handleClick}
        aria-label={t('resize')}
        style={{ width }}
      >
        <FontAwesomeIcon className='drag-handle-icon' icon={['fas', 'ellipsis-v']} />
      </button>
    );
  }
}
