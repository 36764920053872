import { Observable, concat, of, from } from 'rxjs';
import { concat as _concat, map as _map } from 'lodash';
import { StateObservable, ofType } from 'redux-observable';
import { AppState } from 'store';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { requestStart, requestSuccess, requestError } from 'store/requests/actions';
import InstallationDto from 'models/dto/installationDto';
import { receiveTemplates } from 'store/templates/actions';
import { receiveAccounts, receiveOriginalAccounts } from 'store/accounts/actions';
import { fetchPaginatedCourses } from 'store/courses/actions';
import AccountDto from 'models/dto/accountDto';
import { fetchInstallationData } from './api';
import { InstallationActionTypes, FetchInstallationDataAction, receiveInstallation, receiveConfig, setLmsBaseUrl, setTopAccountId } from './actions';
import Account from 'models/canvas/account';
import { setAccountsExpanded, setActiveAccount } from 'store/account-tree/actions';
import { RequestLabel } from 'constants/index';

export const fetchInstallationDataEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(InstallationActionTypes.FETCH_INSTALLATION_DATA),
  mergeMap((action: FetchInstallationDataAction) => {
    const label = RequestLabel.FETCH_INSTALLATION_DATA;
    const start = requestStart(label);
    const { requestUuid } = start.payload;
    return concat(
      of(start),
      from(fetchInstallationData()).pipe(
        switchMap((installationDto: InstallationDto) => {
          const topAccountDto: AccountDto = installationDto?.topAccount;
          const subAccounts: Account[] = _map(installationDto?.subAccounts, (dto: AccountDto) => dto.toAccount());
          const lmsBaseUrl: string = installationDto?.lmsBaseUrl;
          const topAccount: Account = topAccountDto.toAccount();
          const topAccountId: Account['id'] = topAccount?.id;
          return concat(
            // Start fetching courses with pagination now that the rest of installation data is loaded
            of(setTopAccountId(topAccountId)),
            of(setAccountsExpanded([topAccountId], true)),
            of(setActiveAccount(topAccountId)),
            of(fetchPaginatedCourses(topAccountId)),
            of(setLmsBaseUrl(lmsBaseUrl)),
            of(receiveInstallation(installationDto.installation)),
            of(receiveTemplates(installationDto.courseTemplates)),
            of(receiveConfig(installationDto.config)),
            of(receiveAccounts(_concat(topAccount, subAccounts))),
            of(receiveOriginalAccounts(_concat(topAccount, subAccounts))),
            of(requestSuccess(requestUuid))
          );
        }),
        catchError((err: Error) => of(requestError(requestUuid, err)))
      )
    );
  })
);
