import ITheme from 'styles/interfaces/theme';

const DefaultTheme: ITheme = {
  color: {
    main: '#0092AA',
    // mainDark: '#007D92',
    /* gradient targets: */
    mainDark: 'rgba(38, 42, 99, 1)',
    mainDark2: 'rgba(15, 34, 76, 1)',
    mainDarkActive: 'rgba(33, 70, 153, 1)',
    mainDarkActive2: 'rgba(43, 48, 117, 1)',

    mainLight: '#EFF7FA',
    mainLightHighlight: '#3E8AC2',

    tableSelectedRow: '#E4E6ED',

    white: '#FFF',
    black: '#111',

    gray1: '#767676',
    gray2: '#898989',
    gray3: '#9B9B9B',
    gray4: '#B5B5B5',
    gray5: '#D9D9D9',
    gray6: '#E6E6E6',
    gray7: '#F2F3F4',
    gray8: '#FBFBFB',

    rows: '#F8F8f8',

    orange: '#F77241',
    green: '#0abca7',
    red: '#B30000',
    lightYellow: 'lightyellow',

    font: '#333',

    dropTargetDraggingOver: '#FFF1EB'
  },

  spacing: {
    listPadding: 25
  },

  shadow: {
    container: '0px 0px 10px #00000040;'
  },

  gradient: {
    container: 'linear-gradient(180deg, #FFFFFF 0%, #D4D4D4 70%, #808080 100%) 0% 0% no-repeat padding-box;'
  },

  font: {
    main: '"Lato", Helvetica, Roboto',
  },

  sizes: {
    progressBar: {
      small: '4px',
      medium: '6px',
      big: '14px',
    }
  }
};

export default DefaultTheme;
