import React, { FC, useContext, memo } from 'react';
import { useTranslation } from 'react-i18next';
import AppMenuContext from '../context/appMenuContext';

export interface IAppMenuDropdownItemProps {
  id: string,
  isMainNav?: boolean
}

/**
 * Classes are saved in an enum here so that the useAppMenuDropdownManager hook
 * can be sure to look for the accurate classnames in the mouse event handler
 * checking if a click is within the app men
 * @see useAppMenuDropdownManager
 */
export enum AppMenuClasses {
  MAIN_ITEM = 'app-menu-item',
  SUB_ITEM = 'app-submenu-item',
  COMPONENT_ITEM = 'app-menu-subcomponent',
  DROPDOWN = 'app-menu-dropdown'
}

/**
 * Component for App Menu dropdown list item.  Can popout to a submenu or a widget component
 * @see {@link IAppMenuDropdownItemProps} for prop information
 */
export const AppMenuDropdownItem: FC<IAppMenuDropdownItemProps> = memo(({
  id,
  isMainNav = false
}) => {
  const { t } = useTranslation();
  
  const { toggleExpandItem, selectMenuItem } = useContext(AppMenuContext);
  const menuItem = selectMenuItem(id);

  /** Will toggle expand sub-menu/component of dropdown item when item's button is clicked */
  const handleExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleExpandItem(menuItem.id);
  };

  return (
    <li className={`${isMainNav ? AppMenuClasses.MAIN_ITEM : AppMenuClasses.SUB_ITEM}`} >
      <button
        className='dropdown-toggle'
        aria-expanded={menuItem.isExpanded}
        aria-haspopup='menu'
        onClick={handleExpand}
        aria-controls={menuItem.id}
        dangerouslySetInnerHTML={{__html:t(menuItem.title)}}
      >
      </button>
      {(menuItem.items?.length > 0 && menuItem.isExpanded) && 
        <ul id={menuItem.id} className={AppMenuClasses.DROPDOWN} >
          {menuItem.items.map((item, index) => {
            return (<AppMenuDropdownItem key={`submenu-${index}`} id={item.id} />);
          })}
        </ul>
      }
      {(!!menuItem.component && menuItem.isExpanded) && 
        <div id={menuItem.id} className={AppMenuClasses.COMPONENT_ITEM} >{menuItem.component}</div>
      }
    </li>
  );
});
