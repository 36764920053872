import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
 0% { opacity: 1}
 30% { opacity: .6}
 40% { opacity: .3}
 100% { opacity: 0}
`;

export const Icon = styled.span`
  ${({ theme }) => css`
    .status-icon {
      margin: 0 .8rem;

      &.status-icon-running {
        color: ${theme.color.green};
      }
      &.status-icon-pending {
        color: ${theme.color.green};
        animation-name: ${pulse};
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
      &.status-icon-unsynced {
        color: ${theme.color.orange};
      }
    }
  `}
`;
