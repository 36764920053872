import { css } from 'styled-components';

export const Tabs = css`
  .nav-tabs {
    .nav-item {
      &.nav-link:not(.active) {
        color: ${({ theme }) => theme.color.mainDark};
      }
    }
  }
  .tab-content {
    .tab-pane {
      padding: 1rem;
    }
  }
`;
