import React from 'react';
import { CustomIconButton } from './style';

export interface IIconButtonProps {
  ariaLabel?: string,
  children?: any,
  color?: 'primary' | 'secondary',
  disabled?: boolean,
  onClick?: (e: any) => void,
  rotate?: number
  theme?: any,
  squared?: boolean,
  selected?: boolean,
  testId?: string
}

const IconButton: React.FC<IIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = React.memo(({
    ariaLabel,
    children,
    color = 'primary',
    disabled,
    onClick,
    rotate = 0,
    squared = false,
    selected = false,
    testId,
    ...otherProps
}) => (
  <CustomIconButton
    aria-label={ariaLabel}
    data-testid={testId}
    color={color}
    disabled={disabled}
    onClick={onClick}
    rotate={rotate}
    type='button'
    squared={squared}
    selected={selected}
    {...otherProps}
  >
    {children}
  </CustomIconButton>
));

export default IconButton;
