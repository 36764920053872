import styled, { css } from 'styled-components';
import { srOnly } from 'styles/classes';

interface IEditableProps {
  visible?: boolean;
}

export const Wrapper = styled.span`
  align-items: center;
  display: inline-flex;
  pointer-events: all;
`;

export const Placeholder = styled.button<IEditableProps>`
  background: transparent;
  border: 0;
  border-bottom: 2px solid transparent;
  cursor: text;
  padding: 0;
  text-align: left;

  ${({ visible }) => visible && css` ${srOnly}`};

  &:hover,
  &:focus {
    border-bottom: 2px solid ${({ theme }) => theme.color.mainDark};
  }
`;

export const InlineInput = styled.input<IEditableProps>`
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.color.mainDark};
  color: ${({ theme }) => theme.color.font};
  font: 500 14px/25px ${({ theme }) => theme.font.main};
  height: 20px;
  margin-bottom: -2px;
  max-width: 250px;
  min-width: 20px;
  padding: 0;

  ${({ visible }) => !visible && css` ${srOnly}`};
`;
