// @ts-nocheck
import styled, { css, keyframes } from 'styled-components';
import Modal, { BaseModalBackground } from 'styled-react-modal';

const keyFrameExampleOne = keyframes`
  0% { top: -100px; }
  100% { top: 0; }
`;

export const Wrapper = Modal.styled`
  ${({ size, theme }) => css`
    animation: ${keyFrameExampleOne} .5s cubic-bezier(0.16, 1, 0.3, 1) 0s;
    background-color: white;
    border-radius: 0 0 4px 4px;
    box-shadow: ${theme.shadow.container};
    cursor: auto;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: ${size === 'big' ? '95vw' : '700px'}
  `}
`;

export const Background = styled(BaseModalBackground)`
  cursor: pointer;
  display: block;
`;
