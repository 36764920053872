import React, { useCallback, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Course from 'models/canvas/course';
import { toggleModal } from 'store/modals/actions';
import { getCourseUrl, getCourseExistsInLmsAlready } from 'store/courses/selectors';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { PopoverButton, Button } from '..';
import { ModalId } from 'constants/index';

export interface ICourseMenuButtonProps {
  courseId: Course['id'];
}

export const CourseMenuButton: React.FC<ICourseMenuButtonProps> = ({ courseId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const courseExistsAlready = useSelectorWithProps(getCourseExistsInLmsAlready, { courseId });
  const courseUrl: string = useSelectorWithProps(getCourseUrl, { courseId });

  // Opens the course setting modals
  const handleCourseSettingsClick = useCallback((e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(toggleModal(ModalId.COURSE_SETTINGS, true, { courseId }));
  }, [dispatch, courseId]);

  return (
    <PopoverButton
      className='course-menu-button'
      testId={`course-${courseId}-menu-button`}
      preferredPosition={['top']}
      isIconButton
      aria-label='Course Menu'
      popoverContent={(
        <ul data-testid={`course-${courseId}-menu-content`}>
          <li>
            <Button
              className='course-link'
              as={courseExistsAlready ? 'a' : 'button'}
              disabled={!courseExistsAlready}
              variant='link'
              color='white'
              size='big'
              ariaLabel='Course Settings'
              href={courseUrl}
              target='_blank'
            >
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
              {t('courseGoTo')}
            </Button>
          </li>

          <li>
            <Button
              className='course-settings-button'
              variant='link'
              color='white'
              size='big'
              onClick={handleCourseSettingsClick}
            >
              <FontAwesomeIcon icon={['fas', 'location-arrow']} />
              {t('courseSettings')}
            </Button>
          </li>
        </ul>
      )}
    >
      <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
    </PopoverButton>
  );
};
