import styled, { css } from 'styled-components';

interface IAppToggleTabsContainer {}

interface IToggleTabListItem {
  active: boolean;
}

export const AppToggleTabsContainer = styled.nav`
  ${({ theme }) => css`
    &.app-toggle-tabs-container {
      height: 100%;
      margin-left: auto;

      .toggle-tabs {
        background: ${theme.color.white};
        display: flex;
        height: 100%;
        margin: 0;
        list-style: none;
        padding: 0;
      }
    }

  `}
`;

export const ToggleTabListItem = styled.li<IToggleTabListItem>`
  ${({ theme, active }) => css`
    position: relative;

    &.toggle-tab-item {
      height: 100%;
      margin-left: 2px;

      &&${active && css`
        .toggle-tab-button {
          outline: 0;
          color: ${theme.color.white};

          &:after {
            height: 100%;
          }
        }
      `}
      
      .toggle-tab-button {
        align-items: center;
        background: ${theme.color.gray7};
        border: 0;
        color: ${theme.color.gray3};
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        position: relative;
        transition: all .2s ease;
        width: 3em;

        &:after {
          background: ${theme.color.mainDark2};
          bottom: 0;
          content: "";
          display: block;
          height: 0;
          left: 0;
          position: absolute;
          transition: all .4s ease-in-out;
          width: 100%;
        }

        &:hover, &:focus, &:active {
          color: ${theme.color.font};
        }

        > * {
          z-index: 1;
        }
      }
    }
  `}
`;
