import React, { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Select, { Styles, ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { filter, forEach, find, isFunction } from 'lodash';

import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import Account from 'models/canvas/account';
import { getAccountAncestorMap, getAccountById, getAllAccountsSortedAlphabetically } from 'store/accounts/selectors';
import { getTopAccountId } from 'store/installation/selectors';
import { AccountSelectWrapper } from './style';
import { NewAccountPopoverContent } from './NewAccountPopoverContent/index';
import { toast } from 'helpers/messages';
import ITheme from 'styles/interfaces/theme';
import { useTheme } from 'styled-components';
import { Popover } from '../Popover';
import { customSelectStyles } from '../shared-style';
import { SelectComponentsProps } from 'react-select/src/Select';

interface IAccountSelect {
  allowCreate?: boolean;
  onChange: (selectedAccountId: Account['id']) => void;
  onCreate?: (tempAccountId: Account['id'], newAccount: Account) => void;
  labelText?: string | ReactNode;
  size?: 'small' | 'big';
  defaultValue?: number | string;
  value?: number | string;
  name?: string;
  id?: string;
}

interface AccountSelectOption {
  label: string;
  value: Account['id'];
}

const CREATE_NEW_ACCOUNT_VALUE = 'CREATE_NEW_ACCOUNT';

/**
 * Displays an improved select filled with all the accounts. You can set the default
 * account selected, the label and the size
 */
const AccountSelect: FC<IAccountSelect & SelectComponentsProps> = ({
  allowCreate = false,
  onChange,
  onCreate,
  name,
  labelText,
  size = 'big',
  defaultValue = null,
  value = null,
  id,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme() as ITheme;
  const accountSelectWrapperRef = useRef<HTMLDivElement>(null);
  const [showNewAccountPopover, setShowNewAccountPopover] = useState<boolean>(false);
  const topAccountId: Account['id'] = useSelector(getTopAccountId);
  const topAccount: Account = useSelectorWithProps(getAccountById, { accountId: topAccountId });
  const allAccounts: Account[] = useSelector(getAllAccountsSortedAlphabetically);
  const accountAncestorsMap: { [accountId: string]: string[] } = useSelector(getAccountAncestorMap);

  // Creates all options to fill the account selector
  const makeOption = useCallback((account: Account): AccountSelectOption => ({
    label: `- ${account?.name}`,
    value: account?.id
  }), []);

  const optionsList = useMemo(() => {
    const options: AccountSelectOption[] = [];

    if (allowCreate) {
      options.push({ label: `* ${t('createNewAccount')}...`, value: CREATE_NEW_ACCOUNT_VALUE });
    }

    const addAccountOptions = (account: Account) => {
      options.push(makeOption(account));
      const childAccounts = filter(allAccounts, (a: Account) => a.parent_account_id === account?.id);
      forEach(childAccounts, addAccountOptions); // recursive call
    };
    addAccountOptions(topAccount);

    return options;
  }, [topAccount, allAccounts, makeOption, allowCreate, t]);
  interface Styles {}
  const customStyles: Partial<Styles> = useMemo(() => ({
    ...customSelectStyles({ size, theme }),
    option: (provided: { backgroundColor: any; color: any; fontStyle: any; }, state: { data: { value: any; }; isSelected: any; }) => {
      const accountId = state?.data?.value;
      const isCreateOption = accountId === CREATE_NEW_ACCOUNT_VALUE;
      const depthLevel = (accountAncestorsMap?.[accountId]?.length || 0) - 1;
      return {
        ...provided,
        backgroundColor: state?.isSelected ? theme.color.mainDark : provided.backgroundColor,
        color: isCreateOption && !state?.isSelected ? theme.color.mainDark : provided.color,
        fontStyle: isCreateOption ? 'italic' : provided.fontStyle,
        paddingLeft: `${depthLevel * 2 + 1}rem`
      };
    }
  }), [size, theme, accountAncestorsMap]);

  // Find the option object that represents the default value account id
  const defaultValueOption = useMemo(() => {
    return find(optionsList, (option: AccountSelectOption) => option.value === defaultValue);
  }, [optionsList, defaultValue]);

  // Find the option object that represents the current value account id
  const valueOption = useMemo(() => {
    return find(optionsList, (option: AccountSelectOption) => option.value === value) || defaultValueOption;
  }, [optionsList, value, defaultValueOption]);

  // Call the change handler with the selected option object's 'value' property
  const handleChange = useCallback((selectedOpt: ValueType<AccountSelectOption, any>) => {
    const selectedValue = (selectedOpt as AccountSelectOption)?.value;
    if (selectedValue === CREATE_NEW_ACCOUNT_VALUE) {
      setShowNewAccountPopover(true);
    } else {
      onChange(selectedValue);
    }
  }, [onChange]);

  const handleNewAccountCreate = useCallback((tempAccountId: string, newAccount: Account) => {
    if (isFunction(onCreate)) {
      onCreate(tempAccountId, newAccount);
    }
    setShowNewAccountPopover(false);
    toast.success(t('toastNewAccountCreated', { name: newAccount?.name }));
  }, [onCreate, setShowNewAccountPopover, t]);

  return (
    <AccountSelectWrapper
      className='account-select-wrapper'
      data-testid='account-select-wrapper'
      ref={accountSelectWrapperRef}
    >
      {
        labelText && <label htmlFor={id}>{labelText}</label>
      }
      <Select
        name={name}
        defaultValue={defaultValueOption}
        id={id}
        menuPortalTarget={document.body}
        onChange={handleChange}
        options={optionsList}
        styles={customStyles}
        placeholder={`${t('select')}...`}
        value={valueOption}
        {...otherProps}
      />

      <Popover
        id='new-account-popover'
        show={showNewAccountPopover}
        targetRef={accountSelectWrapperRef}
        title={t('createNewAccount')}
      >
        <NewAccountPopoverContent
          id='new-account-popover-content'
          onCreate={handleNewAccountCreate}
          onCancel={() => setShowNewAccountPopover(false)}
        />
      </Popover>
    </AccountSelectWrapper>
  );
};

export default AccountSelect;
