import { FieldSet } from 'components/general-styles';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  .selected-target-courses {
    font-weight: 700;
    margin-bottom: .7em;

    .expand-target-courses-button {
      display: inline-block;
      .expand-icon {
        transform: rotate(-90deg);
  
        &.expanded {
          transform: rotate(0deg);
        }
      }
    }

    .target-courses-list {
      font-weight: normal;
      margin: .5em 1em 0 2em;
      max-height: 190px;
      overflow-y: scroll;

      .target-course-list-item {
        list-style: none;
        margin: 0 0 .3em 2em;
        text-indent: -1.5em;

        .course-icon {
          margin-right: .5em;
        }
        .sis-id, .term-name {
          margin-left: .5em;
        }
      }
    }
  }

  ${FieldSet} {
    max-width: 500px;
    input, select {
    }
  }
`;
