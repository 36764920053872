import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import Course from 'models/canvas/course';
import CourseMeta from 'models/courseMeta';
import CourseTemplate from 'models/courseTemplate';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getTemplateById } from 'store/templates/selectors';
import { getCourseById } from 'store/courses/selectors';

interface ICourseSourceDisplayProps {
  courseId: Course['id'];
}

const CourseSourceDisplay: React.FC<ICourseSourceDisplayProps> = ({ courseId }) => {
  const { t } = useTranslation();
  const course: Course = useSelectorWithProps(getCourseById, { courseId }); 
  const courseTemplateId: CourseMeta['courseTemplateId'] = course?._meta?.newCourseTemplateId || course?._meta?.courseTemplateId;  
  const template: CourseTemplate = useSelectorWithProps(
    getTemplateById,
    { courseTemplateId }
  );
  return (
    <div className='course-source'>
      {course?._meta?.sourceCourse?.name ? course?._meta?.sourceCourse?.name : (template ? template?.name : `${t('unknown')}`)}
    </div>
  );
};

export default React.memo(CourseSourceDisplay);
