import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Control, Controller, useWatch } from 'react-hook-form';
import Collapse from 'react-smooth-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { InstallationConfigKey } from 'constants/index';
import { toast } from 'helpers/messages';

import { TermsFeatureState } from 'store/terms/reducer';
import { getAllCourseTags } from 'store/courses/selectors';
import { getTermItems } from 'store/terms/selectors';
import { useInstallationConfig } from 'hooks/useInstallationConfig';

import Account from 'models/canvas/account';
import Course from 'models/canvas/course';
import EnrollmentTerm from 'models/canvas/enrollmentTerm';

import EnhancedNavSettings from 'components/elements/EnhancedNavSettings';
import EnhancedAuthoringSettings from 'components/elements/EnhancedAuthoringSettings';
import EnhancedPageRatingSettings from 'components/elements/EnhancedPageRatingSettings';
import { AccountSelect, Button } from 'components/elements';
import { DatePicker } from 'components/elements/Datepicker';
import { FieldSet } from 'components/general-styles';
import { TagsInput } from 'components/elements/TagsInput';
import { TermSelect } from 'components/elements/TermSelect';

import { ISourceCourseSettingsFormVals } from './SourceCourse';
import { DuplicatedCourseItem } from './style';

interface IDuplicateCourse {
  settings: ISourceCourseSettingsFormVals;
  sourceCourse: Course;
  suffix: string;
  index: number,
  modalControl: Control,
  fieldPrefix: string;
  removeDuplicateCourse: () => void;
}

const DuplicateCourse: React.FC<IDuplicateCourse> = ({
  settings,
  sourceCourse,
  suffix,
  index,
  fieldPrefix,
  modalControl,
  removeDuplicateCourse
}) => {
  const { t } = useTranslation();

  const hasNavigationFeature = useInstallationConfig(InstallationConfigKey.NAV_SETTINGS_FEATURE);
  const hasAuthoringFeature = useInstallationConfig(InstallationConfigKey.AUTHORING_FEATURE);
  const hasPageRatingFeature = useInstallationConfig(InstallationConfigKey.PAGE_RATING_FEATURE);

  const navIsChecked: boolean = useWatch({
    control: modalControl,
    name: `${fieldPrefix}.courseNavSettings.enabled`
  });

  const allCourseTags = useSelector(getAllCourseTags);

  const [expanded, setExpanded] = useState(false);
  const termItems: TermsFeatureState['items'] = useSelector(getTermItems);

  const syncDatesToTerm = (term: EnrollmentTerm) => {
    modalControl.setValue(`${fieldPrefix}.startDate`, term?.start_at);
    modalControl.setValue(`${fieldPrefix}.endDate`, term?.end_at);
  };

  const handleRemoveDuplicatedCourse = () => {
    toast.confirm(t('confirmRemoveItem'), removeDuplicateCourse);
  };

  const handleAccountCreate = (tempAccountId: string, newAccount: Account) => {
    modalControl.setValue(`${fieldPrefix}.accountId`, tempAccountId);
  };

  const handleTermCreate = (tempTermId: string, newTerm: EnrollmentTerm) => {
    modalControl.setValue(`${fieldPrefix}.termId`, tempTermId);
    syncDatesToTerm(newTerm);
  };

  // Hacky fix to set the default value of the nav enabled checkbox
  // because otherwise the useHomeMenu checkbox wasn't reading its 'disabled' value properly on the first render
  useEffect(() => {
    const to = setTimeout(() => {
      modalControl.setValue(
        `${fieldPrefix}.courseNavSettings.enabled`,
        !!settings?.courseNavSettings?.enabled
      );

      modalControl.setValue(
        `${fieldPrefix}.courseAuthoringSettings.enabled`,
        !!settings?.courseAuthoringSettings?.enabled
      );

      modalControl.setValue(
        `${fieldPrefix}.coursePageRatingSettings.enabled`,
        !!settings?.coursePageRatingSettings?.enabled
      );
    }, 400);
    return () => clearTimeout(to);
  }, [modalControl, fieldPrefix, settings]);

  return (
    <DuplicatedCourseItem className='duplicated-course-item' id={`duplicated-course-${sourceCourse.id}-${index}`}>
      <span>{index + 1}</span>

      <div className='duplicated-course-settings'>
        <div className='duplicated-course-fields'>
          <input
            type='hidden'
            ref={modalControl.register()}
            name={`${fieldPrefix}.sourceCourseId`}
            value={sourceCourse?.id}
          />
          <FieldSet className='fieldset-name' flex direction='column'>
            <input
              id={`course-${sourceCourse.id}-duplicated-${index}-name`}
              name={`${fieldPrefix}.name`}
              type='text'
              defaultValue={`${sourceCourse?.name}${suffix ? ` (${suffix})` : ''}`}
              ref={modalControl.register()}
              placeholder={t('name')}
            />
          </FieldSet>

          <FieldSet className='fieldset-course-code' flex direction='column'>
            <input
              id={`course-${sourceCourse.id}-duplicated-${index}-code`}
              defaultValue={`${sourceCourse?.course_code}${suffix ? ` (${suffix})` : ''}`}
              name={`${fieldPrefix}.courseCode`}
              type='text'
              ref={modalControl.register()}
              placeholder={t('courseCode')}
            />
          </FieldSet>

          <FieldSet className='fieldset-sis-course-id' flex direction='column'>
            <input
              id={`course-${sourceCourse.id}-duplicated-${index}-sis-course-id`}
              defaultValue=''
              name={`${fieldPrefix}.sisCourseId`}
              placeholder={t('sisId')}
              ref={modalControl.register()}
              type='text'
            />
          </FieldSet>
        </div>
        <div className='duplicated-course-actions'>
          <Button
            variant='link'
            color='secondary'
            onClick={() => setExpanded(prev => !prev)}
            rotate={expanded ? 90 : 0}
            ariaLabel={`${expanded ? 'Hide' : 'Show'} Details`}
          >
            <FontAwesomeIcon icon={['fas', 'angle-right']} />
          </Button>

          <Button
            aria-label={t('remove')}
            variant='link'
            color='secondary'
            onClick={handleRemoveDuplicatedCourse}
            id={`course-${sourceCourse.id}-duplicated-${index}-remove`}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        </div>
      </div>

      <Collapse
        id={`course-${sourceCourse.id}-duplicated-course-${index}-collapse`}
        expanded={expanded}
        heightTransition='.4s ease'
        eagerRender // ensure collapsed fields still mount so that they register in form state
      >
        <div className={cn('duplicated-course-collapse', { expanded })}>
          <div className='fieldset-group'>
            <FieldSet className='fieldset-account-id' flex direction='column'>
              <label htmlFor={`course-${sourceCourse.id}-duplicated-${index}-destination-account`}>{t('destinationAccount')}</label>
              <Controller
                control={modalControl}
                name={`${fieldPrefix}.accountId`}
                defaultValue={settings?.account}
                render={({ name, value, onChange }) => (
                  <AccountSelect
                    allowCreate
                    id={`course-${sourceCourse.id}-duplicated-${index}-destination-account`}
                    defaultValue={value}
                    onChange={onChange}
                    onCreate={handleAccountCreate}
                    name={name}
                    size='small'
                    tabIndex={expanded ? 0 : -1}
                    value={value}
                  />
                )}
              />
            </FieldSet>
          </div>

          <div className='fieldset-group'>
            <FieldSet className='fieldset-term-id' flex direction='column'>
              <label htmlFor={`course-${sourceCourse.id}-duplicated-${index}-destination-term`}>{t('destinationTerm')}</label>
              <Controller
                control={modalControl}
                name={`${fieldPrefix}.termId`}
                defaultValue={settings?.term}
                render={({ name, value, onChange }) => (
                  <TermSelect
                    allowCreate
                    defaultValue={value}
                    id={`course-${sourceCourse.id}-duplicated-${index}-destination-term`}
                    name={name}
                    onChange={(selectedTermId: string) => {
                      syncDatesToTerm(termItems?.[selectedTermId]);
                      onChange(selectedTermId);
                    }}
                    onCreate={handleTermCreate}
                    size='small'
                    tabIndex={expanded ? 0 : -1}
                    value={value}
                  />
                )}
              />
            </FieldSet>
          </div>

          <div className='fieldset-group'>
            <FieldSet className='fieldset-start-date' flex direction='column'>
              <label htmlFor={`course-${sourceCourse.id}-duplicated-${index}-start-date`}>{t('startDate')}</label>
              <Controller
                control={modalControl}
                defaultValue={settings?.startDate || null}
                name={`${fieldPrefix}.startDate`}
                render={({ name, onBlur, onChange, value }) => (
                  <DatePicker
                    defaultValue={value}
                    id={`course-${sourceCourse.id}-duplicated-${index}-start-date`}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('whenever')}
                    value={value}
                  />
                )}
              />
            </FieldSet>

            <FieldSet className='fieldset-end-date' flex direction='column'>
              <label htmlFor={`course-${sourceCourse.id}-duplicated-${index}-end-date`}>{t('endDate')}</label>
              <Controller
                control={modalControl}
                defaultValue={settings?.endDate || null}
                name={`${fieldPrefix}.endDate`}
                render={({ name, onBlur, onChange, value }) => (
                  <DatePicker
                    defaultValue={value}
                    id={`course-${sourceCourse.id}-duplicated-${index}-end-date`}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('whenever')}
                    value={value}
                  />
                )}
              />
            </FieldSet>
          </div>

          <EnhancedAuthoringSettings
            show={hasAuthoringFeature}
            showTitle={false}
            showLabel
            control={modalControl}
            id={`course-${sourceCourse.id}-duplicated-${index}`}
            defaultValue={settings?.courseAuthoringSettings}
            namePrefix={fieldPrefix}
          />

          <EnhancedNavSettings
            show={hasNavigationFeature}
            showTitle={false}
            showLabel
            control={modalControl}
            id={`course-${sourceCourse.id}-duplicated-${index}`}
            readOnlyHomeMenu={!navIsChecked}
            defaultValue={settings?.courseNavSettings}
            namePrefix={fieldPrefix}
          />          

          <EnhancedPageRatingSettings
            show={hasPageRatingFeature}
            showTitle={false}
            showLabel
            control={modalControl}
            id={`course-${sourceCourse.id}-duplicated-${index}`}
            defaultValue={settings?.coursePageRatingSettings}
            namePrefix={fieldPrefix}
          />

          <div className='fieldset-group'>
            <FieldSet className='fieldset-tags' flex direction='column' style={{ maxWidth: '100%' }}>
              <label htmlFor={`course-${sourceCourse.id}-duplicated-${index}-tags`}>{t('tags')}</label>
              <Controller
                control={modalControl}
                name={`${fieldPrefix}.tags`}
                defaultValue={settings?.tags || []}
                render={({ name, value, onChange }) => (
                  <TagsInput
                    id={`course-${sourceCourse.id}-duplicated-${index}-tags`}
                    name={name}
                    onChange={onChange}
                    tabIndex={expanded ? 0 : -1}
                    tags={allCourseTags}
                    value={value}
                  />
                )}
              />
            </FieldSet>
          </div>
        </div>
      </Collapse>
    </DuplicatedCourseItem>
  );
};

export default DuplicateCourse;
