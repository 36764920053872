import styled from 'styled-components';
import { srOnly } from 'styles/classes';
import { pulseAnimation, rippleAnimation } from 'styles/keyframes';

// Configs
const radioSize = '20px';
const radioRippleSize = '10px';

export const Field = styled.div`
  margin: 16px 0;
`;

export const Radio = styled.input`
  ${srOnly}
  &:checked + label:before {
    border-color: ${({ theme }) => theme.color.mainDark};
    animation: ${rippleAnimation(radioRippleSize)} 0.2s linear forwards;   
  }
  &:checked + label:after {
    transform: scale(1);
  }
  &:focus {
    & + label:before {
      border-color: ${({ theme }) => theme.color.mainDark};
      ${pulseAnimation}
    }
  }
`;

export const Label = styled.label`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  min-height: ${radioSize};
  position: relative;
  vertical-align: bottom;
  width: ${radioSize};

  &:before, &:after {
    border-radius: 50%;
    content: '';  
    position: absolute;            
    transition-property: transform, border-color;
    transition: all .3s ease;
  }
  &:before {
    background: ${({ theme }) => theme.color.white};
    border: 2px solid ${({ theme }) => theme.color.gray6};
    height: ${radioSize};
    left: 0;
    top: 0;
    width: ${radioSize};
  }
  &:after {
    background: ${({ theme }) => theme.color.mainDark};
    height: calc(${radioSize} - 8px);
    left: 4px;
    top: 4px;
    transform: scale(0);
    width: calc(${radioSize} - 8px);
  }

  &:hover {
    &:before {
      border-color: ${({ theme }) => theme.color.mainDark};
      ${pulseAnimation}
    }
  }
`;
