import styled, { css } from 'styled-components';

interface IPageRatingReportContainer {}

export const PageRatingReportContainer = styled.div<IPageRatingReportContainer>`
  ${({ theme }) => css`
    &.pageRatingWidget-container {
      width: 40rem;
      h3, button {
        margin: 0;
      }
      h3 {
        font-weight: 400;
      }
      p {
        margin-bottom: 2.5rem;
        svg {
          margin-right: 1rem;
        }
      }
      .pageRatingWidget-statusBar {
        p {
          margin-bottom: 1rem;
        }

      }
    }
  `}
`;