import BaseSyncItemEntity from 'models/syncOrder/baseSyncItemEntity';
import SyncExistingCourse from 'models/syncOrder/syncExistingCourse';
import SyncExistingDupCourse from 'models/syncOrder/syncExistingDupCourse';
import SyncNewAccount from 'models/syncOrder/syncNewAccount';
import SyncNewCourse from 'models/syncOrder/syncNewCourse';
import SyncNewDupCourse from 'models/syncOrder/syncNewDupCourse';
import { values, filter } from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SyncNewTerm from 'models/syncOrder/syncNewTerm';
import SyncExistingAccount from 'models/syncOrder/syncExistingAccount';

export enum ModalId {
  ADD_COURSES = 'add_courses',
  APPLY_FRAMEWORK = 'apply_framework',
  IMPORT_COURSE = 'import_course',
  DUPLICATE_COURSES = 'duplicate_courses',
  COURSE_SETTINGS = 'course_settings',
  BULK_COURSE_SETTINGS = 'bulk_course_settings',
  UPDATE_LMS = 'update_lms',
  MOVE_ITEMS = 'move_items',
  DATA_TABLE_KEYBOARD_INFO = 'data_table_keyboard_info',
  EDIT_COURSE = 'edit_course'
}

export class RequestLabel {
  static CREATE_SYNC_ORDER = 'create_sync_order';
  static FETCH_ACCOUNT = (accountId: string) => `fetch_account_${accountId}`;
  static FETCH_ACCOUNT_CHILDREN = (accountId: string) => `fetch_account_${accountId}_children`;
  static FETCH_ACCOUNT_SUB_ACCOUNTS = (accountId: string) => `fetch_account_${accountId}_sub_accounts`;
  static FETCH_COURSE = (courseId: string) => `fetch_course_${courseId}`;
  static FETCH_INSTALLATION_DATA = 'fetch_installation_data';
  static FETCH_SYNC_ORDER = (syncOrderId: string) => `fetch_sync_order_${syncOrderId}`;
  static FETCH_SYNC_ORDERS = 'fetch_sync_orders';
  static FETCH_SYNC_ORDERS_PAGINATED = 'fetch_sync_orders_paginated';
  static FETCH_TEMPLATES = 'fetch_templates';
  static REFRESH = 'refresh';
}

// Different sync order and items status values
export enum SyncOrderStatusUnsynced {
  UNSYNCED = 'UNSYNCED'
}
export enum SyncOrderStatusCompleted {
  SUCCESS = 'SUCCESS'
}
export enum SyncOrderStatusPending {
  PENDING = 'PENDING',
}
export enum SyncOrderStatusActive {
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
}
export enum SyncOrderStatusError {
  INCOMPLETE = 'INCOMPLETE'
}

export enum SyncItemStatusUnsynced {
  UNSYNCED = 'UNSYNCED'
}
export enum SyncItemStatusCompleted {
  SUCCESS = 'SUCCESS'
}
export enum SyncItemStatusPending {
  PENDING = 'PENDING',
}
export enum SyncItemStatusActive {
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
}
export enum SyncItemStatusError {
  ERROR = 'ERROR',
  PARENT_ACCOUNT_NOT_FOUND = 'PARENT_ACCOUNT_NOT_FOUND',
  APPLY_COURSE_TEMPLATE_FAILED = 'APPLY_COURSE_TEMPLATE_FAILED',
  COURSE_COPY_FAILED = 'COURSE_COPY_FAILED'
}

export type SyncOrderStatus = SyncOrderStatusUnsynced | SyncOrderStatusCompleted | SyncOrderStatusPending | SyncOrderStatusActive | SyncOrderStatusError;
export type SyncItemStatus = SyncItemStatusUnsynced| SyncItemStatusCompleted | SyncItemStatusPending | SyncItemStatusActive | SyncItemStatusError;

export enum PageRatingReportStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  RUNNING = 'RUNNING',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE'
}

export enum ItemTypes {
  ACCOUNT = 'account',
  COURSE = 'course',
  TERM = 'term'
}

// The two main view of the app
export enum CanvasViewTypes {
  TREE = 'tree',
  TABLE = 'table'
}

// Key code values for each key
export enum KeyCodesTypes {
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  ARROW_LEFT = 37,
  ARROW_RIGHT = 39,
  ENTER = 13,
  SHIFT = 16,
  CTRL = 17,
  ALT = 18,
  ESCAPE = 27,
  SPACE = 32,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  END = 35,
  HOME = 36,
  TAB = 9,
  META_LEFT = 91,
  META_RIGHT = 93,
  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  G = 71,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  N = 78,
  O = 79,
  P = 80,
  Q = 81,
  R = 82,
  S = 83,
  T = 84,
  U = 85,
  V = 86,
  W = 87,
  X = 88,
  Y = 89,
  Z = 90
}

export enum KeyNames {
  ENTER = 'Enter',
  ESC = 'Escape',
  SPACE = 'Space'
}

// The moment.js format to use for datepicker value display
export const DATETIME_FORMAT_COMMA = 'MMM D, YYYY h:mma';
export const DATETIME_FORMAT_SLASH = 'MM/DD/YYYY h:mma';
export const TIME_INPUT_FORMAT = 'HH:mm';

export enum InstallationConfigKey {
  NAV_SETTINGS_FEATURE = 'navSettingsFeature',
  AUTHORING_FEATURE = 'authSettingsFeature',
  PAGE_RATING_FEATURE = 'pageRatingSettingsFeature',
  CREATE_TERM_FEATURE = 'createTermFeature'
}

export enum SyncItemType {
  SYNC_NEW_ACCOUNT = 'SYNC_NEW_ACCOUNT',
  SYNC_EXISTING_ACCOUNT = 'SYNC_EXISTING_ACCOUNT',
  SYNC_NEW_COURSE = 'SYNC_NEW_COURSE',
  SYNC_NEW_DUP_COURSE = 'SYNC_NEW_DUP_COURSE',
  SYNC_EXISTING_COURSE = 'SYNC_EXISTING_COURSE',
  SYNC_EXISTING_DUP_COURSE = 'SYNC_EXISTING_DUP_COURSE',
  SYNC_NEW_TERM = 'SYNC_NEW_TERM'
}

export interface ISyncTypeData {
  classType: new (json: any) => BaseSyncItemEntity,
  idProperty: string,
  lmsItemIdProperty: string,
  iconProp: IconProp,
  itemType: ItemTypes,
  isNew: boolean,
}

export const SyncItemTypeData: Record<SyncItemType, ISyncTypeData> = {
  [SyncItemType.SYNC_NEW_ACCOUNT]: {
    classType: SyncNewAccount,
    iconProp: ['far', 'folder'],
    idProperty: 'syncNewAccountId',
    lmsItemIdProperty: 'accountId',
    itemType: ItemTypes.ACCOUNT,
    isNew: true
  },
  [SyncItemType.SYNC_EXISTING_ACCOUNT]: {
    classType: SyncExistingAccount,
    iconProp: ['far', 'folder'],
    idProperty: 'syncExistingAccountId',
    lmsItemIdProperty: 'accountId',
    itemType: ItemTypes.ACCOUNT,
    isNew: false
  },
  [SyncItemType.SYNC_NEW_COURSE]: {
    classType: SyncNewCourse,
    iconProp: ['fas', 'chalkboard-teacher'],
    idProperty: 'syncNewCourseId',
    lmsItemIdProperty: 'courseId',
    itemType: ItemTypes.COURSE,
    isNew: true
  },
  [SyncItemType.SYNC_NEW_DUP_COURSE]: {
    classType: SyncNewDupCourse,
    iconProp: ['fas', 'chalkboard-teacher'],
    idProperty: 'syncNewDupCourseId',
    lmsItemIdProperty: 'courseId',
    itemType: ItemTypes.COURSE,
    isNew: true
  },
  [SyncItemType.SYNC_EXISTING_COURSE]: {
    classType: SyncExistingCourse,
    iconProp: ['fas', 'chalkboard-teacher'],
    idProperty: 'syncExistingCourseId',
    lmsItemIdProperty: 'courseId',
    itemType: ItemTypes.COURSE,
    isNew: false
  },
  [SyncItemType.SYNC_EXISTING_DUP_COURSE]: {
    classType: SyncExistingDupCourse,
    iconProp: ['fas', 'chalkboard-teacher'],
    idProperty: 'syncExistingDupCourseId',
    lmsItemIdProperty: 'courseId',
    itemType: ItemTypes.COURSE,
    isNew: false
  },
  [SyncItemType.SYNC_NEW_TERM]: {
    classType: SyncNewTerm,
    iconProp: ['fas', 'clock'],
    idProperty: 'syncNewTermId',
    lmsItemIdProperty: 'termId',
    itemType: ItemTypes.TERM,
    isNew: true
  }
};

export const SyncItemAccountTypes: ISyncTypeData[] = filter(
  values(SyncItemTypeData),
  (itemTypeData: ISyncTypeData): boolean => itemTypeData.itemType === ItemTypes.ACCOUNT
);

export const SyncItemCourseTypes: ISyncTypeData[] = filter(
  values(SyncItemTypeData),
  (itemTypeData: ISyncTypeData): boolean => itemTypeData.itemType === ItemTypes.COURSE
);

export const SyncItemTermTypes: ISyncTypeData[] = filter(
  values(SyncItemTypeData),
  (itemTypeData: ISyncTypeData): boolean => itemTypeData.itemType === ItemTypes.TERM
);

export const SyncItemNewTypes: ISyncTypeData[] = filter(
  values(SyncItemTypeData),
  (itemTypeData: ISyncTypeData): boolean => itemTypeData.isNew === true
);

export const SyncItemExistingTypes: ISyncTypeData[] = filter(
  values(SyncItemTypeData),
  (itemTypeData: ISyncTypeData): boolean => itemTypeData.isNew === false
);
