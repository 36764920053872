import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map, truncate, capitalize, get, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import Collapse from 'react-smooth-collapse';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { InstallationConfigKey, ModalId } from 'constants/index';
import { toast } from 'helpers/messages';
import { useModalState } from 'hooks/useModalState';
import { useInstallationConfig } from 'hooks/useInstallationConfig';

import { toggleModal } from 'store/modals/actions';
import { updateCoursesMetas } from 'store/courses/actions';
import { getTermItems } from 'store/terms/selectors';

import Course from 'models/canvas/course';
import CourseMeta from 'models/courseMeta';
import ModalModel from 'models/modal';
import CourseNavSettings from 'models/courseNavSettings';
import CourseAuthoringSettings from 'models/courseAuthoringSettings';
import CoursePageRatingSettings from 'models/coursePageRatingSettings';

import { Button, Modal } from 'components/elements';
import { SectionContent, SectionFooter, ModalHeading } from 'components/general-styles';
import EnhancedNavSettings from 'components/elements/EnhancedNavSettings';
import EnhancedAuthoringSettings from 'components/elements/EnhancedAuthoringSettings';
import EnhancedPageRatingSettings from 'components/elements/EnhancedPageRatingSettings';


import { ContentWrapper } from './style';

interface IBulkCourseSettingsProps {}

interface IBulkCourseSettingsModalData {
  courses: Course[];
}

interface IBulkCourseSettingsFormVals {
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
}

interface IBulkCourseSettingsFormVals {
  name: string;
  courseCode: string;
  sisCourseId: string;
  termId: string;
  accountId: string;
  startAt: Date;
  endAt: Date;
  tags: string[];
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
}

const BulkCourseSettingsModal: React.FC<IBulkCourseSettingsProps> = () => {
  const modalId = ModalId.BULK_COURSE_SETTINGS;
  const { t } = useTranslation();
  const dispatch = useDispatch();
 
  const modalState = useModalState<ModalModel<IBulkCourseSettingsModalData>>(modalId);
  const courses: Course[] = modalState?.data?.courses || [];

  const areCourseSettingIndeterminate = (coursesSettings: Course[], courseSettingsPath: string): [boolean, boolean] => {
    if (!coursesSettings.length) return [false, false];
    const initialValue = get(coursesSettings, `[0].${courseSettingsPath}`);
    const hasSomeValueDifferent = coursesSettings.some((course: Course) => get(course, courseSettingsPath) !== initialValue);
    const defaultValue = hasSomeValueDifferent ? true : initialValue;
    return [hasSomeValueDifferent, defaultValue];
  };

  if (isEmpty(courses)) {
    return <></>;
  }

  const [isNavIndeterminate, navDefaultValue] = areCourseSettingIndeterminate(courses, '_meta.courseNavSettings.enabled');
  const [isHomeIndeterminate, homeDefaultValue] = areCourseSettingIndeterminate(courses, '_meta.courseNavSettings.useHomeMenu');
  const [isAuthoringIndeterminate, authoringDefaultValue] = areCourseSettingIndeterminate(courses, '_meta.courseAuthoringSettings.enabled');
  const [isPageRatingIndeterminate, pageRatingDefaultValue] = areCourseSettingIndeterminate(courses, '_meta.coursePageRatingSettings.enabled');

  const [navEnabledIsIndeterminate, setNavEnabledIsIndeterminate] = useState<boolean>(isNavIndeterminate);
  const [navHomeIsIndeterminate, setNavHomeIsIndeterminate] = useState<boolean>(isHomeIndeterminate);
  const [authoringIsIndeterminate, setAuthoringIsIndeterminate] = useState<boolean>(isAuthoringIndeterminate);
  const [pageRatingIsIndeterminate, setPageRatingIsIndeterminate] = useState<boolean>(isPageRatingIndeterminate);

  const [targetCoursesExpanded, setTargetCoursesExpanded] = useState<boolean>(false);

  const hasNavigationFeature: boolean = useInstallationConfig(InstallationConfigKey.NAV_SETTINGS_FEATURE);
  const hasAuthoringFeature: boolean = useInstallationConfig(InstallationConfigKey.AUTHORING_FEATURE);
  const hasPageRatingFeature: boolean = useInstallationConfig(InstallationConfigKey.PAGE_RATING_FEATURE);

  const schema = yup.object().shape({
    courseNavSettings: yup.object().shape({
      enabled: yup.boolean(),
      useHomeMenu: yup.boolean()
    }),
    courseAuthoringSettings: yup.object().shape({
      enabled: yup.boolean()
    }),
    coursePageRatingSettings: yup.object().shape({
      enabled: yup.boolean()
    })
  });

  const { handleSubmit, watch, control, formState } = useForm<IBulkCourseSettingsFormVals>({
    resolver: yupResolver(schema),
    defaultValues: {    
      courseNavSettings: {
        enabled: navDefaultValue,
        useHomeMenu: homeDefaultValue
      },
      courseAuthoringSettings: {
        enabled: authoringDefaultValue
      },
      coursePageRatingSettings: {
        enabled: pageRatingDefaultValue
      }
    }
  });

  const handleClose = useCallback(() => {
    dispatch(toggleModal(modalId, false, {}));
  }, [modalId, dispatch]);

  // Watchers for each value
  const navIsChecked: boolean = watch('courseNavSettings.enabled');
  const homeIsChecked: boolean = watch('courseNavSettings.useHomeMenu');
  const authoringIsChecked: boolean = watch('courseAuthoringSettings.enabled');
  const ratingIsChecked: boolean = watch('coursePageRatingSettings.enabled');

  const removeAuthoringIndeterminate = (currentStatus: boolean) => {
    setAuthoringIsIndeterminate(false);
  };

  const removePageRatingIndeterminate = (currentStatus: boolean) => {
    setPageRatingIsIndeterminate(false);
  };
  
  const removeNavSettingsIndeterminate = (data: {[key: string]: boolean}) => {
    if ('nav' in data){
      setNavEnabledIsIndeterminate(false);
    } else {
      setNavHomeIsIndeterminate(false);
    }
  };
  

  const submit = () => {
    const updates:Partial<CourseMeta>[] = [];

    // We create a new set of _meta data to update all courses
    courses.forEach(course => {
      const navigationEnabled = navEnabledIsIndeterminate ? course?._meta.courseNavSettings?.enabled : navIsChecked;
      // If enhanced navigation is enabled check for the homepage setting of each course, if not default to homepage is off
      const homepageEnabled = navigationEnabled
        ? navHomeIsIndeterminate ? course?._meta.courseNavSettings?.useHomeMenu : homeIsChecked
        : false;
      updates.push({
        courseId: course.id,
        courseNavSettings: {
          enabled: navigationEnabled,
          useHomeMenu: homepageEnabled
        },
        courseAuthoringSettings: {
          enabled: authoringIsIndeterminate ? course?._meta.courseAuthoringSettings?.enabled : authoringIsChecked
        },
        coursePageRatingSettings: {
          enabled: pageRatingIsIndeterminate ? course?._meta.coursePageRatingSettings?.enabled : ratingIsChecked
        }
      });
    });

    dispatch(updateCoursesMetas(updates));
    handleClose();
    toast.success(t('toastCoursesUpdated'));
  };

  const truncateCourseName = (courseName: string): string => {
    return truncate(courseName, {
      length: 35,
      separator: ' ',
      omission: ' [...]'
    });
  };

  return (
    <Modal id={modalId} handleClose={handleClose}>
      <form onSubmit={handleSubmit(submit)}>
        <SectionContent>
          <ModalHeading>
            <h2 id={`modal-${modalId}-title`}>{t('bulkChangeSettings')}:</h2>
          </ModalHeading>

          <ContentWrapper className='content-wrapper'>
            <div className='selected-target-courses'>
              {t('selectedTargetCoursesWithCount', { count: courses?.length })}:
              <Button
                aria-expanded={targetCoursesExpanded}
                ariaLabel={targetCoursesExpanded ? 'Collapse' : 'Expand'}
                className='expand-target-courses-button'
                onClick={() => setTargetCoursesExpanded(prev => !prev)}
                size='small'
                variant='link'
              >
                <FontAwesomeIcon className={cn('expand-icon', { expanded: targetCoursesExpanded })} icon={['fas', 'chevron-down']} />
              </Button>
              <Collapse expanded={targetCoursesExpanded}>
                <ul className='target-courses-list'>
                  {map(courses, (c: Course) => (
                    <li className='target-course-list-item' key={c?.id}>
                      <FontAwesomeIcon className='course-icon' icon={['fas', 'chalkboard-teacher']} />
                      {c?.course_code}: {truncateCourseName(c?.name)}
                      <br />
                      <small className='sis-id'><strong>SIS:</strong> {c?.sis_course_id || t('unspecified')}</small>,
                      <small className='term-name'><strong>{capitalize(t('term'))}:</strong> {c?.term?.name || t('unspecified')}</small>
                    </li>
                  ))}
                </ul>
              </Collapse>
            </div>

            <EnhancedAuthoringSettings
              show={hasAuthoringFeature}
              control={control}
              id={modalId}
              indeterminate={authoringIsIndeterminate}
              defaultValue={authoringDefaultValue}
              isToggle={false}
              extraBehavior={removeAuthoringIndeterminate}
            />

            <EnhancedNavSettings
              show={hasNavigationFeature}
              control={control}
              id={modalId}
              readOnlyHomeMenu={!navIsChecked}
              navIndeterminate={isNavIndeterminate}
              homeIndeterminate={isHomeIndeterminate}
              defaultValue={{
                enabled: navDefaultValue,
                useHomeMenu: homeDefaultValue
              }}
              isToggle={false}
              extraBehavior={removeNavSettingsIndeterminate}
            />

            <EnhancedPageRatingSettings
              show={hasPageRatingFeature}
              control={control}
              id={modalId}
              indeterminate={isPageRatingIndeterminate}
              defaultValue={pageRatingDefaultValue}
              isToggle={false}
              extraBehavior={removePageRatingIndeterminate}
            />

          </ContentWrapper>

        </SectionContent>

        <SectionFooter>
          <div />
          <div>
            <Button
              color='secondary'
              onClick={handleClose}
              ariaLabel='Cancel'
              testId='course-settings-cancel-button'
            >
              {t('cancel')}
            </Button>
            <Button
              ariaLabel='Apply settings'
              testId='course-settings-submit-button'
              submitting={formState.isSubmitting}
              type='submit'
            >
              {t('apply')}
            </Button>
          </div>
        </SectionFooter>
      </form>
    </Modal>
  );
};

export default React.memo(BulkCourseSettingsModal);
