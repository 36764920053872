import CourseTemplate from 'models/courseTemplate';
import { keyBy } from 'lodash';
import { TemplatesAction, TemplatesActionTypes } from './actions';

export interface TemplatesFeatureState {
  items: Record<CourseTemplate['courseTemplateId'], CourseTemplate>;
}

export const defaultTemplatesFeatureState: TemplatesFeatureState = {
  items: {}
};

export default function templatesReducer(
  state: TemplatesFeatureState = defaultTemplatesFeatureState,
  action: TemplatesAction
): TemplatesFeatureState {
  switch (action.type) {
    case TemplatesActionTypes.RECEIVE_TEMPLATES:
      return {
        ...state,
        items: {
          ...state.items,
          ...keyBy(action.payload.templates, 'courseTemplateId')
        }
      };
    default:
      return state;
  }
}
