import { get } from 'lodash';

import Course from 'models/canvas/course';
import CourseMeta from 'models/courseMeta';

export default class CourseDto {
  course: Course;
  courseJson: any;
  meta: CourseMeta;

  constructor(json: any) {
    this.course = new Course(get(json, 'course'));
    this.meta = new CourseMeta(get(json, 'meta'));
  }

  toCourse(): Course {
    const { course } = this;
    course._meta = this.meta;
    return course;
  }
}
