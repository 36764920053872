// hook from https://usehooks.com/useKeyPress/
import { useState, useEffect, useCallback } from 'react';
import { useWindowFocus } from 'modules/data-table/hooks/useWindowFocus';
import { usePrevious } from './usePrevious';

/**
 * Hook thats handles key press events by sending the keyCode as first argument
 * It also accepts key combinations by sending an array as second argument
 * useKeyPress(65)
 * useKeyPress(65, ['ctrlKey', 'altKey'])
 */
export const useKeyPress = (targetKey: number, extraKeys: Array<string> = [], handleDownEvent?: (e: Event) => void) => {
  const [keyPressed, setKeyPressed] = useState(false);
  const windowFocused = useWindowFocus();
  const prevWindowFocus = usePrevious(windowFocused);
  const windowJustRegainedFocus = !prevWindowFocus && windowFocused;

  const downHandler = useCallback((e) => {
    const { keyCode } = e;

    if (keyCode === targetKey && extraKeys.every((key: string) => e[key])) {
      setKeyPressed(true);
      if (handleDownEvent) { handleDownEvent(e); }
    }
  }, [targetKey, extraKeys, handleDownEvent, setKeyPressed]);

  const upHandler = useCallback((e) => {
    const { keyCode } = e;

    if (keyCode === targetKey) {
      setKeyPressed(false);
    }
  }, [targetKey, setKeyPressed]);

  // Addresses bug where window losing focus (alert, or link opening in new tab) caused the
  // keypress to still be `true` when returning to the window
  useEffect(() => {
    if (windowJustRegainedFocus) {
      setKeyPressed(false);
    }
  }, [windowJustRegainedFocus, setKeyPressed]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);

  return keyPressed;
};
