import Account from 'models/canvas/account';

// types

export enum AccountTreeActionTypes {
  SET_ACCOUNTS_EXPANDED = 'accounts-tree/SET_ACCOUNTS_EXPANDED',
  SET_ACTIVE_ACCOUNT = 'accounts-tree/SET_ACTIVE_ACCOUNT'
}

export interface SetAccountsExpandedAction {
  type: typeof AccountTreeActionTypes.SET_ACCOUNTS_EXPANDED;
  payload: { accountIds: Account['id'][], expanded: boolean };
}

export interface SetActiveAccountAction {
  type: typeof AccountTreeActionTypes.SET_ACTIVE_ACCOUNT;
  payload: { accountId: Account['id'] };
}

export type AccountTreeAction =
  | SetAccountsExpandedAction
  | SetActiveAccountAction;

// action creators

export const setAccountsExpanded = (accountIds: Account['id'][], expanded: boolean): SetAccountsExpandedAction => ({
  type: AccountTreeActionTypes.SET_ACCOUNTS_EXPANDED,
  payload: { accountIds, expanded }
});

export const setActiveAccount = (accountId: Account['id']): SetActiveAccountAction => ({
  type: AccountTreeActionTypes.SET_ACTIVE_ACCOUNT,
  payload: { accountId }
});
