import styled, { css } from 'styled-components';

export const AppContainer = styled.div`
  ${({ theme }) => css`
    .error {
      color: ${theme.color.red}!important;
    }

    .success {
      color: ${theme.color.green}!important;
    }
  `}
`;
