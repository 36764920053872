import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { getInstallationConfig } from 'store/installation/selectors';

/** Hook thats wraps installation data */
export const useInstallationConfig = (configKey: string): any => {
  const installationConfig = useSelector(getInstallationConfig);
  const val = get(installationConfig, configKey);
  return val;
};
