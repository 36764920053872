import CourseTemplate from 'models/courseTemplate';

/** TYPES  */

export enum TemplatesActionTypes {
  FETCH_TEMPLATES = 'templates/FETCH_TEMPLATES',
  RECEIVE_TEMPLATES = 'templates/RECEIVE_TEMPLATES',
}

export interface FetchTemplatesAction {
  type: typeof TemplatesActionTypes.FETCH_TEMPLATES;
}

export interface ReceiveTemplatesAction {
  type: typeof TemplatesActionTypes.RECEIVE_TEMPLATES;
  payload: { templates: CourseTemplate[]; };
}

export type TemplatesAction =
  | FetchTemplatesAction
  | ReceiveTemplatesAction;

/** ACTION CREATORS */

export const fetchTemplates = (): FetchTemplatesAction => ({
  type: TemplatesActionTypes.FETCH_TEMPLATES
});

export const receiveTemplates = (templates: CourseTemplate[]): ReceiveTemplatesAction => ({
  type: TemplatesActionTypes.RECEIVE_TEMPLATES,
  payload: { templates }
});
