import { Button } from 'components/elements';
import React, { FC, ReactNode, ReactText, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastOptions } from 'react-toastify';
import { CustomToastContentWrapper } from '../style';

interface ISuccessUndoToast {
  children: ReactNode;
  onUndo: (toastId: ReactText) => void;
  config: ToastOptions;
}

export const SuccessUndoToast: FC<ISuccessUndoToast> = ({ children, onUndo, config }) => {
  const { toastId } = config;

  const { t } = useTranslation();

  const handleUndoClick = useCallback(() => {
    onUndo(toastId);
  }, [toastId, onUndo]);

  return (
    <CustomToastContentWrapper className='custom-toast-content success-undo-toast-content'>
      {children}
      <div className='buttons-container'>
        <Button
          className='custom-toast-button'
          color='secondary'
          onClick={handleUndoClick}
          size='small'
          testId='toast-undo-button'
        >
          {t('undo')}
        </Button>
      </div>
    </CustomToastContentWrapper>
  );
};
