export default class CourseTemplate {
  courseTemplateId: string;
  description: string;
  // eslint-disable-next-line react/static-property-placement
  displayName: string;
  name: string;

  constructor(json: any) {
    this.courseTemplateId = json?.courseTemplateId;
    this.name = json?.name;
    this.displayName = json?.displayName;
    this.description = json?.description;
  }
}
