import EnrollmentTerm from 'models/canvas/enrollmentTerm';
import React, { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Select, { Styles, ValueType } from 'react-select';
import { getTermById, getTermExistsInLmsAlready, getTerms } from 'store/terms/selectors';
import { TermSelectWrapper } from './style';
import { map, find, concat } from 'lodash';
import { customSelectStyles } from '../shared-style';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from '..';
import { NewTermPopoverContent } from './NewTermPopoverContent';
import { useTheme } from 'styled-components';
import ITheme from 'styles/interfaces/theme';
import { toast } from 'helpers/messages';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import { SelectComponentsProps } from 'react-select/src/Select';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { isLikeClick } from 'helpers/events';
import { InstallationConfigKey } from 'constants/index';
import { useInstallationConfig } from 'hooks/useInstallationConfig';

interface ITermSelect {
  allowCreate?: boolean;
  onChange: (selectedTermId: EnrollmentTerm['id']) => void;
  onCreate?: (tempTermId: EnrollmentTerm['id'], newTerm: EnrollmentTerm) => void;
  labelText?: string | ReactNode;
  size?: 'small' | 'big';
  defaultValue?: number | string;
  value?: number | string;
  name?: string;
  id?: string;
}

interface ITermSelectOption {
  label: string,
  value: string
}

export const CREATE_NEW_TERM_VALUE = 'CREATE_NEW_TERM';

export const TermSelect: FC<ITermSelect & SelectComponentsProps> = ({
  allowCreate = false,
  onChange,
  onCreate,
  labelText,
  size = 'big',
  defaultValue,
  value,
  name,
  id,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const terms = useSelector(getTerms);
  const theme = useTheme() as ITheme;
  const [showNewTermPopover, setShowNewTermPopover] = useState<boolean>(false);
  const hasCreateTermFeatureEnabled: boolean = useInstallationConfig(InstallationConfigKey.CREATE_TERM_FEATURE);

  const term = useSelectorWithProps(getTermById, { termId: value });
  const termExists = useSelectorWithProps(getTermExistsInLmsAlready, { termId: term?.id });

  const [showTermEditPopover, setShowTermEditPopover] = useState<boolean>(false);

  const handleEditUnsyncedTermClick = useCallback((e?: React.MouseEvent) => {
    e?.stopPropagation();
    setShowTermEditPopover(true);
  }, [setShowTermEditPopover]);

  const handleUnsyncedTermUpdate = useCallback((_termId: string, updatedTerm: EnrollmentTerm) => {
    setShowTermEditPopover(false);
    toast.success(t('toastTermUpdated', { name: updatedTerm?.name }));
  }, [setShowTermEditPopover, t]);

  const termToOption = (_term: EnrollmentTerm): ITermSelectOption => ({
    label: _term?.name,
    value: _term?.id
  });

  const options = useMemo(() => {
    const createOption: ITermSelectOption = {
      label: `* ${t('createNewTerm')}...`,
      value: CREATE_NEW_TERM_VALUE
    };
    const termOptions: ITermSelectOption[] = map(terms, termToOption);
    return allowCreate && hasCreateTermFeatureEnabled
      ? concat(createOption, termOptions)
      : termOptions;
  }, [terms, allowCreate, t, hasCreateTermFeatureEnabled]);

  const defaultValueOpt = useMemo(() => {
    return find(options, { value: defaultValue });
  }, [options, defaultValue]);

  const valueOpt = useMemo(() => {
    return find(options, { value }) || defaultValueOpt;
  }, [options, value, defaultValueOpt]);

  const handleChange = useCallback((selectedOpt: ValueType<ITermSelectOption, any>) => {
    const selectedTermId = (selectedOpt as ITermSelectOption)?.value;
    if (selectedTermId === CREATE_NEW_TERM_VALUE) {
      setShowNewTermPopover(true);
    } else {
      onChange(selectedTermId);
    }
  }, [onChange, setShowNewTermPopover]);

  const handleCreate = useCallback((tempTermId: string, newTerm: EnrollmentTerm) => {
    onCreate(tempTermId, newTerm);
    setShowNewTermPopover(false);
    toast.success(t('toastNewTermCreated', { name: newTerm?.name }));
  }, [onCreate, setShowNewTermPopover, t]);

  const customStyles: Partial<Styles<any, any>> = useMemo(() => ({
    ...customSelectStyles({ size, theme }),
    option: (provided, state) => {
      const isCreateOption = state?.data?.value === CREATE_NEW_TERM_VALUE;
      return {
        ...provided,
        backgroundColor: state?.isSelected ? theme.color.mainDark : provided.backgroundColor,
        color: isCreateOption && !state?.isSelected ? theme.color.mainDark : provided.color,
        fontStyle: isCreateOption ? 'italic' : provided.fontStyle,
      };
    }
  }), [size, theme]);

  return (
    <TermSelectWrapper
      className='term-select-wrapper'
      data-testid='term-select-wrapper'
      ref={wrapperRef}
    >
      {labelText && (
        <label htmlFor={id}>{labelText}</label>
      )}
      <Select
        defaultValue={defaultValueOpt}
        id={id}
        menuPortalTarget={document.body}
        name={name}
        onChange={handleChange}
        options={options}
        styles={customStyles}
        value={valueOpt}
        {...otherProps}
      />

      {term && !termExists && (
        <>
          <ReactTooltip
            aria-haspopup='true'
            effect='solid'
            event='focus mouseover'
            eventOff='blur mouseout'
            id={`${id}-edit-new-term-tooltip`}
          >
            {t('editNewTerm')}
          </ReactTooltip>
          <Button
            ariaLabel={t('editNewTerm')}
            className='edit-term-link'
            data-tip
            data-for={`${id}-edit-new-term-tooltip`}
            id={`${id}-edit-new-term-button`}
            onClick={handleEditUnsyncedTermClick}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (isLikeClick(e)) {
                e.stopPropagation();
                handleEditUnsyncedTermClick();
              }
            }}
            testId='edit-new-term-button'
            underline
            variant='link'
          >
            <FontAwesomeIcon className='edit-new-term-icon' icon={['fas', 'pencil-alt']} />
          </Button>
          <Popover
            show={showTermEditPopover}
            targetRef={wrapperRef}
            title={t('editNewTerm')}
          >
            {(overlayProps: OverlayInjectedProps) => (
              <NewTermPopoverContent
                onCancel={() => setShowTermEditPopover(false)}
                onUpdate={handleUnsyncedTermUpdate}
                overlayProps={overlayProps}
                term={term}
              />
            )}
          </Popover>
        </>
      )}

      <Popover
        show={showNewTermPopover}
        targetRef={wrapperRef}
        title={t('createNewTerm')}
      >
        {(overlayProps: OverlayInjectedProps) => (
          <NewTermPopoverContent
            onCancel={() => setShowNewTermPopover(false)}
            onCreate={handleCreate}
            overlayProps={overlayProps}
          />
        )}
      </Popover>

    </TermSelectWrapper>
  );
};
