import React, { FC, memo } from 'react';
import { ModalId } from 'constants/index';
import { useModalState } from 'hooks/useModalState';

import ModalModel from 'models/modal';

import AddCoursesModal from 'components/Modals/AddCourses';
import ApplyTemplateModal from 'components/Modals/ApplyTemplate';
import BulkCourseSettingsModal from 'components/Modals/BulkCourseSettings';
import CourseSettingsModal from 'components/Modals/CourseSettings';
import DuplicateCoursesModal from 'components/Modals/DuplicateCourses';
import MoveItems from 'components/Modals/MoveItems';
import DataTableKeyboardInfo from 'components/Modals/DataTableKeyboardInfo';
import ImportCourseModal from 'components/Modals/ImportCourse';

interface IModals {}

const Modals: FC<IModals> = memo(() => {
  const addCoursesModalState = useModalState<ModalModel>(ModalId.ADD_COURSES);
  const applyTemplateModalState = useModalState<ModalModel>(ModalId.APPLY_FRAMEWORK);
  const bulkCourseSettingsModalState = useModalState<ModalModel>(ModalId.BULK_COURSE_SETTINGS);
  const courseSettingsModalState = useModalState<ModalModel>(ModalId.COURSE_SETTINGS);
  const dataTableKeyboardInfoState = useModalState<ModalModel>(ModalId.DATA_TABLE_KEYBOARD_INFO);
  const duplicateCoursesModalState = useModalState<ModalModel>(ModalId.DUPLICATE_COURSES);
  const importCourseModalState = useModalState<ModalModel>(ModalId.IMPORT_COURSE);
  const moveItemsState = useModalState<ModalModel>(ModalId.MOVE_ITEMS);

  return (
    <>
      { addCoursesModalState.open && <AddCoursesModal /> }
      { applyTemplateModalState.open && <ApplyTemplateModal /> }
      { bulkCourseSettingsModalState.open && <BulkCourseSettingsModal /> }
      { courseSettingsModalState.open && <CourseSettingsModal /> }
      { dataTableKeyboardInfoState.open && <DataTableKeyboardInfo /> }
      { duplicateCoursesModalState.open && <DuplicateCoursesModal /> }
      { importCourseModalState.open && <ImportCourseModal /> }
      { moveItemsState.open && <MoveItems /> }
    </>
  );
});

export default Modals;
