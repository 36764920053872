import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState } from 'store';

export const useSelectorObservable = <T = any>(
  selector: (appState: AppState) => T
): Observable<T> => {
  const val: T = useSelector(selector);
  const obs$ = useRef(new BehaviorSubject<T>(val)).current;

  useEffect(() => {
    obs$.next(val);
  }, [obs$, val]);

  useEffect(() => {
    return () => obs$.unsubscribe();
  }, [obs$]);

  return obs$;
};
