import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getModalById } from 'store/modals/selectors';
import Modal from 'models/modal';

/** Hook thats wraps modal date */
export const useModalState = <ResultType extends Modal>(modalId: string) => {
  const state = useSelectorWithProps(
    getModalById,
    { modalId }
  );

  return state as ResultType;
};
