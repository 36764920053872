import styled from 'styled-components';

export const TagsCellWrapper = styled.div`
  .tags-cell-edit-button {
    display: inline-block;
    margin: 2px;
  }

  .tag-pill {
    margin: 2px 1px;
  }

  .extra-tags-tooltip-trigger {
    display: inline;
    margin: 0 .3em 0 0;
  }
`;

export const TagPill = styled.span`
  background: ${({ theme }) => theme.color.mainDark};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.white};
  display: inline-block;
  padding: .15em .25em;
`;
