import BaseSyncCourse from './baseSyncCourse';

export default class SyncNewCourse extends BaseSyncCourse {
  accountId: string;
  courseTemplateId: string;
  syncNewCourseId: string;
  tempAccountId: string;
  tempCourseId: string;
  restrictEnrollmentsToCourseDates: boolean;

  constructor(json: any) {
    super(json);
    this.accountId = json?.accountId ? String(json.accountId) : null;
    this.courseTemplateId = json?.courseTemplateId;
    this.syncNewCourseId = json?.syncNewCourseId;
    this.tempAccountId = json?.tempAccountId;
    this.tempCourseId = json?.tempCourseId;
    this.restrictEnrollmentsToCourseDates = json?.restrictEnrollmentsToCourseDates;
  }
}
