import React, { FC } from 'react';
import { HiddenKeyboardNavContainer } from './style';

interface IHiddenKeyboardNav {
  children?: any;
}

/**
 * Component to hold special buttons or links meant only for someone using
 * keyboard navigation. The component container is hidden until one of its
 * children takes focus. Since it's hidden, those elements can only take focus
 * via keyboard tabbing. Useful for 'Skip to Content', etc.
 */
export const HiddenKeyboardNav: FC<IHiddenKeyboardNav> = ({ children }) => {
  return (
    <HiddenKeyboardNavContainer className='hidden-keyboard-nav-container'>
      <div className='hidden-keyboard-nav-inner'>
        {children}
      </div>
    </HiddenKeyboardNavContainer>
  );
};
