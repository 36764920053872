import styled from 'styled-components';


export const Switch = styled.label`

  position: relative;
  display: inline-block;
  width: 33px;
  height: 16px;
  margin: 4px 8px 4px 0;

& input[type="checkbox"] {
  display: none;
}
& .switch {
  position: absolute;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.gray4};
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
& .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  transition: transform 0.3s ease;
}
& input[type="checkbox"]:checked + .switch::before {
  transform: translateX(17px);
  background-color: ${({ theme }) => theme.color.white};
}
& input[type="checkbox"]:checked + .switch {
  background-color: ${({ theme }) => theme.color.mainDarkActive2};
}

.collapsibleSubAccounts .selection-checkbox{
  margin-left: 47px;
}
`;
