import { createSelector } from 'reselect';
import { get, map, orderBy, values, filter, maxBy, reduce, includes, concat,
  find, flatMap, compact, keys, forEach, without } from 'lodash';
import { AppState } from 'store';
import SyncNewAccount from 'models/syncOrder/syncNewAccount';
import SyncOrder from 'models/syncOrder/syncOrder';
import SyncOrderDto from 'models/dto/syncOrderDto';
import { SyncOrderStatusCompleted, SyncOrderStatusPending, SyncOrderStatusActive, SyncOrderStatusError,
  SyncItemStatusCompleted, SyncItemStatusError, ISyncTypeData, SyncItemTypeData,
  ItemTypes } from 'constants/index';
import { SyncOrderFeatureState } from './reducer';
import { memoizeSelectorFactory } from 'store/selector-helpers';
import BaseSyncItemEntity from 'models/syncOrder/baseSyncItemEntity';
import memoize from 'fast-memoize';

export type PerSyncOrderProps = { syncOrderId: SyncOrder['syncOrderId'] };
export type MultipleSyncOrdersProps = { syncOrderIds: SyncOrder['syncOrderId'][] };

/** Get a single array of all the sync items for the dto */
export const itemsForSyncOrder = (dto: SyncOrderDto): BaseSyncItemEntity[] => {
  const syncItemKeys: (keyof SyncOrderDto)[] = without(keys(dto), 'syncOrder');
  return reduce(syncItemKeys, (acc: BaseSyncItemEntity[], key: keyof SyncOrderDto) => {
    return concat(acc, dto[key]);
  }, []);
};

export const getSyncItemTypeData = memoize((syncOrderItem: BaseSyncItemEntity): ISyncTypeData => {
  return find(
    values(SyncItemTypeData),
    (itemTypeData: ISyncTypeData) => syncOrderItem instanceof itemTypeData.classType
  ) || {};
});

export const getSyncOrdersFeature = (appState: AppState): SyncOrderFeatureState => get(appState, 'syncOrders');

export const getPaginationStatus = createSelector(
  getSyncOrdersFeature,
  (featureState: SyncOrderFeatureState): SyncOrderFeatureState['pagination'] => featureState.pagination
);

export const getSyncOrderDtoItems = createSelector(
  getSyncOrdersFeature,
  (featureState: SyncOrderFeatureState): SyncOrderFeatureState['syncOrderDtos'] => featureState?.syncOrderDtos
);

export const getAllSyncOrderDtos = createSelector(
  getSyncOrderDtoItems,
  (items: SyncOrderFeatureState['syncOrderDtos']): SyncOrderDto[] => {
    const dtos: SyncOrderDto[] = values(items);
    return orderBy(
      dtos,
      ['syncOrder.createdAt'],
      ['desc']
    );
  }
);

export const getAllActiveSyncOrderDtos = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncOrderDto[] => {
    return filter(
      dtos,
      (dto: SyncOrderDto): boolean => {
        return includes(
        Object.keys(SyncOrderStatusActive),
        dto?.syncOrder?.syncItemResult?.syncStatus
      );
      }
    );
  }
);

export const getAllPendingSyncOrderDtos = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncOrderDto[] => {
    return filter(
      dtos,
      (dto: SyncOrderDto): boolean => includes(
        ...Object.keys(SyncOrderStatusPending),
        dto?.syncOrder?.syncItemResult?.syncStatus
      )
    );
  }
);

export const getAllCompletedSyncOrderDtos = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncOrderDto[] => {
    return filter(
      dtos,
      (dto: SyncOrderDto): boolean => includes(
        [...Object.keys(SyncOrderStatusCompleted), ...Object.keys(SyncOrderStatusError)],
        dto?.syncOrder?.syncItemResult?.syncStatus
      )
    );
  }
);

export const getAllActiveSyncOrderIds = createSelector(
  getAllActiveSyncOrderDtos,
  (activeSyncOrderDtos: SyncOrderDto[]): SyncOrder['syncOrderId'][] => map(activeSyncOrderDtos, 'syncOrder.syncOrderId')
);

export const getAllPendingSyncOrderIds = createSelector(
  getAllPendingSyncOrderDtos,
  (pendingSyncOrderDtos: SyncOrderDto[]): SyncOrder['syncOrderId'][] => map(pendingSyncOrderDtos, 'syncOrder.syncOrderId')
);

export const getAllPendingAndActiveSyncOrdersIds = createSelector(
  getAllActiveSyncOrderDtos,
  getAllPendingSyncOrderDtos,
  (activeSyncOrderDtos: SyncOrderDto[], pendingSyncOrderDtos: SyncOrderDto[]): SyncOrder['syncOrderId'][] => map([...activeSyncOrderDtos, ...pendingSyncOrderDtos], 'syncOrder.syncOrderId')
);

export const getAllCompletedSyncOrderIds = createSelector(
  getAllCompletedSyncOrderDtos,
  (dtos: SyncOrderDto[]): string[] => map(dtos, 'syncOrder.syncOrderId')
);

export const getHasActiveSyncOrders = createSelector(
  getAllActiveSyncOrderDtos,
  (activeDtos: SyncOrderDto[]): boolean => activeDtos?.length > 0
);

export const getHasActiveOrPendingSyncOrders = createSelector(
  getAllActiveSyncOrderDtos,
  getAllPendingSyncOrderDtos,
  (activeDtos: SyncOrderDto[], pendingDtos: SyncOrderDto[]): boolean => [...activeDtos, ...pendingDtos]?.length > 0
);

export const getAllSyncNewAccounts = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncNewAccount[] => flatMap(dtos, 'syncNewAccounts')
);

export const getAllSyncNewCourses = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncNewAccount[] => flatMap(dtos, 'syncNewCourses')
);

export const getAllSyncExistingCourses = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncNewAccount[] => flatMap(dtos, 'syncExistingCourses')
);

export const getAllSyncNewDupCourses = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncNewAccount[] => flatMap(dtos, 'syncNewDupCourses')
);

export const getAllSyncExistingDupCourses = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncNewAccount[] => flatMap(dtos, 'syncExistingDupCourses')
);

export const getSyncOrderDto = memoizeSelectorFactory((props: PerSyncOrderProps) => createSelector(
  getSyncOrderDtoItems,
  (items: SyncOrderFeatureState['syncOrderDtos']): SyncOrderDto => get(items, props.syncOrderId)
));

export const getMultipleSyncOrderDtos = memoizeSelectorFactory((props: MultipleSyncOrdersProps) => createSelector(
  getSyncOrderDtoItems,
  (items: SyncOrderFeatureState['syncOrderDtos']): SyncOrderDto[] => {
    return compact(
      map(props.syncOrderIds, (syncOrderId: string) => get(items, syncOrderId))
    );
  }
));

/** Get all sync items associated with the sync orders ids provided */
export const getAllItemsForMultipleSyncOrders = memoizeSelectorFactory((props: MultipleSyncOrdersProps) => createSelector(
  getMultipleSyncOrderDtos(props),
  (dtos: SyncOrderDto[]): BaseSyncItemEntity[] => flatMap(
    dtos,
    (dto: SyncOrderDto) => itemsForSyncOrder(dto)
  )
));

export const getAllItemsForSyncOrder = memoizeSelectorFactory((props: PerSyncOrderProps) => createSelector(
  getSyncOrderDto(props),
  (dto: SyncOrderDto): BaseSyncItemEntity[] => itemsForSyncOrder(dto)
));

export const getMostRecentSyncOrderDto = createSelector(
  getAllSyncOrderDtos,
  (dtos: SyncOrderDto[]): SyncOrderDto => maxBy(
    dtos,
    (dto: SyncOrderDto) => new Date(get(dto, 'syncOrder.createdAt', null))
  )
);

const calculateSyncOrderProgress = (syncOrderItems: BaseSyncItemEntity[]) => {
  const total = get(syncOrderItems, 'length', 0);
  const status = { success: 0, error: 0 };

  forEach(syncOrderItems, (item: BaseSyncItemEntity) => {
    const itemStatus = item?.syncItemResult?.syncStatus;
    if (includes(Object.keys(SyncItemStatusCompleted), itemStatus)) {
      status.success += 1;
    } else if (includes(Object.keys(SyncItemStatusError), itemStatus)) {
      status.error += 1;
    }
  });

  return {
    total,
    ...status
  };
};

export const getSyncOrderProgress = memoizeSelectorFactory((props: MultipleSyncOrdersProps) => createSelector(
  getAllItemsForMultipleSyncOrders(props),
  (syncOrderItems: BaseSyncItemEntity[]): any => {
    return calculateSyncOrderProgress(syncOrderItems);
  }
));

export const syncItemIsAccount = memoize((syncOrderItem: BaseSyncItemEntity): boolean => {
  return getSyncItemTypeData(syncOrderItem)?.itemType === ItemTypes.ACCOUNT;
});

export const syncItemIsCourse = memoize((syncOrderItem: BaseSyncItemEntity): boolean => {
  return getSyncItemTypeData(syncOrderItem)?.itemType === ItemTypes.COURSE;
});

export const syncItemIsTerm = memoize((syncOrderItem: BaseSyncItemEntity): boolean => {
  return getSyncItemTypeData(syncOrderItem)?.itemType === ItemTypes.TERM;
});

export const syncItemIsNewType = memoize((syncOrderItem: BaseSyncItemEntity): boolean => {
  return getSyncItemTypeData(syncOrderItem)?.isNew;
});

export const syncItemIsExistingType = memoize((syncOrderItem: BaseSyncItemEntity): boolean => {
  return !syncItemIsNewType(syncOrderItem);
});
