import styled, { css } from 'styled-components';
import { pulseAnimation } from 'styles/keyframes';

export interface ICustomButtonProps {
  ariaLabel?: string,
  as?: 'button' | 'a',
  children?: any,
  className?: string,
  color?: 'primary' | 'secondary' | 'white',
  disabled?: boolean,
  href?: string, // when as="a" is supplied
  id?: string,
  italic?: boolean,
  onClick?: any,
  rotate?: number,
  size?: string,
  submitting?: boolean,
  target?: string, // when as="a" is supplied
  testId?: string
  theme?: any,
  type?: 'submit' | 'reset' | 'button',
  underline?: boolean,
  variant?: string,
}

export const CustomButton = styled.button<ICustomButtonProps>`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font: 1.2rem/1.7rem ${({ theme }) => theme.font.mainDark};
  justify-content: center;
  margin: 0 8px;
  transition: all 0.2s ease-in-out;

  svg {
    transition: transform 0.2s ease-in-out;
    transform: rotate(${({ rotate }) => rotate}deg);
  }

  svg:first-child{
    margin-right: 8px;
  }

  svg:last-child{
    margin-left: 8px;
  }

  &:disabled {
    background: ${({ theme }) => theme.color.gray7};
    border: 1px solid ${({ theme }) => theme.color.gray7};
    color: ${({ theme }) => theme.color.gray4};
    cursor: not-allowed;
    opacity: .7;
  }

  /* italic */
  ${({ italic }) => italic && css`
    font-style: italic;
  `}

  /* big */
  ${({ size, theme }) => size === 'big' && css`
    font: 1.4rem/2.5rem ${theme.font.mainDark};
  `}

  /* filled variant */
  ${({ variant, color, size, theme }) => variant === 'filled' && css`
    background: ${theme.color.gray7};
    border: 1px solid ${theme.color.gray7};
    color: ${theme.color.mainDark};
    height: 30px;
    margin: 4px 8px 4px;
    min-width: 80px;
    padding: 0 8px;

    /* filled primary */
    ${color === 'primary' && css`
      background: ${theme.color.mainDark};
      border: 1px solid ${theme.color.mainDark};
      color: ${theme.color.white};
    `}

    /* filled big */
    ${size === 'big' && css`
      height: 40px;
      min-width: 148px;
      padding: 6px 16px 8px;
    `}
    
    &:hover,
    &:focus {
      background: ${(color === 'primary'
      ? theme.color.mainDark
      : theme.color.gray6
    )};

      ${pulseAnimation}
    }

    &:disabled {
      background: ${theme.color.gray1};
      border: 1px solid ${theme.color.font};
      color: ${theme.color.white};
    }
  `}

  /* link variant */
  ${({ variant, color, theme, underline }) => variant === 'link' && css`
    background: transparent;
    border: none;
    color: ${theme.color.font};
    margin: 0 0;
    padding: 0;
    text-decoration: none;

    /* link primary */
    ${color === 'primary' && css`
      color: ${theme.color.mainDark};
      &&:hover,
      &&:focus {
        color: ${theme.color.mainDark}
        text-decoration: underline;
      }
    `}

    /* link white */
    ${color === 'white' && css`
      color: ${theme.color.white};
      &&:hover,
      &&:focus {
        color: ${theme.color.white};
        text-decoration: underline;
      }

      &&:disabled {
        background: transparent;
        border: 0;
        &:hover,
        &:focus {
          color: ${theme.color.gray2};
          cursor: not-allowed;         
        }
      }
    `}
    
    /* link underlined */
    ${!!underline && css`
      text-decoration: underline;
    `}

    &:hover,
    &:focus {
      color: ${theme.color.mainDark};
    }
  `}
`;
