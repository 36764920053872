import styled, { css } from 'styled-components';

interface ILayoutContainer {
  loading: number;
}
interface IHeaderRegion {}
interface IMainRegion {}
interface ILoadingScreen {
  loading: number;
}

export const LayoutContainer = styled.div<ILayoutContainer>`
  ${({ loading }) => css`
    &.layout-container {
      display: flex;
      flex-direction: column;
      height: 100vh;
      pointer-events: ${loading ? 'none' : 'auto'};
      position: relative;
      width: 100vw;
    }
  `}
`;

export const LoadingScreen = styled.div<ILoadingScreen>`
  ${({ loading }) => css`
    &.loading-screen {
      align-items: center;
      background: rgba(256, 256, 256, .8);
      display: flex;
      height: 100vh;
      justify-content: center;
      left: 0;
      opacity: ${loading ? 1 : 0};
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: all .5s ease-in-out;
      width: 100vw;
      z-index: 100000;
    }  
  `}
`;

export const HeaderRegion = styled.header<IHeaderRegion>`
  ${() => css`
    &.header-region {
      flex: 0 0 auto;
    }
  `}
`;

export const MainRegion = styled.main<IMainRegion>`
  ${() => css`
    &.main-region {
      flex: 1 1 auto;
      overflow: hidden; /* child elements should implement height/overflow */
    }
  `}
`;

export const ToastCurtain = styled.div`
  background: ${({ theme }) => theme.color.black};
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity .2s ease;
  width: 100vw;
  z-index: 1000;

  &.show {
    opacity: .7;
    pointer-events: all;
  }
`;
