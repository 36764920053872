import React, { SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SYNC_PAGINATION } from 'config';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { fetchSyncOrders } from 'store/syncOrders/actions';
import { getPaginationStatus, getAllSyncOrderDtos } from 'store/syncOrders/selectors';
import { getLatestRequestInProgressByLabel } from 'store/requests/selectors';

import { Button } from 'components/elements';
import { Wrapper } from './style';
import SyncOrderDto from 'models/dto/syncOrderDto';
import { RequestLabel } from 'constants/index';

export const SyncLazyLoad: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const paginationStatus = useSelector(getPaginationStatus);
  const syncOrderDtos: SyncOrderDto[] = useSelector(getAllSyncOrderDtos);

  const fetchingSyncOrders = useSelectorWithProps(
    getLatestRequestInProgressByLabel,
    { label: RequestLabel.FETCH_SYNC_ORDERS_PAGINATED }
  );

  const isDisabled = syncOrderDtos.length >= paginationStatus.totalElements;

  const handleClearSyncOrder = (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const page = Math.floor(syncOrderDtos.length / SYNC_PAGINATION.perPage);
    dispatch(fetchSyncOrders(page));
  };

  return (
    <Wrapper>
      <Button
        submitting={fetchingSyncOrders}
        disabled={isDisabled || fetchingSyncOrders}
        onClick={handleClearSyncOrder}
      >
        {t('loadMore')}
      </Button>
    </Wrapper>
  );
});
