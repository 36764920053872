import { createSelector } from 'reselect';
import { get } from 'lodash';
import { AppState } from 'store';

import Account from 'models/canvas/account';

import { memoizeSelectorFactory } from 'store/selector-helpers';
import { getAccountsFeature, PerAccountProps } from 'store/accounts/selectors';
import { AccountsFeatureState } from 'store/accounts/reducer';
import { AccountTreeFeatureState } from './reducer';

export const getAccountTreeFeature = (appState: AppState): AccountTreeFeatureState => get(appState, 'accountTree');

export const getExpandedAccountsMap = createSelector(
  getAccountTreeFeature,
  (featureState: AccountTreeFeatureState): AccountTreeFeatureState['expandedAccounts'] => get(featureState, 'expandedAccounts')
);

export const getActiveAccountId = createSelector(
  getAccountTreeFeature,
  (featureState: AccountTreeFeatureState): AccountTreeFeatureState['activeAccountId'] => get(featureState, 'activeAccountId')
);

export const getActiveAccount = createSelector(
  getActiveAccountId,
  getAccountsFeature,
  (accountId: string, accountsFeature: AccountsFeatureState): Account => get(accountsFeature, `items.${accountId}`)
);

export const getAccountIsExpanded = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getExpandedAccountsMap,
  (expandedAccounts: AccountTreeFeatureState['expandedAccounts']): boolean => !!get(expandedAccounts, props.accountId)
));
