import React, { FC, memo } from 'react';
import { AppMenu } from './AppMenu';
import { AppToggleTabs } from './AppToggleTabs';
import { AppMenuBarContainer } from './style';

interface IAppMenuBar {
  match: any;
}

export const AppMenuBar: FC<IAppMenuBar> = memo(({
  match
}) => {

  return (
    <AppMenuBarContainer className='app-menu-bar-container'>
      <AppMenu />
      <AppToggleTabs match={match} />
    </AppMenuBarContainer>
  );
});
