import { get, toString } from 'lodash';

export default class CourseTerm {
  id: string;
  name: string;
  start_at: Date;
  end_at: Date;

  constructor(json: any) {
    this.id = toString(get(json, 'id'));
    this.name = get(json, 'name');
    this.start_at = get(json, 'start_at') ? new Date(Date.parse(json.start_at)) : null;
    this.end_at = get(json, 'end_at') ? new Date(Date.parse(json.end_at)) : null;
  }
}
