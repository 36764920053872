import { keyBy, reduce, get, omit } from 'lodash';

import Account from 'models/canvas/account';
import { AccountsAction, AccountsActionTypes } from './actions';

export interface AccountsFeatureState {
  items: Record<Account['id'], Account>;
  originalItems: Record<Account['id'], Account>;
}

export const defaultAccountsState: AccountsFeatureState = {
  items: {},
  originalItems: {}
};

interface BulkUpdateObj {
  [key: string]: Account;
}

export default function accountsReducer(
  state: AccountsFeatureState = defaultAccountsState,
  action: AccountsAction
): AccountsFeatureState {
  switch (action.type) {
    case AccountsActionTypes.RECEIVE_ACCOUNTS: {
      return {
        ...state,
        items: {
          ...state.items,
          ...keyBy(action.payload.accounts, 'id')
        }
      };
    }

    case AccountsActionTypes.RECEIVE_ORIGINAL_ACCOUNTS: {
      return {
        ...state,
        originalItems: {
          ...state.originalItems,
          ...keyBy(action.payload.accounts, 'id')
        }
      };
    }

    case AccountsActionTypes.UPDATE_ACCOUNTS: {
      const { accountIds, update } = action.payload;
      const bulkUpdateObj: BulkUpdateObj = reduce(
        accountIds,
        (acc: BulkUpdateObj, accountId: Account['id']) => {
          const currentAccountState = get(state, `items.${accountId}`, {});
          const newAccountState: Account = new Account({ ...currentAccountState, ...update });
          return {
            ...acc,
            [accountId]: newAccountState
          };
        },
        {}
      );
      return {
        ...state,
        items: {
          ...state.items,
          ...bulkUpdateObj
        }
      };
    }

    case AccountsActionTypes.DELETE_ACCOUNTS: {
      const { accountIds } = action.payload;
      const itemsAfterDeletions: AccountsFeatureState['items'] = omit(
        state.items,
        accountIds
      );
      return {
        ...state,
        items: itemsAfterDeletions
      };
    }

    case AccountsActionTypes.UPDATE_ACCOUNT_META: {
      const { accountId, update } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [accountId]: new Account({
            ...get(state, `items.${accountId}`, {}),
            _meta: {
              ...get(state, `items.${accountId}._meta`),
              ...update
            }
          })
        }
      };
    }

    case AccountsActionTypes.RESET_ACCOUNTS: {
      return { ...defaultAccountsState };
    }

    default:
      return state;
  }
}
