import { map } from 'lodash';
import BaseSyncItemEntity from './baseSyncItemEntity';
import CourseAuthoringSettings from 'models/courseAuthoringSettings';
import CourseNavSettings from 'models/courseNavSettings';
import CoursePageRatingSettings from 'models/coursePageRatingSettings';
import MigrationIssue from 'models/canvas/migrationIssue';
import Progress from 'models/canvas/progress';

export default class BaseSyncCourse extends BaseSyncItemEntity {
  courseId: string;
  name: string;
  courseCode: string;
  sisCourseId: string;
  migrationId: string;
  progressUrl: string;
  migrationIssuesUrl: string;
  progress: Progress;
  workflowState: string;
  migrationIssues: MigrationIssue[];
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
  tags: string[];
  enrollmentTermId: string;
  tempEnrollmentTermId: string;
  startDate: Date;
  endDate: Date;
  constructor(json: any) {
    super(json);
    this.courseId = json?.courseId ? String(json.courseId) : null;
    this.name = json?.name;
    this.courseCode = json?.courseCode;
    this.sisCourseId = json?.sisCourseId || null; // don't post empty ''
    this.migrationId = json?.migrationId;
    this.progressUrl = json?.progressUrl;
    this.migrationIssuesUrl = json?.migrationIssuesUrl;
    this.progress = new Progress(json?.progress);
    this.workflowState = json?.workflowState;
    this.migrationIssues = map(
      json?.migrationIssues,
      (migrationIssueJson: any) => new MigrationIssue(migrationIssueJson)
    );
    this.courseNavSettings = new CourseNavSettings(json?.courseNavSettings);
    this.courseAuthoringSettings = new CourseAuthoringSettings(json?.courseAuthoringSettings);
    this.coursePageRatingSettings = new CoursePageRatingSettings(json?.coursePageRatingSettings);
    this.tags = json?.tags || [];
    this.enrollmentTermId = json?.enrollmentTermId;
    this.tempEnrollmentTermId = json?.tempEnrollmentTermId;
    this.startDate = json?.startDate ? new Date(json.startDate) : null;
    this.endDate = json?.endDate ? new Date(json.endDate) : null;
  }
}
