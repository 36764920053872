import { useAppMenuDropdownManager } from 'hooks/useAppMenuDropdownManager';
import AppMenuDropdownConfig from 'models/appMenuDropdownConfig';
import React, { FC } from 'react';
import AppMenuContext from './appMenuContext';

interface IAppMenuDropdownProvider {
  appMenuConfigs: AppMenuDropdownConfig[],
  children: JSX.Element;
}

/**
 * Provider wrapper component for the AppMenuContext
 * @param @see {@link IAppMenuDropdownProvider} - Passes appMenuConfig to context and renders children item inside of context component
 */
const AppMenuDropdownProvider: FC<IAppMenuDropdownProvider> = ({appMenuConfigs, children}) => {
  return (
    <AppMenuContext.Provider value={useAppMenuDropdownManager(appMenuConfigs)}>
      {children}
    </AppMenuContext.Provider>
  );
};

export default AppMenuDropdownProvider;
