import { get, map } from 'lodash';

// links to our BE model CanvasPage, which is a partial implementation
// of Pageable. Only the accurate fields are included in this FE class
export default class CanvasPageable<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  // The current page number
  number: number;
  // The number of elements in this response
  numberOfElements: number;
  // The max number of elements in a page
  size: number;
  totalPages: number;

  constructor(json: any, ContentClass: new (itemJson: any) => T) {
    this.content = map(
      get(json, 'content'),
      (itemJson: any) => new ContentClass(itemJson)
    );
    this.empty = !!get(json, 'empty');
    this.first = !!get(json, 'first');
    this.last = !!get(json, 'last');
    this.number = get(json, 'number');
    this.numberOfElements = get(json, 'numberOfElements');
    this.size = get(json, 'size');
    this.totalPages = get(json, 'totalPages');
  }
}
