import React from 'react';
import { Switch } from './style';

export interface ISwitchButtonProps {
  id: string;
  name: any;
  type: string;
  checked: any;
  handleSwitch: any;
  dataFor?: string;
  dataTip?: string;
}

const SwitchButton = React.memo(({ id, name, type, checked, handleSwitch, dataFor, dataTip }: ISwitchButtonProps) => (
  <Switch className='toggle-switch' data-for={dataFor} data-tip={dataTip}>
    <input 
      id={id}    
      name={name}
      type={type}
      defaultChecked={checked}  
      onChange={ (e: any)=> {e.stopPropagation();handleSwitch(e.target.checked);}}
      data-testid={name}
    />
    <span className='switch'/>
  </Switch>
));

export default SwitchButton;
