import { getUuid } from 'helpers';
import Account from 'models/canvas/account';
import React, { FC, useCallback } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { receiveAccounts } from 'store/accounts/actions';
import { Button } from '../..';
import AccountSelect from '..';
import { NewAccountPopoverContainer } from './style';
import { trim } from 'lodash';

interface INewAccountPopoverContent {
  onCreate: (newAccountTempId: string, newAccount: Account) => void;
  onCancel: () => void;
  id: string;
}

interface INewAccountFormVals {
  name: string;
  parentAccountId: string;
}

export const NewAccountPopoverContent: FC<INewAccountPopoverContent> = ({ onCreate, onCancel, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { control, register, getValues, watch } = useForm<INewAccountFormVals>({});
  const nameVal = watch('name');
  const parentAccountIdVal = watch('parentAccountId');

  const handleAddClick = useCallback(() => {
    const { name, parentAccountId } = getValues();
    const tempAccountId = getUuid();
    const newAccount = new Account({
      id: tempAccountId,
      name: trim(name),
      parent_account_id: parentAccountId,
      _meta: { tempAccountId }
    });
    dispatch(receiveAccounts([newAccount]));
    onCreate(tempAccountId, newAccount);
  }, [getValues, dispatch, onCreate]);

  return (
    <NewAccountPopoverContainer className='new-account-popover-content-container'>
      <fieldset className='name-fieldset'>
        <label htmlFor='new-account-popover-name'>{t('name')}</label>
        <input
          id='new-account-popover-name'
          name='name'
          placeholder={t('required')}
          ref={register()}
          type='text'
        />
      </fieldset>
      <fieldset className='parent-account-id-fieldset'>
        <label htmlFor='new-account-popover-parent-account'>{t('parentAccount')}</label>
        <Controller
          control={control}
          name='parentAccountId'
          defaultValue={null}
          render={({ name, value, onChange }) => (
            <AccountSelect
              allowCreate={false}
              id='new-account-popover-parent-account'
              name={name}
              defaultValue={value}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </fieldset>
      <div className='actions'>
        <Button
          color='secondary'
          onClick={onCancel}
          size='small'
          testId='new-account-popover-cancel-button'
        >
          {t('cancel')}
        </Button>
        <Button
          color='primary'
          testId='new-account-popover-ok-button'
          size='small'
          onClick={handleAddClick}
          disabled={!trim(nameVal) || !parentAccountIdVal}
        >
          {t('create')}
        </Button>
      </div>
    </NewAccountPopoverContainer>
  );
};
