import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { isBoolean } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { SwitchButton } from 'components/elements';

import { CourseModalCheckboxesWrapper } from '../../Modals/style';
import { SelectionCheckbox } from '../index';
import { FormFieldGroup } from '../../general-styles';
import { Tooltip } from '../ToggleTooltip';

interface IEnhancedAuthoringSettings {
  show: boolean;
  showTitle?: boolean;
  showLabel?: boolean;
  readOnly?: boolean;
  indeterminate?: boolean;
  id: string;
  control: any;
  defaultValue?: any;
  namePrefix?: string;
  isToggle?: boolean;
  handleSwitch?: any;
  extraBehavior?: (currentStatus: boolean) => void;
}

const EnhancedAuthoringSettings: FC<IEnhancedAuthoringSettings> = ({
  show,
  showTitle = true,
  showLabel = false,
  readOnly = false,
  indeterminate = false,
  defaultValue,
  id,
  control,
  namePrefix = '',
  isToggle,
  handleSwitch,
  extraBehavior = (currentStatus: boolean) => {return;}
}) => {
  const uid = `${id}-${uuidv4()}`;
  const { t } = useTranslation();
  const [isIndeterminate, setIndeterminate] = useState(indeterminate);
  const authoringSettingName = `${namePrefix ? `${namePrefix}.` : ''}courseAuthoringSettings.enabled`;
  const authoringDefaultValue = isBoolean(defaultValue) ? defaultValue : !!defaultValue?.enabled;
  const isVisibleToggle = show && isToggle;
  const isVisibleCheckbox = show && !isToggle;
  return (
    <>
    { isVisibleToggle ? (
      <Controller
        control={control}
        name={authoringSettingName}
        defaultValue={authoringDefaultValue}
        render={({name, value, onChange}) => {
          return (
            <div className='switchContainer'>
              <SwitchButton
                id={`${id}-courseAuthoringSettings`}
                name={name}
                type='checkbox'
                dataTip={t('DesignToolsMenuTooltip')}
                dataFor={`${uid}-toggle-tooltip`}
                checked={value}
                handleSwitch={ (e: any) => {onChange(e); handleSwitch();}}             
              />
              <label className='switchLabel'>{t('DesignToolsMenu')}</label>
            </div>                    
            );
        }}
      />
    ) : (
      isVisibleCheckbox && (
        <FormFieldGroup className='course-authoring-section'>
          {
            showTitle && (
              <>
                <h3>{t('modalCourseAuthoringTitle')}</h3>
                <p>{t('modalCourseAuthoringDescription')}</p>
              </>
            )
          }
          <CourseModalCheckboxesWrapper id={`${id}-course-authoring`}>
            {
              showLabel && (
                <label htmlFor={`${id}-course-authoring`}>
                  {t('modalCourseAuthoringTitle')}
                </label>
              )
            }
            <div>
              <Controller
                control={control}
                name={authoringSettingName}
                defaultValue={authoringDefaultValue}
                render={({ name, value, onChange }) => {
                  return (
                    <SelectionCheckbox
                      readOnly={readOnly}
                      id={`${id}-course-authoring-enabled`}
                      name={name}
                      selected={value}
                      onClick={(currentStatus) => {
                        setIndeterminate(false);
                        onChange(currentStatus);
                        extraBehavior(currentStatus);
                      }}
                      indeterminate={isIndeterminate}
                      dataTip={t('UseHomeSettingsTooltip')}
                      dataFor={`${uid}-button-tooltip`}
                    >
                      {t('enableEnhancedCourseAuthoring')}
                    </SelectionCheckbox>
                  );
                }}
              />
            </div>
            
          </CourseModalCheckboxesWrapper>
        </FormFieldGroup>
      )
    )
    }
    <Tooltip
        aria-haspopup='true'
        delayShow={1250}
        event='focus mouseover'
        eventOff='blur mouseout'
        multiline
        id={`${uid}-toggle-tooltip`}
        place='right'
      />
    </>
  );
};

export default EnhancedAuthoringSettings;
