import Course from 'models/canvas/course';
import CourseMeta from 'models/courseMeta';
import Account from 'models/canvas/account';
import { CoursesFetchStatus } from './reducer';

/** TYPES  */

export enum CoursesActionTypes {
  FETCH_PAGINATED_COURSES = 'courses/FETCH_PAGINATED_COURSES',
  FETCH_COURSE = 'courses/FETCH_COURSE',
  RECEIVE_COURSES = 'courses/RECEIVE_COURSES',
  UPDATE_COURSES = 'courses/UPDATE_COURSES',
  APPLY_TEMPLATES = 'courses/APPLY_TEMPLATES',
  DELETE_COURSES = 'courses/DELETE_COURSES',
  UPDATE_COURSE_META = 'courses/UPDATE_COURSE_META',
  UPDATE_COURSE_METAS = 'courses/UPDATE_COURSE_METAS',
  UPDATE_COURSES_METAS = 'courses/UPDATE_COURSES_METAS',
  RESET_COURSES = 'courses/RESET_COURSES',
  SET_COURSES_FETCH_STATUS = 'courses/SET_COURSES_FETCH_STATUS',
  SET_AUTOGEN_COUNTER = 'courses/SET_AUTOGEN_COUNTER',
  RECEIVE_ORIGINAL_COURSES = 'courses/RECEIVE_ORIGINAL_COURSES'
}

/** Trigger the Epic to fetch all pages */
export interface FetchPaginatedCoursesAction {
  type: typeof CoursesActionTypes.FETCH_PAGINATED_COURSES;
  payload: { accountId: Account['id'] };
}

export interface FetchCourseAction {
  type: typeof CoursesActionTypes.FETCH_COURSE;
  payload: { courseId: Course['id'] };
}

export interface ReceiveCoursesAction {
  type: typeof CoursesActionTypes.RECEIVE_COURSES;
  payload: { courses: Course[]; };
}

export interface UpdateCoursesAction {
  type: typeof CoursesActionTypes.UPDATE_COURSES;
  payload: { courseIds: Course['id'][]; update: Partial<Course> };
}

export interface ApplyTemplatesAction {
  type: typeof CoursesActionTypes.APPLY_TEMPLATES;
  payload: { courseIds: Course['id'][]; courseTemplateId: string };
}

export interface DeleteCoursesAction {
  type: typeof CoursesActionTypes.DELETE_COURSES;
  payload: { courseIds: Course['id'][]; };
}

export interface UpdateCourseMetaAction {
  type: typeof CoursesActionTypes.UPDATE_COURSE_META;
  payload: { courseId: Course['id'], update: Partial<CourseMeta> };
}

export interface UpdateCourseMetasAction {
  type: typeof CoursesActionTypes.UPDATE_COURSE_METAS;
  payload: { courseIds: Course['id'][], update: Partial<CourseMeta> };
}

export interface UpdateCoursesMetasAction {
  type: typeof CoursesActionTypes.UPDATE_COURSES_METAS;
  payload: { updates: Partial<CourseMeta>[] };
}

export interface ResetCoursesAction {
  type: typeof CoursesActionTypes.RESET_COURSES;
}

export interface SetCoursesFetchStatusAction {
  type: typeof CoursesActionTypes.SET_COURSES_FETCH_STATUS;
  payload: CoursesFetchStatus;
}

export interface SetAutogenCounterAction {
  type: typeof CoursesActionTypes.SET_AUTOGEN_COUNTER;
  payload: { count: number };
}

export interface ReceiveOriginalCoursesAction {
  type: typeof CoursesActionTypes.RECEIVE_ORIGINAL_COURSES;
  payload: { courses: Course[] };
}

export type CoursesAction =
  | FetchPaginatedCoursesAction
  | FetchCourseAction
  | ReceiveCoursesAction
  | UpdateCoursesAction
  | ApplyTemplatesAction
  | DeleteCoursesAction
  | UpdateCourseMetaAction
  | UpdateCourseMetasAction
  | UpdateCoursesMetasAction
  | ResetCoursesAction
  | SetCoursesFetchStatusAction
  | SetAutogenCounterAction
  | ReceiveOriginalCoursesAction;

/** ACTION CREATORS */

export const fetchPaginatedCourses = (accountId: Account['id']): FetchPaginatedCoursesAction => ({
  type: CoursesActionTypes.FETCH_PAGINATED_COURSES,
  payload: { accountId }
});

export const fetchCourse = (courseId: Course['id']): FetchCourseAction => ({
  type: CoursesActionTypes.FETCH_COURSE,
  payload: { courseId }
});

export const receiveCourses = (courses: Course[]): ReceiveCoursesAction => ({
  type: CoursesActionTypes.RECEIVE_COURSES,
  payload: { courses }
});

export const updateCourses = (courseIds: Course['id'][], update: Partial<Course>): UpdateCoursesAction => ({
  type: CoursesActionTypes.UPDATE_COURSES,
  payload: { courseIds, update }
});

export const applyTemplates = (courseIds: Course['id'][], courseTemplateId: string): ApplyTemplatesAction => ({
  type: CoursesActionTypes.APPLY_TEMPLATES,
  payload: { courseIds, courseTemplateId }
});

export const deleteCourses = (courseIds: Course['id'][]): DeleteCoursesAction => ({
  type: CoursesActionTypes.DELETE_COURSES,
  payload: { courseIds }
});

export const updateCourseMeta = (courseId: Course['id'], update: Partial<CourseMeta>): UpdateCourseMetaAction => ({
  type: CoursesActionTypes.UPDATE_COURSE_META,
  payload: { courseId, update }
});

export const updateCourseMetas = (courseIds: Course['id'][], update: Partial<CourseMeta>): UpdateCourseMetasAction => ({
  type: CoursesActionTypes.UPDATE_COURSE_METAS,
  payload: { courseIds, update }
});

export const updateCoursesMetas = (updates: Partial<CourseMeta>[]): UpdateCoursesMetasAction => ({
  type: CoursesActionTypes.UPDATE_COURSES_METAS,
  payload: { updates }
});

export const resetCourses = (): ResetCoursesAction => ({
  type: CoursesActionTypes.RESET_COURSES
});

export const setCoursesFetchStatus = (status: CoursesFetchStatus): SetCoursesFetchStatusAction => ({
  type: CoursesActionTypes.SET_COURSES_FETCH_STATUS,
  payload: { ...status }
});

export const setAutogenCounter = (count: number): SetAutogenCounterAction => ({
  type: CoursesActionTypes.SET_AUTOGEN_COUNTER,
  payload: { count }
});

export const receiveOriginalCourses = (courses: Course[]): ReceiveOriginalCoursesAction => ({
  type: CoursesActionTypes.RECEIVE_ORIGINAL_COURSES,
  payload: { courses }
});
