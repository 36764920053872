import { TFunction } from 'i18next';
import { Dispatch } from 'redux';
import { getUuid } from 'helpers';
import { map, concat } from 'lodash';

import Account from 'models/canvas/account';
import Course from 'models/canvas/course';
import DefaultBulkCourseSettings from 'models/defaultBulkCourseSettings';

import { receiveAccounts, deleteAccounts } from 'store/accounts/actions';
import { setAccountsExpanded, setActiveAccount } from 'store/account-tree/actions';
import { toggleModal } from 'store/modals/actions';
import { deleteCourses, receiveCourses } from 'store/courses/actions';
import { getAccountItemsMap, getEditableInputOfAccount } from 'store/accounts/selectors';
import { ModalId } from 'constants/index';
import { toast } from 'helpers/messages';
import { AppState } from 'store';
import { CoursesFeatureState } from 'store/courses/reducer';
import { getCourseItems } from 'store/courses/selectors';
import { AccountsFeatureState } from './reducer';
import { getTopAccountId } from 'store/installation/selectors';


export const triggerAddSubAccount = (accountId: Account['id'], courseBulkSettings: DefaultBulkCourseSettings, t: TFunction) => {
  return (dispatch: Dispatch) => {
    const tempAccountId = getUuid();
    const newAccount = new Account({
      id: tempAccountId,
      name: t('newAccount'),
      parent_account_id: accountId,
      _meta: {
        tempAccountId,
        defaultCourseBulkSettings:{...courseBulkSettings}
      }
    });

    dispatch(receiveAccounts([newAccount]));
    dispatch(setAccountsExpanded([accountId], true));
    dispatch(setActiveAccount(tempAccountId));

    setTimeout(() => {
      const editableInput = getEditableInputOfAccount(newAccount.id);
      if (editableInput) {
        editableInput.click();
      }
    }, 400);
  };
};

export const triggerAddCourses = () => {
  return (dispatch: Dispatch) => {
    dispatch(toggleModal(ModalId.ADD_COURSES, true, { }));
  };
};

export const triggerDelete = (
  accountId: Account['id'],
  descendentCoursesIds: Course['id'][],
  descendentAccountIds: Account['id'][],
  t: TFunction,
  appState: AppState
) => {
  const accountIdsToDelete = concat(accountId, descendentAccountIds);

  const courseItems: CoursesFeatureState['items'] = getCourseItems(appState);
  const accountItems: AccountsFeatureState['items'] = getAccountItemsMap(appState);
  const topAccountId: string = getTopAccountId(appState);

  const coursesToDelete = map(descendentCoursesIds, (courseId: string) => courseItems?.[courseId]);
  const accountsToDelete = map(accountIdsToDelete, (acctId: string) => accountItems?.[acctId]);

  const deleteCount = (coursesToDelete?.length || 0) + (accountsToDelete?.length || 0);

  return (dispatch: Dispatch) => {
    const onUndo = () => {
      dispatch(receiveAccounts(accountsToDelete));
      dispatch(receiveCourses(coursesToDelete));
      dispatch(setActiveAccount(topAccountId));
    };

    const onConfirm = () => {
      dispatch(deleteCourses(descendentCoursesIds));
      dispatch(deleteAccounts([accountId, ...descendentAccountIds]));
      dispatch(setActiveAccount(topAccountId));
      toast.successWithUndo(t('toastDeletedItemsWithCount', { count: deleteCount }), onUndo);
    };

    toast.confirm(t('confirmDeleteSelectedAccount'), onConfirm);
  };
};
