import React, { FC, useCallback, useMemo } from 'react';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import Course from 'models/canvas/course';
import { ColumnDef } from 'modules/data-table/types';
import { useTranslation } from 'react-i18next';
import { getCourseIsLocked } from 'store/general/cross-selectors';
import { DateCellWrapper } from './style';
import { getDateCellStringValue } from 'config/table';
import { useDispatch } from 'react-redux';
import { updateCourses } from 'store/courses/actions';
import { EditSingleValueButton } from 'components/elements/EditSingleValueButton';

interface IDateCell {
  columnDef: ColumnDef;
  row: Course;
  value: Date;
}

export const DateCell: FC<IDateCell> = ({
  columnDef,
  row,
  value
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fieldNames: { [columnId: string]: string } = { start_at: 'start_at', end_at: 'end_at' };
  const fieldName: string = fieldNames[columnDef?.columnId];

  const courseId = row?.id;
  const locked = useSelectorWithProps(getCourseIsLocked, { courseId });

  const displayValue = useMemo(() => {
    const displayDate = fieldName === fieldNames.start_at 
      ? row.displayStartDate : row.displayEndDate;
    return getDateCellStringValue(displayDate, t);
  }, [row, t]);

  const canEdit = !locked;

  const handleUpdate = useCallback((newDate: Date) => {
    const update: Partial<Course> = { [fieldName]: newDate };
    dispatch(updateCourses([courseId], update));
  }, [dispatch, fieldName, courseId]);

  return (
    <DateCellWrapper className='date-cell-wrapper'>
      <EditSingleValueButton
        canEdit={canEdit}
        currentValue={value}
        onUpdate={handleUpdate}
        type='date'
      >
        {displayValue}
      </EditSingleValueButton>
    </DateCellWrapper>
  );
};
