import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { isBoolean } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { CourseModalCheckboxesWrapper } from '../../Modals/style';
import { SelectionCheckbox, SwitchButton } from '../index';
import { FormFieldGroup } from '../../general-styles';
import { Tooltip } from '../ToggleTooltip';

interface IEnhancedPageRatingSettings {
  show: boolean;
  showTitle?: boolean;
  showLabel?: boolean;
  readOnly?: boolean;
  indeterminate?: boolean;
  id: string;
  control: any;
  defaultValue?: any;
  namePrefix?: string;
  isToggle?: boolean;
  handleSwitch?: any;
  extraBehavior?: (currentStatus: boolean) => void;
}

const EnhancedPageRatingSettings: FC<IEnhancedPageRatingSettings> = ({
  show = true,
  showTitle = true,
  showLabel = false,
  readOnly = false,
  indeterminate = false,
  defaultValue,
  id,
  control,
  namePrefix = '',
  isToggle,
  handleSwitch,
  extraBehavior = (currentStatus: boolean) => {return;}
}) => {
  const uid = `${id}-${uuidv4()}`;
  const { t } = useTranslation();
  const [isIndeterminate, setIndeterminate] = useState(indeterminate);
  const pageRatingSettingName = `${namePrefix ? `${namePrefix}.` : ''}coursePageRatingSettings.enabled`;
  const pageRatingDefaultValue = isBoolean(defaultValue) ? defaultValue : !!defaultValue?.enabled;

  const isVisibleToggle = show && isToggle;
  const isVisibleCheckbox = show && !isToggle;

  return (
    <>
    { isVisibleToggle ? (
      <Controller
        control={control}
        name={pageRatingSettingName}
        defaultValue={pageRatingDefaultValue}
        render={({name, value, onChange}) => {
          return (
            <div className='switchContainer'>
              <SwitchButton
                id={`${id}-coursepageRatingSettings`}
                name={name}
                type='checkbox'
                dataTip={t('PageRatingTooltip')}
                dataFor={`${uid}-button-tooltip`}
                checked={value}
                handleSwitch={ (e: any) => {onChange(e); handleSwitch();}}                
              />
              <label className='switchLabel'>
                {t('PageRating')}
              </label>
            </div>                    
            );
        }}
      />
    ) : ( 
      isVisibleCheckbox && (
        <FormFieldGroup className='page-rating-section'>
          {
            showTitle && (
              <>
                <h3>{t('modalCoursePageRatingTitle')}</h3>
                <p>{t('modalCoursePageRatingDescription')}</p>
              </>
            )
          }
          <CourseModalCheckboxesWrapper id={`${id}-page-rating`}>
            {
              showLabel && (
                <label htmlFor={`${id}-page-rating`}>
                  {t('modalCoursePageRatingTitle')}
                </label>
              )
            }
            <div>
              <Controller
                control={control}
                name={pageRatingSettingName}
                defaultValue={pageRatingDefaultValue}
                render={({ name, value, onChange }) => {
                  return (
                    <SelectionCheckbox
                      readOnly={readOnly}
                      id={`${id}-course-page-rating-enabled`}
                      name={name}
                      selected={value}
                      onClick={(currentStatus) => {
                        setIndeterminate(false);
                        onChange(currentStatus);
                        extraBehavior(currentStatus);
                      }}
                      indeterminate={isIndeterminate}
                    >
                      {t('enablePageRating')}
                    </SelectionCheckbox>
                  );
                }}
              />
            </div>
          </CourseModalCheckboxesWrapper>
        </FormFieldGroup>
      )
    )
    }
      <Tooltip
        aria-haspopup='true'
        delayShow={1250}
        event='focus mouseover'
        eventOff='blur mouseout'
        multiline
        id={`${uid}-button-tooltip`}
        place='right'
      />
    </>
  );
};

export default EnhancedPageRatingSettings;
