import React, { FC, memo, useState, useCallback, useRef, useEffect } from 'react';
import cn from 'classnames';
import { DragResizeHandle } from '../DragResizeHandle';
import { usePrevious } from 'hooks/usePrevious';
import { useTranslation } from 'react-i18next';

export const HANDLE_WIDTH = 18;
export const CLOSE_SNAP_ZONE = 30;
export const KEY_INCREMENT = 20;

export interface IResizableColumn {
  children?: any;
  defaultWidth?: number|string;
  forceFull?: boolean;
}

export const ResizableColumn: FC<IResizableColumn> = memo(({
  children,
  defaultWidth,
  forceFull
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(defaultWidth);
  const [closed, setClosed] = useState(false);
  const [showChildren, setShowChildren] = useState(true);
  const [widthToRestore, setWidthToRestore] = useState(defaultWidth);
  const prevForceFull = usePrevious(forceFull);
  const changingForceFull = forceFull !== prevForceFull;

  const prevClosed = usePrevious(closed);
  const justClosed = !prevClosed && closed;

  const shouldHideChildren = (justClosed || (!forceFull && closed));
  const shouldShowChildren = (!closed || forceFull);

  // Sets the current width and memoizes the width as the restore point when going closed -> open
  const updateWidth = useCallback((w: number) => {
    const widthWithinSnapZone = w < HANDLE_WIDTH + CLOSE_SNAP_ZONE;
    // snap closed if within snap zone
    setWidth(widthWithinSnapZone ? HANDLE_WIDTH : w);
    setWidthToRestore(widthWithinSnapZone ? HANDLE_WIDTH + CLOSE_SNAP_ZONE : w);
    setClosed(widthWithinSnapZone);
  }, [setWidth, setWidthToRestore, setClosed]);

  // Drags the column with the mouse, by dragging the draggable area
  const handleDrag = useCallback((e: MouseEvent) => {
    setClosed(false);
    let outOfBounds = e.clientX < HANDLE_WIDTH
      || e.clientX > (window.innerWidth - HANDLE_WIDTH);
    const draggingFromClosed = closed && e.clientX < HANDLE_WIDTH + CLOSE_SNAP_ZONE;
    // If closed, don't set width until user drags beyond snap zone
    if (draggingFromClosed) {
      outOfBounds = true;
    }
    const newWidth = outOfBounds ? HANDLE_WIDTH : e.clientX;
    updateWidth(newWidth);
  }, [updateWidth, setClosed, closed]);

  // Drags the columns with the keyboard, by pressing the arrows keys (left and right)
  const handleArrow = useCallback((dir: 'left'|'right') => {
    if (closed && dir === 'right') {
      updateWidth(HANDLE_WIDTH + CLOSE_SNAP_ZONE + 1);
      setClosed(false);
      return;
    }
    const currentRect = (ref.current as HTMLDivElement).getBoundingClientRect();
    const currentWidth = (currentRect as DOMRect).width;
    let newWidth = dir === 'left'
      ? currentWidth - KEY_INCREMENT
      : currentWidth + KEY_INCREMENT;
    if (newWidth < HANDLE_WIDTH) {
      newWidth = HANDLE_WIDTH;
    } else if (newWidth > window.innerWidth - HANDLE_WIDTH) {
      newWidth = window.innerWidth - HANDLE_WIDTH;
    }
    updateWidth(newWidth);
  }, [closed, setClosed, updateWidth]);

  // Close or open the column when the drag handle is clicked
  const handleClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    if (closed) {
      // restore the pre-closed width, but if it's super small, then just restore the default width
      const newWidth = widthToRestore > HANDLE_WIDTH + CLOSE_SNAP_ZONE
        ? widthToRestore
        : defaultWidth;
      setWidth(newWidth);
    }
    setClosed(!closed);
  }, [closed, setClosed, setWidth, widthToRestore, defaultWidth]);

  // After closing, hide the column contents to prevent keyboard focusability of contents
  useEffect(() => {
    let timeout: number;
    if (shouldHideChildren) {
      timeout = window.setTimeout(() => {
        setShowChildren(false);
      }, 500);
    }

    if (shouldShowChildren) {
      setShowChildren(true);
    }

    // effect cleanup
    return () => clearTimeout(timeout);
  }, [justClosed, setShowChildren, closed, forceFull, shouldHideChildren, shouldShowChildren]);

  return (
    <div
      className={cn('resizable-column', { 'changing-force-full': changingForceFull, 'force-full': forceFull, closed })}
      ref={ref}
      style={{
        width: forceFull
          ? '100%'
          : (closed ? HANDLE_WIDTH : width)
      }}
    >
      {showChildren && children}
      <DragResizeHandle
        onClick={handleClick}
        onDrag={handleDrag}
        onArrowLeft={() => handleArrow('left')}
        onArrowRight={() => handleArrow('right')}
        width={HANDLE_WIDTH}
        t={t}
      />
    </div>
  );
});
