import styled, { css } from 'styled-components';

interface IFunctionMenuBarContainer {
  expanded: boolean;
}

interface IExpandCollapseContainer {
  expanded: boolean;
}

export const FunctionMenuBarContainer = styled.menu<IFunctionMenuBarContainer>`
  ${({ theme, expanded }) => css`
    &.function-menu-bar-container {
      background: linear-gradient(180deg, ${theme.color.mainDark2} 0%, ${theme.color.mainDark} 100%);
      color: ${theme.color.white};
      display: flex;
      justify-content: flex-start;
      margin: 0;
      padding: 0;

      &&${expanded && css`
        .function-menu-container {
          max-height: 6em;
          transition: max-height .1s ease;
          .function-menu {
            .function-menu-item {
              .function-menu-button {
                padding: 16px 4px;
                .function-button-icon {
                  margin: 0 0 4px;
                }
                .function-button-label {
                  max-height: 500px;
                  opacity: 1;
                  transition: opacity .2s .1s ease, max-height 0s .1s ease;
                }
              }
            }
          }
        }
      `}

      .function-menu-container {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        height: 100%;
        max-height: 2.6em;
        overflow-x: auto;
        overflow-y: hidden;
        transition: max-height .1s .2s ease;

        .function-menu {
          display: flex;
          height: 100%;
          list-style: none;
          margin: 0;
          padding: 0;
          width: 100%;

          .function-menu-divider-item {
            align-items: center;
            display: flex;
            margin: 0 3px;
            &:after {
              content: '';
              display: block;
              height: calc(100% - 1.7em);
              border-right: 1px solid ${theme.color.white};
              width: 1px;
            }
          }
          .function-menu-item {
            padding: 2px 0;

            .function-menu-button {
              align-items: center;
              background: transparent;
              border: 0;
              color: ${theme.color.white};
              display: flex;
              flex-direction: column;
              font-size: 13px;
              height: 100%;
              justify-content: center;
              padding: 10px 10px;
              position: relative;
              transition: all .2s ease;
              width: 100px;
              will-change: padding;

              .function-button-icon {
                height: 16px;
                margin: 0;
                z-index: 1;
              }

              .function-button-label {
                line-height: 1.3;
                opacity: 0;
                transition: opacity .2s ease, max-height 0s .3s ease;
                max-height: 0;
                z-index: 1;
              }

              .shortcut-marker {
                text-decoration: underline;
              }

              &:disabled {
                opacity: .5;

                &:hover {
                  color: ${theme.color.white};
                  cursor: not-allowed;

                  &:after {
                    opacity: 0;
                  }
                }
              }

              &:after {
                background: linear-gradient(180deg, ${theme.color.mainDarkActive} 0%, ${theme.color.mainDarkActive2} 100%);
                content: "";
                display: block;
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                transition: all .2s ease;
                width: 100%;
                z-index: 0;
              }

              &:hover,
              &:focus {
                cursor: pointer;

                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  `}
`;

export const ExpandCollapseContainer = styled.div<IExpandCollapseContainer>`
  ${({ theme, expanded }) => css` 
    &.expand-collapse-container {
      flex-shrink: 0;
      margin-left: auto;

      .expand-collapse-button {
        background: transparent;
        border: 0;
        border-radius: 4px;
        color: ${theme.color.white};
        cursor: pointer;
        margin: 10px 5px;

        .expand-collapse-icon {
          display: inline-block;
          transform: rotate(-90deg);
          transition: all .2s ease;
        }

        &:focus,
        &:hover {
          background: linear-gradient(180deg, ${theme.color.mainDarkActive} 0%, ${theme.color.mainDarkActive2} 100%);
        }
      }

      &&${expanded && css`
        .expand-collapse-button {
          .expand-collapse-icon {
            transform: rotate(0deg);
          }
        }
      `}
    }  
  `}
`;
