import React, { FC, memo, useMemo, useCallback, SyntheticEvent } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import FlipMove from 'react-flip-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DIVIDER, getFunctionMenuConfig, getButtonConfigs, FunctionButtonConfig } from 'config/function-menu';
import { getFunctionBarExpanded } from 'store/function-bar/selectors';
import { setExpanded } from 'store/function-bar/actions';

import { FunctionMenuItem } from './FunctionMenuItem';
import { FunctionMenuDividerItem } from './FunctionMenuDividerItem';
import { FunctionMenuBarContainer, ExpandCollapseContainer } from './style';
import { useTranslation } from 'react-i18next';
import { RouteMatch } from 'store/router/selectors';
import { RoutePathnames } from 'config/routePathnames';

interface IFunctionMenuBar {
  match: RouteMatch;
}

export const FunctionMenuBar: FC<IFunctionMenuBar> = memo(({
  match
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentMatchPath = get(match, 'path');
  const isSyncStatusView = currentMatchPath === RoutePathnames.SYNC_STATUS;
  const expanded = useSelector(getFunctionBarExpanded) && !isSyncStatusView;
  const containerId = 'function-menu-bar-container';


  const buttonIdsToRender = useMemo(() => {
    return getFunctionMenuConfig(currentMatchPath);
  }, [currentMatchPath]);

  // Expand or collapse the function menu
  const handleExpandToggleClick = useCallback((e: SyntheticEvent) => {
    dispatch(setExpanded(!expanded));
  }, [dispatch, expanded]);

  const buttonConfigs = useMemo(() => {
    return getButtonConfigs(t, match);
  }, [t, match]);

  return (
    <FunctionMenuBarContainer expanded={expanded} className='function-menu-bar-container' id={containerId}>
      <div className='function-menu-container'>
        <FlipMove
          className='function-menu'
          enterAnimation='fade'
          leaveAnimation='fade'
          easing='ease-out'
          typeName='ul'
          duration={400}
        >
          {buttonIdsToRender.map((id: string, i: number) => {
            const config: FunctionButtonConfig = get(buttonConfigs, id);
            return id === DIVIDER
              ? <FunctionMenuDividerItem key={`${DIVIDER}-${i}`} />
              : <FunctionMenuItem key={config.buttonId} config={config} />;
          })}
        </FlipMove>
      </div>

      <ExpandCollapseContainer
        className='expand-collapse-container'
        expanded={expanded}
      >
        {!isSyncStatusView ?
          <button
            className='expand-collapse-button'
            onClick={handleExpandToggleClick}
            aria-controls={containerId}
            aria-expanded={!!expanded}
            aria-label={`${expanded ? 'Collapse' : 'Expand'} Function Menu Bar.`}
          >
            <FontAwesomeIcon className='expand-collapse-icon' icon={['fas', 'chevron-down']} />
          </button> :
          <p>&nbsp;</p>
        }
      </ExpandCollapseContainer>
    </FunctionMenuBarContainer>
  );
});
