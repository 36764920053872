import styled, { css } from 'styled-components';

export const AppMenuBarContainer = styled.div`
  ${({ theme }) => css`
    &.app-menu-bar-container {
      align-items: center;
      background: ${theme.color.gray7};
      display: flex;
      height: 2.6em;
      justify-content: flex-start;
      width: 100%;
    }
  `}
`;
