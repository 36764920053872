import { Dispatch } from 'redux';
import { map, flatMap } from 'lodash';
import { ICourseDuplicationFormVals, ISourceCourseVals, IDuplicateCourseVals } from 'components/Modals/DuplicateCourses';
import CourseMeta from 'models/courseMeta';
import Course from 'models/canvas/course';
import CourseDuplication from 'models/courseDuplication';
import { getUuid } from 'helpers';
import { getTermItems } from 'store/terms/selectors';
import { AppState } from 'store';
import { receiveCourses, updateCourseMetas } from './actions';
import { getCourseById } from './selectors';


export function processCourseDuplicationForm(formVals: ICourseDuplicationFormVals, dispatch: Dispatch, appState: AppState): void {
  const termItems = getTermItems(appState);

  const newCourses: Course[] = flatMap(
    formVals.sourceCourses,
    (sourceCourseVals: ISourceCourseVals): Course[] => {
      const courses: Course[] = map(
        sourceCourseVals.duplicates,
        (duplicateVals: IDuplicateCourseVals): Course => {
          const tempCourseId = getUuid();
          const sourceCourse: Course = getCourseById({courseId: sourceCourseVals.sourceCourseId })(appState);
          const isRestrictedEnrollmentCourseDates = termItems?.[duplicateVals.termId]?.start_at !== duplicateVals.startDate || termItems?.[duplicateVals.termId]?.end_at !== duplicateVals.endDate;
          return new Course({
            id: tempCourseId,
            name: duplicateVals.name,
            course_code: duplicateVals.courseCode,
            account_id: duplicateVals.accountId,
            term: termItems?.[duplicateVals.termId],
            sis_course_id: duplicateVals.sisCourseId,
            start_at: duplicateVals.startDate,
            end_at: duplicateVals.endDate,
            restrict_enrollments_to_course_dates: isRestrictedEnrollmentCourseDates,
            _meta: {
              courseNavSettings: duplicateVals.courseNavSettings,
              courseAuthoringSettings: duplicateVals.courseAuthoringSettings,
              coursePageRatingSettings: duplicateVals.coursePageRatingSettings,
              tags: duplicateVals.tags,
              courseDuplication: {
                originCourseId: sourceCourseVals.sourceCourseId
              },
              sourceCourse: {
                id: sourceCourseVals.sourceCourseId,
                name: sourceCourse.name,
                course_code: sourceCourse.course_code
              },
              tempCourseId
            }
          });
        }
      );
      return courses;
    }
  );

  dispatch(receiveCourses(newCourses));
}

export function processCourseContentImport(destinationCourses: Course[], sourceCourse: Course, dispatch: Dispatch): void {
  const newMeta: Partial<CourseMeta> = {
    tags: sourceCourse?._meta?.tags,
    courseDuplication: new CourseDuplication({
      originCourseId: sourceCourse?.id,
    }),
    sourceCourse: {
      id: sourceCourse.id,
      name: sourceCourse.name,
      courseCode: sourceCourse.course_code
    },
  };
  const destinationCourseIds: string[] = map(destinationCourses, 'id');

  dispatch(updateCourseMetas(destinationCourseIds, newMeta));
}
