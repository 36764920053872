import { get, toString, trim, map } from 'lodash';
import CourseMeta from 'models/courseMeta';
import CourseTerm from './courseTerm';
import UserDisplay from './userDisplay';

export default class Course {
  account_id: string;
  account_name: string;
  course_code: string;
  end_at?: Date;
  id: string;
  name: string;
  sis_course_id: string;
  start_at?: Date;
  term?: CourseTerm;
  teachers?: UserDisplay[];
  uuid: string;
  restrict_enrollments_to_course_dates: boolean;
  workflow_state: 'available' | 'completed' | 'unpublished' | 'deleted';
  _meta: CourseMeta;

  constructor(json: any) {
    this.account_id = toString(get(json, 'account_id'));
    this.account_name = get(json, 'account_name');
    this.course_code = get(json, 'course_code');
    
    this.id = toString(get(json, 'id'));
    this.name = trim(get(json, 'name'));
    this.sis_course_id = toString(get(json, 'sis_course_id'));

    if (json.start_at instanceof Date){
      this.start_at = get(json, 'start_at') ? new Date(Date.parse(get(json, 'start_at'))) : null;
      this.end_at = get(json, 'end_at') ? new Date(Date.parse(get(json, 'end_at'))) : null;
    } else {
      this.start_at = get(json, 'start_at') ? new Date(get(json, 'start_at').millis) : null;
      this.end_at = get(json, 'end_at') ? new Date(get(json, 'end_at').millis) : null;
    }
    this.term = get(json, 'term') ? new CourseTerm(get(json, 'term')) : null;
    this.teachers = get(json, 'teachers')
      ? map(get(json, 'teachers'), (teacherJson: any) => new UserDisplay(teacherJson))
      : [];
    this.uuid = get(json, 'uuid');
    this.workflow_state = get(json, 'workflow_state');
    this.restrict_enrollments_to_course_dates = get(json, 'restrict_enrollments_to_course_dates', false);
    this._meta = new CourseMeta(get(json, '_meta'));
  }

  /**
   * Checks the restrict_enrollments_toCourse_dates value to determine if
   * the course start or term start should be used for the display
   * @return Date - Course Start if retricted, Term start if not
   */
  get displayStartDate(): Date {
    if (this.restrict_enrollments_to_course_dates) {
      return this.start_at;
    } else {
      return this.term?.start_at;
    }
  }

  /**
   * Checks the restrict_enrollments_toCourse_dates value to determine if
   * the course end or term end should be used for the display
   * @return Date - Course End if retricted, Term end if not
   */
  get displayEndDate(): Date {
    if (this.restrict_enrollments_to_course_dates) {
      return this.end_at;
    } else {
      return this.term?.end_at;
    }
  }
}
