import React, { FC } from 'react';
import { DataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';
import { useObservable } from 'rxjs-hooks';
import { ColumnDef } from 'modules/data-table/types';
import { HeaderCell } from '../HeaderCell';
import cn from 'classnames';

interface ITableHeader {
  api: DataTableObservables;
  tableId: string;
}

export const TableHeader: FC<ITableHeader> = ({
  api,
  tableId
}) => {
  const pinnedLeftColumnDefs = useObservable(() => api.pinnedLeftColumnDefs$, []);
  const centerColumnDefs = useObservable(() => api.centerColumnDefs$, []);
  const columnIndices = useObservable(() => api.columnIndices$, {});
  const columnVisibility = useObservable(() => api.columnVisibility$, {});
  const totalCenterWidth = useObservable(() => api.totalCenterWidth$);
  const totalPinnedLeftWidth = useObservable(() => api.totalPinnedLeftWidth$);
  const centerColumnsScrollPosition = useObservable(() => api.centerColumnsScrollPosition$, { top: 0, left: 0 });
  const centerIsScrolled = centerColumnsScrollPosition.left > 5;

  return (
    <div
      className={cn('table-header-container', { 'center-scrolled': centerIsScrolled })}
      data-testid='table-header-container'
      aria-rowindex={1}
      role='row'
    >
      <div
        className='table-header-pinned-left'
        role='presentation'
        style={{
          width: totalPinnedLeftWidth,
          minWidth: totalPinnedLeftWidth,
          maxWidth: totalPinnedLeftWidth
        }}
      >
        {pinnedLeftColumnDefs.map((columnDef: ColumnDef) => {
          return !!columnVisibility[columnDef.columnId] && (
            <HeaderCell
              key={`${tableId}-header-${columnDef.columnId}`}
              columnDef={columnDef}
              tableId={tableId}
              columnIndex={columnIndices[columnDef.columnId]}
              api={api}
            />
          );
        })}
      </div>
      <div className='table-header-center-viewport' role='presentation'>
        <div
          className='table-header-center-container'
          role='presentation'
          style={{
            width: totalCenterWidth,
            transform: `translate3d(-${centerColumnsScrollPosition.left}px, 0, 0)`
          }}
        >
          {centerColumnDefs.map((columnDef: ColumnDef) => {
            return !!columnVisibility[columnDef.columnId] && (
              <HeaderCell
                key={`${tableId}-header-${columnDef.columnId}`}
                columnDef={columnDef}
                tableId={tableId}
                columnIndex={columnIndices[columnDef.columnId]}
                api={api}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
