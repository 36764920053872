import { forEach, flatMap, mapValues, filter, values, map } from 'lodash';
import { ISyncTypeData, ItemTypes } from 'constants/index';
import SyncOrderDto from 'models/dto/syncOrderDto';
import BaseSyncItemEntity from 'models/syncOrder/baseSyncItemEntity';
import { createSelector } from 'reselect';
import { courseExistsInLmsAlready, courseIsUnsyncedDuplicate, courseIsLocked, courseIsUnsynced, getAllCourses,
  getCourseById, getCourseItems, getOriginalCourseById, getOriginalCourseItems,
  PerCourseProps, getAllOriginalCourses} from 'store/courses/selectors';
import { getAllCompletedSyncOrderDtos, getAllActiveSyncOrderDtos, getAllPendingSyncOrderDtos, getSyncItemTypeData, itemsForSyncOrder } from '../syncOrders/selectors';
import { CoursesFeatureState } from 'store/courses/reducer';
import Course from 'models/canvas/course';

import { accountExistsInLmsAlready, getAccountAncestorMap, getAccountItemsMap, accountIsLocked, getAccountById, PerAccountProps, accountIsUnsynced, getAllAccounts, getOriginalAccountsMap } from 'store/accounts/selectors';
import { AccountsFeatureState } from 'store/accounts/reducer';
import Account from 'models/canvas/account';
import { getUuid } from 'helpers';
import SyncNewDupCourse from 'models/syncOrder/syncNewDupCourse';
import SyncExistingCourse from 'models/syncOrder/syncExistingCourse';
import SyncNewCourse from 'models/syncOrder/syncNewCourse';
import SyncNewAccount from 'models/syncOrder/syncNewAccount';
import Installation from 'models/installation';
import { getInstallation } from 'store/installation/selectors';
import { memoizeSelectorFactory } from 'store/selector-helpers';
import SyncExistingDupCourse from 'models/syncOrder/syncExistingDupCourse';
import { getOriginalTermItems, getTermItems, getTerms, termExistsInLmsAlready, termIsUnsynced } from 'store/terms/selectors';
import EnrollmentTerm from 'models/canvas/enrollmentTerm';
import { TermsFeatureState } from 'store/terms/reducer';
import SyncNewTerm from 'models/syncOrder/syncNewTerm';
import { CONSTANTS } from 'config';
import SyncExistingAccount from 'models/syncOrder/syncExistingAccount';
import { find } from 'lodash';

/**
 * These selectors have dependencies from multiple store states, or depend on each other.
 * This is a file in which to keep such selectors in order to avoid circular depedency issues.
 */

// COURSES
export const getActivelySyncingCoursesMap = createSelector(
  getAllActiveSyncOrderDtos,
  (activeSyncOrderDtos: SyncOrderDto[]): { [courseId: string]: boolean } => {
    const activeCourseMap: { [courseId: string]: boolean } = {};
    const allActiveItems: BaseSyncItemEntity[] = flatMap(activeSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allActiveItems, (activeItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(activeItem);
      if (itemType !== ItemTypes.COURSE) { return; }
      const courseIdProp = isNew ? 'tempCourseId' : 'courseId';
      const courseId = (activeItem as any)?.[courseIdProp];
      activeCourseMap[courseId] = true;
    });
    return activeCourseMap;
  }
);

export const getPendingSyncingCoursesMap = createSelector(
  getAllPendingSyncOrderDtos,
  (pendingSyncOrderDtos: SyncOrderDto[]): { [courseId: string]: boolean } => {
    const pendingCourseMap: { [courseId: string]: boolean } = {};
    const allPendingItems: BaseSyncItemEntity[] = flatMap(pendingSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allPendingItems, (pendingItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(pendingItem);
      if (itemType !== ItemTypes.COURSE) { return; }
      const courseIdProp = isNew ? 'tempCourseId' : 'courseId';
      const courseId = (pendingItem as any)?.[courseIdProp];
      pendingCourseMap[courseId] = true;
    });
    return pendingCourseMap;
  }
);

export const getCourseUnsyncedMap = createSelector(
  getCourseItems,
  getOriginalCourseItems,
  getActivelySyncingCoursesMap,
  (
    courseItems: CoursesFeatureState['items'],
    originalItems: CoursesFeatureState['originalItems'],
    syncingCourses: { [courseId: string]: boolean }
  ): { [courseId: string]: boolean } => {
    return mapValues(courseItems, (c: Course): boolean => {
      const original: Course = originalItems?.[c?.id];
      const isSyncing: boolean = !!syncingCourses?.[c?.id];
      
      return courseIsUnsynced(c, original) && !isSyncing;
    });
  }
);

export const getCompletedSyncingCoursesMap = createSelector(
  getAllCompletedSyncOrderDtos,
  getCourseUnsyncedMap,
  (completedSyncOrderDtos: SyncOrderDto[], unsyncedCourses: { [courseId: string]: boolean }): { [courseId: string]: boolean } => {
    const completedCourseMap: { [courseId: string]: boolean } = {};
    const allCompletedItems: BaseSyncItemEntity[] = flatMap(completedSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allCompletedItems, (completedItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(completedItem);
      if (itemType !== ItemTypes.COURSE) {
        return;
      }
      const courseIdProp = isNew ? 'tempCourseId' : 'courseId';
      const courseId = (completedItem as any)?.[courseIdProp];
      if (!unsyncedCourses[courseId])
      completedCourseMap[courseId] = true;
    });
    return completedCourseMap;
  }
);

// ACCOUNTS
export const getActivelySyncingAccountsMap = createSelector(
  getAllActiveSyncOrderDtos,
  (activeSyncOrderDtos: SyncOrderDto[]): { [accountId: string]: boolean } => {
    const activeAccountMap: { [accountId: string]: boolean } = {};
    const allActiveItems: BaseSyncItemEntity[] = flatMap(activeSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allActiveItems, (activeItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(activeItem);
      if (itemType !== ItemTypes.ACCOUNT) { return; }
      const accountIdProp = isNew ? 'tempAccountId' : 'accountId';
      const accountId = (activeItem as any)?.[accountIdProp];
      activeAccountMap[accountId] = true;
    });
    return activeAccountMap;
  }
);

export const getPendingSyncingAccountsMap = createSelector(
  getAllPendingSyncOrderDtos,
  (pendingSyncOrderDtos: SyncOrderDto[]): { [accountId: string]: boolean } => {
    const pendingAccountMap: { [accountId: string]: boolean } = {};
    const allPendingItems: BaseSyncItemEntity[] = flatMap(pendingSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allPendingItems, (pendingItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(pendingItem);
      if (itemType !== ItemTypes.ACCOUNT) { return; }
      const accountIdProp = isNew ? 'tempAccountId' : 'accountId';
      const accountId = (pendingItem as any)?.[accountIdProp];
      pendingAccountMap[accountId] = true;
    });
    return pendingAccountMap;
  }
);

export const getCompletedSyncingAccountsMap = createSelector(
  getAllCompletedSyncOrderDtos,
  (completedSyncOrderDtos: SyncOrderDto[]): { [accountId: string]: boolean } => {
    const completedAccountMap: { [accountId: string]: boolean } = {};
    const allCompletedItems: BaseSyncItemEntity[] = flatMap(completedSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allCompletedItems, (completedItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(completedItem);
      if (itemType !== ItemTypes.ACCOUNT) { return; }
      const accountIdProp = isNew ? 'tempAccountId' : 'accountId';
      const accountId = (completedItem as any)?.[accountIdProp];
      completedAccountMap[accountId] = true;
    });
    return completedAccountMap;
  }
);

// TERMS
export const getActivelySyncingTermsMap = createSelector(
  getAllActiveSyncOrderDtos,
  (activeSyncOrderDtos: SyncOrderDto[]): { [termId: string]: boolean } => {
    const activeTermMap: { [termId: string]: boolean } = {};
    const allActiveItems: BaseSyncItemEntity[] = flatMap(activeSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allActiveItems, (activeItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(activeItem);
      if (itemType !== ItemTypes.TERM) { return; }
      const termIdProp = isNew ? 'tempTermId' : 'termId';
      const termId = (activeItem as any)?.[termIdProp];
      activeTermMap[termId] = true;
    });
    return activeTermMap;
  }
);

export const getPendingSyncingTermsMap = createSelector(
  getAllPendingSyncOrderDtos,
  (pendingSyncOrderDtos: SyncOrderDto[]): { [termId: string]: boolean } => {
    const pendingTermMap: { [termId: string]: boolean } = {};
    const allPendingItems: BaseSyncItemEntity[] = flatMap(pendingSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allPendingItems, (pendingItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(pendingItem);
      if (itemType !== ItemTypes.TERM) { return; }
      const termIdProp = isNew ? 'tempTermId' : 'termId';
      const termId = (pendingItem as any)?.[termIdProp];
      pendingTermMap[termId] = true;
    });
    return pendingTermMap;
  }
);

export const getCompletedSyncingTermsMap = createSelector(
  getAllCompletedSyncOrderDtos,
  (completedSyncOrderDtos: SyncOrderDto[]): { [accountId: string]: boolean } => {
    const completedTermMap: { [termId: string]: boolean } = {};
    const allCompletedItems: BaseSyncItemEntity[] = flatMap(completedSyncOrderDtos, (dto: SyncOrderDto) => itemsForSyncOrder(dto));
    forEach(allCompletedItems, (completedItem: BaseSyncItemEntity) => {
      const { itemType, isNew }: ISyncTypeData = getSyncItemTypeData(completedItem);
      if (itemType !== ItemTypes.TERM) { return; }
      const termIdProp = isNew ? 'tempTermId' : 'termId';
      const termId = (completedItem as any)?.[termIdProp];
      completedTermMap[termId] = true;
    });
    return completedTermMap;
  }
);

export const getCourseLockedMap = createSelector(
  getCourseItems,
  getActivelySyncingCoursesMap,
  getOriginalCourseItems,
  (
    courseItems: CoursesFeatureState['items'],
    syncingCourses: { [courseId: string]: boolean },
    originalCourses: CoursesFeatureState['originalItems']
  ): { [courseId: string]: boolean } => {
    return mapValues(courseItems, (course: Course) => {
      const originalCourse: Course = originalCourses?.[course?.id];
      const isSyncing = !!syncingCourses?.[course?.id];
      return courseIsLocked(course, originalCourse, isSyncing);
    });
  }
);

export const getNewAccountsToSync = createSelector(
  getAccountItemsMap,
  getActivelySyncingAccountsMap,
  getPendingSyncingAccountsMap,
  getCompletedSyncingAccountsMap,
  (
    accountItems: AccountsFeatureState['items'],
    syncingAccounts: { [accountId: string]: boolean },
    pendingAccounts: { [accountId: string]: boolean },
    completedAccounts: { [accountId: string]: boolean },
  ): Account[] => filter(values(accountItems), (account: Account): boolean => {
    const existsInLmsAlready = accountExistsInLmsAlready(account);
    const isAlreadySyncing = syncingAccounts?.[account?.id];
    const isPendingSyncing = pendingAccounts?.[account?.id];
    const isCompletedSyncing = completedAccounts?.[account?.id];
    return !existsInLmsAlready && !isAlreadySyncing && !isPendingSyncing && !isCompletedSyncing;
  })
);

/** Get all courses that exist and are unsynced (and aren't already syncing) */
export const getAllTypesOfExistingCoursesToSync = createSelector(
  getAllCourses,
  getActivelySyncingCoursesMap,
  getPendingSyncingCoursesMap,
  getCompletedSyncingCoursesMap,
  getOriginalCourseItems,
  (
    courses: Course[],
    syncingCourses: { [courseId: string]: boolean },
    pendingCourses: { [courseId: string]: boolean },
    completedCourses: { [courseId: string]: boolean },
    originalCourses: CoursesFeatureState['originalItems']
  ): Course[] => filter(
    courses,
    (course: Course): boolean => {
      const originalCourse: Course = originalCourses?.[course?.id];
      const inLmsAlready = courseExistsInLmsAlready(course, originalCourse);
      const isAlreadySyncing = syncingCourses?.[course.id];
      const isPendingSyncing = pendingCourses?.[course?.id];
      const isCompletedSyncing = completedCourses?.[course?.id];
      return inLmsAlready && !isAlreadySyncing && !isPendingSyncing && !isCompletedSyncing;
    }
  )
);

/** Get all of the unsynced existing courses that aren't duplicates (to be represented by SyncExistingCourse) */
export const getExistingCoursesToSync = createSelector(
  getAllTypesOfExistingCoursesToSync,
  getAllOriginalCourses,
  (existingCourses: Course[], originalCourses: Course[]): Course[] => {
    const filtered = filter(existingCourses, (existingCourse: Course) => {
      const original = find(
        originalCourses,
        (originalCourse: Course) => originalCourse.id === existingCourse.id
      );
      return courseIsUnsynced(existingCourse, original);
    });
    return filtered;
  }
);

export const getExistingAccountsToSync = createSelector(
  getAllAccounts,
  getOriginalAccountsMap,
  (accounts:Account[], originalAccounts: AccountsFeatureState['originalItems']): Account[] => filter(
    accounts, (account:Account): boolean => {
      const existsInLmsAlready = accountExistsInLmsAlready(account);
      const originalAccount = find(originalAccounts, (sourceAccount:Account) => sourceAccount.id === account.id);
      return existsInLmsAlready && accountIsUnsynced(account, originalAccount);
    }
  )
);

/** Get all of the unsynced existing courses that are duplicates (to be represented by SyncExistingDupCourse) */
export const getExistingDupCoursesToSync = createSelector(
  getAllTypesOfExistingCoursesToSync,
  (courses: Course[]): Course[] => filter(
    courses,
    (course: Course) => courseIsUnsyncedDuplicate(course)
  )
);

/** Get all courses that don't exist yet and aren't already syncing */
export const getAllTypesOfNewCoursesToSync = createSelector(
  getAllCourses,
  getActivelySyncingCoursesMap,
  getPendingSyncingCoursesMap,
  getCompletedSyncingCoursesMap,
  getOriginalCourseItems,
  (
    courses: Course[],
    syncingCourses: { [courseId: string]: boolean },
    pendingCourses: { [courseId: string]: boolean },
    completedCourses: { [courseId: string]: boolean },
    originalCourses: CoursesFeatureState['originalItems']
  ): Course[] => filter(
    courses,
    (course: Course): boolean => {
      const originalCourse: Course = originalCourses?.[course?.id];
      const inLmsAlready = courseExistsInLmsAlready(course, originalCourse);
      const isAlreadySyncing = syncingCourses?.[course.id];
      const isPendingSyncing = pendingCourses?.[course?.id];
      const isCompletedSyncing = completedCourses?.[course?.id];
      return !inLmsAlready && !isAlreadySyncing && !isPendingSyncing && !isCompletedSyncing;
    }
  )
);

/** Get all of the new courses that aren't duplicates (to be represented by SyncNewCourse) */
export const getNewCoursesToSync = createSelector(
  getAllTypesOfNewCoursesToSync,
  (courses: Course[]): Course[] => filter(
    courses,
    (course: Course): boolean => !courseIsUnsyncedDuplicate(course)
  )
);

/** Get all of the new courses that are duplicates (to be represented by SyncNewDupCourse) */
export const getNewDuplicateCoursesToSync = createSelector(
  getAllTypesOfNewCoursesToSync,
  (courses: Course[]): Course[] => filter(
    courses,
    (course: Course): boolean => courseIsUnsyncedDuplicate(course)
  )
);

export const getNewTermsToSync = createSelector(
  getTerms,
  getOriginalTermItems,
  getActivelySyncingTermsMap,
  getPendingSyncingTermsMap,
  getCompletedSyncingTermsMap,
  (
    terms: EnrollmentTerm[],
    originals: TermsFeatureState['originalItems'],
    syncingTerms: { [termId: string]: boolean },
    pendingTerms: { [termId: string]: boolean },
    completedTerms: { [termId: string]: boolean }
  ): EnrollmentTerm[] => {
    return filter(
      terms,
      (term: EnrollmentTerm): boolean => {
        const original = originals?.[term?.id];
        const inLmsAlready = termExistsInLmsAlready(term, original);
        const isAlreadySyncing = !!syncingTerms?.[term?.id];
        const isPendingSyncing = !!pendingTerms?.[term?.id];
        const isCompletedSyncing = !!completedTerms?.[term?.id];
        return !inLmsAlready && !isAlreadySyncing && !isPendingSyncing && !isCompletedSyncing;
      }
    );
  }
);



export const getAccountUnsyncedMap = createSelector(
  getAccountItemsMap,
  getOriginalAccountsMap,
  getActivelySyncingAccountsMap,
  (
    accountItems: AccountsFeatureState['items'],
    originalAccounts: AccountsFeatureState['originalItems'],
    syncingAccounts: { [accountId: string]: boolean }
  ): { [accountId: string]: boolean } => {
    return mapValues(accountItems, (a: Account): boolean => {
      const isSyncing: boolean = !!syncingAccounts?.[a?.id];
      const originalAccount: Account = originalAccounts?.[a?.id];
      return accountIsUnsynced(a, originalAccount) && !isSyncing;
    });
  }
);

export const getTermsUnsyncedMap = createSelector(
  getTermItems,
  getOriginalTermItems,
  getActivelySyncingTermsMap,
  (
    termItems: TermsFeatureState['items'],
    originals: TermsFeatureState['originalItems'],
    syncingTerms: { [termId: string]: boolean }
  ): { [termId: string]: boolean } => {
    return mapValues(termItems, (term: EnrollmentTerm): boolean => {
      const original: EnrollmentTerm = originals?.[term?.id];
      const isSyncing: boolean = !!syncingTerms?.[term?.id];
      return termIsUnsynced(term, original) && !isSyncing;
    });
  }
);

export const getCourseIsUnsynced = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseUnsyncedMap,
  (courseUnsyncedMap: { [courseId: string]: boolean }): boolean => {
    return !!courseUnsyncedMap?.[props?.courseId];
  }
));

/** Get whether there are any changes to be synced to Canvas */
export const getHasChangesToSync = createSelector(
  getAccountUnsyncedMap,
  getCourseUnsyncedMap,
  getTermsUnsyncedMap,
  (
    unsyncedAccountsMap: { [accountId: string]: boolean },
    unsyncedCoursesMap: { [courseId: string]: boolean },
    unsyncedTermsMap: { [termId: string]: boolean }
  ): boolean => {
    const hasAccountsToSync = values(unsyncedAccountsMap).includes(true);
    const hasCoursesToSync = values(unsyncedCoursesMap).includes(true);
    const hasTermsToSync = values(unsyncedTermsMap).includes(true);
    return hasAccountsToSync || hasCoursesToSync || hasTermsToSync;
  }
);

/**
 * Get the SyncOrderDto that we would POST to our back-end
 * if the user were to click the sync-to-Canvas button now.
 * NOTE: Expensive -- best if invoked on demand, rather than continuous selection with useSelector
 */
export const getCurrentSyncOrderDto = createSelector(
  getAccountItemsMap,
  getTermItems,
  getOriginalTermItems,
  getInstallation,
  getNewAccountsToSync,
  getNewTermsToSync,
  getNewCoursesToSync,
  getNewDuplicateCoursesToSync,
  getExistingCoursesToSync,
  getExistingDupCoursesToSync,
  getAccountAncestorMap,
  getExistingAccountsToSync,
  (
    accountItems: AccountsFeatureState['items'],
    termItems: TermsFeatureState['items'],
    originalTerms: TermsFeatureState['originalItems'],
    installation: Installation,
    newAccounts: Account[],
    newTerms: EnrollmentTerm[],
    newCourses: Course[],
    newDupes: Course[],
    existingCourses: Course[],
    existingDupes: Course[],
    accountAncestorMap: { [accountId: string]: string[] },
    existingAccounts: Account[],
  ): SyncOrderDto => {
    const syncOrderId = getUuid();
    const commonFields = { syncOrderId };
    // Omitted fields are added by the back-end after entities are created
    const syncOrderDto = new SyncOrderDto({
      syncOrder: {
        ...commonFields,
        installationId: installation?.installationId,
        lmsId: installation?.lmsId
      },
      syncNewAccounts: map(
        newAccounts,
        (account: Account): SyncNewAccount => {
          const parentAccount: Account = accountItems?.[account?.parent_account_id];
          const parentAccountExistsAlready = accountExistsInLmsAlready(parentAccount);
          const ancestors = accountAncestorMap?.[account?.id] || [];
          return new SyncNewAccount({
            ...commonFields,
            ancestors,
            name: account?.name,
            parentAccountId: parentAccountExistsAlready ? account?.parent_account_id : null,
            syncNewAccountId: getUuid(),
            tempAccountId: account?._meta?.tempAccountId,
            tempDepthLevel: account?._meta?.tempDepthLevel,
            tempParentAccountId: parentAccountExistsAlready ? null : account?.parent_account_id,
            defaultCourseBulkSettings: account._meta.defaultCourseBulkSettings
          });
        }
      ),
      syncNewTerms: map(
        newTerms,
        (term: EnrollmentTerm): SyncNewTerm => {
          return new SyncNewTerm({
            ...commonFields,
            endAt: term?.end_at,
            name: term?.name,
            overrides: term?.overrides,
            sisTermId: term?.sis_term_id,
            startAt: term?.start_at,
            syncNewTermId: getUuid(),
            tempTermId: term?._meta?.tempTermId
          });
        }
      ),
      syncNewCourses: map(
        newCourses,
        (course: Course): SyncNewCourse => {
          const parentAccount: Account = accountItems?.[course?.account_id];
          const parentAccountExistsAlready = accountExistsInLmsAlready(parentAccount);
          const term = termItems?.[course?.term?.id];
          const originalTerm = originalTerms?.[course?.term?.id];
          const termExistsAlready = termExistsInLmsAlready(term, originalTerm);
          return new SyncNewCourse({
            ...commonFields,
            accountId: parentAccountExistsAlready ? course?.account_id : null,
            courseCode: course?.course_code,
            courseNavSettings: course?._meta?.courseNavSettings,
            courseAuthoringSettings: course?._meta?.courseAuthoringSettings,
            coursePageRatingSettings: course?._meta?.coursePageRatingSettings,
            courseTemplateId: course?._meta?.newCourseTemplateId === CONSTANTS.templates.emptyId
              ? null
              : course?._meta?.newCourseTemplateId,
            endDate: course?.end_at,
            enrollmentTermId: termExistsAlready ? course?.term?.id : null,
            name: course?.name,
            restrictEnrollmentsToCourseDates: course?.restrict_enrollments_to_course_dates,
            sisCourseId: course?.sis_course_id,
            startDate: course?.start_at,
            syncNewCourseId: getUuid(),
            tags: course?._meta?.tags,
            tempCourseId: course?._meta?.tempCourseId,
            tempAccountId: parentAccountExistsAlready ? null : course?.account_id,
            tempEnrollmentTermId: termExistsAlready ? null : course?.term?.id
          });
        }
      ),
      syncExistingCourses: map(
        existingCourses,
        (course: Course): SyncExistingCourse => {
          return new SyncExistingCourse({
            ...commonFields,
            accountId: course?.account_id,
            courseCode: course?.course_code,
            courseId: course?.id,
            courseNavSettings: course?._meta?.courseNavSettings,
            courseAuthoringSettings: course?._meta?.courseAuthoringSettings,
            coursePageRatingSettings: course?._meta?.coursePageRatingSettings,
            courseTemplateId: course?._meta?.newCourseTemplateId === CONSTANTS.templates.emptyId
              ? null
              : course?._meta?.newCourseTemplateId,
            name: course?.name,
            syncExistingCourseId: getUuid(),
            tags: course?._meta?.tags
          });
        }
      ),
      syncNewDupCourses: map(
        newDupes,
        (course: Course): SyncNewDupCourse => {
          const parentAccount: Account = accountItems?.[course?.account_id];
          const parentAccountExistsAlready = accountExistsInLmsAlready(parentAccount);
          const termExistsAlready = termExistsInLmsAlready(termItems?.[course?.term?.id], originalTerms?.[course?.term?.id]);
          return new SyncNewDupCourse({
            ...commonFields,
            courseId: null,
            name: course?.name,
            restrictEnrollmentsToCourseDates: course?.restrict_enrollments_to_course_dates,
            courseCode: course?.course_code,
            sisCourseId: course?.sis_course_id || null, // don't post empty ''
            courseNavSettings: course?._meta?.courseNavSettings,
            courseAuthoringSettings: course?._meta?.courseAuthoringSettings,
            coursePageRatingSettings: course?._meta?.coursePageRatingSettings,
            tags: course?._meta?.tags,
            syncNewDupCourseId: getUuid(),
            tempCourseId: course?._meta?.tempCourseId,
            accountId: parentAccountExistsAlready ? course?.account_id : null,
            tempAccountId: parentAccountExistsAlready ? null : course?.account_id,
            enrollmentTermId: termExistsAlready ? course?.term?.id : null,
            tempEnrollmentTermId: termExistsAlready ? null : course?.term?.id,
            startDate: course?.start_at,
            endDate: course?.end_at,
            sourceCourseId: course?._meta?.courseDuplication?.originCourseId
          });
        }
      ),
      syncExistingDupCourses: map(
        existingDupes,
        (course: Course): SyncExistingDupCourse => {
          return new SyncExistingDupCourse({
            ...commonFields,
            name: course?.name,
            courseId: course?.id,
            tags: course?._meta?.tags,
            restrictEnrollmentsToCourseDates: course?.restrict_enrollments_to_course_dates,
            courseNavSettings: course?._meta?.courseNavSettings,
            courseAuthoringSettings: course?._meta?.courseAuthoringSettings,
            coursePageRatingSettings: course?._meta?.coursePageRatingSettings,
            syncExistingDupCourseId: getUuid(),
            sourceCourseId: course?._meta?.courseDuplication?.originCourseId
          });
        }
      ),
      syncExistingAccounts : map(
        existingAccounts,
        (account:Account): SyncExistingAccount => {
          return new SyncExistingAccount(
            { 
              ...commonFields,
              name: account.name,
              accountId: account.id,
              syncExistingAccountId: getUuid(),
              parentAccountId: account.parent_account_id,
              defaultCourseBulkSettings: account._meta.defaultCourseBulkSettings              
            }
          );
        }
      ),
    });
    
    // please send me the expected data structure that will go with this post request
    return syncOrderDto;
  }
);

export const getAccountIsLocked = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAccountById(props),
  getActivelySyncingAccountsMap,
  (account: Account, syncingAccounts: { [accountId: string]: boolean }): boolean => {
    const isSyncing = !!syncingAccounts?.[props?.accountId];
    return accountIsLocked(account, isSyncing);
  }
));

export const getAccountLockedMap = createSelector(
  getAccountItemsMap,
  getActivelySyncingAccountsMap,
  (accountItems: AccountsFeatureState['items'], syncingAccounts: { [accountId: string]: boolean }): { [accountId: string]: boolean } => {
    return mapValues(
      accountItems,
      (val: Account) => {
        const isSyncing = !!syncingAccounts?.[val?.id];
        return accountIsLocked(val, isSyncing);
      }
    );
  }
);

export const getCourseIsSyncing = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getActivelySyncingCoursesMap,
  (syncingCourses: { [courseId: string]: boolean }): boolean => !!syncingCourses?.[props?.courseId]
));

export const getCourseIsLocked = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseById(props),
  getOriginalCourseById(props),
  getCourseIsSyncing(props),
  (course: Course, originalCourse: Course, isSyncing: boolean): boolean => {
    return courseIsLocked(course, originalCourse, isSyncing);
  }
));
