import React, { ReactChildren } from 'react';

import { SrOnlyText } from 'components/general-styles';
import { Field, Label, Radio } from './style';
import { UseFormMethods } from 'react-hook-form';

export interface IRadioButtonProps {
  id: string;
  name: string;
  label: string|ReactChildren;
  value: HTMLInputElement['value'];
  register: UseFormMethods['register'];
}

const RadioButton = React.memo(({ id, name, label, value, register }: IRadioButtonProps) => (
  <Field>
    <Radio
      id={id}
      name={name}
      ref={register}
      type='radio'
      value={value}
    />
    <Label
      htmlFor={id}
    >
      <SrOnlyText>{label}</SrOnlyText>
    </Label>
  </Field>
));

export default RadioButton;
