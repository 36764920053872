import React from 'react';
import { join, map, capitalize, sortBy } from 'lodash';
import moment from 'moment';
import { DataTableSelections, ColumnDef, CellRendererProps } from 'modules/data-table/types';
import { TFunction } from 'i18next';

import { SyncOrderStatusUnsynced, SyncOrderStatusCompleted, SyncOrderStatusPending, SyncOrderStatusActive, DATETIME_FORMAT_SLASH } from 'constants/index';

import Course from 'models/canvas/course';
import CourseTemplate from 'models/courseTemplate';

import { courseExistsInLmsAlready } from 'store/courses/selectors';
import { CoursesFeatureState } from 'store/courses/reducer';
import { AccountsFeatureState } from 'store/accounts/reducer';
import { TermsFeatureState } from 'store/terms/reducer';

import { CourseCodeCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/CourseCodeCell';
import { CourseMenuCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/CourseMenuCell';
import { NameCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/NameCell';
import { SelectionCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/SelectionCell';
import { SelectionHeaderCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/SelectionHeaderCell';
import { SubAccountCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/SubAccountCell';
import { SyncStatusCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/SyncStatusCell';
import { SyncStatusHeaderCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/SyncStatusHeaderCell';
import { TemplateCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/TemplateCell';
import { TagsCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/TagsCell';
import { SelectCell } from 'components/Modals/ImportCourse/SelectCourseTable/SelectCell';
import { TermCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/TermCell';
import { DateCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/DateCell';
import { SisIdCell } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/SisIdCell';

const dateFormat = (date: Date) => moment(date).format(DATETIME_FORMAT_SLASH);
const getSortedTags = (row: Course): string[] => sortBy(row?._meta?.tags || []);

export const getDateCellStringValue = (date: Date, t: TFunction): string => {
  if (!date) {
    return t('unspecified');
  }
  return dateFormat(date);
};

export const getCourseStatus = (
  course: Course,
  unsyncedCourses: {[courseId: string]: boolean},
  pendingCourses: {[courseId: string]: boolean},
  activeCourses: {[courseId: string]: boolean},
  completedCourses: {[courseId: string]: boolean},
) => {
  if (activeCourses[course.id]) return SyncOrderStatusActive.RUNNING;
  if (pendingCourses[course.id]) return SyncOrderStatusPending.PENDING;
  if (completedCourses[course.id]) return SyncOrderStatusCompleted.SUCCESS;
  if (unsyncedCourses[course.id]) return SyncOrderStatusUnsynced.UNSYNCED;
};

// Get the column definitions for the main courses-list table
export function coursesTableColumns(
  templatesHash: Record<string, CourseTemplate>,
  selections: DataTableSelections,
  courseUnsyncedMap: { [courseId: string]: boolean },
  pendingSyncingAccountsMap: { [courseId: string]: boolean },
  activelySyncingCoursesMap: { [courseId: string]: boolean },
  completedSyncingCourseMap: { [courseId: string]: boolean },
  originalCourseItems: CoursesFeatureState['originalItems'],
  accountItems: AccountsFeatureState['items'],
  termItems: TermsFeatureState['items'],
  t: TFunction
): ColumnDef[] {
  return [
    {
      columnId: 'selection',
      width: 35,
      cellValueGetter: (row: Course): boolean => {
        const rowId = row?.id;
        return !!selections?.[rowId];
      },
      cellRenderer: SelectionCell,
      headerCellRenderer: SelectionHeaderCell,
      pinLeft: true,
      toggleable: false
    },
    {
      columnId: 'courseMenu',
      width: 10,
      cellValueGetter: () => null,
      cellRenderer: CourseMenuCell,
      headerCellRenderer: null,
      pinLeft: true,
      toggleable: false,
      className: 'course-menu-table-cell'
    },
    {
      columnId: 'syncStatus',
      width: 25,
      cellValueGetter: (row: Course) => getCourseStatus(row, courseUnsyncedMap, pendingSyncingAccountsMap, activelySyncingCoursesMap, completedSyncingCourseMap),
      sortValueGetter: (row:Course) =>{
        const status = getCourseStatus(row, courseUnsyncedMap, pendingSyncingAccountsMap, activelySyncingCoursesMap, completedSyncingCourseMap);
        return status !== SyncOrderStatusUnsynced.UNSYNCED && status !== SyncOrderStatusActive.RUNNING;
      },
      cellRenderer: SyncStatusCell,
      headerCellRenderer: SyncStatusHeaderCell,
      pinLeft: true,
      toggleable: false,
      className: 'sync-status-table-cell'
    },
    {
      columnId: 'courseFramework',
      headerName: t('source'),
      width: 180,
      cellValueGetter: (row: Course): string => {
        return templatesHash?.[row?._meta?.courseTemplateId]?.name || templatesHash?.[row?._meta?.newCourseTemplateId]?.name || t('applyFramework');
      },
      cellRenderer: TemplateCell
    },
    {
      columnId: 'name',
      headerName: t('name'),
      cellValueGetter: 'name',
      cellRenderer: NameCell,
      width: 200
    },
    {
      columnId: 'course_code',
      headerName: t('courseCode'),
      cellValueGetter: 'course_code',
      cellRenderer: CourseCodeCell,
      width: 150
    },
    {
      columnId: 'term',
      headerName: t('term'),
      cellValueGetter: (row: Course): string => {
        return termItems?.[row?.term?.id]?.name || row?.term?.name;
      },
      cellRenderer: TermCell,
      width: 150
    },
    {
      columnId: 'subAccountName',
      headerName: t('subAccount'),
      cellValueGetter: (row: Course) => {
        return accountItems?.[row?.account_id]?.name || row?.account_name;
      },
      width: 150,
      cellRenderer: SubAccountCell
    },
    {
      columnId: 'id',
      headerName: t('courseId'),
      cellValueGetter: (row: Course): string => {
        const originalCourse: Course = originalCourseItems?.[row?.id];
        return courseExistsInLmsAlready(row, originalCourse) ? row.id : '';
      },
      width: 90
    },
    {
      columnId: 'sis_course_id',
      headerName: t('sisId'),
      cellRenderer: SisIdCell,
      cellValueGetter: 'sis_course_id',
      width: 170
    },
    {
      columnId: 'tags',
      headerName: t('tags'),
      cellValueGetter: getSortedTags,
      cellRenderer: TagsCell,
      filterValueGetter: (row: Course): string => join(getSortedTags(row), ' '),
      sortValueGetter: (row: Course): string => join(getSortedTags(row), ' '),
      width: 200
    },
    {
      columnId: 'workflow_state',
      headerName: t('courseStatus'),
      cellValueGetter: (row: Course): string => capitalize(row?.workflow_state || ''),
      width: 120
    },
    {
      columnId: 'teachers',
      headerName: t('teachers'),
      cellValueGetter: (row: Course) => {
        const names = map(row?.teachers, 'displayName').sort();
        return join(names, ', ');
      },
      width: 200
    },
    {
      columnId: 'start_at',
      headerName: t('startDate'),
      cellValueGetter: 'start_at',
      filterValueGetter: (row: Course): string => getDateCellStringValue(row?.displayStartDate, t),
      cellRenderer: DateCell,
      width: 170
    },
    {
      columnId: 'end_at',
      headerName: t('endDate'),
      cellValueGetter: 'end_at',
      filterValueGetter: (row: Course): string => getDateCellStringValue(row?.displayEndDate, t),
      cellRenderer: DateCell,
      width: 170
    }
  ];
}

// Get the column definitions for the select-a-course table
// (used in the ImportCourse modal)
export function selectCourseTableColumns(
  t: TFunction,
  accountItems: AccountsFeatureState['items'],
  courseIsDisabled: (course: Course) => boolean,
): ColumnDef[] {
  return [
    {
      columnId: 'select',
      headerName: t('select'),
      width: 35,
      cellValueGetter: '',
      cellRenderer: (props: CellRendererProps) => {
        const disabled = typeof courseIsDisabled === 'function'
          ? courseIsDisabled(props?.row)
          : false;
        return <SelectCell {...props} disabled={disabled} />;
      },
      headerCellRenderer: () => <></>,
      pinLeft: true,
      toggleable: false
    },
    {
      columnId: 'name',
      headerName: t('name'),
      cellValueGetter: 'name',
      width: 200
    },
    {
      columnId: 'courseCode',
      headerName: t('courseCode'),
      cellValueGetter: 'course_code',
      width: 150
    },
    {
      columnId: 'term',
      headerName: t('term'),
      cellValueGetter: 'term.name',
      width: 150
    },
    {
      columnId: 'subAccountName',
      headerName: t('subAccount'),
      cellValueGetter: (row: Course) => {
        return accountItems?.[row?.account_id]?.name || row?.account_name;
      },
      width: 180
    },
    {
      columnId: 'id',
      headerName: t('courseId'),
      cellValueGetter: 'id',
      width: 90
    },
    {
      columnId: 'start_at',
      headerName: t('startDate'),
      cellValueGetter: (row: Course): string => getDateCellStringValue(row?.displayStartDate, t),
      sortValueGetter: (row: Course): Date => row?.displayStartDate,
      width: 170,
    },
    {
      columnId: 'end_at',
      headerName: t('endDate'),
      cellValueGetter: (row: Course): string => getDateCellStringValue(row?.displayEndDate, t),
      sortValueGetter: (row: Course): Date => row?.displayEndDate,
      width: 170,
    },
    {
      columnId: 'tags',
      headerName: t('tags'),
      cellValueGetter: getSortedTags,
      cellRenderer: (props: CellRendererProps) => {
        return <TagsCell {...props} allowEdit={false} />;
      },
      filterValueGetter: (row: Course): string => join(getSortedTags(row), ' '),
      sortValueGetter: (row: Course): string => join(getSortedTags(row), ' '),
      width: 200,
    },
    {
      columnId: 'sisCourseId',
      headerName: t('sisId'),
      cellValueGetter: 'sis_course_id',
      width: 170,
    },
  ];
}
