import { get } from 'lodash';
import BaseSyncItemEntity from './baseSyncItemEntity';
import CanvasUpdateJobResult from './canvasUpdateJobResult';

export default class SyncOrder extends BaseSyncItemEntity {
  createdByLtiUserId: string;
  installationId: string;
  lmsId: string;
  lmsUpdateJobResult: CanvasUpdateJobResult;
  syncOrderDtoHash: string;

  constructor(json: any) {
    super(json);
    this.installationId = get(json, 'installationId');
    this.lmsId = get(json, 'lmsId');
    this.createdByLtiUserId = get(json, 'createdByLtiUserId');
    this.lmsUpdateJobResult = new CanvasUpdateJobResult(get(json, 'lmsUpdateJobResult'));
    this.syncOrderDtoHash = get(json, 'syncOrderDtoHash');
  }
}
