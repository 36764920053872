import AccountMeta from 'models/accountMeta';
import { get } from 'lodash';
import Account from '../canvas/account';

export default class AccountDto {
  account: Account;
  meta: AccountMeta;

  constructor(json: any) {
    this.account = new Account(get(json, 'account'));
    this.meta = new AccountMeta(get(json, 'meta'));
  }

  toAccount(): Account {
    const { account } = this;
    account._meta = this.meta;
    return account;
  }
}
