import React from 'react';
import { Observable, concat, of, from, interval } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { AppState } from 'store';
import { mergeMap, switchMap, catchError, switchMapTo, filter, withLatestFrom, startWith, tap } from 'rxjs/operators';
import { requestStart, requestError, requestSuccess } from 'store/requests/actions';
import SyncOrderDto from 'models/dto/syncOrderDto';
import MongoPageable from 'models/mongoPageable';
import * as api from './api';
import { SyncOrderActionTypes, FetchSyncOrdersAction, receiveSyncOrderPageableInfo, FetchSyncOrderAction, CreateSyncOrderAction, fetchSyncOrders, receiveSyncOrderDtos } from './actions';
import { getHasActiveOrPendingSyncOrders } from './selectors';
import { RequestLabel } from 'constants/index';
import { toast } from 'helpers/messages';
import { SyncStartedToast } from 'components/CustomToasts/SyncStartedToast';
import { getUuid } from 'helpers';

/**
 * Poll sync statuses regularly but infrequently when there are no active/pending SyncOrder operations
 */
export const longPollSyncStatusEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(SyncOrderActionTypes.START_SYNC_STATUS_POLLS),
  switchMapTo(interval(1000 * 60).pipe(startWith(0))), // every minute
  withLatestFrom(state$),
  filter(([n, appState]: [number, AppState]) => !getHasActiveOrPendingSyncOrders(appState)),
  switchMapTo(of(fetchSyncOrders(0)))
);

/**
 * Poll sync statuses quickly and often when there are active/pending SyncOrder operations
 */
export const shortPollSyncStatusEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(SyncOrderActionTypes.START_SYNC_STATUS_POLLS),
  switchMapTo(interval(1000 * 5).pipe(startWith(0))), // every 5 seconds
  withLatestFrom(state$),
  filter(([n, appState]: [number, AppState]) => getHasActiveOrPendingSyncOrders(appState)),
  switchMapTo(of(fetchSyncOrders(0)))
);

export const fetchSyncOrdersEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(SyncOrderActionTypes.FETCH_SYNC_ORDERS),
  mergeMap((action: FetchSyncOrdersAction) => {
    const { page } = action.payload;

    const label = page === 0 ? RequestLabel.FETCH_SYNC_ORDERS : RequestLabel.FETCH_SYNC_ORDERS_PAGINATED;
    const start = requestStart(label);
    const { requestUuid } = start.payload;

    return concat(
      of(start),
      from(api.fetchSyncOrders(page)).pipe(
        switchMap((mongoPageable: MongoPageable<SyncOrderDto>) => concat(
          of(receiveSyncOrderPageableInfo(mongoPageable)),
          of(receiveSyncOrderDtos(mongoPageable.content)),
          of(requestSuccess(requestUuid))
        )),
        catchError((err: Error) => of(requestError(requestUuid, err)))
      )
    );
  })
);

export const fetchSyncOrderEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(SyncOrderActionTypes.FETCH_SYNC_ORDER),
  mergeMap((action: FetchSyncOrderAction) => {
    const label = RequestLabel.FETCH_SYNC_ORDER(action.payload.syncOrderId);
    const start = requestStart(label);
    const { requestUuid } = start.payload;
    return concat(
      of(start),
      from(api.fetchSyncOrder(action.payload.syncOrderId)).pipe(
        switchMap((syncOrderDto: SyncOrderDto) => concat(
          of(receiveSyncOrderDtos([syncOrderDto])),
          of(requestSuccess(requestUuid))
        )),
        catchError((err: Error) => of(requestError(requestUuid, err)))
      )
    );
  })
);

export const createSyncOrderEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(SyncOrderActionTypes.CREATE_SYNC_ORDER),
  mergeMap((action: CreateSyncOrderAction) => {
    const label = RequestLabel.CREATE_SYNC_ORDER;
    const start = requestStart(label);
    const { requestUuid } = start.payload;
    const TOAST_DURATION_MS = 10 * 1000;
    const toastId = getUuid();
    const toastComponent = (
      <SyncStartedToast toastId={toastId} />
    );
    const toastConfig = { autoClose: TOAST_DURATION_MS, toastId };
    return concat(
      of(start),
      from(api.createSyncOrder(action.payload.syncOrderDto)).pipe(
        switchMap((syncOrderDto: SyncOrderDto) => concat(
          of(receiveSyncOrderDtos([syncOrderDto])),
          of(requestSuccess(requestUuid)).pipe(
            tap(() => toast.success(toastComponent, toastConfig))
          )
        )),
        catchError((err: Error) => of(requestError(requestUuid, err)))
      )
    );
  })
);
