import { AxiosResponse } from 'axios';
import api from 'api';
import mockApi from 'api/mock';

import { API, SYNC_PAGINATION } from 'config';

import MongoPageable from 'models/mongoPageable';
import SyncOrderDto from 'models/dto/syncOrderDto';

const customApi = API.useMock ? mockApi() : api();

export async function fetchSyncOrders(page: number): Promise<MongoPageable<SyncOrderDto>> {
  return customApi.get(`/api/sync-orders?size=${SYNC_PAGINATION.perPage}&page=${page}`).then((resp: AxiosResponse<MongoPageable<SyncOrderDto>>) => {
    const mongoPageableJson = resp.data;
    return new MongoPageable<SyncOrderDto>(mongoPageableJson, SyncOrderDto);
  });
}

export async function fetchSyncOrder(syncOrderId: string): Promise<SyncOrderDto> {
  return customApi.get(`/api/sync-orders/${syncOrderId}`).then((resp: AxiosResponse<SyncOrderDto>) => {
    const dtoJson: any = resp.data;
    return new SyncOrderDto(dtoJson);
  });
}

export async function createSyncOrder(syncOrderDto: SyncOrderDto): Promise<SyncOrderDto> {
  return customApi.post('/api/sync-orders', syncOrderDto).then((resp: AxiosResponse<SyncOrderDto>) => {
    const dtoJson: any = resp.data;
    return new SyncOrderDto(dtoJson);
  });
}
