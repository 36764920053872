import React, { FC, memo } from 'react';

interface IFillColumn {
  children?: any;
}

export const FillColumn: FC<IFillColumn> = memo(({
  children
}) => {
  return (
    <div className='fill-column'>
      {children}
    </div>
  );
});
