import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFile,
  faFileImage,
  faFilePdf,
  faFolder as farFolder,
  faMinusSquare as farMinusSquare,
  faPlusSquare as farPlusSquare,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faArrowsAlt,
  faAsterisk,
  faBackspace,
  faCalendar,
  faChalkboardTeacher,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faCircle,
  faClock,
  faColumns,
  faCopy,
  faDolly,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExchangeAlt,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFileExport,
  faFileImport,
  faFillDrip,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faGripVertical,
  faICursor,
  faLayerGroup,
  faList,
  faLocationArrow,
  faLock,
  faMinusCircle,
  faMousePointer,
  faPencilAlt,
  faPlay,
  faPlusCircle,
  faPlusSquare,
  faProjectDiagram,
  faSearch,
  faSitemap,
  faSpinner,
  faSyncAlt,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faArrowsAlt,
  faAsterisk,
  faBackspace,
  faCalendar,
  faChalkboardTeacher,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faCircle,
  faClock,
  faColumns,
  faCopy,
  faDolly,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExchangeAlt,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFileExport,
  faFileImport,
  faFileImage,
  faFilePdf,
  faFillDrip,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faGripVertical,
  faICursor,
  faLayerGroup,
  faList,
  faLocationArrow,
  faLock,
  faMinusCircle,
  faMousePointer,
  faPencilAlt,
  faPlay,
  faPlusCircle,
  faPlusSquare,
  faProjectDiagram,
  faSearch,
  faSitemap,
  faSpinner,
  faSquare,
  faSyncAlt,
  faTimes,
  faTrashAlt,
  farFolder,
  farMinusSquare,
  farPlusSquare,
);
