import { get } from 'lodash';

export default class EnrollmentTermMeta {
  termMetaId: string;
  lmsId: string;
  termId: string;
  tempTermId: string;

  constructor(json: any) {
    this.termMetaId = get(json, 'termMetaId');
    this.lmsId = get(json, 'lmsId');
    this.termId = get(json, 'termId');
    this.tempTermId = get(json, 'tempTermId');
  }
}
