import { createSelector } from 'reselect';
import { AppState } from 'store';
import { get, find, values, orderBy } from 'lodash';

import CourseTemplate from 'models/courseTemplate';
import { TemplatesFeatureState } from './reducer';
import { memoizeSelectorFactory } from 'store/selector-helpers';

export type PerCourseTemplateProps = { courseTemplateId: CourseTemplate['courseTemplateId'] };

export const getTemplatesFeature = (appState: AppState) => get(appState, 'templates');

export const getTemplatesItemsHash = createSelector(
  getTemplatesFeature,
  (featureState: TemplatesFeatureState): TemplatesFeatureState['items'] => get(featureState, 'items')
);

export const getAllTemplates = createSelector(
  getTemplatesItemsHash,
  (templatesItems: TemplatesFeatureState['items']): CourseTemplate[] => orderBy(
    values(templatesItems),
    ['name'],
    ['asc']
  )
);

export const getTemplateById = memoizeSelectorFactory((props: PerCourseTemplateProps) => createSelector(
  getAllTemplates,
  (templates: CourseTemplate[]): CourseTemplate => find(
    templates,
    (template: CourseTemplate) => template.courseTemplateId === props.courseTemplateId
  )
));
