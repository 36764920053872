import { get, map } from 'lodash';

import CourseDto from './courseDto';
import AccountDto from './accountDto';

export default class AccountChildrenDto {
  accounts: AccountDto[];

  courses: CourseDto[];

  constructor(json: any) {
    this.accounts = map(
      get(json, 'accounts'),
      (accountJson: any) => new AccountDto(accountJson)
    );
    this.courses = map(
      get(json, 'courses'),
      (courseJson: any) => new CourseDto(courseJson)
    );
  }
}
