import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { generatePath } from 'react-router';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import { trim, padStart, concat } from 'lodash';
import * as yup from 'yup';

import { CONSTANTS } from 'config';
import { getUuid } from 'helpers';

import CourseMeta from 'models/courseMeta';
import Course from 'models/canvas/course';
import { toggleModal } from 'store/modals/actions';
import { receiveCourses, setAutogenCounter } from 'store/courses/actions';
import { getAllCourseTags, getAutogenCount } from 'store/courses/selectors';
import { getActiveAccount, getActiveAccountId } from 'store/account-tree/selectors';
import { getAccountAncestorMap } from 'store/accounts/selectors';

import { setAccountsExpanded, setActiveAccount } from 'store/account-tree/actions';

import { useInstallationConfig } from 'hooks/useInstallationConfig';
import { HorizFieldGroup } from './style';
import { InstallationConfigKey, ModalId } from 'constants/index';
import { TermsFeatureState } from 'store/terms/reducer';
import { getTermItems } from 'store/terms/selectors';
import EnrollmentTerm from 'models/canvas/enrollmentTerm';
import { push } from 'connected-react-router';
import { RoutePathnames } from 'config/routePathnames';

import CourseNavSettings from 'models/courseNavSettings';
import CourseAuthoringSettings from 'models/courseAuthoringSettings';
import CoursePageRatingSettings from 'models/coursePageRatingSettings';

import { TemplateList, Button, Modal, Input, AccountSelect } from 'components/elements';
import { SectionContent, SectionFooter, ModalHeading } from 'components/general-styles';
import { Errors, FormSection } from 'components/elements/shared-style';
import { TagsInput } from 'components/elements/TagsInput';
import { TermSelect } from 'components/elements/TermSelect';
import { FieldErrors } from 'components/elements/FieldErrors';
import { DatePicker } from 'components/elements/Datepicker';
import EnhancedNavSettings from 'components/elements/EnhancedNavSettings';
import EnhancedAuthoringSettings from 'components/elements/EnhancedAuthoringSettings';
import EnhancedPageRatingSettings from 'components/elements/EnhancedPageRatingSettings';

interface IAddCoursesFormValues {
  quantity: number;
  templateId: string;
  accountId: string;
  termId: string;
  startDate: Date;
  endDate: Date;
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
  tags: string[];
}

const AddCourses = () => {
  const modalId = ModalId.ADD_COURSES;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tagOptions: string[] = useSelector(getAllCourseTags);
  const activeAccountId: string = useSelector(getActiveAccountId);
  const termItems: TermsFeatureState['items'] = useSelector(getTermItems);
  const accountAncestorMap = useSelector(getAccountAncestorMap);
  const selectedAccount = useSelector(getActiveAccount);  
  const courseNavSettings = selectedAccount?._meta?.defaultCourseBulkSettings?.courseNavSettings;
  const coursePageRatingSettings = selectedAccount?._meta?.defaultCourseBulkSettings?.coursePageRatingSettings;
  const courseAuthoringSettings = selectedAccount?._meta?.defaultCourseBulkSettings?.courseAuthoringSettings;
  
  
  const schema = yup.object().shape({
    quantity: yup
      .number()
      .typeError(t('quantityNumberError'))
      .positive(t('quantityMinimunError'))
      .integer(t('quantityIntegerError')),
    templateId: yup
      .string().nullable()
      .required(t('optionError')),
    accountId: yup
      .string(),
    termId: yup
      .string()
      .nullable(),
    startDate: yup
      .date()
      .nullable(),
    endDate: yup
      .date()
      .nullable(),
    tags: yup
      .array().of(yup.string()),
    courseNavSettings: yup.object().shape({
      enabled: yup.boolean(),
      useHomeMenu: yup.boolean()
    }),
    courseAuthoringSettings: yup.object().shape({
      enabled: yup.boolean()
    }),
    coursePageRatingSettings: yup.object().shape({
      enabled: yup.boolean()
    })
  });

  const { control, formState, handleSubmit, register, errors, watch, setValue, getValues } = useForm<IAddCoursesFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      quantity: 0,
      templateId: CONSTANTS.templates.emptyId,
      accountId: selectedAccount?.id,      
      termId: null,
      startDate: null,
      endDate: null,
      tags: [],      
      courseNavSettings: {
        enabled: courseNavSettings?.enabled,
        useHomeMenu: courseNavSettings?.useHomeMenu
      },
      courseAuthoringSettings: {
        enabled: courseAuthoringSettings?.enabled
      },
      coursePageRatingSettings: {
        enabled: coursePageRatingSettings?.enabled
      }
    }
  });

  const hasNavigationFeature: boolean = useInstallationConfig(InstallationConfigKey.NAV_SETTINGS_FEATURE);
  const hasAuthoringFeature: boolean = useInstallationConfig(InstallationConfigKey.AUTHORING_FEATURE);
  const hasPageRatingFeature: boolean = useInstallationConfig(InstallationConfigKey.PAGE_RATING_FEATURE);

  const globalAutogenCount: number = useSelector(getAutogenCount);

  const handleClose = () => {
    dispatch(toggleModal(modalId, false, {}));
  };

  const syncDatesWithTerm = (term: EnrollmentTerm) => {
    setValue('startDate', term?.start_at);
    setValue('endDate', term?.end_at);
  };

  // Creates an array of new temp courses, and stores them
  const submit = () => {
    const formVals = getValues();
    const tags = (formVals?.tags || []).map(trim) as string[];
    const courseQuantity: number = Math.floor(formVals?.quantity) || 0;
    const newCourses = Array
      .from({ length: courseQuantity })
      .map((v, i) => {
        const num: number = globalAutogenCount + 1 + i;
        const paddedKey = padStart(num, 3, '0');
        const tempCourseId = getUuid();

        //Logic to restrict to enrollment course dates: 
        //* if user selects the term and the dates are not changed, restrictToEnrollmentCourseDates is set to false because by default Term (start_at & end_at) dates are applied
        //* if user changed the dates (start_at & end_at ) after selecting term restrictToEnrollmentCourseDates should be true because the user wants to set their own dates.
        const isRestrictedEnrollmentCourseDates = termItems?.[formVals?.termId]?.start_at !== formVals?.startDate || termItems?.[formVals?.termId]?.end_at !== formVals?.endDate;

        return new Course({
          id: tempCourseId,
          name: `${t('courseName')} ${paddedKey}`,
          account_id: formVals?.accountId,
          term: termItems?.[formVals?.termId],
          restrict_enrollments_to_course_dates: isRestrictedEnrollmentCourseDates,
          start_at: formVals?.startDate,
          end_at: formVals?.endDate,
          course_code: `${t('courseCode')} ${paddedKey}`,
          _meta: new CourseMeta({
            tempCourseId,
            newCourseTemplateId: formVals?.templateId === CONSTANTS.templates.emptyId ? '' : formVals?.templateId,
            courseNavSettings: {
              enabled: !!formVals?.courseNavSettings?.enabled,
              useHomeMenu: !!formVals?.courseNavSettings?.useHomeMenu
            },
            courseAuthoringSettings: {
              enabled: !!formVals?.courseAuthoringSettings?.enabled
            },
            coursePageRatingSettings: {
              enabled: !!formVals?.coursePageRatingSettings?.enabled
            },
            tags
          })
        });
      });

    const newGlobalAutogenCount: number = globalAutogenCount + courseQuantity;
    const accountId: string = formVals?.accountId;
    const accountAncestorIds: string[] = accountAncestorMap?.[accountId];
    const accountIdsToExpand: string[] = concat(accountId, accountAncestorIds);

    dispatch(setAutogenCounter(newGlobalAutogenCount));
    dispatch(receiveCourses(newCourses));
    // ensure that the account is expanded so that we can see the new children
    dispatch(setActiveAccount(accountId));
    dispatch(push(generatePath(RoutePathnames.ACCOUNT_COURSES, { accountId })));
    dispatch(setAccountsExpanded(accountIdsToExpand, true));
    handleClose();
  };
 
  const navIsChecked: boolean = watch('courseNavSettings.enabled');
  
  return (
    <Modal id={modalId} handleClose={handleClose}>
      <form onSubmit={handleSubmit(submit)}>
        <SectionContent>
          <ModalHeading>
            <h2 id={`modal-${modalId}-title`}>
              {t('coursesAdd')}
            </h2>
            <p>{t('modalCourseDescription')}</p>
          </ModalHeading>

          <FormSection>
            <Input
              errors={errors.quantity}
              label={t('quantity')}
              name='quantity'
              register={register}
              type='number'
              min='0'
            />

            <TemplateList
              label={t('framework_plural')}
              name='templateId'
              noFrameWorkSelectedOption={true}
              register={register}
            />

            {errors.templateId && (
              <Errors
                id='error'
                data-testid='form-error-sc'
              >
                { errors.templateId.message && errors.templateId.message}
              </Errors>
            )}
          </FormSection>

          <FormSection>
            <label htmlFor='add-courses-account'>{t('destinationAccount')}</label>
            <Controller
              control={control}
              name='accountId'
              defaultValue={activeAccountId}
              render={({ name, value, onChange, onBlur }) => (
                <AccountSelect
                  allowCreate
                  defaultValue={value}
                  id='add-courses-account'
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onCreate={(tempAccountId: string) => {
                    setValue('accountId', tempAccountId);
                  }}
                />
              )}
            />
            <FieldErrors errors={errors?.accountId} />
          </FormSection>

          <FormSection>
            <label htmlFor='add-courses-term'>{t('destinationTerm')}</label>
            <Controller
              control={control}
              name='termId'
              defaultValue={null}
              render={({ name, value, onChange, onBlur }) => (
                <TermSelect
                  allowCreate
                  defaultValue={value}
                  id='add-courses-term'
                  name={name}
                  onBlur={onBlur}
                  onChange={(selectedTermId: string) => {
                    syncDatesWithTerm(termItems?.[selectedTermId]);
                    onChange(selectedTermId);
                  }}
                  onCreate={(tempTermId: string, newTerm: EnrollmentTerm) => {
                    setValue('termId', tempTermId);
                    syncDatesWithTerm(newTerm);
                  }}
                  value={value}
                />
              )}
            />
            <FieldErrors errors={errors?.termId} />
          </FormSection>

          <HorizFieldGroup>
            <FormSection>
              <label htmlFor='add-courses-startDate'>{t('startDate')}</label>
              <Controller
                control={control}
                name='startDate'
                defaultValue={null}
                render={({ name, value, onBlur, onChange }) => (
                  <DatePicker
                    defaultValue={value}
                    id='add-courses-startDate'
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('whenever')}
                    value={value}
                  />
                )}
              />
              <FieldErrors errors={errors?.startDate} />
            </FormSection>

            <FormSection>
              <label htmlFor='add-courses-endDate'>{t('endDate')}</label>
              <Controller
                control={control}
                name='endDate'
                defaultValue={null}
                render={({ name, value, onBlur, onChange }) => (
                  <DatePicker
                    defaultValue={value}
                    id='add-courses-endDate'
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('whenever')}
                    value={value}
                  />
                )}
              />
              <FieldErrors errors={errors?.endDate} />
            </FormSection>
          </HorizFieldGroup>

          {/* Settings */}          

          <EnhancedAuthoringSettings
            show={hasAuthoringFeature}
            control={control}
            id={modalId}
            defaultValue={courseAuthoringSettings} 
            isToggle={false}
          />

        <EnhancedNavSettings
            show={hasNavigationFeature}
            control={control}
            id={modalId}
            readOnlyHomeMenu={ !navIsChecked}
            defaultValue={courseNavSettings} 
            isToggle={false}
          />

          <EnhancedPageRatingSettings
            show={hasPageRatingFeature}
            control={control}
            id={modalId}
            defaultValue={coursePageRatingSettings}
            isToggle={false}
          />
          
          <FormSection className='tags-section'>
            <h3>{t('tags')}</h3>
            <div>
              <Controller
                control={control}
                defaultValue={[]}
                name='tags'
                render={({ name, value, onChange }) => (
                  <TagsInput
                    id={`${modalId}_tags`}
                    name={name}
                    onChange={onChange}
                    tags={tagOptions}
                    value={value}
                  />
                )}
              />
            </div>
          </FormSection>
        </SectionContent>

        <SectionFooter>
          <div />
          <div>
            <Button
              className='add-courses-cancel'
              ariaLabel='Cancel'
              color='secondary'
              onClick={handleClose}
            >
              {t('cancel')}
            </Button>
            <Button
              className='add-courses-submit'
              ariaLabel='Add courses'
              submitting={formState.isSubmitting}
              type='submit'
            >
              {t('add')}
            </Button>
          </div>
        </SectionFooter>
      </form>
    </Modal>
  );
};

export default React.memo(AddCourses);
