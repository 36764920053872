import Installation from 'models/installation';
import CourseBuilderInstallationConfig from 'models/courseBuilderInstallationConfig';
import { InstallationAction, InstallationActionTypes } from './actions';
import Account from 'models/canvas/account';

export interface InstallationFeatureState {
  item: Installation;
  config: CourseBuilderInstallationConfig;
  lmsBaseUrl: string;
  topAccountId: Account['id'];
}

export const defaultInstallationState: InstallationFeatureState = {
  item: null,
  config: null,
  lmsBaseUrl: null,
  topAccountId: null
};

export default function installationReducer(
  state: InstallationFeatureState = defaultInstallationState,
  action: InstallationAction
): InstallationFeatureState {
  switch (action.type) {
    case InstallationActionTypes.RECEIVE_INSTALLATION:
      return {
        ...state,
        item: action.payload.installation
      };
    case InstallationActionTypes.RECEIVE_CONFIG:
      return {
        ...state,
        config: action.payload.config
      };
    case InstallationActionTypes.SET_LMS_BASE_URL:
      return {
        ...state,
        lmsBaseUrl: action.payload.lmsBaseUrl
      };
    case InstallationActionTypes.SET_TOP_ACCOUNT_ID:
      return {
        ...state,
        topAccountId: action.payload.topAccountId
      };
    default:
      return state;
  }
}
