import styled from 'styled-components';

export interface ILoadingProps {
  message?: string
}

export const Wrapper = styled.div<ILoadingProps>`
  align-items: center;
  color: ${({ theme }) => theme.color.mainDark};
  display: flex;
  flex-direction: column;
  margin: 5px auto;
`;

export const Message = styled.p<ILoadingProps>`
  && {
    font: 1em ${({ theme }) => theme.color.mainDark};
    margin-bottom: 10px;
    text-align: center;
  }
`;
