import React, { forwardRef, SyntheticEvent } from 'react';

interface IHorizontalScroller {
  onScroll: (e: SyntheticEvent<HTMLDivElement>) => void;
  scrollbarWidth: number;
  totalPinnedLeftWidth: number;
  totalCenterWidth: number;
}

export const HorizontalScroller = forwardRef<HTMLDivElement, IHorizontalScroller>((
  {
    onScroll,
    scrollbarWidth,
    totalCenterWidth,
    totalPinnedLeftWidth
  },
  horizontalScrollViewportRef
) => {
  return (
    <div className='horizontal-scroller-container'>
      <div
        className='horizontal-scroll-left-spacer'
        style={{
          width: totalPinnedLeftWidth,
          minWidth: totalPinnedLeftWidth,
          maxWidth: totalPinnedLeftWidth,
          borderWidth: scrollbarWidth ? 1 : 0
        }}
      />
      <div
        className='horizontal-scroll-viewport'
        ref={horizontalScrollViewportRef}
        onScroll={onScroll}
        style={{
          height: `${scrollbarWidth}px`,
          minHeight: `${scrollbarWidth}px`,
          maxHeight: `${scrollbarWidth}px`
        }}
      >
        <div
          className='horizontal-scroll-main'
          style={{
            width: totalCenterWidth,
            height: `${scrollbarWidth}px`,
            maxHeight: `${scrollbarWidth}px`,
            minHeight: `${scrollbarWidth}px`
          }}
        />
      </div>
      <div
        className='horizontal-scroll-corner-spacer'
        style={{
          width: `${scrollbarWidth}px`,
          maxWidth: `${scrollbarWidth}px`,
          minWidth: `${scrollbarWidth}px`,
          borderWidth: scrollbarWidth ? 1 : 0
        }}
      />
    </div>
  );
});
