import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { isBoolean } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { SwitchButton } from 'components/elements';

import { CourseModalCheckboxesWrapper } from '../../Modals/style';
import { SelectionCheckbox } from '../index';
import { FormFieldGroup } from '../../general-styles';
import { Tooltip } from '../ToggleTooltip';

interface IEnhancedNavSettings {
  show: boolean;
  showTitle?: boolean;
  showLabel?: boolean;
  readOnlyNavSetting?: boolean;
  readOnlyHomeMenu?: boolean;
  navIndeterminate?: boolean;
  homeIndeterminate?: boolean;
  id: string;
  control: any;
  defaultValue?: any;
  namePrefix?: string;
  isToggle?: boolean;
  handleSwitch?: any;
  extraBehavior?: (data: {[key: string]: boolean}) => void;
}

const EnhancedNavSettings: FC<IEnhancedNavSettings> = ({
  show,
  showTitle = true,
  showLabel = false,
  readOnlyNavSetting = false,
  readOnlyHomeMenu = false,
  navIndeterminate = false,
  homeIndeterminate = false,
  defaultValue,
  id,
  control,
  namePrefix = '',
  isToggle,
  handleSwitch,
  extraBehavior = (data: {[key: string]: boolean}) => {return;}
}) => {
  const uid = `${id}-${uuidv4()}`;
  const { t } = useTranslation();
  const [isNavIndeterminate, setNavIndeterminate] = useState(navIndeterminate);
  const [isHomeIndeterminate, setHomeIndeterminate] = useState(homeIndeterminate);

  const navSettingName = `${namePrefix ? `${namePrefix}.` : ''}courseNavSettings.enabled`;
  const menuSettingName = `${namePrefix ? `${namePrefix}.` : ''}courseNavSettings.useHomeMenu`;
  
  const navDefaultValue = isBoolean(defaultValue) ? defaultValue.enabled : !!defaultValue?.enabled;
  const menuDefaultValue = isBoolean(defaultValue) ? defaultValue.useHomeMenu : !!defaultValue?.useHomeMenu;

  const isVisibleToggle = show && isToggle;
  const isVisibleCheckbox = show && !isToggle;

  return (
    <>
    { isVisibleToggle ? (
      <>
      <Controller
        control={control}
        name={'courseNavSettings.enabled'}
        defaultValue={navDefaultValue}
        render={({name, value, onChange}) => {
          return (
            <div className='switchContainer'>
              <SwitchButton
                id={`${id}-courseNavSettings`}
                name={name}
                type='checkbox'
                dataTip={t('EnhancedNavigationTooltip')}
                dataFor={`${uid}-button-tooltip`}
                checked={value}
                handleSwitch={(e: any) => {onChange(e); handleSwitch();}}              
              />
              <label className='switchLabel'>{t('EnhancedNavigation')}</label>
            </div>                    
            );
        }}
      />      

      <Controller        
        control={control}
        name={'courseNavSettings.useHomeMenu'}
        defaultValue={menuDefaultValue}        
        render={({name, value, onChange}) => {          
          return (
            <div className='switchContainer'>
              <SelectionCheckbox
                readOnly={readOnlyHomeMenu}
                id={`${id}-use-home`}
                name={name}
                selected={value}
                onClick={(e: any) => {onChange(e); handleSwitch();}}
                indeterminate={isHomeIndeterminate}
                dataTip={t('UseHomeSettingsTooltip')}
                dataFor={`${uid}-button-tooltip`}
              >
                <label className='switchLabel'>{t('MenuStyleHomePage')}</label>
              </SelectionCheckbox>
            </div>                    
            );
        }}
      />   
    
      </>
    ) :
    isVisibleCheckbox && (
      <FormFieldGroup className='nav-settings-section'>
        {
          showTitle && (
            <>
              <h3>{t('modalCourseNavigationTitle')}</h3>
              <p>{t('modalCourseNavigationDescription')}</p>
            </>
          )
        }
        <CourseModalCheckboxesWrapper>
          {
            showLabel && (
              <label htmlFor={`${id}-course-navigation`}>
                {t('courseNavigation')}
              </label>
            )
          }
          <div>
            <Controller
              control={control}
              name={navSettingName}
              defaultValue={navDefaultValue}
              render={({ name, value, onChange }) => {
                return (
                  <SelectionCheckbox
                    readOnly={readOnlyNavSetting}
                    id={`${id}-nav-enabled`}
                    name={name}
                    selected={value}
                    onClick={(currentStatus) => {
                      setNavIndeterminate(false);
                      onChange(currentStatus);
                      extraBehavior({'nav': currentStatus});
                    }}
                    indeterminate={isNavIndeterminate}
                  >
                    {t('enableEnhancedCourseNavigation')}
                  </SelectionCheckbox>
                );
              }}
            />
          </div>
          <div>
            <Controller
              name={menuSettingName}
              control={control}
              defaultValue={menuDefaultValue}
              render={({ name, value, onChange }) => {
                return (<SelectionCheckbox
                  readOnly={readOnlyHomeMenu}
                  id={`${id}-nav-use-home-menu`}
                  name={name}
                  selected={value}
                  onClick={(currentStatus) => {
                    setHomeIndeterminate(false);
                    onChange(currentStatus);
                    extraBehavior({'useHomeMenu': currentStatus});
                  }}
                  indeterminate={isHomeIndeterminate}
                >
                  {t('addNavigationMenu')}
                </SelectionCheckbox>);
              }
            }
            />
          </div>
        </CourseModalCheckboxesWrapper>
      </FormFieldGroup>
    )
    }
    <Tooltip
      aria-haspopup='true'
      delayShow={1250}
      event='focus mouseover'
      eventOff='blur mouseout'
      multiline
      id={`${uid}-button-tooltip`}
      place='right'
    />
    </>
  );
};

export default EnhancedNavSettings;
