import React, { FC, memo, useCallback, useRef, KeyboardEvent, SyntheticEvent, useEffect, useState } from 'react';
import { map, get, includes, compact, some, filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from 'react-smooth-collapse';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { RoutePathnames } from 'config/routePathnames';
import { isOwnEvent, isLikeClick } from 'helpers/events';
import { toast } from 'helpers/messages';
import { useAccount } from 'hooks/useAccount';

import Account from 'models/canvas/account';
import { getIsAccountsView } from 'store/router/selectors';
import { setAccountsExpanded, setActiveAccount } from 'store/account-tree/actions';

import AccountItemDescription from './AccountItemDescription';
import AccountDropDownItem from './AccountDropDownItem';
import AccountItemActions, { accountItemActionsClassName } from './AccountItemActions';

import { AccountListItem } from '../style';
import { ItemTypes } from 'constants/index';
import { receiveAccounts, updateAccounts } from 'store/accounts/actions';
import Course from 'models/canvas/course';
import { receiveCourses, updateCourses } from 'store/courses/actions';
import { getCourseLockedMap } from 'store/general/cross-selectors';
import { useTranslation } from 'react-i18next';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useStateDurationEffect } from 'hooks/useStateDurationEffect';
import { getCourseItems } from 'store/courses/selectors';
import { getAccountItemsMap } from 'store/accounts/selectors';


interface IAccountTreeItem {
  accountId: Account['id'];
  depthLevel: number;
}

export const AccountTreeItem: FC<IAccountTreeItem> = memo(({
  accountId,
  depthLevel
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    account,
    allSubAccounts,
    ancestorIsActive,
    descendentAccountIds,
    expanded,
    hasSubAccounts,
    isActiveAccount,
    syncStatus,
    isTopAccount,
    locked
  } = useAccount(accountId);
  const isAccountsView = useSelector(getIsAccountsView);
  const accountButtonRef = useRef<HTMLDivElement>(null);
  const accountName = get(account, 'name');
  const courseLockedMap = useSelector(getCourseLockedMap);
  const courseItems = useSelector(getCourseItems);
  const accountItems = useSelector(getAccountItemsMap);
  const [toggleDropDown, setToggleDropdown] = useState<boolean>(false);

  const showSubAccounts: boolean = location.pathname === RoutePathnames.ACCOUNTS ? true : false;

  // Sets the account as active and expands it when the account is clicked
  const handleAccountClick = useCallback((e: SyntheticEvent) => {
    const isAccountActionsClick = map(e.nativeEvent.composedPath(), 'classList')
      .some((list: DOMTokenList) => list && list.contains(accountItemActionsClassName));
    if (isAccountActionsClick) { return; } // don't expand or set active when clicking the acct. actions popover button
    if (!isTopAccount) {
      if (isActiveAccount || (!isActiveAccount && !expanded)) {
        dispatch(setAccountsExpanded([accountId], !expanded));
      }
    }
    if (!isAccountsView) {
      dispatch(push(generatePath(RoutePathnames.ACCOUNT_COURSES, { accountId })));
    }
    dispatch(setActiveAccount(accountId));
    setToggleDropdown(!toggleDropDown);
  }, [dispatch, accountId, isTopAccount, expanded, isAccountsView, isActiveAccount]);

  // Triggers the 'handleAccountClick' when pressing ENTER or SPACE keys while focusing the account row
  const handleAccountKeyPress = useCallback((e: KeyboardEvent) => {
    // If the event wasn't triggered by this component (bubble) then, dont do anything
    if (!isOwnEvent(e, accountButtonRef)) return;

    if (isLikeClick(e)) {
      accountButtonRef.current.click();
    }
  }, [accountButtonRef]);

  // Define unlocked accounts as draggable
  const [{ isDragging, canDrag }, dragRef, preview] = useDrag({
    // when this account is dragged, this 'item' object is passed into the functions in useDrop
    item: {
      type: ItemTypes.ACCOUNT,
      data: {
        accountId,
        currentParentAccountId: get(account, 'parent_account_id'),
        descendentAccountIds
      }
    },
    // whether this item may be dragged
    canDrag: () => !locked,
    // define some collected props (see item 0 of the returned tuple from useDrag)
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
      canDrag: !!monitor.canDrag()
    })
  });

  // Define account as droppable
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: [ItemTypes.COURSE, ItemTypes.ACCOUNT],
    // whether the item being dragged over this account may drop here
    canDrop: (item: DataTransferItem) => {
      const isAccount = item.type === ItemTypes.ACCOUNT;
      if (isAccount) {
        const isSelf = get(item, 'data.accountId') === accountId;
        const isDescendentAccount = includes(get(item, 'data.descendentAccountIds'), accountId);
        return !isSelf && !isDescendentAccount;
      }
      return true;
    },
    // handle when an item is dropped here
    drop: (item: DataTransferItem) => {
      if (item.type === ItemTypes.COURSE) {
        const draggedRowIsSelected: boolean = !!get(item, 'data.draggedRowIsSelected');

        let droppedCourseIds: Course['id'][] = draggedRowIsSelected
          ? compact(get(item, 'data.selectedRowIds', []))
          : compact([get(item, 'data.draggedRowId')]);

        const containsLockedCourse = some(droppedCourseIds, (courseId: string) => !!courseLockedMap[courseId]);
        const droppedCourses = map(droppedCourseIds, (id: string) => get(courseItems, id));

        const undo = () => {
          dispatch(receiveCourses(droppedCourses));
        };

        const onConfirm = () => {
          droppedCourseIds = containsLockedCourse
            ? filter(droppedCourseIds, (courseId: string) => !courseLockedMap[courseId])
            : droppedCourseIds;

          dispatch(updateCourses(droppedCourseIds, { account_id: accountId }));
          dispatch(setAccountsExpanded([accountId], true));
          toast.successWithUndo(t('toastItemsMovedToAccountWithCount', { accountName, count: droppedCourseIds.length }), undo);
        };

        if (containsLockedCourse) {
          toast.confirm(t('confirmMoveOnlyUnlockedItems'), onConfirm);
        } else {
          onConfirm();
        }
      }

      if (item.type === ItemTypes.ACCOUNT) {
        const droppedAccountId: Account['id'] = get(item, 'data.accountId');
        const droppedAccount = get(accountItems, droppedAccountId);

        const undo = () => {
          dispatch(receiveAccounts([droppedAccount]));
        };

        dispatch(updateAccounts([droppedAccountId], { parent_account_id: accountId }));
        dispatch(setAccountsExpanded([accountId], true));
        toast.successWithUndo(t('toastItemsMovedToAccountWithCount', { accountName, count: 1 }), undo);
      }
    },
    // define some collected props (see item 0 of the returned tuple from useDrop)
    collect: (monitor: DropTargetMonitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    })
  });

  const handleLongDragOver = useCallback(() => {
    dispatch(setAccountsExpanded([accountId], true));
  }, [dispatch, accountId]);

  // Expand account if dragging over it for 1 second
  useStateDurationEffect(
    isOver,
    (over: boolean) => !!over,
    1000,
    handleLongDragOver
  );

  // don't use the default HTML5 elem. screenshot as drag preview b/c we are creating
  // a custom one in CustomDragLayer component
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  // identify both the dragRef and the dropRef as the accountButtonRef element
  dragRef(dropRef(accountButtonRef));

  if (!account) {
    return;
  }

  return (
    <AccountListItem
      active={isActiveAccount}
      ancestorIsActive={ancestorIsActive}
      aria-level={depthLevel + 1} // property is 1-indexed
      canDrag={canDrag}
      className='account-tree-item'
      data-testid={`account-${accountId}-tree-item`}
      draggingOver={isOver && canDrop}
      expanded={expanded}
      fullAccountView={isAccountsView}
      hasChildren={hasSubAccounts}
      id={`account-${accountId}-tree-item`}
      isDragging={isDragging}
      topAccount={isTopAccount}
    >

      <div className='account-item-wrapper'>
        {/* inline style b/c it caused a problem as a styled-comp prop when expanding/collapsing */}
        <div className={`list ${toggleDropDown ? 'toggle' : ''}`}>
          <div className='bracket' style={{ marginLeft: `calc(${depthLevel}em + 5px)` }} />
          <div
            aria-controls={`account-${accountId}-sub-accounts-collapse`}
            aria-expanded={expanded}
            aria-label={`${expanded ? 'Collapse' : 'Expand'} ${accountName}`}
            className='account-button'
            data-can-drop={canDrop}
            data-can-drag={canDrag}
            data-is-dragging={isDragging}
            data-is-over={isOver} // i.e., has an item being dragged over it
            onClick={handleAccountClick}
            onKeyPress={handleAccountKeyPress}
            role='button'
            tabIndex={0}
            ref={accountButtonRef}
          >
            <AccountItemDescription
              account={account}
              expanded={expanded}
              syncStatus={syncStatus}
              fullAccountView={isAccountsView}
              locked={locked}
            />
            <AccountItemActions
              accountId={accountId}
              locked={locked}
            />
          </div>
        </div>
        {expanded && <AccountDropDownItem showSubAccounts={showSubAccounts} depthLevel={depthLevel} account={account} displayOptions={expanded}/>}
      </div>

      {hasSubAccounts && (
        <Collapse
          id={`account-${accountId}-sub-accounts-collapse`}
          className='sub-accounts-collapse'
          expanded={expanded}
          heightTransition='.4s ease'
        >
          <ul className='sub-accounts-list' aria-label={`Sub-Accounts of ${accountName}.`}>
            {allSubAccounts.map((subAccount: Account) => (
              <AccountTreeItem
                key={subAccount.id}
                accountId={subAccount.id}
                depthLevel={depthLevel + 1}
              />
            ))}
          </ul>
        </Collapse>
      )}    
    </AccountListItem>
  );
});

