import { get } from 'lodash';
import EnrollmentTerm from 'models/canvas/enrollmentTerm';
import EnrollmentTermMeta from 'models/enrollmentTermMeta';

export default class EnrollmentTermDto {
  term: EnrollmentTerm;
  meta: EnrollmentTermMeta;

  constructor(json: any) {
    this.term = get(json, 'term') ? new EnrollmentTerm(json.term) : null;
    this.meta = get(json, 'meta') ? new EnrollmentTermMeta(json.meta) : null;
  }

  toEnrollmentTerm(): EnrollmentTerm {
    if (this.term) {
      this.term._meta = this.meta;
    }
    return this.term;
  }
}
