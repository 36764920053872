import React, { FC, memo } from 'react';
import { isNaN } from 'lodash';
import { ProgressBarContainer, Progress } from './style';

export interface IProgressBar {
  errors?: number;
  fixed?: boolean;
  success: number;
  size?: 'small' | 'medium' | 'big';
  total?: number;
}

const ProgressBar: FC<IProgressBar> = memo(({
  errors = 0,
  fixed = false,
  success = 0,
  size = 'small',
  total = 100
}) => {
    const successPercentage = (success / total) * 100;
    const errorPercentage = (errors / total) * 100;

    return (
      <ProgressBarContainer
        className='progress-bar-container'
        size={size}
        fixed={fixed}
        data-success-percent={isNaN(successPercentage) ? 0 : successPercentage}
        data-error-percent={isNaN(errorPercentage) ? 0 : errorPercentage}
      >
        {
          successPercentage > 0 && (
            <Progress
              className='progress-bar success-bar'
              type='success'
              percentage={isNaN(successPercentage) ? 0 : successPercentage}
            />
          )
        }

        {
          errorPercentage > 0 && (
            <Progress
              className='progress-bar error-bar'
              type='error'
              percentage={isNaN(errorPercentage) ? 0 : errorPercentage}
            />
          )
        }
      </ProgressBarContainer>
    );
});

export default ProgressBar;
