import { get, toString, trim } from 'lodash';
import AccountMeta from 'models/accountMeta';

export default class Account {
  id: string;
  name: string;
  parent_account_id: string;
  uuid: string;
  _meta: AccountMeta;

  constructor(json: any) {
    this.id = toString(get(json, 'id'));
    this.name = trim(get(json, 'name'));
    this.parent_account_id = toString(get(json, 'parent_account_id'));
    this.uuid = get(json, 'uuid');
    this._meta = new AccountMeta(get(json, '_meta'));
  }

  get isInCanvas() {
    return !this.id.includes('-');
  }
}
