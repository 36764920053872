import React, { FC, useCallback, useRef } from 'react';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import Course from 'models/canvas/course';
import { useTranslation } from 'react-i18next';
import { getCourseIsLocked } from 'store/general/cross-selectors';
import { getTermItems } from 'store/terms/selectors';
import { TermCellWrapper } from './style';
import { EditSingleValueButton } from 'components/elements/EditSingleValueButton';
import { updateCourses } from 'store/courses/actions';
import { useDispatch, useSelector } from 'react-redux';

interface ITermCell {
  row: Course;
  value: string;
}

export const TermCell: FC<ITermCell> = ({ row, value }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const termId = row?.term?.id;
  const courseId = row?.id;

  const termItems = useSelector(getTermItems);
  const courseLocked = useSelectorWithProps(getCourseIsLocked, { courseId });

  const termName = value;
  const canEdit = !courseLocked;

  const handleCourseTermUpdate = useCallback((newTermId: string) => {
    const update: Partial<Course> = { term: termItems?.[newTermId] };
    dispatch(updateCourses([courseId], update));
  }, [dispatch, courseId, termItems]);

  return (
    <TermCellWrapper className='term-cell-wrapper' ref={wrapperRef}>
      <EditSingleValueButton
        canEdit={canEdit}
        currentValue={termId}
        labelText={t('term')}
        onUpdate={handleCourseTermUpdate}
        type='term'
      >
        {termName}
      </EditSingleValueButton>
    </TermCellWrapper>
  );
};
