import React, { FC } from 'react';
import Course from 'models/canvas/course';
import CourseSourceDisplay from 'components/elements/CourseSourceDisplay';
import { TemplateCellWrapper } from './style';

interface ITemplateCell {
  row: Course;
}

export const TemplateCell: FC<ITemplateCell> = ({
  row
}) => {
  const courseId = row?.id;

  return (
    <TemplateCellWrapper className='template-cell-wrapper'>
      <CourseSourceDisplay courseId={courseId} />
    </TemplateCellWrapper>
  );
};
