import { AxiosResponse } from 'axios';
import { get, map } from 'lodash';
import api from 'api';
import mockApi from 'api/mock';

import { API } from 'config';

import AccountDto from 'models/dto/accountDto';
import AccountChildrenDto from 'models/dto/accountChildrenDto';

const customApi = API.useMock ? mockApi() : api();

export async function fetchAccount(accountId: string): Promise<AccountDto> {
  return customApi.get(`/api/accounts/${accountId}`)
    .then((resp: AxiosResponse<AccountDto>) => new AccountDto(resp.data));
}

export async function fetchAccountChildren(accountId: string): Promise<AccountChildrenDto> {
  return customApi.get(`/api/accounts/${accountId}/children`)
    .then((resp: AxiosResponse<AccountChildrenDto>) => {
      const data = get(resp, 'data', {});
      return new AccountChildrenDto(data);
    });
}

export async function fetchAccountSubAccounts(accountId: string): Promise<AccountDto[]> {
  return customApi.get(`/api/accounts/${accountId}/sub_accounts`)
    .then((resp: AxiosResponse<AccountDto[]>) => {
      const data = get(resp, 'data', []);
      return map(data, (itemJson: any) => new AccountDto(itemJson));
    });
}
