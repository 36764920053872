import { get, toString } from 'lodash';

export default class MigrationIssue {
  id: string;

  content_migration_url: string;

  description: string;

  workflow_state: string;

  fix_issue_html_url: string;

  issue_type: string;

  error_report_html_url: string;

  error_message: string;

  created_at: string;

  updated_at: string;

  constructor(json: any) {
    this.id = toString(get(json, 'id'));
    this.content_migration_url = get(json, 'content_migration_url');
    this.description = get(json, 'description');
    this.workflow_state = get(json, 'workflow_state');
    this.fix_issue_html_url = get(json, 'fix_issue_html_url');
    this.issue_type = get(json, 'issue_type');
    this.error_report_html_url = get(json, 'error_report_html_url');
    this.error_message = get(json, 'error_message');
    this.created_at = get(json, 'created_at');
    this.updated_at = get(json, 'updated_at');
  }
}
