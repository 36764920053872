import ITheme from 'styles/interfaces/theme';
import { Styles } from 'react-select';
import styled from 'styled-components';

export const Errors = styled.p`
  && {
    color: ${({ theme }) => theme.color.red};
    font: 12px ${({ theme }) => theme.font.main};
    margin: 8px 0;
    text-align: left;
    width: 100%;
  }
`;

export const FormSection = styled.div`
  margin: 0 0 1rem 0;
`;

// Styles to extend for configuring react-select

export const customSelectStyles = (
  config: {
    size?: 'small'|'big',
    theme?: ITheme
  },
): Partial<Styles<any, any>> => ({
  menuPortal: (provided: any, state: any) => {
    return {
      ...provided,
      zIndex: 2000
    };
  },
  control: (provided: { backgroundColor: any; cursor: any; }, state: { isDisabled: any; }) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? config?.theme?.color?.gray7
      : provided?.backgroundColor,
    cursor: state.isDisabled ? 'not-allowed' : provided?.cursor,
    flexWrap: 'nowrap',
    height: config?.size === 'small' ? '2.5rem' : '3.5rem',
    minHeight: '2.5rem'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0 8px'
  }),
  input: (provided: any) => ({
    ...provided,
    padding: 0,
    margin: '0 2px'
  })
});
