import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { SyncOrderStatus } from 'constants/index';
import Account from 'models/canvas/account';
import { updateAccounts } from 'store/accounts/actions';

import EditableTextInput from 'components/elements/EditableTextInput';
import SnycOrderStatusIcon from 'components/elements/SyncOrderStatusIcon';

export interface IAccountItemDescriptionProps {
  account: Account;
  expanded: boolean;
  syncStatus?: SyncOrderStatus;
  fullAccountView: boolean;
  locked: boolean;
  isSyncing?: boolean;
}

const AccountItemDescription: React.FC<IAccountItemDescriptionProps> = ({
  account,
  expanded,
  fullAccountView,
  syncStatus,
  locked
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(get(account, 'name'));
  const accountId = get(account, 'id');

  // Triggers an action to update the account name when it changes
  useEffect(() => {
    if (name !== get(account, 'name')) {
      const update = { name };
      dispatch(updateAccounts([accountId], update));
    }
  }, [name, account, accountId, dispatch]);

  return (
    <div className='account-item-description'>
      <FontAwesomeIcon className='account-icon' icon={['fas', expanded ? 'folder-open' : 'folder']} fixedWidth />
      {(!fullAccountView || locked) && (
        name
      )}
      {(fullAccountView && !locked) && (
        <EditableTextInput
          text={name}
          onSetText={(v: any) => setName(v)}
        />
      )}
      {/* <SnycOrderStatusIcon status={syncStatus} /> */}
    </div>
  );
};

export default React.memo(AccountItemDescription);
