/* eslint-disable max-len */

import axios from 'axios';
import { range } from 'lodash';
import MockAdapter from 'axios-mock-adapter';
import { fillWithCourses, fillWithSyncOrders } from 'helpers/filler';
import { paginatedCoursesEndpoint } from 'store/courses/api';
import { InstallationConfigKey } from 'constants/index';

export default (contentType = 'application/json') => {
  const mockApi = axios.create({
    headers: {
      Accept: `${contentType}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': `${contentType}`,
      'X-Requested-With': 'XMLHttpRequest'
    }
  });

  const mock = new MockAdapter(mockApi, { delayResponse: 750 });

  /**
   * ENDPOINTS
   * @documentation https://github.com/ctimmerm/axios-mock-adapter
   */

  mock.onGet('/api/terms').reply(200, (
    [
      {
        id: 88,
        name: 'Default Term',
        start_at: '2020-05-01T03:00:00Z',
        end_at: null,
        created_at: '2012-09-07T22:02:00Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: null,
        sis_import_id: null
      },
      {
        id: 3130,
        name: 'Outcome Test',
        start_at: '2017-09-01T05:00:00Z',
        end_at: '2020-06-30T05:00:00Z',
        created_at: '2017-09-21T16:08:07Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: null,
        sis_import_id: null
      },
      {
        id: 1117,
        name: '4162',
        start_at: '2016-01-18T18:30:00Z',
        end_at: '2016-05-12T18:30:00Z',
        created_at: '2013-12-24T08:26:14Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: '8da6ac8e-c62e-4151-bb1d-3ab5cb6157bc',
        sis_import_id: 1891541
      },
      {
        id: 1285,
        name: '4146-2014 Summer',
        start_at: '2014-06-02T18:30:00Z',
        end_at: '2014-07-24T18:30:00Z',
        created_at: '2014-04-07T13:26:44Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: '0ef1e256-1d06-4c10-84af-b4599ce57c30',
        sis_import_id: 2422739
      },
      {
        id: 917,
        name: 'Spring II Term',
        start_at: '2014-03-17T06:00:00Z',
        end_at: '2014-05-30T06:00:00Z',
        created_at: '2013-07-25T19:28:18Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: null,
        sis_import_id: null
      },
      {
        id: 916,
        name: 'Spring I Term',
        start_at: '2014-01-21T07:00:00Z',
        end_at: '2014-03-14T06:00:00Z',
        created_at: '2013-07-25T19:27:55Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: null,
        sis_import_id: null
      },
      {
        id: 1118,
        name: '4142',
        start_at: '2014-01-21T00:00:00Z',
        end_at: '2014-05-16T00:00:00Z',
        created_at: '2013-12-27T09:37:23Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: 'd120cb2d-2137-4b7c-bfa7-2afab098d4cd',
        sis_import_id: 1891494
      },
      {
        id: 851,
        name: 'Trial Term',
        start_at: '2013-05-01T05:00:00Z',
        end_at: '2013-10-01T05:00:00Z',
        created_at: '2013-05-16T16:23:38Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: null,
        sis_import_id: null
      },
      {
        id: 1107,
        name: '4132-2013 Spring',
        start_at: '2013-01-21T18:30:00Z',
        end_at: '2013-05-16T18:30:00Z',
        created_at: '2013-12-12T09:29:30Z',
        workflow_state: 'active',
        grading_period_group_id: null,
        sis_term_id: '06307584-e1d5-488b-8e91-9f3f7fbdb04d',
        sis_import_id: 3197651
      }
    ]
  ));

  mock.onPost('/api/sync-orders').reply(() => new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        200,
        {
          syncOrder: { syncOrderId: 'sync0' },
          syncNewAccounts: [],
          syncNewCourses: [],
          syncExistingCourses: []
        }
      ]);
    }, 2000);
  }));

  mock.onGet('/api/sync-orders').reply(200, fillWithSyncOrders());

  mock.onGet('/api/installations/installation').reply(200, {
    installation: {
    },
    topAccount: {
      account: {
        id: 1,
        name: 'Top Account',
        parent_account_id: undefined
      },
      meta: null
    },
    subAccounts: [
      {
        account: {
          id: 2,
          name: 'College of Business',
          parent_account_id: 1
        },
        meta: null
      },
      {
        account: {
          id: 3,
          name: 'College of Business',
          parent_account_id: 2
        },
        meta: null
      },
      {
        account: {
          id: 4,
          name: 'MBA - Management',
          parent_account_id: 3
        },
        meta: null
      },
      {
        account: {
          id: 5,
          name: 'MBA - Marketing',
          parent_account_id: 4
        },
        meta: null
      }
    ],
    courseTemplates: [
      {
        courseTemplateId: 'template1Id',
        name: 'Module-Based Template',
        description: 'This template is designed with modules to create a consistent learning path through each topic. Modules can be deleted or duplicated to fit the number of topics in the course. This template works best for faculty who have some time to curate and upload instructional resources and utilize asynchronous discussion forums for a more engaging learning experience.'
      },
      {
        courseTemplateId: 'template2Id',
        name: 'Calendar-Based Template',
        description: 'This template is designed around weeks of instruction, with a dedicated page for each week that links to required readings, assignments, and/or discussions. Pages can be added or duplicated to match the number of weeks in the term. This template works best for faculty who need to rapidly move their course online and/or want to rely on synchronous sessions to adapt their on-ground course.'
      },
      {
        courseTemplateId: 'template3Id',
        name: 'Project-Based Template',
        description: 'This template is designed around one overarching project with several check-ins and deliverables. Instructional resources are tied around project milestones, and discussion forums are built in to maintain a feedback loop between students and instructor. This template works best for a capstone course, practicum, or independent study.'
      }
    ],
    config: {
      [InstallationConfigKey.NAV_SETTINGS_FEATURE]: true
    },
    lmsBaseUrl: 'https://everspring.instructure.com'
  });

  const TOTAL_NUM_COURSES = 20;
  const numCoursePages = Math.ceil(TOTAL_NUM_COURSES / 100);
  const weightedAccountIds = [1, 2, 2, 3, 3, 4, 5];

  // Mock each page of Courses
  range(1, numCoursePages + 1).forEach((pageNum: number) => {
    const last = pageNum === numCoursePages;
    const numDtosForPage = last ? TOTAL_NUM_COURSES % 100 : 100;
    const content = fillWithCourses(numDtosForPage, weightedAccountIds);
    const courseDtosPageJson = {
      content,
      empty: false,
      first: pageNum === 1,
      last: pageNum === numCoursePages,
      number: pageNum,
      numberOfElements: content.length, // num of elements in this page
      size: 100, // max size of page
      totalPages: numCoursePages
    };
    mock.onGet(paginatedCoursesEndpoint('1', pageNum)).reply(200, courseDtosPageJson);
  });

  return mockApi;
};
