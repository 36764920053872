/* eslint-disable no-extra-boolean-cast */
import { concat, get, includes } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Account from 'models/canvas/account';
import { getTopAccountId } from 'store/installation/selectors';

import {
  SyncOrderStatusUnsynced,
  SyncOrderStatusCompleted,
  SyncOrderStatusPending,
  SyncOrderStatusActive,
} from 'constants/index';

import {
  getAccountById,
  getAccountUnsortedNewSubAccounts,
  getAccountExistingSubAccounts,
  getAccountAncestorMap,
  getAccountAncestors,
  getDescendentAccountsIdsOfAccount,
  getAccountIsUnsynced
} from 'store/accounts/selectors';

import { getAccountIsLocked, getActivelySyncingAccountsMap, getCompletedSyncingAccountsMap, getPendingSyncingAccountsMap } from 'store/general/cross-selectors';

import {
  getAccountIsExpanded,
  getActiveAccountId,
} from 'store/account-tree/selectors';

import { useSelectorWithProps } from './useSelectorWithProps';

/**
 * Hook thats wraps account data
 * Useful to avoid having to call all this selectors inside components
 */
export function useAccount(accountId: Account['id']) {
  const runningAccountsIds = useSelector(getActivelySyncingAccountsMap);
  const pendingAccountsIds = useSelector(getPendingSyncingAccountsMap);
  const completedAccountsIds = useSelector(getCompletedSyncingAccountsMap);

  const isUnsynced = useSelectorWithProps(getAccountIsUnsynced, { accountId });

  const account: Account = useSelectorWithProps(
    getAccountById,
    { accountId }
  );

  const newSubAccounts: Account[] = useSelectorWithProps(
    getAccountUnsortedNewSubAccounts,
    { accountId }
  );

  const existingSubAccounts: Account[] = useSelectorWithProps(
    getAccountExistingSubAccounts,
    { accountId }
  );

  const expanded: boolean = useSelectorWithProps(
    getAccountIsExpanded,
    { accountId }
  );

  const locked: boolean = useSelectorWithProps(
    getAccountIsLocked,
    { accountId },
  );

  const ancestors = useSelectorWithProps(
    getAccountAncestors,
    { accountId }
  );

  const descendentAccountIds = useSelectorWithProps(
    getDescendentAccountsIdsOfAccount,
    { accountId }
  );

  const ancestorMap: { [accountId: string]: string[] } = useSelector(getAccountAncestorMap);

  const ancestorIds = get(ancestorMap, accountId, []);

  const activeAccountId: Account['id'] = useSelector(getActiveAccountId);
  const topAccountId: Account['id'] = useSelector(getTopAccountId);

  const allSubAccounts: Account[] = useMemo(() => {
    return concat(newSubAccounts, existingSubAccounts);
  }, [newSubAccounts, existingSubAccounts]);

  const hasSubAccounts: boolean = get(allSubAccounts, 'length', 0) > 0;
  const isActiveAccount: boolean = get(account, 'id', '.') === activeAccountId;
  const isTopAccount: boolean = get(account, 'id', '.') === topAccountId;
  const ancestorIsActive: boolean = useMemo(() => {
    return includes(ancestorIds, activeAccountId);
  }, [ancestorIds, activeAccountId]);

  const getSyncStatus = () => {
    if (!!get(runningAccountsIds, accountId)) {return SyncOrderStatusActive.RUNNING;}
    if (!!get(pendingAccountsIds, accountId)) {return SyncOrderStatusPending.PENDING;}
    if (!!get(completedAccountsIds, accountId)) {return SyncOrderStatusCompleted.SUCCESS;}
    if (isUnsynced) {return SyncOrderStatusUnsynced.UNSYNCED;}
  };

  const syncStatus = getSyncStatus();

  return {
    account,
    allSubAccounts,
    ancestorIds,
    ancestorIsActive,
    ancestors,
    descendentAccountIds,
    existingSubAccounts,
    expanded,
    hasSubAccounts,
    isActiveAccount,
    syncStatus,
    isTopAccount,
    locked,
    newSubAccounts
  };
}
