import { get } from 'lodash';

export default class CourseNavSettings {
  enabled: boolean;
  useHomeMenu: boolean;

  constructor(json: any) {
    this.enabled = !!get(json, 'enabled');
    this.useHomeMenu = !!get(json, 'useHomeMenu');
  }
}
