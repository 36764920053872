import { get, toString } from 'lodash';
import { getUuid } from 'helpers';
import CourseNavSettings from './courseNavSettings';
import CourseDuplication from './courseDuplication';
import CourseAuthoringSettings from './courseAuthoringSettings';
import CoursePageRatingSettings from './coursePageRatingSettings';
import SourceCourseMeta from './sourceCourseMeta';
import { CONSTANTS } from 'config';

export default class CourseMeta {
  courseId: string;
  courseMetaId: string;
  courseTemplateId: string;
  newCourseTemplateId?: string;
  lmsId: string;
  tempCourseId: string;
  courseDuplication: CourseDuplication;
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
  tags: string[];
  sourceCourse: SourceCourseMeta;

  constructor(json: any) {
    this.courseMetaId = get(json, 'courseMetaId', getUuid());
    this.lmsId = get(json, 'lmsId');
    this.courseId = toString(get(json, 'courseId'));
    this.courseTemplateId = get(json, 'courseTemplateId');
    this.newCourseTemplateId = get(json, 'newCourseTemplateId');
    this.tempCourseId = get(json, 'tempCourseId');
    this.courseDuplication = new CourseDuplication(get(json, 'courseDuplication'));
    this.courseNavSettings = new CourseNavSettings(get(json, 'courseNavSettings'));
    this.courseAuthoringSettings = new CourseAuthoringSettings(get(json, 'courseAuthoringSettings'));
    this.coursePageRatingSettings = new CoursePageRatingSettings(get(json, 'coursePageRatingSettings'));
    this.tags = get(json, 'tags', []);
    this.sourceCourse = new SourceCourseMeta(get(json, 'sourceCourse', null));
  }

  get hasNewTemplate() {
    return this.newCourseTemplateId && this.newCourseTemplateId !== CONSTANTS.templates.emptyId;
  }
}
