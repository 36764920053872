import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
  display: flex;

  .date-picker-display-input {
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 100%;
    min-width: 12em;
    width: 100%;
    /** see note in component about applying readonly prop to the display input */
    &[readonly]:not(.readOnly) {
      background-color: transparent; 
      color: ${({ theme }) => theme.color.font};
    }
  }

  .date-picker-trigger-button {
    border: 1px solid ${({ theme }) => theme.color.gray4};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0 0 0 -1px;
    padding: 0 .3em;
    height: auto;
    min-width: 1em;
  }
`;

export const DatePickerPopoverWrapper = styled.div`
  min-width: 350px;
  width: 30vw;

  .top-buttons-container {
    display: flex;
    font-size: 1.6rem;
    justify-content: space-between;
    margin: 0 0 .3rem 0;
    padding: .5rem;
  }

  .calendar-time-container {
    display: flex;
    
    .calendar-wrapper {
      .DayPicker {
        font-size: 1.3rem;

        .DayPicker-Day--selected {
          background-color: ${({ theme }) => theme.color.mainDark};
        }
      }
    }

    .time-wrapper {
      flex-grow: 1;
      padding: 1rem .5rem;
    }
  }
`;
