import styled from 'styled-components';

export const DragLayerContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

export const DragPreviewItemWrapper = styled.div``;

export const DragPreviewItemInnerWrapper = styled.div`
  background: ${({ theme }) => theme.color.mainDark};
  display: inline-block;
  color: white;
  padding: 5px;
  max-width: 200;
  border-radius: 5px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);

  .move-icon {
    margin-right: .5em;
  }
`;
