import styled from 'styled-components';

export const CourseModalCheckboxesWrapper = styled.div`
  label {
    margin-bottom: .3em;
  }

  & > label {
    display: block;
    margin-bottom: 1rem;
  }
`;
