import { createSelector } from 'reselect';
import { values, orderBy } from 'lodash';
import { AppState } from 'store';

import EnrollmentTerm from 'models/canvas/enrollmentTerm';
import { TermsFeatureState } from './reducer';
import { memoizeSelectorFactory } from 'store/selector-helpers';

export type PerTermProps = { termId: EnrollmentTerm['id'] };

export function termExistsInLmsAlready(term: EnrollmentTerm, originalTerm: EnrollmentTerm): boolean {
  const termId = term?.id;
  const hasOriginal = !!originalTerm;
  const tempIdDiffers = !!termId && termId !== term?._meta?.tempTermId;
  return hasOriginal || tempIdDiffers;
}

export function termIsUnsynced(term: EnrollmentTerm, originalTerm: EnrollmentTerm): boolean {
  return !termExistsInLmsAlready(term, originalTerm);
}

export const getTermFeature = (appState: AppState): TermsFeatureState => appState?.terms;

export const getTermItems = createSelector(
  getTermFeature,
  (featureState: TermsFeatureState): TermsFeatureState['items'] => featureState?.items
);

export const getOriginalTermItems = createSelector(
  getTermFeature,
  (featureState: TermsFeatureState): TermsFeatureState['originalItems'] => featureState?.originalItems
);

export const getTerms = createSelector(
  getTermItems,
  (items: TermsFeatureState['items']): EnrollmentTerm[] => {
    return orderBy(
      values(items),
      ['start_at'],
      ['asc']
    );
  }
);

export const getTermById = memoizeSelectorFactory((props: PerTermProps) => createSelector(
  getTermItems,
  (items: TermsFeatureState['items']): EnrollmentTerm => items?.[props?.termId]
));

export const getOriginalTermById = memoizeSelectorFactory((props: PerTermProps) => createSelector(
  getOriginalTermItems,
  (originals: TermsFeatureState['originalItems']): EnrollmentTerm => originals?.[props.termId]
));

export const getTermExistsInLmsAlready = memoizeSelectorFactory((props: PerTermProps) => createSelector(
  getTermById(props),
  getOriginalTermById(props),
  (term: EnrollmentTerm, original: EnrollmentTerm): boolean => termExistsInLmsAlready(term, original)
));
