import styled from 'styled-components';

export const NameCellWrapper = styled.div`
  a[href] {
    color: ${({ theme }) => theme.color.mainDark};
    text-decoration: underline;
  }

  .lock-icon-wrapper {
    display: inline-flex;
    margin-right: .3em;
    align-items: center;
    .edit-button {
      width: 1em;
    }
    .lock-icon, .edit-icon {
      font-size: 1rem;
    }
    .lock-icon {
      color: ${({ theme }) => theme.color.gray3};
    }
    .edit-icon {
      color: ${({ theme }) => theme.color.mainDark};
    }
  }
`;
