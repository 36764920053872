import styled, { css } from 'styled-components';

export const SyncJobItem = styled.div`
  ${({ theme }) => css`
    border-bottom: none;
    border: 1px solid ${theme.color.gray6};
    display: flex;
    flex-direction: column;

    &:last-child {
      border-bottom: 1px solid ${theme.color.gray6};
    }

    .sync-item-collapse {
      border-top: 1px solid ${theme.color.gray6};
    }

    .sync-job-details {
      border-spacing: 0;
      text-align: left;
      width: 100%;

      .sync-details-icon {
        margin-right: 12px;
      }
      
      tr {
        &:nth-child(even) {
          background: ${theme.color.rows};
        }
      }

      th, td {
        font: 400 1.4rem/2.5rem Lato;
        padding: 8px 16px;

        &:nth-child(1){ width: 35%}
        &:nth-child(2){ width: 10%}
        &:nth-child(3){ width: 40%}
        &:nth-child(4){ width: 15%}
      }
      th {
        font-weight: 500;
      }
      td {}

      a {
        color: ${theme.color.mainDark};
        text-decoration: underline;
      }
    }
  `}
`;

export const Description = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 40px;
  padding: 0 20px;
`;

export const Data = styled.div`
  display: flex;
  flex-grow: 1;

  & > p {
    font: 400 1.6rem/2.5rem "Lato",Helvetica,Roboto;
    margin: 0;

    &:after {
      content: '|';
      margin: 0 1.2rem;
    }

    &:last-of-type {
      &:after {
        display: none
      }
    }
  }
`;
