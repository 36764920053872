import { DataTableConfig, ColumnSortConfig, ColumnFilterConfig } from 'modules/data-table/types';

// TYPES

export enum DataTableActionTypes {
  REGISTER_TABLE = 'data-table/REGISTER_TABLE',
  SET_DATA = 'data-table/SET_DATA',
  SET_COLUMNS_VISIBLE = 'data-table/SET_COLUMNS_VISIBLE',
  SET_COLUMN_SORT = 'data-table/SET_COLUMN_SORT',
  SET_COLUMN_FILTER = 'data-table/SET_COLUMN_FILTER',
  SET_ROWS_SELECTED = 'data-table/SET_ROWS_SELECTED',
  CLEAR_ALL_SORTS = 'data-table/CLEAR_ALL_SORTS',
  CLEAR_ALL_FILTERS = 'data-table/CLEAR_ALL_FILTERS',
  CLEAR_COLUMNS_SORTS_FILTERS = 'data-table/CLEAR_COLUMNS_SORTS_FILTERS'
}

export interface RegisterTableAction {
  type: typeof DataTableActionTypes.REGISTER_TABLE;
  payload: { tableId: string, config: DataTableConfig };
}

export interface SetDataAction {
  type: typeof DataTableActionTypes.SET_DATA;
  payload: { tableId: string, data: Object[] };
}

export interface SetColumsVisibleAction {
  type: typeof DataTableActionTypes.SET_COLUMNS_VISIBLE;
  payload: { tableId: string, columnIds: string[], visible: boolean, replace?: boolean };
}

export interface SetColumnSortAction {
  type: typeof DataTableActionTypes.SET_COLUMN_SORT;
  payload: { tableId: string, columnId: string, sort: ColumnSortConfig };
}

export interface SetColumnFilterAction {
  type: typeof DataTableActionTypes.SET_COLUMN_FILTER;
  payload: { tableId: string, columnId: string, filter: ColumnFilterConfig };
}

export interface SetRowsSelectedAction {
  type: typeof DataTableActionTypes.SET_ROWS_SELECTED;
  payload: { tableId: string, rowIds: string[], selected: boolean, replace?: boolean };
}

export interface ClearAllSortsAction {
  type: typeof DataTableActionTypes.CLEAR_ALL_SORTS;
  payload: { tableId: string };
}

export interface ClearAllFiltersAction {
  type: typeof DataTableActionTypes.CLEAR_ALL_FILTERS;
  payload: { tableId: string };
}

export interface ClearColumnsSortsFiltersAction {
  type: typeof DataTableActionTypes.CLEAR_COLUMNS_SORTS_FILTERS;
  payload: { tableId: string, columnIds: string[] };
}

export type DataTableAction =
  | RegisterTableAction
  | SetDataAction
  | SetColumsVisibleAction
  | SetColumnSortAction
  | SetColumnFilterAction
  | SetRowsSelectedAction
  | ClearAllSortsAction
  | ClearAllFiltersAction
  | ClearColumnsSortsFiltersAction;

// ACTION CREATORS

export const registerTable = (tableId: string, config: DataTableConfig): RegisterTableAction => ({
  type: DataTableActionTypes.REGISTER_TABLE,
  payload: { tableId, config }
});

export const setData = (tableId: string, data: any[]): SetDataAction => ({
  type: DataTableActionTypes.SET_DATA,
  payload: { tableId, data }
});

export const setColumnsVisible = (tableId: string, columnIds: string[], visible: boolean, replace?: boolean): SetColumsVisibleAction => ({
  type: DataTableActionTypes.SET_COLUMNS_VISIBLE,
  payload: { tableId, columnIds, visible, replace }
});

export const setColumnSort = (tableId: string, columnId: string, sort: ColumnSortConfig): SetColumnSortAction => ({
  type: DataTableActionTypes.SET_COLUMN_SORT,
  payload: { tableId, columnId, sort }
});

export const setColumnFilter = (tableId: string, columnId: string, filter: ColumnFilterConfig): SetColumnFilterAction => ({
  type: DataTableActionTypes.SET_COLUMN_FILTER,
  payload: { tableId, columnId, filter }
});

export const setRowsSelected = (tableId: string, rowIds: string[], selected: boolean, replace?: boolean): SetRowsSelectedAction => ({
  type: DataTableActionTypes.SET_ROWS_SELECTED,
  payload: { tableId, rowIds, selected, replace }
});

export const clearAllSorts = (tableId: string): ClearAllSortsAction => ({
  type: DataTableActionTypes.CLEAR_ALL_SORTS,
  payload: { tableId }
});

export const clearAllFilters = (tableId: string): ClearAllFiltersAction => ({
  type: DataTableActionTypes.CLEAR_ALL_FILTERS,
  payload: { tableId }
});

export const clearColumnsSortsFilters = (tableId: string, columnIds: string[]): ClearColumnsSortsFiltersAction => ({
  type: DataTableActionTypes.CLEAR_COLUMNS_SORTS_FILTERS,
  payload: { tableId, columnIds }
});
