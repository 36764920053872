import { get, toString } from 'lodash';

export default class Progress {
  completion: number;
  context_id: string;
  context_type: string;
  created_at: string;
  id: string;
  message: string;
  results: {
    [property: string]: any
  };
  tag: string;
  updated_at: string;
  url: string;
  user_id: string;
  workflow_state: string;

  constructor(json: any) {
    this.completion = get(json, 'completion');
    this.context_id = get(json, 'context_id');
    this.context_type = get(json, 'context_type');
    this.created_at = get(json, 'created_at');
    this.id = toString(get(json, 'id'));
    this.message = get(json, 'message');
    this.results = get(json, 'results');
    this.tag = get(json, 'tag');
    this.updated_at = get(json, 'updated_at');
    this.url = get(json, 'url');
    this.user_id = toString(get(json, 'user_id'));
    this.workflow_state = get(json, 'workflow_state');
  }
}
