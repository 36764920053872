import React, { FC, useCallback, useState } from 'react';
import { NewTermPopoverContentWrapper } from './style';
import EnrollmentTerm, { EnrollmentType } from 'models/canvas/enrollmentTerm';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button } from 'components/elements';
import { isFunction, values, trim } from 'lodash';
import { getUuid } from 'helpers';
import { receiveTerms } from 'store/terms/actions';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import StartEndDates from 'models/canvas/startEndDates';
import { DatePicker } from 'components/elements/Datepicker';

interface INewTermPopoverContent {
  onCancel: () => void;
  onCreate?: (termTempId: string, newTerm: EnrollmentTerm) => void;
  onUpdate?: (termId: string, updatedTerm: EnrollmentTerm) => void;
  overlayProps?: OverlayInjectedProps;
  term?: EnrollmentTerm; // if the form should be prepopulated with existing term vals for update
}

interface ITermFormVals {
  name: string;
  sisTermId: string;
  startAt: string;
  endAt: string;
  overrides: Partial<Record<EnrollmentType, StartEndDates>>;
}

const ENROLLMENT_TYPES = values(EnrollmentType);

enum TabKey {
  MAIN = 'main',
  OVERRIDES = 'overrides'
}

export const NewTermPopoverContent: FC<INewTermPopoverContent> = ({
  onCancel,
  onCreate,
  onUpdate,
  overlayProps,
  term
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.MAIN);

  const { register, getValues, watch, control } = useForm<ITermFormVals>({});

  const nameVal = watch('name');

  const shouldDisableSubmit = !trim(nameVal);

  const handleCancelClick = useCallback(() => {
    if (isFunction(onCancel)) {
      onCancel();
    }
  }, [onCancel]);

  const handleCreateClick = useCallback(() => {
    const formVals: ITermFormVals = getValues();
    const tempTermId = getUuid();

    const newTerm = new EnrollmentTerm({
      id: tempTermId,
      name: trim(formVals?.name),
      sis_term_id: trim(formVals?.sisTermId),
      start_at: formVals?.startAt,
      end_at: formVals?.endAt,
      overrides: formVals?.overrides,
      _meta: { tempTermId }
    });

    dispatch(receiveTerms([newTerm]));
    typeof onCreate === 'function' && onCreate(tempTermId, newTerm);
  }, [onCreate, dispatch, getValues]);

  const handleEditClick = useCallback(() => {
    const formVals: ITermFormVals = getValues();
    const updatedTerm: EnrollmentTerm = new EnrollmentTerm({
      ...term,
      name: trim(formVals?.name),
      sis_term_id: trim(formVals?.sisTermId),
      start_at: formVals?.startAt,
      end_at: formVals?.endAt,
      overrides: formVals?.overrides
    });
    dispatch(receiveTerms([updatedTerm]));
    typeof onUpdate === 'function' && onUpdate(term?.id, updatedTerm);
  }, [onUpdate, term, dispatch, getValues]);

  const handleTabSelect = useCallback((eventKey: string) => {
    setActiveTab(eventKey as TabKey);
  }, [setActiveTab]);

  return (
    <NewTermPopoverContentWrapper
      className='new-term-popover-content-wrapper'
    >
      <Tabs
        id='new-term-tabs'
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        <Tab eventKey={TabKey.MAIN} title={t('mainSettings')}>
          <fieldset className='name-fieldset'>
            <label htmlFor='new-term-name'>{t('termName')}</label>
            <input
              defaultValue={term?.name}
              id='new-term-name'
              name='name'
              placeholder={t('required')}
              ref={register()}
            />
          </fieldset>
          <fieldset className='sis-id-fieldset'>
            <label htmlFor='new-term-sis-id'>{t('sisId')}</label>
            <input
              defaultValue={term?.sis_term_id}
              id='new-term-sis-id'
              name='sisTermId'
              ref={register()}
            />
          </fieldset>
          <div className='term-dates flex-fieldset-container'>
            <fieldset className='term-dates-from-fieldset'>
              <label htmlFor='new-term-start-at'>{t('termRunsFrom')}</label>
              <Controller
                control={control}
                defaultValue={term?.start_at || null}
                name='startAt'
                render={({ name, onBlur, onChange, value }) => (
                  <DatePicker
                    defaultValue={value}
                    id='new-term-start-at'
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('whenever')}
                    value={value}
                  />
                )}
              />
            </fieldset>
            <fieldset className='term-dates-to-fieldset'>
              <label htmlFor='new-term-end-at'>{t('dateRangeTo')}</label>
              <Controller
                control={control}
                defaultValue={term?.end_at || null}
                name='endAt'
                render={({ name, onBlur, onChange, value }) => (
                  <DatePicker
                    defaultValue={value}
                    id='new-term-end-at'
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('whenever')}
                    value={value}
                  />
                )}
              />
            </fieldset>
          </div>
        </Tab>

        <Tab eventKey={TabKey.OVERRIDES} title={t('roleAccessOverrides')}>
          <table className='role-overrides-table'>
            <tbody>
              {ENROLLMENT_TYPES.map((enrollmentType: EnrollmentType) => {
                const idPrefix = `new-term-override-${enrollmentType}`;
                const startPlaceholder = enrollmentType === EnrollmentType.StudentEnrollment ? t('termStart') : t('whenever');
                const endPlaceholder = t('termEnd');
                return (
                  <tr className={`${enrollmentType}-row`} key={enrollmentType}>
                    <td className='role-name-cell'>
                      <Trans i18nKey={`roleCanAccess_${enrollmentType}`} components={{ b: <strong /> }} />
                    </td>
                    <td className='date-input-cell'>
                      <fieldset className={`${enrollmentType}-dates-from-fieldset`}>
                        <label htmlFor={`${idPrefix}-startAt`}>{t('dateRangeFrom')}</label>
                        <Controller
                          control={control}
                          defaultValue={term?.overrides?.[enrollmentType]?.start_at || null}
                          name={`overrides.${enrollmentType}.start_at`}
                          render={({ name, onBlur, onChange, value }) => (
                            <DatePicker
                              defaultValue={value}
                              id={`${idPrefix}-startAt`}
                              name={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder={startPlaceholder}
                              value={value}
                            />
                          )}
                        />
                      </fieldset>
                    </td>
                    <td className='date-input-cell'>
                      <fieldset className={`${enrollmentType}-dates-to-fieldset`}>
                        <label htmlFor={`${idPrefix}-endAt`}>{t('dateRangeTo')}</label>
                        <Controller
                          control={control}
                          defaultValue={term?.overrides?.[enrollmentType]?.end_at || null}
                          name={`overrides.${enrollmentType}.end_at`}
                          render={({ name, onBlur, onChange, value }) => (
                            <DatePicker
                              defaultValue={value}
                              id={`${idPrefix}-endAt`}
                              name={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder={endPlaceholder}
                              value={value}
                            />
                          )}
                        />
                      </fieldset>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Tab>
      </Tabs>

      <div className='actions'>
        <Button
          className='cancel-button'
          color='secondary'
          onClick={handleCancelClick}
          size='small'
          testId='new-term-popover-cancel-button'
        >
          {t('cancel')}
        </Button>
        <Button
          className='submit-button'
          color='primary'
          disabled={shouldDisableSubmit}
          onClick={term ? handleEditClick : handleCreateClick}
          size='small'
          testId='new-term-popover-submit-button'
        >
          {term ? t('update') : t('create')}
        </Button>
      </div>
    </NewTermPopoverContentWrapper>
  );
};
