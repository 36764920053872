import React, { FC, memo, forwardRef, RefObject } from 'react';

interface IFunctionMenuDividerItem {}

export const FunctionMenuDividerItem: FC<IFunctionMenuDividerItem> = memo(forwardRef((props, ref) => {
  return (
    <li
      ref={ref as RefObject<HTMLLIElement>}
      className='function-menu-divider-item'
    />
  );
}));
