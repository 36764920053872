import PageRatingJobStatus from 'models/pageRatingReport/pageRatingJobStatus';
import PageRatingReport from 'models/pageRatingReport/pageRatingReport';

/** TYPES */
export enum PageRatingReportActionTypes {
  CREATE_REPORT = 'pageRatingReport/CREATE_REPORT',
  FETCH_STATUS = 'pageRatingReport/FETCH_STATUS',
  RECEIVE_STATUS = 'pageRatingReport/RECEIVE_STATUS',
  DOWNLOAD_REPORT = 'pageRatingReport/DOWNLOAD_REPORT',
  RECEIVE_REPORT = 'pageRatingReport/RECEIVE_REPORT',
  RESET_STATUS = 'pageRatingReport/RESET_STATUS'
}

export interface CreatePageRatingReportAction {
  type: typeof PageRatingReportActionTypes.CREATE_REPORT;
  payload: { accountId: string };
}

export interface FetchPageRatingReportStatusAction {
  type: typeof PageRatingReportActionTypes.FETCH_STATUS;
}

export interface ReceivePageRatingReportStatusAction {
  type: typeof PageRatingReportActionTypes.RECEIVE_STATUS;
  payload: { reportStatus: PageRatingJobStatus };
}

export interface DownloadPageRatingReportAction {
  type: typeof PageRatingReportActionTypes.DOWNLOAD_REPORT;
}

export interface ReceivePageRatingReportAction {
  type: typeof PageRatingReportActionTypes.RECEIVE_REPORT;
  payload: { report: PageRatingReport};
}

export interface ResetPageRatingReportStatusAction {
  type: typeof PageRatingReportActionTypes.RESET_STATUS;
}

export type PageRatingReportAction = 
  | CreatePageRatingReportAction
  | FetchPageRatingReportStatusAction
  | ReceivePageRatingReportStatusAction
  | DownloadPageRatingReportAction
  | ReceivePageRatingReportAction
  | ResetPageRatingReportStatusAction;

/** ACTION CREATORS */
export const createRatingReport = (accountId: string): CreatePageRatingReportAction => ({
  type: PageRatingReportActionTypes.CREATE_REPORT,
  payload: { accountId }
});

export const fetchPageRatingReportStatus = (): FetchPageRatingReportStatusAction => ({
  type: PageRatingReportActionTypes.FETCH_STATUS
});

export const receivePageRatingReportStatus = (reportStatus: PageRatingJobStatus): ReceivePageRatingReportStatusAction => ({
  type: PageRatingReportActionTypes.RECEIVE_STATUS,
  payload: { reportStatus }
});

export const downloadPageRatingReport = (): DownloadPageRatingReportAction => ({
  type: PageRatingReportActionTypes.DOWNLOAD_REPORT
});

export const receivePageRatingReport = (report: PageRatingReport): ReceivePageRatingReportAction => ({
  type: PageRatingReportActionTypes.RECEIVE_REPORT,
  payload: { report }
});

export const resetPageRatingReportStatus = (): ResetPageRatingReportStatusAction => ({
  type: PageRatingReportActionTypes.RESET_STATUS
});
