import React, { ReactText } from 'react';
import { Bounce, toast, ToastContent, ToastOptions, cssTransition, UpdateOptions } from 'react-toastify';
import { assign } from 'lodash';
import { getUuid } from 'helpers';
import { SuccessUndoToast } from 'components/CustomToasts/SuccessUndoToast';
import { ConfirmToast } from 'components/CustomToasts/ConfirmToast';

type ToastId = ReactText;

const SHOW_SECONDS = 7;

const CustomZoom = cssTransition({
  enter: 'customZoomIn',
  exit: 'customZoomOut',
  duration: 200
});

const defaultToastConfig: ToastOptions = {
  autoClose: SHOW_SECONDS * 1000,
  position: toast.POSITION.BOTTOM_LEFT,
  transition: Bounce,
};

function getConfig(customConfig: ToastOptions): ToastOptions {
  return assign({}, defaultToastConfig, { toastId: getUuid() }, customConfig);
}

function success(content: ToastContent, customConfig?: ToastOptions): ToastId {
  return toast.success(content, getConfig(customConfig));
}

function info(content: ToastContent, customConfig?: ToastOptions): ToastId {
  return toast.info(content, getConfig(customConfig));
}

function error(content: ToastContent, customConfig?: ToastOptions): ToastId {
  return toast.error(content, getConfig(customConfig));
}

function warning(content: ToastContent, customConfig?: ToastOptions): ToastId {
  return toast.warning(content, getConfig(customConfig));
}

function dismiss(toastId?: ToastId): void {
  toast.dismiss(toastId);
}

function update(toastId: ToastId, updateOptions: UpdateOptions): void {
  toast.update(toastId, updateOptions);
}

function isActive(toastId: ToastId): boolean {
  return toast.isActive(toastId);
}

function successWithUndo(
  content: ToastContent,
  onUndo: (toastId?: ToastId) => void,
  customConfig?: ToastOptions
): ToastId {
  const config = getConfig(customConfig);
  config.className = 'success-undo-toast';
  config.containerId = `toast-${config.toastId}`;

  const component = (
    <SuccessUndoToast config={config} onUndo={onUndo}>
      {content}
    </SuccessUndoToast>
  );

  return toast.success(component, config);
}

// TODO: Create a ConfirmToastConfig interface to replace arguments
function confirm(
  content: ToastContent,
  onConfirm: (toastId?: ToastId) => void,
  onCancel?: (toastId?: ToastId) => void,
  customConfig?: ToastOptions,
  customTitle?: string,
  toastClassname?: string,
  ok?: string,
  cancel?: string,
  cancelVisible: boolean = true
): ToastId {
  const toastId = getUuid();

  const confirmDefaultConfig: ToastOptions = {
    autoClose: false,
    className: toastClassname ? toastClassname: 'confirm-toast',
    closeButton: false,
    closeOnClick: false,
    containerId: `toast-${toastId}`,
    draggable: false,
    onOpen: () => {
      document.getElementById('toast-curtain')?.classList.add('show');
    },
    position: toast.POSITION.TOP_CENTER,
    toastId,
    transition: CustomZoom
  };

  const config: ToastOptions = assign({}, defaultToastConfig, confirmDefaultConfig, customConfig);

  const component = (
    <ConfirmToast title={customTitle} config={config} onConfirm={onConfirm} onCancel={onCancel} ok={ok} cancel={cancel} cancelVisible={cancelVisible}>
      {content}
    </ConfirmToast>
  );

  return toast(component, config);
}

export default {
  confirm,
  dismiss,
  error,
  info,
  isActive,
  success,
  successWithUndo,
  update,
  warning
};
