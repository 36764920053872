import { AxiosResponse } from 'axios';

import api from 'api';
import mockApi from 'api/mock';

import { API } from 'config';

import PageRatingJobStatus from 'models/pageRatingReport/pageRatingJobStatus';
import PageRatingReport from 'models/pageRatingReport/pageRatingReport';

const customApi = API.useMock ? mockApi() : api();

/**
 * Triggers new PageRatingReport Job in CB backend
 * @returns @see {@link PageRatingJobStatus}
 */
export async function createReport(accountId: string): Promise<PageRatingJobStatus> {
  return customApi.post(`/api/page-ratings-report/job-export/${accountId}`).then((resp: AxiosResponse<PageRatingJobStatus>) => {
    const responseJson = resp.data;
    return new PageRatingJobStatus(responseJson);
  });
}

/**
 * Fetching latest status of PageRatingReport Job from CB backend
 * @param reportJobId - String Id of the job
 * @returns @see {@link PageRatingJobStatus}
 */
export async function fetchReportStatus(reportJobId: string): Promise<PageRatingJobStatus> {
  return customApi.get(`/api/page-ratings-report/job-status/${reportJobId}`).then((resp: AxiosResponse<PageRatingJobStatus>) => {
    const responseJson = resp.data;
    return new PageRatingJobStatus(responseJson);
  });
}

/**
 * Get result of the completed PageRatingReport Job from CB backend
 * @param reportJobId - String Id of the job
 * @returns @see {@link PageRatingReport}
 */
export async function downloadReport(reportJobId: string): Promise<PageRatingReport> {
  return customApi.get(`/api/page-ratings-report/download/${reportJobId}`).then((resp: AxiosResponse) => {
    // Extracting assigned filename from response headers
    const filenameSearchString = 'filename=';
    const dispositionHeader: string = resp?.headers?.['content-disposition'];
    const filename = dispositionHeader.substring(dispositionHeader.indexOf(filenameSearchString) + filenameSearchString.length);
  
    return new PageRatingReport(filename, resp?.data);
  });
}
