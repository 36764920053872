import { EditSingleValueButton } from 'components/elements/EditSingleValueButton';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import Course from 'models/canvas/course';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateCourses } from 'store/courses/actions';
import { getCourseIsLocked } from 'store/general/cross-selectors';
import { SisIdCellWrapper } from './style';

interface ISisIdCell {
  row: Course;
  value: string;
}

export const SisIdCell: FC<ISisIdCell> = ({
  row,
  value
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const courseId = row?.id;
  const locked = useSelectorWithProps(getCourseIsLocked, { courseId });

  const canEdit = !locked;

  return (
    <SisIdCellWrapper className='sis-id-cell-wrapper'>
      <EditSingleValueButton
        canEdit={canEdit}
        currentValue={value}
        labelText={t('sisId')}
        onUpdate={(newSisId: string) => {
          dispatch(updateCourses([courseId], { sis_course_id: newSisId }));
        }}
        type='text'
      >
        {!!value && value}
      </EditSingleValueButton>
    </SisIdCellWrapper>
  );
};
