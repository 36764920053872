import React, { FC, useState, useEffect, SyntheticEvent, useMemo, createElement, ReactNode, ComponentClass, FunctionComponent } from 'react';
import { debounce, trim, isNull } from 'lodash';
import { ColumnDef, ColumnSortConfig, ColumnFilterConfig } from 'modules/data-table/types';
import { useDispatch } from 'react-redux';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getDataTable } from 'modules/data-table/store/data-table/selectors';
import { setColumnFilter, setColumnSort } from 'modules/data-table/store/data-table/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';
import cn from 'classnames';
import { isValidElementType } from 'react-is';
import { TABLE_CELL_CLASSNAME, HEADER_CELL_CLASSNAME } from '../TableBody';
import { useTranslation } from 'react-i18next';

interface IHeaderCell {
  columnDef: ColumnDef;
  tableId: string;
  columnIndex: number;
  api: DataTableObservables;
}

export const HeaderCell: FC<IHeaderCell> = ({
  columnDef,
  tableId,
  columnIndex,
  api
}) => {
  const { t } = useTranslation();
  const headerCellRenderer = columnDef?.headerCellRenderer;
  const className = columnDef?.className;
  const columnId = columnDef?.columnId;
  const width = columnDef?.width;
  const headerName = columnDef?.headerName;

  const noHeader = isNull(headerCellRenderer);

  const customContent: ReactNode = useMemo(() => {
    let content: ReactNode;
    if (isValidElementType(headerCellRenderer)) {
      content = createElement(headerCellRenderer as FunctionComponent<any> | ComponentClass<any, any>, { api, columnDef, tableId });
    }
    return content;
  }, [columnDef, tableId, headerCellRenderer, api]);

  const [filterInputVal, setFilterInputVal] = useState('');
  const dispatch = useDispatch();

  const debouncedColumnFilterUpdate = useMemo(() => debounce((filterValue: string) => {
    const filterConfig: ColumnFilterConfig = filterValue ? { columnId, filterValue, caseSensitive: false } : null;
    dispatch(setColumnFilter(tableId, columnId, filterConfig));
  }, 200), [tableId, dispatch, columnId]);

  const table = useSelectorWithProps(getDataTable, { tableId });
  const columnSortDirection: 'asc'|'desc' = table?.sorts?.[columnId]?.direction;
  const currentColumnFilterVal: string = table?.filters?.[columnId]?.filterValue || '';

  useEffect(() => {
    debouncedColumnFilterUpdate(trim(filterInputVal));
  }, [filterInputVal, debouncedColumnFilterUpdate]);

  useEffect(() => {
    setFilterInputVal(currentColumnFilterVal);
  }, [currentColumnFilterVal, setFilterInputVal]);

  const handleSortClick = (e: SyntheticEvent) => {
    e.preventDefault();
    let direction: 'asc'|'desc' = 'asc';
    if (columnSortDirection === 'asc') {
      direction = 'desc';
    } else if (columnSortDirection === 'desc') {
      direction = null;
    }
    const sortConfig: ColumnSortConfig = direction ? { columnId, direction } : null;
    dispatch(setColumnSort(tableId, columnId, sortConfig));
  };

  const handleFocus = (e: any) => {
    api.focusedHeaderCell$.next(e.currentTarget as HTMLDivElement);
  };

  const handleBlur = () => {
    api.focusedHeaderCell$.next(null);
  };

  /** Aria column indices start at 1 */
  const ariaColumnIndex = columnIndex + 1;
  let ariaSort: 'ascending' | 'descending' | 'none' | 'other' = 'none'; // valid aria-sort values

  if (columnSortDirection) {
    ariaSort = columnSortDirection === 'asc' ? 'ascending' : 'descending';
  }

  return (
    <div
      className={cn(TABLE_CELL_CLASSNAME, HEADER_CELL_CLASSNAME, `column-${columnId}`, { [className]: !!className, [columnSortDirection]: !!columnSortDirection })}
      data-testid={`table-header-cell-${columnIndex}`}
      role='columnheader'
      aria-colindex={ariaColumnIndex}
      aria-rowindex={1}
      aria-sort={ariaSort}
      aria-label={`${headerName || columnId} column header`}
      tabIndex={0}
      style={{
        width,
        minWidth: columnDef.width,
        maxWidth: columnDef.width,
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {noHeader && <></>}
      {!noHeader && !!customContent && customContent}
      {!noHeader && !customContent && (
        <>
          <div className='header-cell-heading-wrapper' style={{ whiteSpace: 'nowrap' }}>
            {columnDef?.sortable && (
              <button className='sort-button' onClick={handleSortClick} aria-label={t('tableColumnSortButtonAriaLabel', { currentSort: ariaSort })}>
                {headerName || columnId}
                {columnSortDirection && (
                  <FontAwesomeIcon className='sort-icon' icon={['fas', 'arrow-up']} />
                )}
              </button>
            )}
            {!columnDef?.sortable && (
              headerName || columnId
            )}
          </div>
          {columnDef?.filterable && (
            <div className='filter-input-wrapper'>
              <input
                className='filter-input'
                aria-label='Filter search'
                type='text'
                onChange={(e) => setFilterInputVal(e.currentTarget.value)}
                value={filterInputVal}
                placeholder='&nbsp;'
              />
              <span className='filter-input-icon-wrapper'>
                <FontAwesomeIcon className='filter-input-icon' icon={['fas', 'search']} />
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
