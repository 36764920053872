import React, { useEffect, FC, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Collapse from 'react-smooth-collapse';
import { includes, map, keys, truncate, capitalize, filter } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { ModalId } from 'constants/index';
import { clearAllFilters, clearAllSorts, setRowsSelected } from 'modules/data-table/store/data-table/actions';
import { DataTableSelections } from 'modules/data-table/types';
import { getDataTableSelections } from 'modules/data-table/store/data-table/selectors';

import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { useModalState } from 'hooks/useModalState';
import Course from 'models/canvas/course';

import { getAllExistingCourses, getCourseItems } from 'store/courses/selectors';
import { toggleModal } from 'store/modals/actions';
import { processCourseContentImport } from 'store/courses/composite-actions';
import { ModalHeading, SectionContent, SectionFooter } from 'components/general-styles';
import { Button, Loading, Modal } from 'components/elements';

import { SelectCourseTable } from './SelectCourseTable';
import { ContentInnerWrapper } from './style';

interface IImportCourse {}

export const SELECT_IMPORT_SOURCE_COURSE_TABLE_ID = 'select-import-source-course-table';

const ImportCourseModal: FC<IImportCourse> = () => {
  const modalId = ModalId.IMPORT_COURSE;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [showTable, setShowTable] = useState<boolean>(false);
  const modalState = useModalState(modalId);
  const courseItems = useSelector(getCourseItems);

  const [targetCoursesExpanded, setTargetCoursesExpanded] = useState<boolean>(false);
  const [sourceCourseExpanded, setSourceCourseExpanded] = useState<boolean>(true);

  const targetCourseIds = modalState?.data?.targetCourseIds || [];


  // The course selected in this modal as the content import source
  const sourceCourseTableSelections: DataTableSelections = useSelectorWithProps(
    getDataTableSelections,
    { tableId: SELECT_IMPORT_SOURCE_COURSE_TABLE_ID }
  );

  const selectedCourseId: string = keys(sourceCourseTableSelections).find((courseId: string) => !!sourceCourseTableSelections?.[courseId]);
  const selectedSourceCourse: Course = courseItems?.[selectedCourseId];

  // The courses selected to have content imported into
  const targetCourses = map(
    targetCourseIds,
    (courseId: string) => courseItems?.[courseId]
  );

  const allExistingCourses = useSelector(getAllExistingCourses);

  // Remove all target courses from the source course selection list
  const potentialSourceCourses = filter(
    allExistingCourses,
    (c: Course) => !includes(targetCourseIds, c?.id)
  );

  const courseIsDisabledFn = (c: Course) => {
    return includes(targetCourseIds, c?.id);
  };

  // Close modal and reset local state and data-table state
  const handleClose = () => {
    dispatch(toggleModal(modalId, false, {}));
    dispatch(clearAllSorts(SELECT_IMPORT_SOURCE_COURSE_TABLE_ID));
    dispatch(clearAllFilters(SELECT_IMPORT_SOURCE_COURSE_TABLE_ID));
    dispatch(setRowsSelected(SELECT_IMPORT_SOURCE_COURSE_TABLE_ID, [], true, true));
    setShowTable(false);
  };

  const handleImportClick = () => {
    const importedCourseValues: Course = new Course({
      ...selectedSourceCourse,
      _meta: {
        ...selectedSourceCourse?._meta
      }
    });

    processCourseContentImport(targetCourses, importedCourseValues, dispatch);
    handleClose();
  };

  const truncateCourseName = (courseName: string): string => {
    return truncate(courseName, {
      length: 35,
      separator: ' ',
      omission: ' [...]'
    });
  };

  useEffect(() => {
    cancelButtonRef.current && cancelButtonRef.current.focus();
    setTimeout(() => setShowTable(true), 500);
  }, []);

  return (
    <Modal
      id={modalId}
      handleClose={handleClose}
      size='big'
    >
      <SectionContent>
        <ModalHeading>
          <h2 id={`modal-${modalId}-title`}>
            {t('modalImportCourseTitle')}
          </h2>
          <p id={`modal-${modalId}-description`}>{t('modalImportCourseDescription', { count: targetCourses?.length })}:</p>
        </ModalHeading>

        <ContentInnerWrapper>
          {!showTable && (
            <Loading message={t('loading')} />
          )}
          {showTable && (
            <>
              <div className='selected-target-courses'>
                <div className='import-course-header'>
                  <strong>{t('selectedTargetCoursesWithCount', { count: targetCourses?.length })}:</strong>
                  <Button
                    aria-expanded={targetCoursesExpanded}
                    ariaLabel={targetCoursesExpanded ? 'Collapse' : 'Expand'}
                    className='expand-target-courses-button'
                    onClick={() => setTargetCoursesExpanded(prev => !prev)}
                    size='small'
                    variant='link'
                  >
                    <FontAwesomeIcon className={cn('expand-icon', { expanded: targetCoursesExpanded })} icon={['fas', 'chevron-down']} />
                  </Button>
                </div>
                <Collapse expanded={targetCoursesExpanded}>
                  <ul className='target-courses-list'>
                    {map(targetCourses, (c: Course) => (
                      <li className='import-course-data' key={c?.id}>
                        <FontAwesomeIcon className='course-icon' icon={['fas', 'chalkboard-teacher']} />
                        {c?.course_code}: {truncateCourseName(c?.name)}
                        <br />
                        <small className='sis-id'><strong>SIS:</strong> {c?.sis_course_id || t('unspecified')}</small>,
                        <small className='term-name'><strong>{capitalize(t('term'))}:</strong> {c?.term?.name || t('unspecified')}</small>
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </div>

              <div className='selected-source-course'>
                <div className='selected-course-wrapper'>
                  {selectedSourceCourse && (
                    <>
                      <div className='import-course-header'>
                        <strong>{t('selectedSourceCourse')}:</strong>
                        <Button
                          aria-expanded={sourceCourseExpanded}
                          ariaLabel={sourceCourseExpanded ? 'Collapse' : 'Expand'}
                          className='expand-target-courses-button'
                          onClick={() => setSourceCourseExpanded(prev => !prev)}
                          size='small'
                          variant='link'
                        >
                          <FontAwesomeIcon className={cn('expand-icon', { expanded: sourceCourseExpanded })} icon={['fas', 'chevron-down']} />
                        </Button>
                      </div>
                      <div className='import-course-data'>
                        <FontAwesomeIcon className='course-icon' icon={['fas', 'chalkboard-teacher']} />
                        <span className='selected-source-name'>{selectedSourceCourse?.course_code}: {truncateCourseName(selectedSourceCourse?.name)}</span>
                        <br />
                        <small className='sis-id'><strong>SIS:</strong> {selectedSourceCourse?.sis_course_id || t('unspecified')}</small>,
                        <small className='term-name'><strong>{capitalize(t('term'))}:</strong> {selectedSourceCourse?.term?.name || t('unspecified')}</small>
                      </div>
                    </>
                  )}
                  {!selectedSourceCourse && (
                    <>
                      <div className='import-course-header'>
                        <strong>{t('selectedSourceCourse')}:</strong>
                      </div>
                      <div className='import-course-data'>
                        <span className='empty-message'>{t('selectFromList')}...</span>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className='table-container'>
                <SelectCourseTable
                  courses={potentialSourceCourses}
                  courseIsDisabled={courseIsDisabledFn}
                  tableId={SELECT_IMPORT_SOURCE_COURSE_TABLE_ID}
                />
              </div>
            </>
          )}
        </ContentInnerWrapper>
      </SectionContent>

      <SectionFooter>
        <div />
        <div>
          <Button
            ref={cancelButtonRef}
            className='import-course-cancel'
            ariaLabel='Cancel'
            color='secondary'
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            className='import-course-submit'
            disabled={!selectedSourceCourse}
            ariaLabel='Import course'
            data-testid='import-course-submit-button'
            onClick={handleImportClick}
          >
            {t('import')}
          </Button>
        </div>
      </SectionFooter>
    </Modal>
  );
};

export default ImportCourseModal;
