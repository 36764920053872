import React, { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { coursesTableColumns } from 'config/table';
import { ItemTypes } from 'constants/index';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { DataTable } from 'modules/data-table/components/DataTable';
import { getDataTableSelections } from 'modules/data-table/store/data-table/selectors';
import { ColumnDef, DataTableSelections } from 'modules/data-table/types';
import Course from 'models/canvas/course';
import { getDescendentCoursesOfAccountOrderedByNew, getOriginalCourseItems } from 'store/courses/selectors';
import { getTemplatesItemsHash } from 'store/templates/selectors';
import { getCompletedSyncingCoursesMap, getActivelySyncingCoursesMap, getPendingSyncingCoursesMap, getCourseLockedMap, getCourseUnsyncedMap } from 'store/general/cross-selectors';

import { CoursesListTableWrapper } from './style';
import { getAccountItemsMap } from 'store/accounts/selectors';
import { useTranslation } from 'react-i18next';
import { getTermItems } from 'store/terms/selectors';

interface ICoursesListTable {
  accountId: string;
}

export const COURSES_LIST_TABLE_ID = 'courses-list';

export const CoursesListTable: FC<ICoursesListTable> = ({ accountId }) => {
  const { t } = useTranslation();
  const courses = useSelectorWithProps(getDescendentCoursesOfAccountOrderedByNew, { accountId });
  const templatesHash = useSelector(getTemplatesItemsHash);
  const selections: DataTableSelections = useSelectorWithProps(getDataTableSelections, { tableId: COURSES_LIST_TABLE_ID });

  const courseUnsyncedMap: { [courseId: string]: boolean } = useSelector(getCourseUnsyncedMap);
  const pendingSyncingAccountsMap = useSelector(getPendingSyncingCoursesMap);
  const activelySyncingCoursesMap = useSelector(getActivelySyncingCoursesMap);
  const completedSyncingCourseMap = useSelector(getCompletedSyncingCoursesMap);

  const originalCourseItems = useSelector(getOriginalCourseItems);
  const accountItems = useSelector(getAccountItemsMap);
  const termItems = useSelector(getTermItems);

  const columnDefs: ColumnDef[] = useMemo(() => {
    return coursesTableColumns(
      templatesHash,
      selections,
      courseUnsyncedMap,
      pendingSyncingAccountsMap,
      activelySyncingCoursesMap,
      completedSyncingCourseMap,
      originalCourseItems,
      accountItems,
      termItems,
      t
    );
  }, [templatesHash, selections, courseUnsyncedMap, pendingSyncingAccountsMap, activelySyncingCoursesMap, completedSyncingCourseMap, originalCourseItems, accountItems, termItems, t]);

  const courseLockedMap = useSelector(getCourseLockedMap);

  const rowCanDrag = useCallback((row: Course) => {
    const locked = !!courseLockedMap?.[row?.id];
    return !locked;
  }, [courseLockedMap]);

  return (
    <CoursesListTableWrapper className='courses-list-table-wrapper'>
      <DataTable
        key={COURSES_LIST_TABLE_ID}
        tableId={COURSES_LIST_TABLE_ID}
        data={courses}
        rowIdProperty='id'
        rowCanDrag={rowCanDrag}
        columnDefs={columnDefs}
        defaultRowHeight={45}
        hasCustomDragItemRenderer
        dragItemType={ItemTypes.COURSE}
      />
    </CoursesListTableWrapper>
  );
};
