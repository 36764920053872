// All the methods in this file are used only to mock data
import { padStart, random, sample, range } from 'lodash';
import { getUuid } from 'helpers';
import SyncOrderDto from 'models/dto/syncOrderDto';
import SyncOrder from 'models/syncOrder/syncOrder';
import SyncNewAccount from 'models/syncOrder/syncNewAccount';
import SyncNewCourse from 'models/syncOrder/syncNewCourse';
import SyncExistingCourse from 'models/syncOrder/syncExistingCourse';
import SyncNewTerm from 'models/syncOrder/syncNewTerm';

const workflowStates = ['available', 'completed', 'unpublished', 'deleted'];

const getRandomDate = () => {
  const year = 2020;
  const month = padStart(random(1, 12), 2, 0);
  const day = padStart(random(1, 30), 2, 0);
  const hour = padStart(random(1, 23), 2, 0);
  const minute = padStart(random(1, 59), 2, 0);
  const second = padStart(random(1, 59), 2, 0);

  return `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
};

export const fillWithCourses = (amount: number, accountIds: number[]) => [...Array(amount)].map(i => {
  const id = random(500, 99999);
  const termId = random(1000, 1010);
  const teacherId = random(200, 210);
  const accountId = sample(accountIds);
  return {
    course: {
      id,
      name: `Course ${id} Name`,
      course_code: `Course-${id}-Code`,
      accountId,
      end_at: getRandomDate(),
      sis_course_id: getUuid(),
      start_at: getRandomDate(),
      workflow_state: workflowStates[random(0, 3)],
      term: {
        id: termId,
        name: `Term ${termId}`,
        start_at: getRandomDate(),
        end_at: getRandomDate()
      },
      teachers: [
        {
          id: teacherId,
          displayName: `Teacher ${teacherId}`,
          avatarImageUrl: 'https://via.placeholder.com/150?text=img',
          htmlUrl: 'https://google.com'
        }
      ],
      account_name: `Account ${accountId} Name`
    },
    meta: {
      tempCourseId: `tempCourse${i}Id`
    }
  };
});

const syncOrderStatus: any = ['SUCCESS', 'ACTIVE', 'PENDING', 'INCOMPLETE'];
const syncAccountStatus: any = ['SUCCESS', 'ACTIVE', 'PENDING', 'ERROR', 'PARENT_ACCOUNT_NOT_FOUND'];
const syncCoursesStatus: any = ['SUCCESS', 'ACTIVE', 'PENDING', 'ERROR', 'PARENT_ACCOUNT_NOT_FOUND', 'APPLY_COURSE_TEMPLATE_FAILED'];

export const createNewSyncOrderDto = () => new SyncOrderDto({
  syncOrder: {},
  syncNewAccounts: [],
  syncNewCourses: [],
  syncExistingCourses: [],
});

export const createNewSyncOrder = (syncOrderId:string, status:any = random(0, 3)) => new SyncOrder({
  id: getUuid(),
  createdAt: getRandomDate(),
  syncOrderId,
  syncItemResult: {
    syncStatus: syncOrderStatus[status],
    message: null
  },
  installationId: getUuid(),
  lmsId: getUuid(),
  createdByLtiUserId: getUuid(),
  lmsUpdateJobResult: null
});

export const createNewAccounts = (syncOrderId: string, total: number = random(1, 5), status: any = random(0, 4)) => [...Array(total)].map(() => new SyncNewAccount({
  accountId: getUuid(),
  createdAt: getRandomDate(),
  name: 'Account name',
  parentAccountId: '1',
  syncNewAccountId: getUuid(),
  syncItemResult: {
    syncStatus: syncAccountStatus[status],
  },
  syncOrderId,
  tempAccountId: getUuid()
}));

export const createNewCourses = (syncOrderId: string, total: number = random(1, 5), status = random(0, 5)) => [...Array(total)].map(() => new SyncNewCourse({
  accountId: '1',
  courseCode: 'Course code',
  courseId: getUuid(),
  courseTemplateId: getUuid(),
  createdAt: getRandomDate(),
  name: 'Course name',
  syncNewCourseId: getUuid(),
  syncOrderId,
  syncItemResult: {
    syncStatus: syncCoursesStatus[status],
  },
  tempCourseId: getUuid(),
  workflowState: 'running',
}));

export const createNewTerms = (syncOrderId: string, total: number = random(1, 5), status = random(0, 5)) => [...Array(total)].map(() => new SyncNewTerm({
  name: 'Term name',
  termId: getUuid(),
  sisTermId: getUuid(),
  startAt: getRandomDate(),
  endAt: getRandomDate(),
  syncOrderId,
  syncItemResult: {
    syncStatus: syncCoursesStatus[status],
  },
  syncNewTermId: getUuid(),
  tempTermId: getUuid(),
  workflowState: 'running',
}));

export const createExistingCourses = (syncOrderId: string, total: number = random(1, 5), status = random(0, 5)) => [...Array(total)].map(() => new SyncExistingCourse({
  accountId: '1',
  courseCode: 'Course code',
  courseId: getUuid(),
  courseTemplateId: getUuid(),
  createdAt: getRandomDate(),
  name: 'Course name',
  syncExistingCourseId: getUuid(),
  syncOrderId,
  syncItemResult: {
    syncStatus: syncCoursesStatus[status],
  },
  tempCourseId: getUuid(),
  workflowState: 'running',
}));

export const fillWithSyncOrders = () => {
  const data: any = [];
  const syncOrders: any = random(5, 10);

  range(0, syncOrders + 1).forEach((syncOrderIndex: number) => {
    const syncOrderId = `syncOrder${syncOrderIndex}`;
    data[syncOrderIndex] = createNewSyncOrderDto();
    data[syncOrderIndex].syncOrder = createNewSyncOrder(syncOrderId);
    data[syncOrderIndex].syncNewAccounts = createNewAccounts(syncOrderId, random(1, 5));
    data[syncOrderIndex].syncNewCourses = createNewCourses(syncOrderId, random(1, 5));
    data[syncOrderIndex].syncExistingCourses = createExistingCourses(syncOrderId, random(1, 5));
  });

  return data;
};
