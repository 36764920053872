import React, { FC, useState, useEffect, KeyboardEvent, useCallback } from 'react';
import { get, concat, map, compact, trimStart, filter, includes, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import FlipMove from 'react-flip-move';
import FocusLock from 'react-focus-lock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isLikeClick } from 'helpers/events';
import { useKeyPress } from 'hooks/useKeyPress';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';

import { KeyCodesTypes } from 'constants/index';
import Account from 'models/canvas/account';
import { getAllAccountsSortedAlphabetically, getAccountAncestorMap, getAccountItemsMap } from 'store/accounts/selectors';
import { setAccountsExpanded, setActiveAccount } from 'store/account-tree/actions';

import { SrOnlyText } from 'components/general-styles';
import { Filter } from './style';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { RoutePathnames } from 'config/routePathnames';
import { AccountsFeatureState } from 'store/accounts/reducer';

interface IAccountFilter { }

export const AccountFilter: FC<IAccountFilter> = () => {
  const dispatch = useDispatch();

  const accounts: Account[] = useSelector(getAllAccountsSortedAlphabetically);
  const accountItems: AccountsFeatureState['items'] = useSelector(getAccountItemsMap);
  const accountAncestorMap: { [accountId: string]: string[] } = useSelector(getAccountAncestorMap);

  const [filterBy, setFilterBy] = useState('');
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  const esc = useKeyPress(KeyCodesTypes.ESCAPE);

  // Resets the state of the filter, removing all filtered accounts and setting as '' the filter input
  const handleReset = useCallback(() => {
    setFilteredAccounts([]);
    setFilterBy('');
  }, [setFilteredAccounts, setFilterBy]);

  const expandAncestorAccounts = (account: Account): any => {
    const ancestorIds = get(accountAncestorMap, account.id, []);
    const idsToExpand = concat(ancestorIds, account.id);
    dispatch(setAccountsExpanded(idsToExpand, true));
  };

  const handleSelect = (account: Account): any => {
    const accountId = account.id;
    expandAncestorAccounts(account);
    dispatch(setActiveAccount(accountId));
    dispatch(push(generatePath(RoutePathnames.ACCOUNT_COURSES, { accountId })));
  };

  const generateBreadcrumb = (account: Account): any => {
    const ancestorIds = get(accountAncestorMap, account.id, []);
    const ancestorNames = map(ancestorIds, (accountId: string) => get(accountItems, `${accountId}.name`));
    return compact(ancestorNames).join(' / ');
  };

  // It shows a list of accounts that matches the filterBy value after a small delay
  // to prevent it from running after each keypress
  useDebouncedEffect(() => {
    if (filterBy) {
      setFilteredAccounts(filter(accounts, (acc: Account) => {
        return includes(acc.name.toLowerCase(), filterBy.toLowerCase());
      }));
    }
    if (!filterBy && !isEmpty(filteredAccounts)) {
      setFilteredAccounts([]);
    }
  }, 200, [filterBy, accounts, filteredAccounts, setFilteredAccounts]);

  // If the user presses the esc key and there are filtered accounts,
  // we reset it to hide the list
  useEffect(() => {
    if (esc && !isEmpty(filteredAccounts)) {
      handleReset();
    }
  }, [esc, filteredAccounts, handleReset]);

  return (
    <FocusLock
      disabled={isEmpty(filteredAccounts)}
    >
      <Filter
        className='account-filter-container'
        hasResults={!isEmpty(filteredAccounts)}
      >
        <div className='account-filter-input' role='search'>
          <input
            aria-label='Search accounts'
            name='filter'
            onChange={(e) => setFilterBy(trimStart(e.target.value))}
            type='text'
            value={filterBy}
          />

          <FontAwesomeIcon className='account-filter-icon' icon={['fas', 'search']} />
          {
            !isEmpty(filteredAccounts) && (
              <button
                className='account-filter-reset'
                onClick={handleReset}
                aria-label='Reset filter.'
              >
                <FontAwesomeIcon icon={['fas', 'times']} />
              </button>
            )
          }
        </div>

        {get(filteredAccounts, 'length', 0) > 0 && (
          <SrOnlyText>
            {`Filter result: ${filteredAccounts.length} accounts`}
          </SrOnlyText>
        )}

        <FlipMove
          className='account-filter-list'
          enterAnimation='fade'
          leaveAnimation='fade'
          easing='ease-out'
          typeName='ul'
          duration={400}
        >
          {
            !isEmpty(filteredAccounts) && (
              filteredAccounts.map(acc => (
                <div
                  aria-label={`Select ${acc.name}`}
                  className='account-filter-result'
                  key={`account-${acc.id}`}
                  onClick={() => handleSelect(acc)}
                  onKeyPress={(e: KeyboardEvent) => isLikeClick(e) && handleSelect(acc)}
                  role='button'
                  tabIndex={0}
                >
                  <small>{generateBreadcrumb(acc)}</small>
                  <p>{acc.name}</p>
                </div>
              ))
            )
          }
        </FlipMove>
      </Filter>
    </FocusLock>
  );
};
