import styled, { css } from 'styled-components';

interface ICourseListContainer {

}

export const CourseListContainer = styled.section<ICourseListContainer>`
  ${({ theme }) => css`
    
    &.courses-list-container {
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      padding: 10px 5px 10px 10px;

      .courses-heading-wrapper {
        display: inline-block;
        max-width: 70%;
        .courses-heading {
          font: 1.8rem/2rem 'Lato';
          margin-bottom: 0;
          .heading-account-name {
            font-style: italic;
          }
        }
      }

      .courses-list-table-wrapper {
        flex: 1 1;
        margin-top: -2.8rem;
      }

    }
  `}
`;
