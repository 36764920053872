import { useEffect, useRef } from 'react';
import { filter as _filter, includes, map as _map, concat, compact, get, find, forEach } from 'lodash';
import { useSelector, useStore } from 'react-redux';
import { AnyAction, Store } from 'redux';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, pairwise, switchMap, tap } from 'rxjs/operators';
import { AppState } from 'store';
import { getAllPendingAndActiveSyncOrdersIds, getAllItemsForSyncOrder, getSyncItemTypeData,
getSyncOrderDto, syncItemIsAccount, syncItemIsCourse, syncItemIsTerm } from 'store/syncOrders/selectors';
import { useSelectorObservable } from './useSelectorObservable';
import SyncOrderDto from 'models/dto/syncOrderDto';
import { deleteCourses, fetchCourse, fetchPaginatedCourses } from 'store/courses/actions';
import { deleteAccounts, fetchAccount } from 'store/accounts/actions';
import { getTopAccountId } from 'store/installation/selectors';
import { toast } from 'helpers/messages';
import { useTranslation } from 'react-i18next';
import { getActiveAccountId } from 'store/account-tree/selectors';
import { getAccountExistsInLmsAlready } from 'store/accounts/selectors';
import SyncNewAccount from 'models/syncOrder/syncNewAccount';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { ACCOUNTS_COURSES_PATTERN, RoutePathnames } from 'config/routePathnames';
import { setActiveAccount } from 'store/account-tree/actions';
import { getPathname } from 'store/router/selectors';
import BaseSyncItemEntity from 'models/syncOrder/baseSyncItemEntity';
import { deleteTerms, fetchTermsPage } from 'store/terms/actions';

const MAX_CONCURRENT = 10;

export const useSyncOrdersEffect = () => {
  const store: Store<AppState, AnyAction> = useStore();
  const activeSyncOrderIds$ = useSelectorObservable(getAllPendingAndActiveSyncOrdersIds);
  const topAccountId = useSelector(getTopAccountId);
  const { t } = useTranslation();

  // stream of ids of SyncOrders that just completed
  const justCompletedSyncOrderIds$: Observable<string> = useRef(
    activeSyncOrderIds$.pipe(
      pairwise(),
      mergeMap(([prevActiveIds, currActiveIds]: [string[], string[]]) => {
        const justCompleted: string[] = _filter(
          prevActiveIds,
          (id: string) => !includes(currActiveIds, id)
        );
        return from(justCompleted);
      })
    )
  ).current;

  // re-fetch accounts and courses associated with the completed sync order
  useEffect(() => {
    const sub = justCompletedSyncOrderIds$.pipe(
      map((syncOrderId: string) => getSyncOrderDto({ syncOrderId })(store.getState())),
      tap(() => {
        toast.success(t('toastSyncJobCompleted'));
      }),
      switchMap((dto: SyncOrderDto) => {
        const syncItems: BaseSyncItemEntity[] = getAllItemsForSyncOrder({ syncOrderId: dto?.syncOrder?.syncOrderId })(store.getState());
        const activeAccountId = getActiveAccountId(store.getState());
        const activeAccountIsNew = !getAccountExistsInLmsAlready({ accountId: activeAccountId })(store.getState());
        const pathname = getPathname(store.getState());
        const isAccountsCoursesRoute = ACCOUNTS_COURSES_PATTERN.test(pathname);

        const courseIdsToFetch: string[] = [];
        const courseTempIdsToDelete: string[] = [];
        const accountIdsToFetch: string[] = [];
        const accountTempIdsToDelete: string[] = [];
        const termTempIdsToDelete: string[] = [];
        let shouldFetchTerms: boolean = false;

        forEach(syncItems, (item: BaseSyncItemEntity) => {
          const { isNew } = getSyncItemTypeData(item);
          const isCourse = syncItemIsCourse(item);
          const isAccount = syncItemIsAccount(item);
          const isTerm = syncItemIsTerm(item);

          if (isCourse) {
            courseIdsToFetch.push(get(item, 'courseId'));
            if (isNew) { courseTempIdsToDelete.push(get(item, 'tempCourseId')); }
          }
          if (isAccount) {
            accountIdsToFetch.push(get(item, 'accountId'));
            if (isNew) { accountTempIdsToDelete.push(get(item, 'tempAccountId')); }
          }
          if (isTerm && isNew) {
            shouldFetchTerms = true;
            termTempIdsToDelete.push(get(item, 'tempTermId'));
          }
        });

        const deleteUnsyncedActions = concat(
          deleteCourses(courseTempIdsToDelete),
          deleteAccounts(accountTempIdsToDelete),
          deleteTerms(termTempIdsToDelete)
        );
        const individualCourseFetchActions = _map(
          courseIdsToFetch,
          (courseId: string) => fetchCourse(courseId)
        );
        const individualAccountFetchActions = _map(
          accountIdsToFetch,
          (accountId: string) => fetchAccount(accountId)
        );

        let actions: AnyAction[] = concat(deleteUnsyncedActions, individualAccountFetchActions);
        if (individualCourseFetchActions.length >= MAX_CONCURRENT) {
          // If it was a large SyncOrder (10+ items, just re-fetch all courses)
          actions.push(fetchPaginatedCourses(topAccountId));
        } else {
          actions = concat(actions, individualCourseFetchActions);
        }

        if (shouldFetchTerms) {
          actions.push(fetchTermsPage(1));
        }

        // If a new account is the actively selected account,
        // activate the real, created account id to replace it
        if (activeAccountIsNew) {
          const relatedSyncNewAccount = find(dto.syncNewAccounts, (sna: SyncNewAccount) => sna.tempAccountId === activeAccountId);
          const createdAccountId = get(relatedSyncNewAccount, 'accountId');

          actions = compact(concat(
            actions,
            createdAccountId ? setActiveAccount(createdAccountId) : null,
            isAccountsCoursesRoute
              ? push(generatePath(RoutePathnames.ACCOUNT_COURSES, { accountId: createdAccountId }))
              : null
          ));
        }
        // Fetch all items individually
        return from(actions);
      }),
      mergeMap((action: AnyAction) => of(action), MAX_CONCURRENT),
      tap(store.dispatch)
    ).subscribe();
    return () => sub.unsubscribe();
  }, [justCompletedSyncOrderIds$, store, topAccountId, t]);
};
