import styled, { css } from 'styled-components';

export const HiddenKeyboardNavContainer = styled.div`
  ${({ theme }) => css`
    background: transparent;
    display: block;
    height: 0;
    margin: 0;
    max-width: 100%;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    text-align: center;

    .hidden-keyboard-nav-inner {
      background: ${theme.color.white};
      border: 1px solid ${theme.color.gray4};
      color: ${theme.color.font};
      display: inline-block;
      padding: .5rem;
      text-align: left;
    }

    button, a[href] {
      padding: .5rem 1rem;
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }

    /** keep classname in this selector for use in a test */
    &.hidden-keyboard-nav-container:focus-within { 
      margin: 3.6rem 2rem 0 0;
      height: auto;
      opacity: 1;
      pointer-events: all;
    }
  `}

  @media only screen and (max-width: 800px) {
    margin-top: 4rem;
    &.hidden-keyboard-nav-container:focus-within { 
      margin-bottom: .8rem;
    }
  }
  
`;
