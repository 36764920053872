import { FieldSet } from 'components/general-styles';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  ${FieldSet} {
    max-width: 500px;
    input, select {
    }
  }
`;
