import styled from 'styled-components';

export const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 7em;
  margin: 0;
  padding: 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  && p {
    color: ${({ theme }) => theme.color.black};
    padding: 0;
    margin: 0 0 10px;
    font-size: 16px;
  }
`;
