import React, { FC, useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { concat } from 'lodash';

import { KeyCodesTypes } from 'constants/index';
import { AppState } from 'store';
import Course from 'models/canvas/course';
import { useKeyPress } from 'hooks/useKeyPress';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { setRowsSelected } from 'modules/data-table/store/data-table/actions';
import { getDataTableSelections, getShiftSelectionRangeForRow } from 'modules/data-table/store/data-table/selectors';
import { SelectionCheckbox } from 'components/elements';
import { ScreenReaderLabelText } from 'components/elements/SelectionCheckbox/style';
import { SelectionCellWrapper } from './style';
import { AnyAction, Store } from 'redux';

interface ISelectionCell {
  tableId: string;
  row: Course;
}

export const SelectionCell: FC<ISelectionCell> = ({
  tableId,
  row,
}) => {
  const { t } = useTranslation();
  const store: Store<AppState, AnyAction> = useStore();
  const dispatch = useDispatch();
  const rowId = row?.id;
  const selections = useSelectorWithProps(getDataTableSelections, { tableId });
  const shift = useKeyPress(KeyCodesTypes.SHIFT);
  const selected = !!selections?.[rowId];

  // Sets the current row as selected, and mark the checkbox as 'checked',
  // It can set as selected multiple rows
  const handleSelectClick = useCallback((checked: boolean) => {
    let rowIds = [rowId];
    if (shift && !selected) {
      const appState = store.getState();
      const selectionRange = getShiftSelectionRangeForRow({ tableId, selectedRow: row })(appState);
      rowIds = concat(rowId, selectionRange);
    }
    dispatch(setRowsSelected(tableId, rowIds, !!checked, false));
  }, [tableId, rowId, dispatch, shift, row, selected, store]);

  return (
    <SelectionCellWrapper
      className='selection-cell-wrapper'
      aria-selected={selected}
    >
      <SelectionCheckbox
        id={`select-${tableId}-${rowId}`}
        selected={selected}
        onClick={handleSelectClick}
      >
        <ScreenReaderLabelText>
          {selected
            ? t('deselectRow')
            : t('selectRow')}
        </ScreenReaderLabelText>
      </SelectionCheckbox>
    </SelectionCellWrapper>
  );
};
