import { KeyboardEvent } from 'react';
import { KeyNames } from 'constants/index';

/**
 * Checks if the key pressed was SPACE or ENTER to handle the event as a click
 */
export const isLikeClick = (e: KeyboardEvent): boolean => e.key === KeyNames.SPACE || e.key === KeyNames.ENTER;

/**
 * Checks if the event was triggered by the target element
 */
export const isOwnEvent = (e: KeyboardEvent, ref: any): boolean => e.target === ref.current;
