/** TYPES */

export enum FunctionBarActionTypes {
  SET_EXPANDED = 'function-bar/SET_EXPANDED'
}

export interface SetExpandedAction {
  type: typeof FunctionBarActionTypes.SET_EXPANDED;
  payload: { expanded: boolean }
}

export type FunctionBarAction =
  | SetExpandedAction;

/** ACTION CREATORS */

export const setExpanded = (expanded: boolean): SetExpandedAction => ({
  type: FunctionBarActionTypes.SET_EXPANDED,
  payload: { expanded }
});
