import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
 0% { opacity: 1}
 30% { opacity: .8}
 40% { opacity: .6}
 100% { opacity: .4}
`;

interface IProgressContainer {
  size?: string,
  fixed?: boolean
}
interface IProgress {
  type: string,
  percentage: number
}

export const ProgressBarContainer = styled.div<IProgressContainer>`
  ${({ theme, size, fixed }) => css`
    background: ${theme.color.gray7};
    display: flex;
    height: ${theme.sizes.progressBar[size]};
    width: 100%;

    ${size === 'small' && css`
      & > .progress-bar {
        border: none!important;
      }
    `}
    ${fixed && css`
      left: 0;
      position: absolute;
      top: 0;
      z-index: 2;
    `};
  `}
`;

export const Progress = styled.div<IProgress>`
  ${({ theme, type, percentage }) => css`
    background: ${type === 'success' ? theme.color.green : theme.color.red};
    height: 100%;
    transition: width .2s ease-in-out;
    width: ${percentage}%;
    animation-name: ${pulse};
    animation-duration: 1s;
    animation-iteration-count: infinite;

    &:nth-of-type(2) {
      border-left: 2px solid ${theme.color.white};
    }
  `}
`;
