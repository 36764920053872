import { FieldSet } from 'components/general-styles';
import styled, { css } from 'styled-components';

export const SourceCoursesContainer = styled.div`
  font-style: italic;

  ${FieldSet} {
    margin-bottom: .5rem;
    input, select {
      height: 2.5rem;
    }
  }
`;

export const SourceCourseItem = styled.div`
  ${({ theme }) => css`
    & {
      overflow: hidden;
      margin-bottom: 32px;
      position: relative;

      &:after {
        position: absolute;
        background: ${theme.color.mainDark};
        content: '';
        height: 1000vh;
        left: 2px;
        top: 0;
        width: 1px;
      }

       /* Source course  */
      .course-heading,
      .course-settings {
        border: 1px solid ${theme.color.gray6};
        border-left: solid 5px ${theme.color.mainDark};
        padding: 8px 16px 8px 24px;

        h4 {
          font: 400 1.4rem/2.5rem ${theme.font.main};
          margin: 0 8px 0 0;
        }

        p {
          font: 400 1.6rem/2.5rem ${theme.font.main};
          margin: 0 16px 0 0;
        }
      }

      .course-heading {
        background: ${theme.color.rows};
        justify-content: space-between;
        display: flex;

        .course-heading-description,
        .course-heading-actions {
          align-items: center;
          display: flex;
        }
      }
      
      .course-settings {
        .course-settings-fields {
          .course-settings-main {
            display: flex;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .fieldset-autosuffix {
              flex-basis: 100px;
            }

            .fieldset-term {
              flex-basis: 150px;
            }

            .fieldset-account {
              flex-basis: 190px;
            }

            .fieldset-start-date,
            .fieldset-end-date {
              flex-basis: 210px;
              max-width: 210px;
            }

          }

          .course-settings-meta {
            .course-settings-meta-expand-toggle {
              margin: 2px 0 0 0;
              .expand-icon {
                font-size: 10px;
                transition: all .2s ease;
                transform: rotate(-90deg);
              }
            }
            .expand-container {
              padding: 0;
              .expand-container-inner {
                padding: 5px 15px;
                border-left: 1px solid lightgray;
                visibility: hidden;

                [class*=fieldset] {
                  margin: 0 0 10px 0;
                }

                .fieldset-tags {
                  max-width: 100%;
                  .tags-with-check {
                    flex-grow: 1
                  }
                  label[for*="tags-include"] {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    button {
                      margin-bottom: 0.4rem;
                    }
                  }
                }
              }
            }
            &.expanded {
              .expand-icon {
                transform: rotate(0deg);
              }
              .expand-container-inner {
                visibility: visible;
              }
            }
          }

          .duplication-actions {
            align-items: center;
            display: flex;
            height: 25px;
            margin: 15px 0 0 0;
            min-width: auto;

            .fieldset-quantity {
              width: 150px;
              max-width: 150px;
              margin-bottom: 0;
              input {
                max-width: 90px;
              }
            }
            
            button {
              height: 25px;
              min-width: auto;
            }
          }
          .duplication-validation-error {
            color: ${theme.color.red};
            font-size: 1.4rem;

          }
        }
      }
    }
  `}
`;

export const DuplicatedCourseItem = styled.li`
  ${({ theme }) => css`       
    border: 1px solid ${theme.color.gray6};
    margin-left: 5.8rem;
    position: relative;

    & > span {
      left: -5rem;
      position: absolute;
      top: 1.3rem;
    }

    &:before {
      border-top: 1px solid ${theme.color.mainDark};
      content: '';
      height: 1px;
      left: -5.7rem;
      position: absolute;
      top: 2.2rem;
      width: 4px;
    }

    &:last-child:after {
      background: ${theme.color.white};
      content: '';
      height: 100vh;
      left: -6.1rem;
      position: absolute;
      top: 2.3rem;
      width: 5px;
      z-index: 2;
    }

    &:nth-child(odd) {
      .duplicated-course-settings { background: ${theme.color.rows} }
    }

    .duplicated-course-settings {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;

      .duplicated-course-fields,
      .duplicated-course-actions {
        align-items: center;
        display: flex;
      }

      .duplicated-course-fields {
        .fieldset-name {
          max-width: 300px;
          flex: 1 1 auto;
          width: 300px;
        }
        .fieldset-course-code {
          flex: 0 1 auto;
          width: 150px;
        }
        .fieldset-sis-course-id {
          flex: 1 1 auto;
          width: 300px;
        }
      }
    }
    .duplicated-course-collapse {
      display: flex;
      flex-direction: column;
      padding: 8px 16px;
      visibility: hidden;

      .fieldset-group {
        align-items: center;
        display: flex;
        margin-bottom: 16px;
        max-width: 600px;
        width: 100%;
      }

      .selection-checkbox {
        display: flex;

        .selection-checkbox-button {
          flex-shrink: 0;
        }
        .selection-checkbox-label {
          font: 400 1.4rem/2rem ${theme.font.main};
        }
      }

      &.expanded {
        visibility: visible;
      }
    }
  `}
`;
