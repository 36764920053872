import { get, toString } from 'lodash';
import { getUuid } from 'helpers';
import DefaultBulkSettings from './defaultBulkCourseSettings';
export default class AccountMeta {
  accountMetaId: string;
  accountId: string;
  lmsId: string;
  tempAccountId: string;
  tempDepthLevel: number;
  createdAt: Date;
  modifiedAt: Date;
  defaultCourseBulkSettings?: DefaultBulkSettings;

  constructor(json: any) {
    this.accountMetaId = get(json, 'accountMetaId', getUuid());
    this.accountId = toString(get(json, 'accountId'));
    this.lmsId = get(json, 'lmsId');
    this.tempAccountId = get(json, 'tempAccountId');
    this.tempDepthLevel = get(json, 'tempDepthLevel');
    this.createdAt = get(json, 'createdAt') ? new Date(get(json, 'createdAt')) : null;
    this.modifiedAt = get(json, 'modifiedAt') ? new Date(get(json, 'modifiedAt')) : null;
    this.defaultCourseBulkSettings = get(json, 'defaultCourseBulkSettings');
  }
}
