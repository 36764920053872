import { getUuid } from 'helpers';
import React, { ReactNode, ReactNodeArray, RefObject, useCallback, useEffect, useMemo, useRef, useState, Children } from 'react';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { Button } from '..';
import { DatePicker } from '../Datepicker';
import { Popover } from '../Popover';
import { TagsInput } from '../TagsInput';
import { TermSelect } from '../TermSelect';
import { EditSingleValueWrapper, PopoverContentsWrapper } from './style';
import ReactTooltip from 'react-tooltip';
import { KeyNames } from 'constants/index';
import AccountSelect from '../AccountSelect';
import { CellMessage } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable/CellMessage';

interface IEditSingleValueButton<T> {
  canEdit: boolean;
  className?: string;
  componentProps?: any;
  currentValue: T;
  disabled?: boolean;
  id?: string;
  labelText?: string;
  isValid?: (newVal: T) => boolean;
  onUpdate: (newVal: T) => void;
  targetRef?: RefObject<HTMLElement>;
  type: 'text' | 'date' | 'term' | 'account' | 'tags';
  children?: ReactNode | ReactNodeArray;
}

/**
 * A Button to display a single field's value and make it click-to-edit.
 * The button contents are the current value. When the button is clicked,
 * a popover is shown where the value can be updated to something new.
 */
export const EditSingleValueButton = <T extends any>({
  canEdit,
  className,
  componentProps,
  currentValue,
  disabled,
  id,
  labelText,
  isValid,
  onUpdate,
  targetRef,
  type,
  children,
}: IEditSingleValueButton<T>) => {
  const { t } = useTranslation();
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const contentsRef = useRef<HTMLDivElement>(null);
  const uuid = useRef<string>(`uuid-${getUuid()}`);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [newValue, setNewValue] = useState<T>(currentValue);
  // If the input field has a popover or menu of its own, keep track of whether that extra
  // component is open, so that we know whether to ignore certain keyDown events.
  const [extraComponentOpen, setExtraComponentOpen] = useState(false);

  const handleCancelClick = useCallback(() => {
    setPopoverOpen(false);
    setNewValue(currentValue);
  }, [setPopoverOpen, setNewValue, currentValue]);

  const handleUpdateClick = useCallback(() => {
    setPopoverOpen(false);
    onUpdate(newValue);
  }, [setPopoverOpen, onUpdate, newValue]);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    const shouldHandle = popoverOpen && !extraComponentOpen;
    if (!shouldHandle) { return; }
    if (e.key === KeyNames.ENTER) {
      handleUpdateClick();
    } else if (e.key === KeyNames.ESC && popoverOpen) {
      handleCancelClick();
    }
  }, [handleUpdateClick, handleCancelClick, popoverOpen, extraComponentOpen]);

  const disableUpdateButton = useMemo(() => {
    if (disabled) { return true; }
    return isFunction(isValid) ? !isValid(newValue) : false;
  }, [disabled, isValid, newValue]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const field = useMemo(() => {
    switch (type) {
      case 'text': {
        return (
          <input
            id={uuid.current}
            onChange={e => setNewValue(e.target.value as T)}
            type='text'
            value={newValue as string}
            {...componentProps}
          />
        );
      }
      case 'date': {
        return (
          <DatePicker
            defaultValue={currentValue as Date}
            id={uuid.current}
            onChange={date => setNewValue(date as T)}
            onPopoverClose={() => setExtraComponentOpen(false)}
            onPopoverOpen={() => setExtraComponentOpen(true)}
            value={newValue as Date}
            {...componentProps}
          />
        );
      }
      case 'term': {
        return (
          <TermSelect
            defaultValue={currentValue as string}
            id={uuid.current}
            onChange={termId => setNewValue(termId as T)}
            value={newValue as string}
            onMenuOpen={() => setExtraComponentOpen(true)}
            onMenuClose={() => setExtraComponentOpen(false)}
            {...componentProps}
          />
        );
      }
      case 'tags': {
        return (
          <TagsInput
            id={uuid.current}
            onChange={newTags => setNewValue(newTags as T)}
            value={newValue as string[]}
            onMenuOpen={() => setExtraComponentOpen(true)}
            onMenuClose={() => setExtraComponentOpen(false)}
            {...componentProps}
          />
        );
      }
      case 'account': {
        return (
          <AccountSelect
            defaultValue={currentValue as string}
            id={uuid.current}
            onChange={newAccountId => setNewValue(newAccountId as T)}
            value={newValue as string}
            onMenuOpen={() => setExtraComponentOpen(true)}
            onMenuClose={() => setExtraComponentOpen(false)}
            {...componentProps}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  }, [type, setNewValue, uuid, newValue, currentValue, componentProps]);

  const hasChildren = Children.toArray(children).length > 0;

  if (!canEdit) {
    return <>{children}</>;
  }

  return (
    <EditSingleValueWrapper className='edit-single-field-wrapper'>
      <ReactTooltip
        aria-haspopup='true'
        delayShow={600}
        event='focus mouseover'
        eventOff='blur mouseout'
        id={`${uuid.current}-button-tooltip`}
      >
        {t('edit')}
      </ReactTooltip>
      <Button
        className={`edit-button ${className || ''}`}
        color='secondary'
        data-tip
        data-for={`${uuid.current}-button-tooltip`}
        disabled={disabled}
        id={id}
        onClick={() => setPopoverOpen(true)}
        ref={editButtonRef}
        size='small'
        testId='edit-single-value-button'
        title={t('edit')}
      >
        {hasChildren && children}
        {!hasChildren && <CellMessage message={t('edit')} />}
      </Button>
      <Popover
        show={popoverOpen}
        targetRef={targetRef || editButtonRef}
      >
        <PopoverContentsWrapper className='popover-contents-wrapper' ref={contentsRef}>
          {labelText && (
            <label htmlFor={uuid.current}>{labelText}</label>
          )}
          {field}
          <div className='actions'>
            <Button
              className='cancel-button'
              color='secondary'
              onClick={handleCancelClick}
              size='small'
              testId='edit-single-value-cancel-button'
            >
              {t('cancel')}
            </Button>
            <Button
              className='submit-button'
              color='primary'
              disabled={disableUpdateButton}
              onClick={handleUpdateClick}
              size='small'
              testId='edit-single-value-submit-button'
            >
              {t('update')}
            </Button>
          </div>
        </PopoverContentsWrapper>
      </Popover>
    </EditSingleValueWrapper>
  );
};
