import styled from 'styled-components';

export const TermSelectWrapper = styled.div`
  display: flex;

  [class*=-container] {
    flex: 1 1 auto;
  }

  .edit-term-link {
    font-size: 1.2rem;
    margin: 0 .2rem 0 .3rem;
    width: 1.2rem;
  }
`;
