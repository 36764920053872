import React, { FC, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { push } from 'connected-react-router';
import { get } from 'lodash';

import { RoutePathnames } from 'config/routePathnames';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getActiveAccountId } from 'store/account-tree/selectors';
import { getAllActiveSyncOrderIds, getSyncOrderProgress } from 'store/syncOrders/selectors';
import { ToggleTabItem } from './ToggleTabItem';
import { AppToggleTabsContainer } from './style';
import { getTopAccountId } from 'store/installation/selectors';

interface IAppToggleTabs {
  match: any;
}

export const AppToggleTabs: FC<IAppToggleTabs> = memo(({
  match
}) => {
  const dispatch = useDispatch();
  const activeAccountId = useSelector(getActiveAccountId);
  const topAccountId = useSelector(getTopAccountId);
  const activeSyncOrdersIds = useSelector(getAllActiveSyncOrderIds);
  const activeSyncOrderProgress = useSelectorWithProps(
    getSyncOrderProgress,
    { syncOrderIds: activeSyncOrdersIds }
  );
  const currentMatchPath = get(match, 'path');

  const syncTotal: number = get(activeSyncOrderProgress, 'total', 0);
  const syncSuccess: number = get(activeSyncOrderProgress, 'success', 0);
  const syncError: number = get(activeSyncOrderProgress, 'error', 0);
  const hasSyncOrderInProgress: boolean = !!(syncTotal && (syncError + syncSuccess < syncTotal));

  const navigateTo = (pathname: string) => {
    dispatch(push(pathname));
  };

  return (
    <AppToggleTabsContainer className='app-toggle-tabs-container'>
      <ul className='toggle-tabs'>
        <ToggleTabItem
          active={currentMatchPath === RoutePathnames.ACCOUNTS}
          icon={['fas', 'sitemap']}
          onClick={() => navigateTo(RoutePathnames.ACCOUNTS)}
          screenreaderText='Accounts Tree View'
          testid='toggle-tab-account-view'
        />
        <ToggleTabItem
          active={currentMatchPath === RoutePathnames.ACCOUNT_COURSES}
          icon={['fas', 'list']}
          onClick={() => navigateTo(generatePath(RoutePathnames.ACCOUNT_COURSES, { accountId: activeAccountId || topAccountId }))}
          screenreaderText='Course List View'
          testid='toggle-tab-course-view'
        />
        <ToggleTabItem
          active={currentMatchPath === RoutePathnames.SYNC_STATUS}
          icon={['fas', 'sync-alt']}
          onClick={() => navigateTo(RoutePathnames.SYNC_STATUS)}
          progressError={syncError}
          progressSuccess={syncSuccess}
          progressTotal={syncTotal}
          screenreaderText='Sync Status'
          showProgress={hasSyncOrderInProgress}
          spinIcon={hasSyncOrderInProgress}
          testid='toggle-tab-sync-view'
        />
      </ul>
    </AppToggleTabsContainer>
  );
});
