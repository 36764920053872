import { get, toString } from 'lodash';
import { getUuid } from 'helpers';
import BaseSyncAccount from './baseSyncAccount';
export default class SyncNewAccount extends BaseSyncAccount {
  ancestors: string[];
  syncNewAccountId: string;
  tags: string[];
  tempAccountId: string;
  tempDepthLevel: number;
  tempParentAccountId: string;

  constructor(json: any) {
    super(json);
    this.ancestors = get(json, 'ancestors', []);
    this.syncNewAccountId = get(json, 'syncNewAccountId', getUuid());
    this.tags = get(json, 'tags', []);
    this.tempAccountId = get(json, 'tempAccountId');
    this.tempDepthLevel = get(json, 'tempDepthLevel');
    this.tempParentAccountId = toString(get(json, 'tempParentAccountId'));
  }
}
