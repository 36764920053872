/**
 * Helper to allow the download of blob data
 * @param filename - String defining the name on the file that will be downloaded
 * @param contents - Blob data to populate the content of file that will be downloaded
 */
export const downloadAsCsv = (filename: string, contents: Blob) => {
    // Axios does not communicate with browser to trigger a download
    // To get around the issue generate a new link in dom and click it via js
    const url = window.URL.createObjectURL(contents);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    // Cleaning up link after click is triggered
    document.body.removeChild(link);
};
