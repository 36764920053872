import EnrollmentTerm from 'models/canvas/enrollmentTerm';

/** TYPES  */

export enum TermsActionTypes {
  FETCH_TERMS_PAGE = 'terms/FETCH_TERMS_PAGE',
  RECEIVE_TERMS = 'terms/RECEIVE_TERMS',
  RECEIVE_ORIGINAL_TERMS = 'terms/RECEIVE_ORIGINAL_TERMS',
  DELETE_TERMS = 'terms/DELETE'
}

/** Trigger the Epic to fetch all pages */
export interface FetchTermsPageAction {
  type: typeof TermsActionTypes.FETCH_TERMS_PAGE;
  payload: { page: number }
}

export interface ReceiveTermsAction {
  type: typeof TermsActionTypes.RECEIVE_TERMS;
  payload: { terms: EnrollmentTerm[] };
}

export interface ReceiveOriginalTermsAction {
  type: typeof TermsActionTypes.RECEIVE_ORIGINAL_TERMS;
  payload: { terms: EnrollmentTerm[] };
}

export interface DeleteTermsAction {
  type: typeof TermsActionTypes.DELETE_TERMS;
  payload: { ids: string[] };
}

export type TermsAction =
  | FetchTermsPageAction
  | ReceiveTermsAction
  | ReceiveOriginalTermsAction
  | DeleteTermsAction;

/** ACTION CREATORS */

export const fetchTermsPage = (page: number): FetchTermsPageAction => ({
  type: TermsActionTypes.FETCH_TERMS_PAGE,
  payload: { page }
});

export const receiveTerms = (terms: EnrollmentTerm[]): ReceiveTermsAction => ({
  type: TermsActionTypes.RECEIVE_TERMS,
  payload: { terms }
});

export const receiveOriginalTerms = (terms: EnrollmentTerm[]): ReceiveOriginalTermsAction => ({
  type: TermsActionTypes.RECEIVE_ORIGINAL_TERMS,
  payload: { terms }
});

export const deleteTerms = (ids: string[]): DeleteTermsAction => ({
  type: TermsActionTypes.DELETE_TERMS,
  payload: { ids }
});
