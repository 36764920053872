import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Course from 'models/canvas/course';
import { getCourseIsLocked } from 'store/general/cross-selectors';
import { updateCourses } from 'store/courses/actions';
import { CourseCodeCellWrapper } from './style';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { EditSingleValueButton } from 'components/elements/EditSingleValueButton';
import { useTranslation } from 'react-i18next';
import { trim } from 'lodash';

interface ICourseCodeCell {
  row: Course;
  value: string;
}

export const CourseCodeCell: FC<ICourseCodeCell> = ({ row, value }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const courseId = row?.id;
  const courseCode = value;
  const locked = useSelectorWithProps(getCourseIsLocked, { courseId });

  // Updates the course code
  const handleSetCourseCode = useCallback((newCourseCode: string) => {
    const updateObj: Partial<Course> = { course_code: newCourseCode };
    dispatch(updateCourses([courseId], updateObj));
  }, [dispatch, courseId]);

  return (
    <CourseCodeCellWrapper className='course-code-cell-wrapper'>
      <EditSingleValueButton
        canEdit={!locked}
        currentValue={courseCode}
        isValid={(val: string) => !!trim(val)}
        labelText={t('courseCode')}
        onUpdate={handleSetCourseCode}
        type='text'
      >
        {courseCode}
      </EditSingleValueButton>
    </CourseCodeCellWrapper>
  );
};
