import React, { FC, ReactNode, ReactNodeArray, RefObject } from 'react';
import { isFunction } from 'lodash';
import Overlay, { OverlayInjectedProps, OverlayProps } from 'react-bootstrap/Overlay';
import BSPopover from 'react-bootstrap/Popover';
import ReactFocusLock from 'react-focus-lock';

// Our wrapper around the react-bootstrap implementation of Overlay/Popover

type Placement = OverlayProps['placement'];

interface IPopover {
  autoFocus?: boolean;
  id?: string;
  placement?: Placement;
  returnFocus?: boolean;
  show: boolean;
  targetRef: RefObject<HTMLElement>;
  title?: ReactNode;
  children?: ReactNode | ReactNodeArray | ((injectedProps: OverlayInjectedProps) => ReactNode);
}

export const Popover: FC<IPopover> = ({
  autoFocus = true,
  id,
  placement = 'auto',
  returnFocus = true,
  show = false,
  targetRef,
  title,
  children
}) => {
  return (
    <Overlay
      show={show}
      target={targetRef.current}
      placement={placement}
      flip // allows automatic adjustment of 'auto' placement
    >
      {(injectedProps: OverlayInjectedProps) => (
        <BSPopover id={id} {...injectedProps}>
          <ReactFocusLock returnFocus={returnFocus} autoFocus={autoFocus}>
            {title && (
              <BSPopover.Title>
                {title}
              </BSPopover.Title>
            )}
            <BSPopover.Content>
              {isFunction(children) && (children as Function)(injectedProps)}
              {!isFunction(children) && children}
            </BSPopover.Content>
          </ReactFocusLock>
        </BSPopover>
      )}
    </Overlay>
  );
};
