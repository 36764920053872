import styled, { css } from 'styled-components';

interface IAccountsCoursesContainer {}

export const AccountsCoursesContainer = styled.div`
  ${({ theme }) => css`
    &.accounts-courses-manager-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      width: 100%;
    }
  `}
`;
