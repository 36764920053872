import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Collapse from 'react-smooth-collapse';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getAllActiveSyncOrderDtos, getAllActiveSyncOrderIds, getAllCompletedSyncOrderDtos, getSyncOrderProgress } from 'store/syncOrders/selectors';
import { Button, ProgressBar, Loading } from 'components/elements';

import { SyncOrderItem } from './SyncOrderItem';
import { SyncLazyLoad } from './SyncLazyLoad';

import { SyncStatusManagerContainer, SyncOrdersList, SyncOrdersContainer } from './style';
import SyncOrderDto from 'models/dto/syncOrderDto';

interface ISyncStatusManager {
}

export const SyncStatusManager: FC<ISyncStatusManager> = React.memo(() => {
  const { t } = useTranslation();
  const [showSyncOrders, setShowSyncOrders] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const activeSyncOrderDtos = useSelector(getAllActiveSyncOrderDtos);
  const completedSyncOrderDtos = useSelector(getAllCompletedSyncOrderDtos);
  const activeSyncOrderIds = useSelector(getAllActiveSyncOrderIds);

  const progress = useSelectorWithProps(
    getSyncOrderProgress,
    { syncOrderIds: activeSyncOrderIds }
  );

  // Show the view after a delay to avoid UI lag on route change
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSyncOrders(true);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <SyncStatusManagerContainer className='sync-status-manager-container'>
      {
        showSyncOrders
        ? (
          <SyncOrdersList>
            {
              !isEmpty(activeSyncOrderDtos) && (
                <SyncOrdersContainer className='active-sync-orders-container'>
                  <header className='sync-status-heading'>
                    <h3>{t('activeSyncJobWithCount', { count: activeSyncOrderDtos.length })}</h3>
                  </header>

                  <div className='global-progress-bar'>
                    <p>{t('totalProgress')}</p>
                    <ProgressBar
                      total={progress.total}
                      success={progress.success}
                      errors={progress.error}
                      size='big'
                    />
                  </div>

                  {
                    activeSyncOrderDtos.map((dto: SyncOrderDto) => (
                      <SyncOrderItem
                        syncOrderId={dto?.syncOrder?.syncOrderId}
                        type='active'
                        key={`sync-order-${dto?.syncOrder?.syncOrderId}`}
                      />
                    ))
                  }
                </SyncOrdersContainer>
              )
            }
            <SyncOrdersContainer className='previous-sync-orders-container'>
              <header className='sync-status-heading'>
                <h3>{t('previousSyncJobs')}</h3>
                {
                    !isEmpty(completedSyncOrderDtos) && (
                      <Button
                        variant='link'
                        color='secondary'
                        onClick={() => setExpanded(prev => !prev)}
                        rotate={expanded ? 90 : 0}
                        ariaLabel={`${expanded ? 'Hide' : 'Show'} Previous Sync Jobs`}
                      >
                        <FontAwesomeIcon icon={['fas', 'angle-right']} />
                      </Button>
                    )
                  }
              </header>

              {
                  !isEmpty(completedSyncOrderDtos)
                    ? (
                      <Collapse
                        id='previous-sync-jobs-accordion'
                        className='sync-order-container-collapse'
                        heightTransition='.4s ease'
                        expanded={expanded}
                      >
                        {
                          completedSyncOrderDtos.map((dto: SyncOrderDto) => (
                            <SyncOrderItem
                              syncOrderId={dto?.syncOrder?.syncOrderId}
                              key={`sync-order-${dto?.syncOrder?.syncOrderId}`}
                            />
                          ))
                        }
                        <SyncLazyLoad />
                      </Collapse>
                    )
                    : t('noPreviousSyncJobs')
                }
            </SyncOrdersContainer>
          </SyncOrdersList>
        )
        : <Loading message={`${t('loading')}...`} />
      }
    </SyncStatusManagerContainer>
  );
});
