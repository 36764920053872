import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Course from 'models/canvas/course';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { buildAccountUrl, getAccountExistsInLmsAlready } from 'store/accounts/selectors';
import { getLmsBaseUrl } from 'store/installation/selectors';
import { CellMessage } from '../CellMessage';
import { SubAccountCellWrapper } from './style';
import { EditSingleValueButton } from 'components/elements/EditSingleValueButton';
import { getCourseIsLocked } from 'store/general/cross-selectors';
import { updateCourses } from 'store/courses/actions';

interface ISubAccountCell {
  row: Course;
  value: string;
}

export const SubAccountCell: FC<ISubAccountCell> = ({
  row,
  value
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountId = row?.account_id;
  const courseId = row?.id;
  const locked = useSelectorWithProps(getCourseIsLocked, { courseId });
  const existsAlready: boolean = useSelectorWithProps(getAccountExistsInLmsAlready, { accountId });
  const lmsBaseUrl = useSelector(getLmsBaseUrl);
  const name = value;
  const url = buildAccountUrl(lmsBaseUrl, accountId);

  const showLink = existsAlready && locked;

  return (
    <SubAccountCellWrapper className='sub-account-cell-wrapper'>
      <EditSingleValueButton
        canEdit={!locked}
        currentValue={accountId}
        labelText={t('subAccount')}
        onUpdate={(newVal: string) => dispatch(updateCourses([courseId], { account_id: newVal }))}
        type='account'
      >
        {showLink && (
          <a
            className='course-sub-account-external-link'
            href={url}
            target='_blank'
            rel='noopener noreferrer'
            title={t('openInNewTab')}
          >
            {name}
          </a>
        )}
        {!showLink && name}
        {!existsAlready && (
          <CellMessage message={`(${t('notYetSynced')})`} />
        )}
      </EditSingleValueButton>
    </SubAccountCellWrapper>
  );
};
