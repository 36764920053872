import { get, toString } from 'lodash';

export default class UserDisplay {
  id: string;
  avatarImageUrl: string;
  // eslint-disable-next-line react/static-property-placement
  displayName: string;
  htmlUrl: string;

  constructor(json: any) {
    /**
     * The back-end is currently annotated to convert the cased_properties from
     * CanvasAPI response to camelCasedProperties, but I don't think it's working
     * right now; that's why this is attempting to read both.
     */
    this.id = toString(get(json, 'id'));
    this.displayName = get(json, 'display_name') || get(json, 'displayName');
    this.avatarImageUrl = get(json, 'avatar_image_url') || get(json, 'avatarImageUrl');
    this.htmlUrl = get(json, 'html_url') || get(json, 'htmlUrl');
  }
}
