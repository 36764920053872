export interface IAppMenuDropdownItem {
  /** Key for i18next translation */
  title: string;
  /** Id of object to pass in */
  id?: string;
  /** Id of parent object to create mapping id */
  parentId?: string;
  /** Designate item to be styled for main appMenu bar */
  isMainNav?: boolean;
  /** Determines if subcomponent/dropdown should be expanded */
  isExpanded?: boolean;
  /** List of dropdown items to populate a submenu - not to be used when component is populated */
  items?: IAppMenuDropdownItem[];
  /** JSX to display as a popout from a dropdown item - not to be used when items are populated */
  component?: React.ReactNode;
}

/**
 * Converts normal string to lowercase dash seperated Id
 * @param title - String
 * @returns String with capitalization removed and spaces replaced by dashes
 */
export const idFormatter = (title: string) => {
  return title.replace(/\s/g, '-').toLowerCase();
};

/**
 * Takes {@link IAppMenuDropdownItem} to convert to config for use with AppMenuDropdownItem component
 */
export default class AppMenuDropdownConfig {
  /** Text that appears on the item */
  title: string;
  /** Id to identify location in AppMenu map */
  id: string;
  /** Designate item to be styled for main appMenu bar */
  isMainNav?: boolean;
  /** Determines if subcomponent/dropdown should be expanded */
  isExpanded?: boolean;
  /** List of dropdown items to populate a submenu - not to be used when component is populated */
  items?: AppMenuDropdownConfig[];
  /** JSX to display as a popout from a dropdown item - not to be used when items are populated */
  component?: React.ReactNode;

  constructor(dropdownJson: IAppMenuDropdownItem) { 
    this.title = dropdownJson.title;
    // If Id was passed use that, else replace spaces with dash and lower case title to create id
    const newId = !!dropdownJson.id ? dropdownJson.id : idFormatter(dropdownJson.title);
    // If an Id was not passed, and a parent Id was then prepend parentId to id from title, else use formatted id
    this.id = !dropdownJson.id && !!dropdownJson.parentId ? `${dropdownJson.parentId}-${newId}` : newId;
    this.isMainNav = dropdownJson?.isMainNav || false;
    this.isExpanded = dropdownJson?.isExpanded || false;
    this.items = !!dropdownJson?.items ? dropdownJson?.items.map(item => new AppMenuDropdownConfig({parentId: this.id, ...item})) : null;
    this.component = dropdownJson?.component || null;
  }
}