import { get } from 'lodash';
import BaseSyncCourse from './baseSyncCourse';

export default class SyncExistingCourse extends BaseSyncCourse {
  syncExistingCourseId: string;
  courseTemplateId: string;

  constructor(json: any) {
    super(json);
    this.courseTemplateId = get(json, 'courseTemplateId');
    this.syncExistingCourseId = get(json, 'syncExistingCourseId');
  }
}
