import styled from 'styled-components';

export const SyncChangesTableWrapper = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #929193;
    border-radius: 50px;
    border: 1px solid #929193;
  }
  .syncChangesTable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #dddddd;
    table-layout: fixed;
    .syncChangesTableHeading {
      width: 100%;
      height: 40px;
      background: #2d305e;
      th {
        padding: 0 1.4rem;
        text-align: left;
        color: #fff;
      }
    }
    .syncChangesTableRow {
      &:nth-child(even) {
        background: #eee;
      }
      .syncChangesTableRowCell {
        min-height: 40px;
        height: auto;
        padding: 1rem 1.4rem;
      }
    }
  }
`;
