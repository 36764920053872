import React, { FC, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'rxjs/operators';
import { useObservable } from 'rxjs-hooks';

import { toggleModal } from 'store/modals/actions';
import { ColumnDef } from 'modules/data-table/types';
import { useDataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';
import { registerTable, setData } from 'modules/data-table/store/data-table/actions';
import { defaultDataTableConfig } from 'modules/data-table/defaults';
import { useDataTableKeyboardNav } from 'modules/data-table/hooks/useDataTableKeyboardNav';
import { HiddenKeyboardNav } from 'components/elements/HiddenKeyboardNav';
import { TableHeader } from '../TableHeader';
import { TableBody } from '../TableBody';
import { TableControls } from '../TableControls';
import '../data-table.scss';
import { ModalId } from 'constants/index';

interface IDataTable {
  columnDefs: ColumnDef[];
  data: any[];
  defaultRowHeight: number;
  hasCustomDragItemRenderer?: boolean;
  dragItemType?: string;
  rowCanDrag?: (row: any) => boolean;
  rowIdProperty: string;
  tableId: string;
  keyboardInstructions?: boolean;
  columnsMenu?: boolean;
}

export const DataTable: FC<IDataTable> = ({
  columnDefs,
  data,
  defaultRowHeight,
  dragItemType,
  hasCustomDragItemRenderer,
  rowCanDrag,
  rowIdProperty,
  tableId,
  keyboardInstructions = true,
  columnsMenu = true
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const api = useDataTableObservables(tableId);
  useDataTableKeyboardNav(tableId, columnDefs, containerRef);

  const numSortedFilteredRows = useObservable(() => api.sortedFilteredRows$.pipe(
    map((rows: any[]) => rows?.length || 0)
  ), 0);

  const ariaRowCount = numSortedFilteredRows + 1; // add header row
  const ariaColCount = columnDefs.length;

  const topButtonId = `${tableId}-top-button`;
  const skipButtonId = `${tableId}-skip-button`;

  const handleSkipClick = useCallback((nextId: string) => {
    const nextEl: HTMLElement = document.getElementById(nextId);
    if (nextEl) { nextEl.focus(); }
  }, []);

  const handleKeyboardInfoClick = useCallback(() => {
    dispatch(toggleModal(ModalId.DATA_TABLE_KEYBOARD_INFO, true, {}));
  }, [dispatch]);

  useEffect(() => {
    const config = { ...defaultDataTableConfig, columnDefs, rowIdProperty, defaultRowHeight };
    dispatch(registerTable(tableId, config));
  }, [tableId, columnDefs, rowIdProperty, dispatch, defaultRowHeight]);

  useEffect(() => {
    dispatch(setData(tableId, data));
  }, [data, tableId, dispatch]);

  return (
    <div
      className='--data-table-container'
      ref={containerRef}
      data-testid='table-container'
      role='grid'
      id={tableId}
      aria-rowcount={ariaRowCount}
      aria-colcount={ariaColCount}
    >
      <div
        className='table-container-controlbar'
      >
        <HiddenKeyboardNav>
          <button onClick={() => handleSkipClick(topButtonId)} id={skipButtonId}>
            {t('skipToBottom')}
          </button>
          {keyboardInstructions && (
            <button onClick={handleKeyboardInfoClick}>
              {t('modalKeyboardTitle')}
            </button>
          )}
        </HiddenKeyboardNav>
        <TableControls tableId={tableId} api={api} columnsMenu={columnsMenu} />
      </div>
      <TableHeader tableId={tableId} api={api} />
      <TableBody tableId={tableId} api={api} dragItemType={dragItemType} rowCanDrag={rowCanDrag} hasCustomDragItemRenderer={hasCustomDragItemRenderer} />
      <HiddenKeyboardNav>
        <button onClick={() => handleSkipClick(skipButtonId)} id={topButtonId} aria-label='Back to Top.'>
          {t('top')}
        </button>
      </HiddenKeyboardNav>
    </div>
  );
};
