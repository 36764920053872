import styled from 'styled-components';

export const SplitResizableLayoutContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden; // overflow can be set in column content children
  width: 100%;

  .resizable-column,
  .fill-column {
    height: 100%;

    // required to make some children w/ overflow work properly:
    min-width: 0;
    min-height: 0;
    padding: 0;
  }

  .fill-column {
    flex: 1 1 auto;
  }

  .resizable-column {
    border-right: 1px solid #ddd;
    flex: 0 0 auto;
    overflow: hidden; // overflow can be set in column content children
    padding: 0;
    position: relative;
    transition: width .4s ease-out;
    will-change: width;
    background: ${({ theme }) => theme.color.gray6};
    padding-right: 18px;


    .drag-resize-handle {
      background: transparent;
      border: 0;
      color: ${({ theme }) => theme.color.gray1};
      display: block;
      font-size: 16px;
      height: 100%;
      padding: 0;
      position: absolute;
      right: 0px;
      top: 0;
      transition: all .3s ease;
      z-index: 20;

      &:hover,
      &:focus,
      &.mouse-down {
        background: #efefef;
        background-position: 0 0;
        color: ${({ theme }) => theme.color.mainDark};
        cursor: col-resize;
        outline: none;
      }
    }

    &.closed {
      .drag-resize-handle {
        background: ${({ theme }) => theme.color.gray6};
        &:hover, &:focus {
          background: #efefef;
        }
      }
    }

    &.force-full {
      padding-right: 0;
      .drag-resize-handle {
        display: none;
      }
    }
  }
`;
