import { ofType, StateObservable } from 'redux-observable';
import { Observable, concat, from, of } from 'rxjs';
import { mergeMap, switchMap, withLatestFrom, catchError } from 'rxjs/operators';
import { AppState } from 'store';
import * as actions from './actions';

import {
  PageRatingReportActionTypes,
  CreatePageRatingReportAction,
  FetchPageRatingReportStatusAction,
  DownloadPageRatingReportAction
} from './actions';
import * as PageRatingReportApi from './api';
import PageRatingJobStatus from 'models/pageRatingReport/pageRatingJobStatus';
import PageRatingReport from 'models/pageRatingReport/pageRatingReport';
import { getPageRatingReportStatus } from './selectors';

export const createPageRatingReportEpic$ = (action$: Observable<any>) => action$.pipe(
  ofType(PageRatingReportActionTypes.CREATE_REPORT),
  mergeMap((action: CreatePageRatingReportAction) => {
    return concat(
      from(PageRatingReportApi.createReport(action.payload.accountId)).pipe(
        switchMap((reportStatus: PageRatingJobStatus) => {
          return of(actions.receivePageRatingReportStatus(reportStatus));
        }),
        catchError((error: Error) => of(error))
      )
    );
  })
);

export const fetchPageRatingReportStatusEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(PageRatingReportActionTypes.FETCH_STATUS),
  withLatestFrom(state$),
  mergeMap(([action, appState]: [FetchPageRatingReportStatusAction, AppState]) => {
    const jobId = getPageRatingReportStatus(appState)?.jobId;
    return concat(
      from(PageRatingReportApi.fetchReportStatus(jobId)).pipe(
        switchMap((reportStatus: PageRatingJobStatus) => {
          return of(actions.receivePageRatingReportStatus(reportStatus));
        }),
        catchError((error: Error) => of(error))
      )
    );
  })
);

export const downloadPageRatingReportEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(PageRatingReportActionTypes.DOWNLOAD_REPORT),
  withLatestFrom(state$),
  mergeMap(([action, appState]: [DownloadPageRatingReportAction, AppState]) => {
    const jobId = getPageRatingReportStatus(appState)?.jobId;
    return concat(
      from(PageRatingReportApi.downloadReport(jobId)).pipe(
        switchMap((report: PageRatingReport) => {
          return of(actions.receivePageRatingReport(report));
        }),
        catchError((error: Error) => of(error))
      )
    );
  })
);
