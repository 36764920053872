import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { useTranslation } from 'react-i18next';
import { get, capitalize, some, filter, map } from 'lodash';

import { ItemTypes, ModalId } from 'constants/index';
import { useModalState } from 'hooks/useModalState';
import { toast } from 'helpers/messages';

import Account from 'models/canvas/account';
import { receiveAccounts, updateAccounts } from 'store/accounts/actions';
import { receiveCourses, updateCourses } from 'store/courses/actions';
import { setAccountsExpanded } from 'store/account-tree/actions';
import { toggleModal } from 'store/modals/actions';
import { getCourseItems } from 'store/courses/selectors';
import { getAccountItemsMap } from 'store/accounts/selectors';
import { getCourseLockedMap, getAccountLockedMap } from 'store/general/cross-selectors';

import { Button, Modal, AccountSelect } from 'components/elements';
import { ModalHeading, SectionContent, SectionFooter } from 'components/general-styles';
import Course from 'models/canvas/course';

interface IMoveItems {}

const MoveItems: FC<IMoveItems> = () => {
  const [selectedAccountId, setSelectedAccountId] = useState<string>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalId = ModalId.MOVE_ITEMS;
  const accountItems = useSelector(getAccountItemsMap);
  const courseItems = useSelector(getCourseItems);
  const courseLockedMap = useSelector(getCourseLockedMap);
  const accountLockedMap = useSelector(getAccountLockedMap);

  const handleClose = useCallback(() => {
    dispatch(toggleModal(modalId, false, {}));
  }, [modalId, dispatch]);

  const modalState = useModalState(modalId);
  const itemType = modalState?.data?.itemType;
  const itemIds = modalState?.data?.itemIds || [];

  const itemName = useMemo(() => {
    const multiple = get(itemIds, 'length', 0) > 1;

    switch (itemType) {
      case ItemTypes.ACCOUNT: {
        return <em className='item-name'>{accountItems?.[itemIds[0]]?.name}</em>;
      }
      case ItemTypes.COURSE: {
        return multiple
          ? t('selectedCourses')
          : <em className='item-name'>{courseItems?.[itemIds[0]]?.name}</em>;
      }
      default:
        return null;
    }
  }, [accountItems, courseItems, itemType, itemIds, t]);

  const handleMoveClick = useCallback(() => {
    const selectedAccountName = accountItems?.[selectedAccountId]?.name;
    let updateAction: Action;
    let hasLockedItems = false;
    let unlockedItemsIds = itemIds;
    let undoAction: Action;

    switch (itemType) {
      case ItemTypes.ACCOUNT: {
        hasLockedItems = some(itemIds, (itemId: string) => accountLockedMap[itemId]);
        unlockedItemsIds = hasLockedItems
          ? filter(itemIds, (itemId: string) => !accountLockedMap[itemId])
          : itemIds;
        updateAction = updateAccounts(unlockedItemsIds, { parent_account_id: selectedAccountId });
        const unlockedItems: Account[] = map(unlockedItemsIds, (id: string) => accountItems?.[id]);
        undoAction = receiveAccounts(unlockedItems);
        break;
      }
      case ItemTypes.COURSE: {
        hasLockedItems = some(itemIds, (itemId: string) => courseLockedMap[itemId]);
        unlockedItemsIds = hasLockedItems
          ? filter(itemIds, (itemId: string) => !courseLockedMap[itemId])
          : itemIds;
        updateAction = updateCourses(unlockedItemsIds, { account_id: selectedAccountId });
        const unlockedItems: Course[] = map(unlockedItemsIds, (id: string) => courseItems?.[id]);
        undoAction = receiveCourses(unlockedItems);
        break;
      }
      default: break;
    }

    const undo = () => {
      undoAction && dispatch(undoAction);
    };

    const onConfirm = () => {
      dispatch(updateAction);
      dispatch(setAccountsExpanded([selectedAccountId], true));
      dispatch(toggleModal(modalId, false, {}));
      toast.successWithUndo(
        t('toastItemsMovedToAccountWithCount',
        { accountName: selectedAccountName, count: unlockedItemsIds.length }),
        undo
      );
    };

    if (hasLockedItems) {
      toast.confirm(t('confirmMoveOnlyUnlockedItems'), onConfirm);
    } else {
      onConfirm();
    }
  }, [dispatch, itemType, itemIds, selectedAccountId, modalId, t, accountItems, courseItems, accountLockedMap, courseLockedMap]);

  const handleAccountCreate = useCallback((tempAccountId: string, newAccount: Account) => {
    setSelectedAccountId(tempAccountId);
  }, [setSelectedAccountId]);

  return (
    <Modal id={modalId} handleClose={handleClose}>
      <SectionContent>
        <ModalHeading>
          <h2 id={`modal-${modalId}-title`}>{capitalize(t('move'))} {itemName}</h2>
        </ModalHeading>
        <AccountSelect
          allowCreate
          onCreate={handleAccountCreate}
          id='move-items-select-account'
          onChange={setSelectedAccountId}
          labelText={`${t('moveTo')}:`}
          value={selectedAccountId}
        />
      </SectionContent>
      <SectionFooter>
        <div />
        <div>
          <Button
            color='secondary'
            onClick={handleClose}
            testId='move-item-cancel-button'
          >
            {t('cancel')}
          </Button>
          <Button
            className='move-item-submit-button'
            disabled={!selectedAccountId}
            onClick={handleMoveClick}
            testId='move-item-submit-button'
          >
            {t('move')}
          </Button>
        </div>
      </SectionFooter>
    </Modal>
  );
};

export default React.memo(MoveItems);
