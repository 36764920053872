import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SyncItemStatusUnsynced, SyncItemStatusCompleted, SyncItemStatusPending, SyncItemStatusActive, SyncItemStatusError, SyncItemStatus as SyncItemStatusType } from 'constants/index';

import { Badge } from 'components/general-styles';
import BaseSyncItemEntity from 'models/syncOrder/baseSyncItemEntity';

interface ISyncItemStatus {
  syncItem: BaseSyncItemEntity,
  belongToActiveSyncOrder: boolean
}

export const SyncItemStatus: React.FC<ISyncItemStatus> = React.memo(({ syncItem, belongToActiveSyncOrder }) => {
  const { t } = useTranslation();
  const status: SyncItemStatusType = syncItem?.syncItemResult?.syncStatus as SyncItemStatusType;

  const statuses: Record<SyncItemStatusType, ReactNode> = {
    [SyncItemStatusCompleted.SUCCESS]: (
      <span className='success'>
        <FontAwesomeIcon className='status-icon success-check' icon={['fas', 'check']} />
      </span>
    ),
    [SyncItemStatusActive.RUNNING]: t('running'),
    [SyncItemStatusPending.PENDING]: belongToActiveSyncOrder ? t('pending') : t('notExecuted'),
    [SyncItemStatusUnsynced.UNSYNCED]: '',
    [SyncItemStatusError.ERROR]: <Badge type='error'>{t('error')}</Badge>,
    [SyncItemStatusError.PARENT_ACCOUNT_NOT_FOUND]: <Badge type='error'>{t('error')}</Badge>,
    [SyncItemStatusError.APPLY_COURSE_TEMPLATE_FAILED]: <Badge type='error'>{t('error')}</Badge>,
    [SyncItemStatusError.COURSE_COPY_FAILED]: <Badge type='error'>{t('error')}</Badge>
  };

  return (
    <>
      {statuses[status]}
    </>
  );
});
