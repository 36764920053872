import styled, { css } from 'styled-components';

interface IFooterActionsContainer {
  hidden: boolean;
}
interface IFooterAction {}
interface IFooterActionsLeft {}

export const FooterActionsContainer = styled.footer<IFooterActionsContainer>`
  ${({ theme, hidden }) => css`
    &.footer-actions-container {
      z-index: 1;
      display: block;
      height: ${hidden ? 0 : '5em'};
      transition: height .2s ease;
      box-shadow: ${hidden ? 'none' : '0 2px 10px 0 #42424254'};

      .footer-actions-inner-wrapper {
        align-items: center;
        display: ${hidden ? 'none' : 'flex'};
        height: 100%;
        justify-content: flex-end;
        margin-top: auto;
        padding: 5px 10px;
        width: 100%;
      }
    }
  `}
`;

export const FooterAction = styled.div<IFooterAction>`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-start;
  `}
`;

export const FooterActionsLeft = styled.div<IFooterActionsLeft>`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    justify-content: flex-start;
    margin-right: auto;
  `}
`;
