import styled from 'styled-components';

export const CustomToastContentWrapper = styled.div`
  .toast-heading {
    font: 18px/20px 'Lato';
  }

  .request-error-toast-icon {
    display: inline-block;
    margin-right: .5em;
  }

  .buttons-container {
    margin: 1rem 0 0 0;
    display: flex;
    font-family: ${({ theme }) => theme.font.main};
    justify-content: flex-start;

    .custom-toast-button {
      display: inline-block;
      margin: 0 1em 0 0;

      &[data-variant="link"] {
        color: ${({ theme }) => theme.color.white};
        font-weight: bold;
        &::hover, &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .error-details-container {
    margin: 1em 0 0 0;
    max-height: 300px;
    overflow: auto;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
  }
`;
