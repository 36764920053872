import styled, { css } from 'styled-components';
import { srOnly } from 'styles/classes';

export const Container = styled.div`
  margin: 32px auto 0;
  max-width: 1400px;
  width: 95%;
  font-family: ${({ theme }) => theme.font.main};
  
  h1 {
    color: ${({ theme }) => theme.color.font};
    font: 30px/36px ${({ theme }) => theme.font.main};
    margin: 0 0 16px;
  }
`;

export const SectionBox = styled.div`
  box-shadow: ${({ theme }) => theme.shadow.container};
  border-radius: 8px;
`;

export const SectionContent = styled.section`
  height: auto;
  max-height: 74vh;
  min-height: 25vh;
  overflow-y: scroll;
  padding: 36px;

  h2, h3 {
    color: ${({ theme }) => theme.color.font};
    margin: 0 0 16px;
  }

  h2 {
    font: 24px/30px ${({ theme }) => theme.font.main};
  }

  h3 {
    font: 20px/24px ${({ theme }) => theme.font.main};
  }

  p {
    color: ${({ theme }) => theme.color.font};
    font: 14px/18px ${({ theme }) => theme.font.main};
    margin: 0 0 8px;
  }
`;

export const SectionHeading = styled.header`
  padding-right: 40%;
  position: relative;
`;

export const ModalHeading = styled.header`
  margin-bottom: 40px;
`;

export const SectionFooter = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  position: relative;

  & > div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  p {
    color: ${({ theme }) => theme.color.font};
    font: 12px/15px ${({ theme }) => theme.font.main};
    margin: 0;
  }

  &:before {
    background: transparent ${({ theme }) => theme.gradient.container};
    content: '';
    height: 27px;
    left: 0;
    opacity: .1;
    pointer-events: none;
    position: absolute;
    top: -27px;
    width: 100%;
  }
`;

export const SrOnlyText = styled.span`
  ${srOnly}
`;

interface IBadge {
  type: string;
}
export const Badge = styled.span<IBadge>`
  ${({ theme, type }) => css`
    background: ${type === 'error'
      ? theme.color.red
      : theme.color.green
    };
    color: ${theme.color.white};
    border-radius: 4px;
    padding: .4rem .8rem;
  `}
`;

export const FormFieldGroup = styled.div`
  margin: 0 0 1rem 0;
`;

interface IFieldsetProps {
  direction?: 'row' | 'column',
  flex?: boolean
}
export const FieldSet = styled.div<IFieldsetProps>`
  ${({ theme, direction, flex }) => css`
    position: relative;
    display: ${flex ? 'flex' : 'block'};
    margin: 0 .8rem 1.5rem 0;
    width: 100%;

    ${flex && css`
      flex: 1 1 auto;
      flex-direction: ${direction};

      ${direction === 'row'
        ? css` align-items: center;`
        : css` justify-content: center;`
      }

      &:last-child {
        margin-right: 0;
      }
    `}

    & > label {
      color: ${theme.color.gray1};
      font: 400 1.2rem/1.2rem ${theme.font.main};
      margin: 0 1rem 1rem 0;
    }
    input, select {
      background-color: ${theme.color.white};
      border-radius: 4px;
      border: 1px solid ${theme.color.gray4};
      height: 3.5rem;
      width: 100%;
      flex: 1 1 auto;
      &:disabled, &[readonly] {
        background-color: ${theme.color.gray7};
        border-color: ${theme.color.gray6};
        color: ${theme.color.gray3};
        cursor: not-allowed;
      }
    }
  `}
`;
