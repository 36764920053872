import React, { FC, useCallback, SyntheticEvent } from 'react';
import { ColumnDef, DataTableSorts, ColumnSortConfig } from 'modules/data-table/types';
import { DataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';
import { SyncStatusHeaderCellWrapper } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDataTableSorts } from 'modules/data-table/store/data-table/selectors';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { useDispatch } from 'react-redux';
import { setColumnSort } from 'modules/data-table/store/data-table/actions';

interface ISyncStatusHeaderCell {
  columnDef: ColumnDef;
  api?: DataTableObservables;
  tableId: string;
}

export const SyncStatusHeaderCell: FC<ISyncStatusHeaderCell> = ({ columnDef, tableId }) => {
  const dispatch = useDispatch();
  const columnId = columnDef?.columnId;
  const sorts: DataTableSorts = useSelectorWithProps(getDataTableSorts, { tableId });
  const columnSort: ColumnSortConfig = sorts?.[columnId];
  const currentSortDir: 'asc'|'desc' = columnSort?.direction;

  const handleSortClick = useCallback((e: SyntheticEvent) => {
    const direction = !currentSortDir ? 'asc' : (currentSortDir === 'asc' ? 'desc' : null);
    const config: ColumnSortConfig = direction ? { columnId, direction } : null;
    dispatch(setColumnSort(tableId, columnId, config));
  }, [currentSortDir, dispatch, tableId, columnId]);

  return (
    <SyncStatusHeaderCellWrapper className='sync-status-header-cell-wrapper' desc={currentSortDir === 'desc'} aria-label='Sync status'>
      <button className='sync-sort-button' onClick={handleSortClick} aria-label='Toggle column sort'>
        <span className='sync-sort-icon-wrapper'>
          {currentSortDir && (
            <FontAwesomeIcon className='sort-icon' icon={['fas', 'arrow-up']} />
          )}
        </span>
        <FontAwesomeIcon className='sync-icon' icon={['fas', 'sync-alt']} />
      </button>
    </SyncStatusHeaderCellWrapper>
  );
};
