import React, { FC, useMemo, useCallback } from 'react';
import { map, every, values, some } from 'lodash';
import { DataTableSelections, ColumnDef } from 'modules/data-table/types';
import { useDispatch } from 'react-redux';
import { setRowsSelected } from 'modules/data-table/store/data-table/actions';
import { SelectionCheckbox } from 'components/elements';
import { ScreenReaderLabelText } from 'components/elements/SelectionCheckbox/style';
import { DataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getDataTableSelections, getSortedFilteredRows, getRowIdProperty } from 'modules/data-table/store/data-table/selectors';
import { SelectionHeaderCellWrapper } from './style';
import Course from 'models/canvas/course';

interface ISelectionHeaderCell {
  tableId: string;
  columnDef?: ColumnDef;
  api?: DataTableObservables;
}

enum SelectionState {
  NONE = 'none',
  SOME = 'some',
  ALL = 'all'
}

export const SelectionHeaderCell: FC<ISelectionHeaderCell> = ({
  tableId,
}) => {
  const dispatch = useDispatch();
  const selections: DataTableSelections = useSelectorWithProps(getDataTableSelections, { tableId });
  const sortedFilteredRows: Course[] = useSelectorWithProps(getSortedFilteredRows, { tableId });
  const rowIdProperty: string = useSelectorWithProps(getRowIdProperty, { tableId });

  const someAreSelected = some(values(selections), (val: boolean) => !!val);

  const allAreSelected = useMemo(() => {
    return someAreSelected && every(sortedFilteredRows, (row: any) => {
      const rowId = row[rowIdProperty];
      return !!selections[rowId];
    });
  }, [selections, sortedFilteredRows, rowIdProperty, someAreSelected]);

  let selectionState: SelectionState = SelectionState.NONE;

  if (allAreSelected) {
    selectionState = SelectionState.ALL;
  } else if (someAreSelected) {
    selectionState = SelectionState.SOME;
  }

  const nextState = selectionState === SelectionState.ALL ? SelectionState.NONE : SelectionState.ALL;

  // It either select all rows if none is selected, or unselect all if at least one is selected
  const handleButtonClick = useCallback(() => {
    const rowIds = nextState === SelectionState.ALL ? map(sortedFilteredRows, rowIdProperty) : [];
    dispatch(setRowsSelected(tableId, rowIds, true, true));
  }, [nextState, rowIdProperty, dispatch, sortedFilteredRows, tableId]);

  return (
    <SelectionHeaderCellWrapper
      className='selection-header-wrapper'
    >
      <SelectionCheckbox
        id={`table-${tableId}-selection-header-checkbox`}
        selected={someAreSelected}
        indeterminate={someAreSelected && !allAreSelected}
        onClick={handleButtonClick}
      >
        <ScreenReaderLabelText>
          Select {nextState}
        </ScreenReaderLabelText>
      </SelectionCheckbox>
    </SelectionHeaderCellWrapper>
  );
};
