import SyncOrderDto from 'models/dto/syncOrderDto';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAllTemplates } from 'store/templates/selectors';
import { getAllCourses } from 'store/courses/selectors';
import { getLmsBaseUrl } from 'store/installation/selectors';
import { useTranslation } from 'react-i18next';
import { buildCourseUrl } from 'store/courses/selectors';
import SyncNewCourse from 'models/syncOrder/syncNewCourse';
import SyncExistingCourse from 'models/syncOrder/syncExistingCourse';
import SyncExistingDupCourse from 'models/syncOrder/syncExistingDupCourse';
import SyncNewDupCourse from 'models/syncOrder/syncNewDupCourse';

export interface ISyncSummary {
  id: string;
  name: string;
  type: string;
  desc: string;
  linkData?: LinkDataType;
  courseId?: string;
}

export interface LinkDataType {
  url: string;
  name: string;
}

export default function useSyncSummary(syncOrderDto: SyncOrderDto) {

  const templates = useSelector(getAllTemplates);
  const courses = useSelector(getAllCourses);
  const lmsBaseUrl = useSelector(getLmsBaseUrl);
  const { t } = useTranslation();
  const syncArray: ISyncSummary[] = [];

  const addDataToCourseArray = (newVal: ISyncSummary) => {
    syncArray.push(newVal);
  };

  const templateCheck = (course: SyncNewCourse | SyncExistingCourse) => {
    return templates.find(
      (template) => template.courseTemplateId === course.courseTemplateId
    );
  };

  const sourceCourseCheck = (
    dupCourses: SyncExistingDupCourse | SyncNewDupCourse
  ) => {
    return courses.find((course) => course.id === dupCourses.sourceCourseId);
  };

  useEffect(() => {

    const existingCourseMap = new Map<string, ISyncSummary>();

    if (!syncOrderDto?.syncExistingAccounts) {
      return;
    }

    for (const account of syncOrderDto.syncExistingAccounts) {
      addDataToCourseArray({
        id: account.syncExistingAccountId,
        name: account.name,
        type: t('account'),
        desc: t('syncExistingAccount'),
        linkData: null,
        courseId: null,
      });
    }

    for (const account of syncOrderDto.syncNewAccounts) {
      addDataToCourseArray({
        id: account.syncNewAccountId,
        name: account.name,
        type: t('account'),
        desc: t('syncNewAccount'),
        linkData: null,
        courseId: null,
      });
    }

    for (const term of syncOrderDto.syncNewTerms) {
      addDataToCourseArray({
        id: term.syncNewTermId,
        name: term.name,
        type: t('term'),
        desc: t('syncNewTerm'),
        linkData: null,
        courseId: null,
      });
    }

    for (const course of syncOrderDto.syncExistingCourses) {

      const targetTemplate = templateCheck(course);
      
      const desc = t('syncExistingCourse') + (!!targetTemplate?.courseTemplateId ? ', ' + t('syncExistingCourseWithTemplateId', { templateName: targetTemplate.name }) : '');

      const summary: ISyncSummary = {
        id: course.syncExistingCourseId,
        name: course.name,
        type: t('course'),
        desc: desc,
        linkData: null,
        courseId: course.courseId,
      };

      existingCourseMap.set(course.courseId, summary);

    }

    for (const course of syncOrderDto.syncNewCourses) {
      let desc = t('syncNewCourseWithoutTemplateId');

      if (course.courseTemplateId) {
        const targetTemplate = templateCheck(course);

        desc = t('syncNewCourseWithTemplateId', {
          templateName: targetTemplate.name,
        });
      }

      addDataToCourseArray({
        id: course.syncNewCourseId,
        name: course.name,
        type: t('course'),
        desc: desc,
        linkData: null,
      });
    }

    for (const course of syncOrderDto.syncNewDupCourses) {

      const sourceCourse = sourceCourseCheck(course);

      const sourceCourseUrl: string = buildCourseUrl(
        lmsBaseUrl,
        course.sourceCourseId
      );

      const desc = t('syncNewDupCourse', {
        sourceCourseUrl,
        sourceCourseName: sourceCourse?.name,
      });

      const linkData = { url: sourceCourseUrl, name: sourceCourse?.name };

      addDataToCourseArray({
        id: course.syncNewDupCourseId,
        name: course.name,
        type: t('course'),
        desc: desc,
        linkData: linkData,
      });
    }

    for (const course of syncOrderDto.syncExistingDupCourses) {
      const existing = existingCourseMap.get(course.courseId);

      const sourceCourse = sourceCourseCheck(course);

      const sourceCourseUrl: string = buildCourseUrl(
        lmsBaseUrl,
        course.sourceCourseId
      );

      const desc = !!existing ?

      existing.desc + ', ' + t('syncExistingDupCourse', {
        sourceCourseUrl,
        sourceCourseName: sourceCourse?.name,
      })
      
      :

      t('syncExistingCourse') + ', ' + t('syncExistingDupCourse', {
        sourceCourseUrl,
        sourceCourseName: sourceCourse?.name,
      });

      const linkData = { url: sourceCourseUrl, name: sourceCourse?.name };

      const summary = {
        id: course.syncExistingDupCourseId,
        name: course.name,
        type: t('course'),
        desc: desc,
        linkData: linkData,
        courseId: course.courseId,
      };

      existingCourseMap.set(course.courseId, summary);
    }

    existingCourseMap.forEach((summary) => {
      addDataToCourseArray(summary);
    });

  }, [syncOrderDto]);

  return {
    syncArray,
  };
}