import styled from 'styled-components';

export const EditSingleValueWrapper = styled.div`
  display: inline;

  .edit-button {
    background: transparent;
    border: 1px dashed transparent;
    display: block;
    height: auto;
    margin: 0;
    padding: .7rem .5rem;
    text-align: left;
    width: 100%;

    .cell-message-wrapper {
      margin: 0;
    }

    &:hover, &:focus {
      border-color: ${({ theme }) => theme.color.mainDark};
    }
  }
`;

export const PopoverContentsWrapper = styled.div`
  .tags-select-input {
    width: 500px;
  }
`;
