import React, { FC, useCallback } from 'react';
import { RoutePathnames } from 'config/routePathnames';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/elements';
import { toast } from 'helpers/messages';
import { SyncStartedToastWrapper } from './style';

interface ISyncStartedToast {
  toastId: string;
}

export const SyncStartedToast: FC<ISyncStartedToast> = ({ toastId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const gotoSyncStatusClick = useCallback(() => {
    toast.dismiss(toastId);
    dispatch(push(RoutePathnames.SYNC_STATUS));
  }, [toastId, dispatch]);

  return (
    <SyncStartedToastWrapper className='sync-started-toast-wrapper'>
      {t('syncStarted')}
      <div className='buttons-container'>
        <Button
          className='custom-toast-button goto-sync-status-button'
          color='secondary'
          onClick={gotoSyncStatusClick}
          size='small'
          testId='goto-sync-status-button'
        >
          {t('checkSyncStatus')}
        </Button>
      </div>
    </SyncStartedToastWrapper>
  );
};
