import { getUuid, getDateISOString } from 'helpers';

/** TYPES */

export enum RequestsActionTypes {
  REQUEST_START = 'requests/REQUEST_START',
  REQUEST_SUCCESS = 'requests/REQUEST_SUCCESS',
  REQUEST_ERROR = 'requests/REQUEST_ERROR'
}

export interface RequestStartAction {
  type: typeof RequestsActionTypes.REQUEST_START;
  payload: {
    requestUuid: string;
    startedAt: string;
    label: string;
  };
}

export interface RequestSuccessAction<ResponseType = any> {
  type: typeof RequestsActionTypes.REQUEST_SUCCESS;
  payload: {
    requestUuid: string;
    completedAt: string;
    responseBody?: ResponseType;
  };
}

export interface RequestErrorAction {
  type: typeof RequestsActionTypes.REQUEST_ERROR;
  payload: {
    requestUuid: string;
    error: Error;
    completedAt: string;
  };
}

export type RequestAction =
  | RequestStartAction
  | RequestSuccessAction
  | RequestErrorAction;

/** ACTION CREATORS */

export const requestStart = (label: string, requestUuid: string = getUuid()): RequestStartAction => {
  const startedAt = getDateISOString();
  return {
    type: RequestsActionTypes.REQUEST_START,
    payload: { label, requestUuid, startedAt }
  };
};

// We may not always need to keep the response body. That arg
// is optional, to save store memory
export const requestSuccess = <ResponseType>(
  requestUuid: string,
  responseBody?: ResponseType
): RequestSuccessAction<ResponseType> => {
  const completedAt = getDateISOString();
  return {
    type: RequestsActionTypes.REQUEST_SUCCESS,
    payload: { requestUuid, responseBody, completedAt }
  };
};

export const requestError = (requestUuid: string, error: any): RequestErrorAction => {
  const completedAt = getDateISOString();
  return {
    type: RequestsActionTypes.REQUEST_ERROR,
    payload: { requestUuid, error, completedAt }
  };
};
