import React, { FC, useEffect, useRef, forwardRef, RefObject, SyntheticEvent, useMemo, memo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { isFunction, toLower } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { useKeyPress } from 'hooks/useKeyPress';
import { getFunctionBarExpanded } from 'store/function-bar/selectors';
import { FunctionButtonConfig } from 'config/function-menu';
import { AnyAction, Store } from 'redux';
import { AppState } from 'store';
import equal from 'fast-deep-equal';

interface IFunctionMenuItem {
  config: FunctionButtonConfig;
}

export const FunctionMenuItem: FC<IFunctionMenuItem> = memo(forwardRef(({
  config
}, ref) => {
  const {
    ariaKeyShortcuts,
    buttonId,
    disabledSelector,
    icon,
    label,
    onClick,
    shortcutExtraKeys,
    shortcutTargetKey,
    getTooltipContent
  } = config;

  const store: Store<AppState, AnyAction> = useStore();
  const dispatch = useDispatch();
  const disabled = useSelector(disabledSelector || (() => false));
  const functionBarExpanded = useSelector(getFunctionBarExpanded);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const keyShortcut = useKeyPress(shortcutTargetKey, shortcutExtraKeys);
  const id = toLower(`function-button-${buttonId}`);

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isFunction(onClick)) {
      onClick(dispatch, store.getState());
    }
  };

  // Triggers the click event on the button after the predefined key combination shortcut is performed
  useEffect(() => {
    if (keyShortcut) {
      buttonRef.current.click();
    }
  }, [keyShortcut]);

  const tooltipContent = useMemo(() => {
    return isFunction(getTooltipContent)
      ? getTooltipContent(functionBarExpanded)
      : null;
  }, [getTooltipContent, functionBarExpanded]);

  return (
    <li
      className='function-menu-item'
      data-testid={id}
      ref={ref as RefObject<HTMLLIElement>}
    >
      {!!tooltipContent && (
        <ReactTooltip
          id={`${id}-tooltip`}
          aria-haspopup='true'
          border
          borderColor='#ccc'
          delayShow={functionBarExpanded ? 400 : 0}
          disable={disabled}
          effect='solid'
          event='focus mouseover'
          eventOff='blur mouseout'
          multiline
          place='bottom'
          type='light'
        >
          <div className='function-menu-button-tooltip-content' style={{ fontSize: '1.2rem' }}>
            {tooltipContent}
          </div>
        </ReactTooltip>
      )}
      <button
        aria-keyshortcuts={ariaKeyShortcuts}
        className='function-menu-button'
        id={id}
        onClick={handleClick}
        ref={buttonRef}
        disabled={disabled}
        data-tip={!!tooltipContent}
        data-for={`${id}-tooltip`}
      >
        <span className='function-button-icon'>{icon}</span>
        <span className='function-button-label'>{label}</span>
      </button>
    </li>
  );
}), equal);
