import { CoursesSuffixes } from 'config/index';

const ABC = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

/**
 * Creates a suffix of two different types
 * Numbers: 1, 2, 3, 4, ... 100, 101
 * Letters: A, B, C, ... Z, AA, BB, CC, ... ZZ, AAA, BBB
 */
export const getSuffix = (suffix: string, index: number) => {
  if (suffix === CoursesSuffixes.NONE) return;

  if (suffix === CoursesSuffixes.NUMBERS) {
    return index + 1;
  }

  if (suffix === CoursesSuffixes.LETTERS) {
    if (index < 25) {
      return ABC[index];
    }

    const letter = ABC[index % 26];
    const repetitions = Math.floor(index / 26) + 1;

    return letter.repeat(repetitions);
  }
};
