import styled, { css } from 'styled-components';
import ITheme from 'styles/interfaces/theme';

export interface IIconButtonProps {
  ariaLabel?: string,
  rotate: number,
  color: string,
  squared: boolean,
  selected: boolean,
  theme: ITheme
}

export const CustomIconButton = styled.button<IIconButtonProps>`
  background: ${({ theme, color, squared, selected }) => (squared
    ? selected
      ? color === 'primary' ? theme.color.main : theme.color.white
      : color === 'primary' ? theme.color.white : theme.color.white
    : 'none'
  )};
  border: none;
  color: ${({ theme, color, squared, selected }) => (squared
    ? selected
      ? color === 'primary' ? theme.color.white : theme.color.mainDark
      : color === 'primary' ? theme.color.gray3 : theme.color.gray3
    : color === 'primary' ? theme.color.mainDark : theme.color.gray3
  )};

  cursor: pointer;
  display: inline-block;
  margin:0 4px;
  transition: all 0.2s ease-in-out;
  position: relative;
  ${({ squared }) => squared && css`
    height: 40px;
    width: 40px;
  `}

  &:before {
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.color.main};
    content: '';
    height: 28px;
    left: 50%;
    margin-left: -14px;
    margin-top: -14px;
    position: absolute;
    top: 50%;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
    width: 28px;
    z-index: 0;
  }

  &:hover,
  &:focus {
    color: ${({ theme, color, squared }) => (squared
      ? color === 'primary' ? theme.color.white : theme.color.main
      : theme.color.mainDark
    )};

    ${({ theme, color, squared }) => (squared && css`
      background-color: ${color === 'primary' ? theme.color.main : theme.color.gray7}
      box-shadow: inset 0 0 0 1px ${theme.color.main}, inset 0 0 0 2px ${theme.color.white}
    `)}
  }

  &:focus {
    ${({ squared }) => (!squared && css`      
      &:before {
        transform: scale(1);
      }
    `)}
  }

  &:disabled {
    color: ${({ theme, color }) => (color === 'primary'
    ? theme.color.gray1
    : theme.color.gray1
  )};
    cursor: auto;
  }

  svg {
    transition: transform 0.2s ease-in-out;
    transform: rotate(${({ rotate }) => rotate}deg);
  }
`;
