// @ts-nocheck
import React from 'react';

import { Ref, FieldError } from 'react-hook-form/dist/types';
import { Field, Input, Label } from './style';
import { Errors } from '../shared-style';

export interface IInputElementProps {
  errors?: FieldError;
  label: string;
  name: string;
  register: Ref;
  type: string;
  min?: string;
  inlineCheck?: boolean;
}

const InputElement: React.FC<IInputElementProps> = React.memo(({ errors, label, name, register, type, min, inlineCheck }) => (
  <Field
    error={!!errors}
    inlineCheck={inlineCheck}
  >
    <Label htmlFor={`${name}-input`}>
      {label}
    </Label>
    <Input
      id={`${name}-input`}
      name={name}
      type={type}
      min={min}
      ref={register}
    />

    {
      errors
        && (
        <Errors
          role='alert'
          id={`${name}-error`}
          data-testid='form-error-sc'
        >
          {errors.message ? errors.message : errors}
        </Errors>
        )
    }
  </Field>
));

export default InputElement;
