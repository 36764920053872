import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { Trans } from 'react-i18next';
import { get, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SHORTCUTS, USER_AGENT } from 'config';
import { log } from 'helpers/messages';
import { ItemTypes, KeyCodesTypes, ModalId } from 'constants/index';
import Account from 'models/canvas/account';
import { AppState } from 'store';
import { triggerAddSubAccount, triggerAddCourses } from 'store/accounts/composite-actions';

import { getCourseExistsInLmsAlready, getSelectedCourses } from 'store/courses/selectors';

import { RoutePathnames } from './routePathnames';
import { setRowsSelected } from 'modules/data-table/store/data-table/actions';
import { toggleModal } from 'store/modals/actions';
import { getAddAccountIsDisabled, getAddCoursesIsDisabled, getApplyTemplatesIsDisabled, getBulkSettingsIsDisabled, getClearSelectionsIsDisabled, getDeleteUnlockedIsDisabled, getDuplicateButtonIsDisabled, getImportCourseIsDisabled, getMoveItemIsDisabled } from 'store/function-bar/selectors';
import { RouteMatch } from 'store/router/selectors';
import { COURSES_LIST_TABLE_ID } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable';
import { getSelectedRowIds } from 'modules/data-table/store/data-table/selectors';
import { TFunction } from 'i18next';
import { handleDeleteAccount, handleDeleteCourses, handleDuplicateCourses, handleImportCourse, handleBulkCourseSettings } from 'helpers/function-menu-helpers';
import { getActiveAccountId } from 'store/account-tree/selectors';
import { filter } from 'lodash';
import Course from 'models/canvas/course';

export interface FunctionButtonConfig {
  buttonId: FunctionButtonId;
  icon: ReactNode;
  label: ReactNode;
  shortcutTargetKey?: number,
  shortcutExtraKeys?: Array<keyof KeyboardEvent>,
  ariaKeyShortcuts?: string,
  disabledSelector?: (appState: AppState) => boolean;
  onClick?: (dispatch: Dispatch, appState: AppState) => void;
  getTooltipContent?: (expanded: boolean) => ReactNode;
}

export enum FunctionButtonId {
  ADD_ACCOUNT = 'ADD_ACCOUNT',
  ADD_COURSES = 'ADD_COURSES',
  NEW_TEMPLATE = 'NEW_TEMPLATE',
  APPLY_FRAMEWORK = 'APPLY_FRAMEWORK',
  IMPORT_COURSE = 'IMPORT_COURSE',
  DUPLICATE_COURSES = 'DUPLICATE_COURSES',
  CLEAR_SELECTION = 'CLEAR_SELECTION',
  DELETE_UNLOCKED = 'DELETE_UNLOCKED',
  CLEAR_SYNC_HISTORY = 'CLEAR_SYNC_HISTORY',
  MOVE_ITEMS = 'MOVE_ITEMS',
  BULK_COURSE_SETTINGS = 'BULK_COURSE_SETTINGS'
}

export const DIVIDER = 'DIVIDER';

const IS_MAC = USER_AGENT.isMac;
const altKeyDisplay = IS_MAC ? 'opt' : 'alt';
const ctrlKeyDisplay = 'ctrl';
const shortcutComponents = {
  wrapper: <span />,
  shortcut: <span className='shortcut-marker' />
};

const buildTooltipContent = (targetKeyStr: ReactNode, label: ReactNode) => {
  return (expanded: boolean): ReactNode => (expanded
      ? <kbd>{ctrlKeyDisplay}+{altKeyDisplay}+{targetKeyStr}</kbd>
      : <span>{label}<br /><kbd>{ctrlKeyDisplay}+{altKeyDisplay}+{targetKeyStr}</kbd></span>);
};

export const getButtonConfigs = (
  t: TFunction,
  match: RouteMatch
): Partial<Record<FunctionButtonId, FunctionButtonConfig>> => ({
  [FunctionButtonId.ADD_ACCOUNT]: {
    buttonId: FunctionButtonId.ADD_ACCOUNT,
    icon: <FontAwesomeIcon icon={['fas', 'folder-plus']} />,
    shortcutTargetKey: KeyCodesTypes.A,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+A`,
    disabledSelector: getAddAccountIsDisabled,
    label: <Trans i18nKey='functionBarAddAccount' components={shortcutComponents} />,
    onClick: (dispatch, appState) => {
      const accountId: Account['id'] = getActiveAccountId(appState);
      const settings = appState.accounts.items[accountId]._meta.defaultCourseBulkSettings ? appState.accounts.items[accountId]._meta.defaultCourseBulkSettings : {
        courseNavSettings: { enabled: true, useHomeMenu: true },
        courseAuthoringSettings: { enabled: true },
        coursePageRatingSettings: { enabled: true }
      };
      triggerAddSubAccount(accountId, settings, t)(dispatch);
    },
    getTooltipContent: buildTooltipContent('A', <Trans i18nKey='functionBarAddAccount' components={shortcutComponents} />)
  },
  [FunctionButtonId.ADD_COURSES]: {
    buttonId: FunctionButtonId.ADD_COURSES,
    icon: <FontAwesomeIcon icon={['fas', 'plus-circle']} />,
    shortcutTargetKey: KeyCodesTypes.C,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+C`,
    disabledSelector: getAddCoursesIsDisabled,
    label: <Trans i18nKey='functionBarAddCourses' components={shortcutComponents} />,
    onClick: (dispatch, appState) => {
      triggerAddCourses()(dispatch);
    },
    getTooltipContent: buildTooltipContent('C', <Trans i18nKey='functionBarAddCourses' components={shortcutComponents} />)
  },
  [FunctionButtonId.NEW_TEMPLATE]: {
    buttonId: FunctionButtonId.NEW_TEMPLATE,
    icon: <FontAwesomeIcon icon={['fas', 'plus-square']} />,
    shortcutTargetKey: KeyCodesTypes.P,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+P`,
    label: <Trans i18nKey='functionBarNewTemplate' components={shortcutComponents} />,
    onClick: (dispatch, appState) => {
      log('NEW_TEMPLATE');
    },
    getTooltipContent: buildTooltipContent('P', <Trans i18nKey='functionBarNewTemplate' components={shortcutComponents} />)
  },
  [FunctionButtonId.APPLY_FRAMEWORK]: {
    buttonId: FunctionButtonId.APPLY_FRAMEWORK,
    icon: <FontAwesomeIcon icon={['fas', 'fill-drip']} />,
    shortcutTargetKey: KeyCodesTypes.F,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+F`,
    label: <Trans i18nKey='functionBarApplyFramework' components={shortcutComponents} />,
    disabledSelector: getApplyTemplatesIsDisabled,
    onClick: (dispatch, appState) => {
      const selectedCourses = getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID })(appState);
      const courseIds = map(selectedCourses, 'id');
      dispatch(toggleModal(ModalId.APPLY_FRAMEWORK, true, { courseIds }));
    },
    getTooltipContent: buildTooltipContent('F', <Trans i18nKey='functionBarApplyFramework' components={shortcutComponents} />)
  },
  [FunctionButtonId.IMPORT_COURSE]: {
    buttonId: FunctionButtonId.IMPORT_COURSE,
    icon: <FontAwesomeIcon icon={['fas', 'file-import']} />,
    shortcutTargetKey: KeyCodesTypes.M,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+M`,
    label: <Trans i18nKey='functionBarImportCourse' components={shortcutComponents} />,
    disabledSelector: getImportCourseIsDisabled,
    onClick: (dispatch, appState) => {
      handleImportCourse(dispatch, appState, t);
    },
    getTooltipContent: buildTooltipContent('M', <Trans i18nKey='functionBarImportCourse' components={shortcutComponents} />)
  },
  [FunctionButtonId.DUPLICATE_COURSES]: {
    buttonId: FunctionButtonId.DUPLICATE_COURSES,
    icon: <FontAwesomeIcon icon={['fas', 'layer-group']} />,
    shortcutTargetKey: KeyCodesTypes.U,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+U`,
    label: <Trans i18nKey='functionBarDuplicateCourses' components={shortcutComponents} />,
    disabledSelector: getDuplicateButtonIsDisabled,
    onClick: (dispatch, appState) => {
      handleDuplicateCourses(dispatch, appState, t);
    },
    getTooltipContent: buildTooltipContent('U', <Trans i18nKey='functionBarDuplicateCourses' components={shortcutComponents} />)
  },
  [FunctionButtonId.BULK_COURSE_SETTINGS]: {
    buttonId: FunctionButtonId.BULK_COURSE_SETTINGS,
    icon: <FontAwesomeIcon icon={['fas', 'dolly']} />,
    shortcutTargetKey: KeyCodesTypes.S,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+S`,
    label: <Trans i18nKey='functionBarBulkCourseSettings' components={shortcutComponents} />,
    disabledSelector: getBulkSettingsIsDisabled,
    onClick: (dispatch, appState) => {
      handleBulkCourseSettings(dispatch, appState, t);
    },
    getTooltipContent: buildTooltipContent('S', <Trans i18nKey='functionBarBulkCourseSettings' components={shortcutComponents} />)
  },
  [FunctionButtonId.CLEAR_SELECTION]: {
    buttonId: FunctionButtonId.CLEAR_SELECTION,
    icon: <FontAwesomeIcon icon={['far', 'square']} />,
    shortcutTargetKey: KeyCodesTypes.L,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+L`,
    label: <Trans i18nKey='functionBarClearSelection' components={shortcutComponents} />,
    disabledSelector: getClearSelectionsIsDisabled,
    onClick: (dispatch, appState) => {
      dispatch(setRowsSelected(COURSES_LIST_TABLE_ID, [], false, true));
    },
    getTooltipContent: buildTooltipContent('L', <Trans i18nKey='functionBarClearSelection' components={shortcutComponents} />)
  },
  [FunctionButtonId.DELETE_UNLOCKED]: {
    buttonId: FunctionButtonId.DELETE_UNLOCKED,
    icon: <FontAwesomeIcon icon={['fas', 'minus-circle']} />,
    shortcutTargetKey: KeyCodesTypes.D,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    ariaKeyShortcuts: `${SHORTCUTS.extraKeysAria}+D`,
    label: <Trans i18nKey='functionBarDelete' components={shortcutComponents} />,
    disabledSelector: getDeleteUnlockedIsDisabled({ match }),
    onClick: (dispatch, appState) => {
      const matchPath: string = match.path;
      if (matchPath === RoutePathnames.ACCOUNTS) {
        handleDeleteAccount(dispatch, appState, t);
      } else if (matchPath === RoutePathnames.ACCOUNT_COURSES) {
        handleDeleteCourses(dispatch, appState, t);
      }
    },
    getTooltipContent: buildTooltipContent('D', <Trans i18nKey='functionBarDelete' components={shortcutComponents} />)
  },
  [FunctionButtonId.MOVE_ITEMS]: {
    buttonId: FunctionButtonId.MOVE_ITEMS,
    icon: <FontAwesomeIcon icon={['fas', 'arrows-alt']} />,
    shortcutTargetKey: KeyCodesTypes.V,
    shortcutExtraKeys: SHORTCUTS.extraKeys,
    label: <Trans i18nKey='functionBarMoveItem' components={shortcutComponents} />,
    onClick: (dispatch, appState) => {
      const matchPath: string = get(match, 'path');
      let itemIds: string[] = [];
      let itemType: ItemTypes;
      if (matchPath === RoutePathnames.ACCOUNTS) {
        itemIds = [getActiveAccountId(appState)];
        itemType = ItemTypes.ACCOUNT;
      } else if (matchPath === RoutePathnames.ACCOUNT_COURSES) {
        itemIds = getSelectedRowIds({ tableId: COURSES_LIST_TABLE_ID })(appState);
        itemType = ItemTypes.COURSE;
      }
      dispatch(toggleModal(ModalId.MOVE_ITEMS, true, { itemIds, itemType }));
    },
    disabledSelector: getMoveItemIsDisabled({ match }),
    getTooltipContent: buildTooltipContent('V', <Trans i18nKey='functionBarMoveItem' components={shortcutComponents} />)
  },
});

export const routeFunctionConfigs: Record<string, (FunctionButtonConfig['buttonId'] | typeof DIVIDER)[]> = {
  default: [
  ],
  [RoutePathnames.ACCOUNT_COURSES]: [
    FunctionButtonId.ADD_COURSES,
    DIVIDER,
    FunctionButtonId.APPLY_FRAMEWORK,
    FunctionButtonId.IMPORT_COURSE,
    FunctionButtonId.DUPLICATE_COURSES,
    FunctionButtonId.BULK_COURSE_SETTINGS,
    DIVIDER,
    FunctionButtonId.CLEAR_SELECTION,
    FunctionButtonId.DELETE_UNLOCKED,
    FunctionButtonId.MOVE_ITEMS
  ],
  [RoutePathnames.ACCOUNTS]: [
    FunctionButtonId.ADD_ACCOUNT,
    FunctionButtonId.DELETE_UNLOCKED,
    FunctionButtonId.MOVE_ITEMS
  ],
  [RoutePathnames.SYNC_STATUS]: []
};

export const getFunctionMenuConfig = (pathname: string) => {
  return get(routeFunctionConfigs, pathname, routeFunctionConfigs.default);
};
