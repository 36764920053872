import { keyBy, omit } from 'lodash';
import SyncOrder from 'models/syncOrder/syncOrder';
import SyncOrderDto from 'models/dto/syncOrderDto';
import { SyncOrderAction, SyncOrderActionTypes } from './actions';

interface ISyncOrderPagination {
  last: boolean;
  first: boolean;
  number: number;
  totalElements: number;
}
export interface SyncOrderFeatureState {
  pagination: ISyncOrderPagination;
  syncOrderDtos: Record<SyncOrder['syncOrderId'], SyncOrderDto>;
}

export const defaultSyncOrderState: SyncOrderFeatureState = {
  pagination: {
    last: false,
    first: true,
    number: 0,
    totalElements: 0
  },
  syncOrderDtos: {},
};

export default function syncOrderReducer(
  state: SyncOrderFeatureState = defaultSyncOrderState,
  action: SyncOrderAction
): SyncOrderFeatureState {
  switch (action.type) {
    case SyncOrderActionTypes.RECEIVE_SYNC_ORDER_DTOS: {
      return {
        ...state,
        syncOrderDtos: {
          ...state.syncOrderDtos,
          ...keyBy(action.payload.syncOrderDtos, 'syncOrder.syncOrderId')
        }
      };
    }

    case SyncOrderActionTypes.RECEIVE_SYNC_ORDER_PAGEABLE_INFO: {
      const { first, last, number, totalElements } = action.payload.mongoPageable;
      return {
        ...state,
        pagination: {
          first: number > state.pagination?.number ? first : state.pagination?.first,
          last: number > state.pagination?.number ? last : state.pagination?.last,
          number: number > state.pagination?.number ? number : state.pagination?.number,
          totalElements,
        },
      };
    }

    case SyncOrderActionTypes.CLEAR_SYNC_ORDER: {
      return {
        ...state,
        syncOrderDtos: omit(state.syncOrderDtos, action.payload.syncOrderIds)
      };
    }

    default:
      return state;
  }
}
