import React, { FC, useRef, useEffect, useMemo, useCallback } from 'react';
import { forEach, filter, keys } from 'lodash';
import { ColumnDef, RowHeights, DataTableSelections, RowPositions, DataTableColumnVisibility, DataTableColumnIndices } from 'modules/data-table/types';
import { buildCellKey } from 'modules/data-table/util';
import { TableCell } from '../TableCell';
import { DataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';
import cn from 'classnames';
import { TABLE_CELL_CLASSNAME, ROW_CLASSNAME } from '../TableBody';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface ITableRow {
  api: DataTableObservables;
  canDrag: boolean;
  // whether this is the left-pinned group of columns or the main center-grouped columns
  container: 'left'|'center';
  columnsToInclude: ColumnDef[];
  columnIndices: DataTableColumnIndices;
  columnVisibility: DataTableColumnVisibility;
  defaultRowHeight: number;
  dragItemType: string;
  hasCustomDragItemRenderer: boolean;
  row: any;
  rowId: string;
  rowPositions: RowPositions;
  rowHeights: RowHeights;
  scrollTop: number;
  selections: DataTableSelections;
  tableId: string;
  minWidth: number;
}

export const TableRow: FC<ITableRow> = ({
  api,
  canDrag,
  columnsToInclude,
  columnIndices,
  columnVisibility,
  container,
  defaultRowHeight,
  dragItemType,
  hasCustomDragItemRenderer,
  row,
  rowPositions,
  rowId,
  selections,
  tableId,
  minWidth
}) => {
  const ref = useRef<HTMLDivElement>();

  const { top, left, height, rowIndex } = useMemo(() => {
    return rowPositions?.[rowId] || {};
  }, [rowId, rowPositions]);

  const updateHeight = useCallback(() => {
    const memberCells = ref.current.querySelectorAll(`.${TABLE_CELL_CLASSNAME}`);
    let maxCellHeight: number = 0;
    // determine the tallest member cell
    forEach(memberCells, (cell: Element) => {
      if (cell.parentElement !== ref.current) { return; }
      if (cell.scrollHeight > maxCellHeight) {
        maxCellHeight = cell.scrollHeight;
      }
    });
    if (!height || height < maxCellHeight) {
      const heightUpdate = Math.max(maxCellHeight, defaultRowHeight);
      api.rowHeightUpdate$.next({ rowId, height: heightUpdate, container });
    }
  }, [rowId, height, api, container, ref, defaultRowHeight]);

  /** aria grid indices start at 1, and header row is 1, so we add 2 */
  const ariaRowIndex = rowIndex + 2;
  const odd = rowIndex % 2 > 0;

  const selected = !!selections[rowId];

  useEffect(() => {
    updateHeight();
    const interval = setInterval(() => {
      updateHeight();
    }, 400);
    return () => clearInterval(interval);
  }, [updateHeight, ref]);

  const dragItem = useMemo(() => {
    return {
      type: dragItemType || 'NONE',
      data: {
        row,
        draggedRowId: rowId,
        draggedRowIsSelected: selected,
        selectedRowIds: filter(
          keys(selections),
          (_rowId: string) => selections[_rowId]
        )
      }
    };
  }, [dragItemType, rowId, selected, selections, row]);

  // define the draggable data & behavior of a row
  const [{ isDragging, canDragRow }, dragRef, preview] = useDrag({
    // the data to 'drop' on a droppable item
    item: dragItem,
    canDrag: () => canDrag,
    // define some values to be returned in the 1st element of the array returned by useDrag
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
      canDragRow: monitor.canDrag()
    })
  });

  // don't use the default HTML5 drag preview if a custom drag renderer was provided
  useEffect(() => {
    if (hasCustomDragItemRenderer) {
      preview(getEmptyImage(), { captureDraggingState: true });
    }
  }, [hasCustomDragItemRenderer, dragItem, preview]);

  // assign the row-wrapper div ref as the draggable element
  dragRef(ref);

  return (
    <div
      ref={ref}
      className={cn(ROW_CLASSNAME, { selected, odd, canDragRow, isDragging })}
      data-testid={`table-row-${rowId}-${container}`}
      data-can-drag={canDragRow}
      data-is-dragging={isDragging}
      data-drag-item-type={dragItem?.type}
      id={`table-row-${rowId}-${container}`}
      role='row'
      aria-rowindex={ariaRowIndex}
      style={{
        height: height || 'auto',
        top,
        left,
        opacity: height ? 1 : 0,
        minWidth
      }}
    >
      {columnsToInclude.map((columnDef: ColumnDef) => {
        return !!columnVisibility[columnDef.columnId] && (
          <TableCell
            key={buildCellKey(columnDef.columnId, rowId)}
            columnIndex={columnIndices[columnDef.columnId]}
            row={row}
            rowHeight={height}
            rowId={rowId}
            columnDef={columnDef}
            api={api}
            ariaRowIndex={ariaRowIndex}
            tableId={tableId}
          />
        );
      })}
    </div>
  );
};
