import React, { FC, useCallback } from 'react';
import { SelectionCheckbox } from 'components/elements';
import Course from 'models/canvas/course';
import { SelectCellWrapper } from './style';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setRowsSelected } from 'modules/data-table/store/data-table/actions';
import { DataTableSelections } from 'modules/data-table/types';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getDataTableSelections } from 'modules/data-table/store/data-table/selectors';

interface ISelectCell {
  row: Course;
  tableId: string;
  disabled?: boolean;
}

export const SelectCell: FC<ISelectCell> = ({
  row,
  tableId,
  disabled = false
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSelectClick = useCallback(() => {
    dispatch(setRowsSelected(tableId, [row?.id], true, true));
  }, [dispatch, tableId, row]);

  const tableSelections: DataTableSelections = useSelectorWithProps(getDataTableSelections, { tableId });

  const selected = !!tableSelections?.[row?.id];

  return (
    <SelectCellWrapper className='select-cell-wrapper'>
      <SelectionCheckbox
        aria-label={selected ? t('selected') : t('select')}
        readOnly={disabled}
        id={`select-row-${row?.id}`}
        selected={selected}
        onClick={handleSelectClick}
        checkboxStyle='radio'
      />
    </SelectCellWrapper>
  );
};
