import { AxiosResponse } from 'axios';
import api from 'api';
import mockApi from 'api/mock';

import { API } from 'config';

import InstallationDto from 'models/dto/installationDto';

const customApi = API.useMock ? mockApi() : api();

export async function fetchInstallationData(): Promise<InstallationDto> {
  return customApi.get('/api/installations/installation').then((resp: AxiosResponse<InstallationDto>) => new InstallationDto(resp.data));
}
