import { FormSection } from 'components/elements/shared-style';
import styled from 'styled-components';

export const HeadingAccountName = styled.span`
  font-style: italic;
`;

export const HorizFieldGroup = styled.div`
  display: flex;
  justify-content: space-between;

  ${FormSection} {
    width: 100%;
    margin-right: .5em;
  }
`;
