import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { map } from 'lodash';
import * as yup from 'yup';

import { CONSTANTS } from 'config';

import { useModalState } from 'hooks/useModalState';
import { toggleModal } from 'store/modals/actions';
import { yupResolver } from '@hookform/resolvers';

import { ModalId } from 'constants/index';

import { applyTemplates } from 'store/courses/actions';
import { getCourseItems } from 'store/courses/selectors';

import Course from 'models/canvas/course';
import CourseTemplate from 'models/courseTemplate';
import ModalModel from 'models/modal';
import CourseNavSettings from 'models/courseNavSettings';
import CourseAuthoringSettings from 'models/courseAuthoringSettings';
import CoursePageRatingSettings from 'models/coursePageRatingSettings';

import { TemplateList, Button, Modal } from 'components/elements';
import { SectionContent, SectionFooter, ModalHeading, FormFieldGroup } from 'components/general-styles';
import { Errors } from 'components/elements/shared-style';

import { HeadingCourseName } from './style';

interface IAddCoursesFormValues {
  templateId: CourseTemplate['courseTemplateId'];
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
}

interface IApplyTemplateProps {
}

interface IApplyTemplateModalData {
  courseIds: Course['id'][];
}

const ApplyTemplate: React.FC<IApplyTemplateProps> = () => {
  const modalId = ModalId.APPLY_FRAMEWORK;
  const { t } = useTranslation();

  const modalState = useModalState<ModalModel<IApplyTemplateModalData>>(modalId);
  const selectedCourseIds = modalState?.data?.courseIds;
  const multipleCoursesSelected = (selectedCourseIds?.length || 0) > 1;
  const courseItems = useSelector(getCourseItems);

  const selectedCourses = map(selectedCourseIds, (id: string) => courseItems?.[id]);

  const firstCourseName = selectedCourses?.[0]?.name;

  const courseNameForHeading = multipleCoursesSelected
    ? t('selectedCourses')
    : <HeadingCourseName className='heading-course-name'>{firstCourseName}</HeadingCourseName>;

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    templateId: yup
      .string().nullable()
      .required(t('optionError'))
  });

  const { handleSubmit, register, errors, formState, getValues } = useForm<IAddCoursesFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      templateId: CONSTANTS.templates.emptyId
    }
  });

  const handleClose = () => {
    dispatch(toggleModal(modalId, false, { courseIds: [] }));
  };

  const submit = () => {
    const formVals = getValues();
    dispatch(applyTemplates(selectedCourseIds, formVals?.templateId));
    handleClose();
  };

  return (
    <Modal id={modalId} handleClose={handleClose}>
      <form onSubmit={handleSubmit(submit)}>
        <SectionContent>
          <ModalHeading>
            <h2 tabIndex={0} id={`modal-${modalId}-title`}>{t('applyFrameworkTo')} {courseNameForHeading}</h2>
            <Trans i18nKey='modalApplyFrameworkDescription' components={{ line: <p />, bold: <strong /> }} />
          </ModalHeading>

          <FormFieldGroup>
            <TemplateList
              label={t('framework_plural')}
              name='templateId'
              noFrameWorkSelectedOption={false}
              register={register}
            />

            {
              errors?.templateId && (
                <Errors
                  id='error'
                  data-testid='form-error-sc'
                >
                  { errors?.templateId?.message }
                </Errors>
              )
            }
          </FormFieldGroup>


        </SectionContent>

        <SectionFooter>
          <div />
          <div>
            <Button
              ariaLabel={t('cancel')}
              color='secondary'
              onClick={handleClose}
            >
              {t('cancel')}
            </Button>
            <Button
              ariaLabel={t('applyFramework')}
              className='apply-template-submit-button'
              submitting={formState?.isSubmitting}
              type='submit'
            >
              {t('apply')}
            </Button>
          </div>
        </SectionFooter>
      </form>
    </Modal>
  );
};

export default React.memo(ApplyTemplate);
