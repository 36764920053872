import styled from 'styled-components';

export const NewTermPopoverContentWrapper = styled.div`
  width: 58vw;
  max-width: 700px; 

  .role-access-overrides-description {
    margin: .5em 0;
    width: 95%;
  }

  .role-overrides-table {
    .role-name-cell {
      text-align: right;
    }
    .date-input-cell {
      padding-left: .5em;
      fieldset {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin: .6rem 0 0;
        .date-picker-wrapper {
          width: 100%;
        }
      }
    }
  }
  
  .flex-fieldset-container {
    display: flex;
    justify-content: space-between;
    fieldset {
      flex-basis: 50%;
      max-width: 50%;
      &:first-child {
        margin-right: .7rem;
      }
    }
  }
`;
