import React, { FC, memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { usePrevious } from 'hooks/usePrevious';
import { getCoursesFetchInProgress } from 'store/courses/selectors';
import { getLatestRequestInProgressByLabel } from 'store/requests/selectors';
import { getAccountById } from 'store/accounts/selectors';
import CoursesFetchStatus from 'components/elements/CoursesFetchStatus';
import { Loading } from 'components/elements';
import { CoursesListTable } from './CoursesListTable';
import { RequestLabel } from 'constants/index';
import { CourseListContainer } from './style';

interface ICoursesList {
  accountId: string;
  isAccountsRoute: boolean;
}

export const CoursesList: FC<ICoursesList> = memo(({
  accountId,
  isAccountsRoute
}) => {
  const { t } = useTranslation();
  const [showTable, setShowTable] = useState(true);
  const prevAccountId = usePrevious(accountId);
  const isNewAccountId = accountId !== prevAccountId;
  const account = useSelectorWithProps(getAccountById, { accountId });
  const accountName = account?.name;
  const coursesFetchInProgress = useSelector(getCoursesFetchInProgress);
  const installationDataFetching = useSelectorWithProps(
    getLatestRequestInProgressByLabel,
    { label: RequestLabel.FETCH_INSTALLATION_DATA }
  );

  // Hide and Show the view after a delay to avoid UI lag on route change
  useEffect(() => {
    let timer: number;
    if (isNewAccountId || isAccountsRoute) {
      setShowTable(false);
    }
    if (isNewAccountId || !isAccountsRoute) {
      timer = window.setTimeout(() => {
        setShowTable(true);
      }, 400);
    }
    return () => clearTimeout(timer);
  }, [isAccountsRoute, isNewAccountId, setShowTable]);

  if (coursesFetchInProgress || installationDataFetching) {
    return (
      <CourseListContainer className='courses-list-container'>
        <CoursesFetchStatus />
      </CourseListContainer>
    );
  }

  return (
    <CourseListContainer
      className='courses-list-container'
      aria-label='List of courses in the selected account'
      role='region'
    >
      <header className='courses-heading-wrapper'>
        <h2 className='courses-heading'>
          {t('coursesIn')} <span className='heading-account-name'>{accountName}</span>
        </h2>
      </header>
      {!showTable && !isAccountsRoute && (
        <Loading message={`${t('loading')}...`} />
      )}
      {accountId && showTable && (
        <CoursesListTable accountId={accountId} />
      )}
    </CourseListContainer>
  );
});
