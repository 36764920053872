import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSyncOrdersEffect } from 'hooks/useSyncOrdersEffect';
import { fetchInstallationData } from 'store/installation/actions';
import { startSyncStatusPolls } from 'store/syncOrders/actions';
import { fetchTermsPage } from 'store/terms/actions';

import { Layout } from './Layout';
import { AppContainer } from './styles';
import { IS_DEV } from 'config';

const beforeUnload = (e: any) => {
  // Cancel the event
  e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  (e || window.event).returnValue = '';
};

const App = () => {
  const dispatch = useDispatch();

  useSyncOrdersEffect();

  // Initial app data fetch
  useEffect(() => {
    dispatch(fetchInstallationData());
    dispatch(startSyncStatusPolls());
    dispatch(fetchTermsPage(1));
  }, [dispatch]);

  // Asks for confirmation before closing the tab
  useEffect(() => {
    if (IS_DEV) return;
    // EV-641 Adding Event listener to alert user to unsaved changes when navigating from tool
    window.addEventListener('beforeunload', beforeUnload);
  }, []);

  return (
    <AppContainer data-testid='app-container' className='cb-app-container'>
      <Layout />
    </AppContainer>
  );
};

export default App;
