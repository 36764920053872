import { get } from 'lodash';
import BaseSyncCourse from './baseSyncCourse';

export default class SyncExistingDupCourse extends BaseSyncCourse {
  syncExistingDupCourseId: string;
  sourceCourseId: string;

  constructor(json: any) {
    super(json);
    this.syncExistingDupCourseId = get(json, 'syncExistingDupCourseId');
    this.sourceCourseId = get(json, 'sourceCourseId');
  }
}
