import React, { FC } from 'react';
import Course from 'models/canvas/course';
import { CourseMenuCellWrapper } from './style';
import { CourseMenuButton } from 'components/elements/CourseMenuButton';

interface ICourseMenuCell {
  row: Course;
}

export const CourseMenuCell: FC<ICourseMenuCell> = ({ row }) => {
  const courseId = row?.id;
  return (
    <CourseMenuCellWrapper className='course-menu-cell-wrapper'>
      <CourseMenuButton courseId={courseId} />
    </CourseMenuCellWrapper>
  );
};
