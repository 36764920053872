import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { trim, concat, includes, omitBy, isUndefined } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { InstallationConfigKey, ModalId } from 'constants/index';
import { toast } from 'helpers/messages';
import { useModalState } from 'hooks/useModalState';
import { useInstallationConfig } from 'hooks/useInstallationConfig';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';

import { toggleModal } from 'store/modals/actions';
import { updateCourseMetas, updateCourses } from 'store/courses/actions';
import { getAllCourseTags, getCourseById } from 'store/courses/selectors';
import { getTermItems } from 'store/terms/selectors';
import { getCourseIsLocked } from 'store/general/cross-selectors';

import Course from 'models/canvas/course';
import CourseMeta from 'models/courseMeta';
import ModalModel from 'models/modal';
import CourseNavSettings from 'models/courseNavSettings';
import CourseAuthoringSettings from 'models/courseAuthoringSettings';
import CoursePageRatingSettings from 'models/coursePageRatingSettings';

import { AccountSelect, Button, Modal } from 'components/elements';
import { SectionContent, SectionFooter, ModalHeading, FormFieldGroup, FieldSet } from 'components/general-styles';
import { TagsInput } from 'components/elements/TagsInput';
import { TermSelect } from 'components/elements/TermSelect';
import { DatePicker } from 'components/elements/Datepicker';
import { FieldErrors } from 'components/elements/FieldErrors';
import EnhancedNavSettings from 'components/elements/EnhancedNavSettings';
import EnhancedAuthoringSettings from 'components/elements/EnhancedAuthoringSettings';
import EnhancedPageRatingSettings from 'components/elements/EnhancedPageRatingSettings';
import EnrollmentTerm from 'models/canvas/enrollmentTerm';

import { ContentWrapper } from './style';

interface ICourseSettingsFormVals {
  name: string;
  courseCode: string;
  sisCourseId: string;
  termId: string;
  accountId: string;
  startAt: Date;
  endAt: Date;
  tags: string[];
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
}

interface ICourseSettingsProps {}

interface ICourseSettingsModalData {
  courseId: Course['id'];
}

const CourseSettingsModal: React.FC<ICourseSettingsProps> = () => {
  const modalId = ModalId.COURSE_SETTINGS;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modalState = useModalState<ModalModel<ICourseSettingsModalData>>(modalId);
  const courseId: Course['id'] = modalState?.data?.courseId;
  const course = useSelectorWithProps(getCourseById, { courseId });
  const courseName = course?.name;
  const tagsOptions = useSelector(getAllCourseTags);
  const termItems = useSelector(getTermItems);
  const courseLocked = useSelectorWithProps(getCourseIsLocked, { courseId });

  const hasNavigationFeature: boolean = useInstallationConfig(InstallationConfigKey.NAV_SETTINGS_FEATURE);
  const hasAuthoringFeature: boolean = useInstallationConfig(InstallationConfigKey.AUTHORING_FEATURE);
  const hasPageRatingFeature: boolean = useInstallationConfig(InstallationConfigKey.PAGE_RATING_FEATURE);


  const alwaysEnabledFields = ['tags', 'coursePageRatingSettings.enabled', 'courseNavSettings.enabled', 'courseNavSettings.useHomeMenu'];
  const enabledFieldsWhenUnlocked = ['name', 'courseCode', 'accountId', 'sisCourseId', 'termId', 'startAt', 'endAt'];
  const enabledFields = courseLocked ? alwaysEnabledFields : concat(alwaysEnabledFields, enabledFieldsWhenUnlocked);

  const fieldIdPrefix = `${modalId}-${courseId}`;

  /**
   * Helper to return boolean flag for readOnly state of input fields
   * @param fieldName The name registered for the input in the form schema
   * @returns boolean - True if field should not be editable
   */
  const fieldIsReadOnly = (fieldName: string): boolean => {
    return !includes(enabledFields, fieldName);
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('required')),
    courseCode: yup.string().required(t('required')),
    sisCourseId: yup.string(),
    termId: yup.string(),
    accountId: yup.string(),
    startAt: yup.date().nullable().default(null),
    endAt: yup.date().nullable().default(null),
    tags: yup.array().of(yup.string()),
    courseNavSettings: yup.object().shape({
      enabled: yup.boolean(),
      useHomeMenu: yup.boolean()
    }),
    courseAuthoringSettings: yup.object().shape({
      enabled: yup.boolean()
    }),
    coursePageRatingSettings: yup.object().shape({
      enabled: yup.boolean()
    })
  });

  const defaultValues = {
    name: course?.name,
    courseCode: course?.course_code,
    sisCourseId: course?.sis_course_id,
    termId: course?.term?.id,
    accountId: course?.account_id,
    startAt: course?.start_at || course?.term?.start_at,
    endAt: course?.end_at || course?.term?.end_at,
    tags: course?._meta?.tags,
    courseNavSettings: {...course?._meta?.courseNavSettings},
    courseAuthoringSettings: {...course?._meta?.courseAuthoringSettings},
    coursePageRatingSettings: {...course?._meta?.coursePageRatingSettings}
  };

  const { handleSubmit, errors, watch, control, formState, register, setValue, getValues } = useForm<ICourseSettingsFormVals>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const navIsChecked: boolean = watch('courseNavSettings.enabled');

  /**
   * Updates input fields for start and end dates of course when term selection has changed
   * @param term - The currently selected term in the form
   */
  const syncDatesWithTerm = (term: EnrollmentTerm) => {
    setValue('startAt', term?.start_at);
    setValue('endAt', term?.end_at);
  };

  const handleClose = () => {
    dispatch(toggleModal(modalId, false, {}));
  };

  const submit = () => {
    const formVals = getValues();
    const newName = trim(formVals?.name);
    const courseUpdate: Partial<Course> = omitBy({
      name: newName,
      course_code: trim(formVals?.courseCode),
      sis_course_id: formVals?.sisCourseId,
      term: termItems?.[formVals?.termId],
      account_id: formVals?.accountId,
      start_at: formVals?.startAt,
      end_at: formVals?.endAt
    }, (value: any) => isUndefined(value));
    const metaUpdate: Partial<CourseMeta> = {
      courseNavSettings: {
        enabled: !!formVals?.courseNavSettings?.enabled,
        useHomeMenu: !!formVals?.courseNavSettings?.useHomeMenu
      },
      courseAuthoringSettings: {
        enabled: !!formVals?.courseAuthoringSettings?.enabled
      },
      coursePageRatingSettings: {
        enabled: !!formVals?.coursePageRatingSettings?.enabled
      },
      tags: (formVals?.tags || []).map(trim) as string[]
    };
    dispatch(updateCourses([courseId], courseUpdate));
    dispatch(updateCourseMetas([courseId], metaUpdate));
    handleClose();
    toast.success(t('toastCourseUpdated', { name: newName || courseName }));
  };

  if (!course) {
    return <></>;
  }

  return (
    <Modal id={modalId} handleClose={handleClose}>
      <form onSubmit={handleSubmit(submit)}>
        <SectionContent>
          <ModalHeading>
            <h2 id={`modal-${modalId}-title`}>{t('courseSettings')}: <em>{course?.name}</em></h2>
          </ModalHeading>

          <ContentWrapper className='content-wrapper'>
            <FormFieldGroup className='main-settings-fields'>
              <FieldSet className='fieldset-name'>
                <label htmlFor={`${fieldIdPrefix}-name`}>{t('name')}</label>
                <input
                  defaultValue={course?.name}
                  readOnly={fieldIsReadOnly('name')}
                  id={`${fieldIdPrefix}-name`}
                  name='name'
                  ref={register()}
                  type='text'
                />
                <FieldErrors errors={errors?.name} />
              </FieldSet>

              <FieldSet className='fieldset-courseCode'>
                <label htmlFor={`${fieldIdPrefix}-courseCode`}>{t('courseCode')}</label>
                <input
                  defaultValue={course?.course_code}
                  readOnly={fieldIsReadOnly('courseCode')}
                  id={`${fieldIdPrefix}-courseCode`}
                  name='courseCode'
                  ref={register()}
                  type='text'
                />
                <FieldErrors errors={errors?.courseCode} />
              </FieldSet>

              <FieldSet className='fieldset-accountId'>
                <label htmlFor={`${fieldIdPrefix}-accountId`}>{t('subAccount')}</label>
                <Controller
                  control={control}
                  defaultValue={course?.account_id}
                  name='accountId'
                  render={({ name, value, onBlur, onChange }) => (
                    <AccountSelect
                      allowCreate
                      defaultValue={value}
                      isDisabled={fieldIsReadOnly('accountId')}
                      id={`${fieldIdPrefix}-accountId`}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      onCreate={(tempAccountId: string) => setValue('accountId', tempAccountId)}
                      value={value}
                    />
                  )}
                />
                <FieldErrors errors={errors?.accountId} />
              </FieldSet>

              <FieldSet className='fieldset-termId'>
                <label htmlFor={`${fieldIdPrefix}-termId`}>{t('term')}</label>
                <Controller
                  control={control}
                  defaultValue={course?.term?.id}
                  name='termId'
                  render={({ name, onBlur, onChange, value }) => (
                    <TermSelect
                      allowCreate
                      defaultValue={value}
                      isDisabled={fieldIsReadOnly('termId')}
                      id={`${fieldIdPrefix}-termId`}
                      name={name}
                      onBlur={onBlur}
                      onChange={(selectedTermId: string) => {
                        syncDatesWithTerm(termItems?.[selectedTermId]);
                        onChange(selectedTermId);
                      }}
                      onCreate={(tempTermId: string, newTerm: EnrollmentTerm) => {
                        setValue('termId', tempTermId);
                        syncDatesWithTerm(newTerm);
                      }}
                      value={value}
                    />
                  )}
                />
                <FieldErrors errors={errors?.termId} />
              </FieldSet>

              <FieldSet className='fieldset-sisCourseId'>
                <label htmlFor={`${fieldIdPrefix}-sisCourseId`}>{t('sisId')}</label>
                <input
                  defaultValue={course?.sis_course_id}
                  readOnly={fieldIsReadOnly('sisCourseId')}
                  id={`${fieldIdPrefix}-sisCourseId`}
                  name='sisCourseId'
                  ref={register()}
                  type='text'
                />
                <FieldErrors errors={errors?.sisCourseId} />
              </FieldSet>

              <FieldSet className='fieldset-startAt'>
                <label htmlFor={`${fieldIdPrefix}-startAt`}>{t('startDate')}</label>
                <Controller
                  control={control}
                  defaultValue={course?.start_at}
                  name='startAt'
                  render={({ name, onBlur, onChange, value }) => (
                    <DatePicker
                      defaultValue={value}
                      readOnly={fieldIsReadOnly('startAt')}
                      id={`${fieldIdPrefix}-startAt`}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={t('whenever')}
                      value={value}
                    />
                  )}
                />
                <FieldErrors errors={errors?.startAt} />
              </FieldSet>

              <FieldSet className='fieldset-endAt'>
                <label htmlFor={`${fieldIdPrefix}-endAt`}>{t('endDate')}</label>
                <Controller
                  control={control}
                  defaultValue={course?.end_at}
                  name='endAt'
                  render={({ name, onBlur, onChange, value }) => (
                    <DatePicker
                      defaultValue={value}
                      readOnly={fieldIsReadOnly('endAt')}
                      id={`${fieldIdPrefix}-endAt`}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={t('whenever')}
                      value={value}
                    />
                  )}
                />
                <FieldErrors errors={errors?.endAt} />
              </FieldSet>
            </FormFieldGroup>

            {/* Settings */}

            <EnhancedAuthoringSettings
              show={hasAuthoringFeature}
              control={control}
              id={modalId}
              defaultValue={course?._meta?.courseAuthoringSettings}
            />

            <EnhancedNavSettings
              show={hasNavigationFeature}
              control={control}
              id={modalId}
              readOnlyNavSetting={fieldIsReadOnly('courseNavSettings.enabled')}
              readOnlyHomeMenu={!navIsChecked || fieldIsReadOnly('courseNavSettings.useHomeMenu')}
              defaultValue={course?._meta?.courseNavSettings}
            />            

            <EnhancedPageRatingSettings
              show={hasPageRatingFeature}
              control={control}
              id={modalId}
              defaultValue={course?._meta?.coursePageRatingSettings}
            />

            <FormFieldGroup className='tags-fields'>
              <h3>{t('tags')}</h3>
              <div className='course-tags-input-wrapper'>
                <Controller
                  name='tags'
                  control={control}
                  defaultValue={course?._meta?.tags || []}
                  render={({ value, name, onChange }: any) => (
                    <TagsInput
                      isDisabled={fieldIsReadOnly('tags')}
                      id={`${fieldIdPrefix}-tags`}
                      tags={tagsOptions}
                      onChange={onChange}
                      value={value}
                      name={name}
                    />
                  )}
                />
                <FieldErrors errors={errors?.tags} />
              </div>
            </FormFieldGroup>
          </ContentWrapper>

        </SectionContent>

        <SectionFooter>
          <div />
          <div>
            <Button
              color='secondary'
              onClick={handleClose}
              ariaLabel='Cancel'
              testId='course-settings-cancel-button'
            >
              {t('cancel')}
            </Button>
            <Button
              ariaLabel='Apply settings'
              testId='course-settings-submit-button'
              submitting={formState.isSubmitting}
              type='submit'
            >
              {t('apply')}
            </Button>
          </div>
        </SectionFooter>
      </form>
    </Modal>
  );
};

export default CourseSettingsModal;
