import { createSelector } from 'reselect';
import { values, filter, orderBy, maxBy } from 'lodash';

import { AppState } from 'store';
import { RequestsFeatureState, RequestState } from './reducer';
import { memoizeSelectorFactory } from 'store/selector-helpers';

export type PerRequestProps = { requestUuid: string };
export type PerRequestLabelProps = { label: string };

export const getRequestsFeature = (appState: AppState): RequestsFeatureState => appState?.requests;

export const getAllRequests = createSelector(
  getRequestsFeature,
  (featureState: RequestsFeatureState): RequestState[] => orderBy(
    values(featureState),
    ['startedAt'],
    ['asc']
  )
);

export const getRequestByUuid = memoizeSelectorFactory((props: PerRequestProps) => createSelector(
  getRequestsFeature,
  (featureState: RequestsFeatureState): RequestState => featureState?.[props?.requestUuid]
));

export const getRequestInProgressByUuid = memoizeSelectorFactory((props: PerRequestProps) => createSelector(
  getRequestByUuid(props),
  (request: RequestState): boolean => !!request?.inProgress
));

export const getRequestsByLabel = memoizeSelectorFactory((props: PerRequestLabelProps) => createSelector(
  getAllRequests,
  (requests: RequestState[]): RequestState[] => filter(requests, (r: RequestState) => r.label === props.label)
));

export const getLatestRequestByLabel = memoizeSelectorFactory((props: PerRequestLabelProps) => createSelector(
  getRequestsByLabel(props),
  (requestsWithLabel: RequestState[]): RequestState => maxBy(requestsWithLabel, 'startedAt')
));

export const getLatestRequestInProgressByLabel = memoizeSelectorFactory((props: PerRequestLabelProps) => createSelector(
  getLatestRequestByLabel(props),
  (request: RequestState): boolean => !!request?.inProgress
));

export const getLatestRequestIsCompleteByLabel = memoizeSelectorFactory((props: PerRequestLabelProps) => createSelector(
  getLatestRequestByLabel(props),
  (request: RequestState): boolean => !!request?.completedAt
));
