import React, { FC } from 'react';

import { SyncOrderStatus } from 'constants/index';
import Course from 'models/canvas/course';
import SnycOrderStatusIcon from 'components/elements/SyncOrderStatusIcon';
import { SyncStatusCellWrapper } from './style';

interface ISyncStatusCell {
  row: Course;
  value: SyncOrderStatus;
}

export const SyncStatusCell: FC<ISyncStatusCell> = ({ row, value }) => {
  return (
    <SyncStatusCellWrapper className='sync-status-cell-wrapper' aria-label={value}>
      <SnycOrderStatusIcon status={value} />
    </SyncStatusCellWrapper>
  );
};
