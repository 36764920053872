import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin: 0;

  table {
    border-collapse: collapse;
    margin: -2rem 0 2rem 0;

    caption {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }

    th, td {
      padding: 1rem 1.5rem;
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
        td {
          &:first-child {
            width: 30%;
            border-right: 1px solid #ccc;
          }
        }
      }
    }
  }
`;
