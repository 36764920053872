import { get } from 'lodash';
import CourseNavSettings from './courseNavSettings';
import CourseAuthoringSettings from './courseAuthoringSettings';
import CoursePageRatingSettings from './coursePageRatingSettings';

export default class DefaultBulkCourseSettings {
  courseNavSettings?: CourseNavSettings;
  courseAuthoringSettings?: CourseAuthoringSettings;
  coursePageRatingSettings?: CoursePageRatingSettings;

  constructor(json: any) {
    this.courseNavSettings = new CourseNavSettings(get(json, 'courseNavSettings'));
    this.courseAuthoringSettings = new CourseAuthoringSettings(get(json, 'courseAuthoringSettings'));
    this.coursePageRatingSettings = new CoursePageRatingSettings(get(json, 'coursePageRatingSettings'));
  }
}
