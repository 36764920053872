import axios from 'axios';
import { API } from 'config';

const api = (contentType = 'application/json') => {
  const customAxios = axios.create({
    baseURL: API.url,
    headers: {
      Accept: `${contentType}`,
      'Content-Type': `${contentType}`
    },
  });
  customAxios.defaults.withCredentials = true;
  return customAxios;
};

export default api;
