import { keyframes, css } from 'styled-components';

//  Pulse Animation
export const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 #ddd;
  }
`;

export const pulseAnimation = css`
  animation: ${pulse} .4s linear;
  box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
`;

// Ripple Animation
export const rippleAnimation = (radioRippleSize: string) => keyframes`
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }
  50% { 
    box-shadow: 0px 0px 0px ${radioRippleSize} rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px ${radioRippleSize} rgba(0, 0, 0, 0);
  }
}
`;
