import SyncItemResult from './syncItemResult';

abstract class BaseSyncItemEntity {
  syncOrderId: string;
  createdAt?: Date;
  syncItemResult: SyncItemResult;

  protected constructor(json: any) {
    this.syncItemResult = new SyncItemResult(json?.syncItemResult);
    this.syncOrderId = json?.syncOrderId;
    this.createdAt = json?.createdAt ? new Date(json.createdAt) : null;
  }
}

export default BaseSyncItemEntity;
