import { maxBy, parseInt } from 'lodash';
import { RefObject, useEffect, useCallback } from 'react';
import { ColumnDef } from '../types';
import { KeyCodesTypes } from 'constants/index';
import { TABLE_BODY_VIEWPORT_CLASSNAME, CENTER_COLUMNS_CLIPPER_CLASSNAME, TABLE_CELL_CLASSNAME, BODY_CELL_CLASSNAME, HEADER_CELL_CLASSNAME } from '../components/TableBody';
// import { USER_AGENT } from 'config';

const FOCUSABLE_SELECTOR = `
  button:not([tabindex="-1"]),
  input:not([tabindex="-1"]),
  select:not([tabindex="-1"]),
  a[href]:not([tabindex="-1"]),
  textarea:not([tabindex="-1"]),
  [tabindex="0"]
`;

// const IS_MAC = USER_AGENT.isMac;
export const EXTRA_EVENT_KEY: keyof KeyboardEvent = 'altKey';
const SCROLL_FOCUS_DELAY_MS: number = 700;

export const useDataTableKeyboardNav = (tableId: string, columnDefs: ColumnDef[], containerRef: RefObject<HTMLDivElement>) => {
  /**
   * HELPER FUNCTIONS
   */

  const getBodyViewport = useCallback((): HTMLDivElement => {
    return containerRef.current.querySelector(`.${TABLE_BODY_VIEWPORT_CLASSNAME}`);
  }, [containerRef]);

  const getCenterColumnsClipper = useCallback((): HTMLDivElement => {
    return containerRef.current.querySelector(`.${CENTER_COLUMNS_CLIPPER_CLASSNAME}`);
  }, [containerRef]);

  const getUpCell = useCallback((ariaRow: number, ariaColumn: number) => {
    return containerRef.current.querySelector(`.${TABLE_CELL_CLASSNAME}[aria-rowindex="${ariaRow - 1}"][aria-colindex="${ariaColumn}"]`);
  }, [containerRef]);

  const getDownCell = useCallback((ariaRow: number, ariaColumn: number, isHeaderCell: boolean) => {
    if (isHeaderCell) {
      return document.querySelector(`.${BODY_CELL_CLASSNAME}[aria-colindex="${ariaColumn}"]`); // get first rendered body row
    }
    return containerRef.current.querySelector(`.${TABLE_CELL_CLASSNAME}[aria-rowindex="${ariaRow + 1}"][aria-colindex="${ariaColumn}"]`);
  }, [containerRef]);

  const getLeftCell = useCallback((ariaRow: number, ariaColumn: number) => {
    let leftEl: HTMLDivElement;
    // Try to go to the previous column, trying indices until a visible/rendered column is found
    for (let i = ariaColumn - 1; i >= 0; i -= 1) {
      leftEl = containerRef.current.querySelector(`.${TABLE_CELL_CLASSNAME}[aria-rowindex="${ariaRow}"][aria-colindex="${i}"]`);
      if (leftEl) { break; }
    }
    return leftEl;
  }, [containerRef]);

  const getRightCell = useCallback((ariaRow: number, ariaColumn: number) => {
    let rightEl: HTMLDivElement;
    // Try to go to the next column, trying indices until a visible/rendered column is found
    for (let i = ariaColumn + 1; i <= columnDefs.length + 1; i += 1) {
      rightEl = containerRef.current.querySelector(`.${TABLE_CELL_CLASSNAME}[aria-rowindex="${ariaRow}"][aria-colindex="${i}"]`);
      if (rightEl) { break; }
    }
    return rightEl;
  }, [containerRef, columnDefs]);

  const getLeftmostCell = useCallback((ariaRow: number) => {
    return containerRef.current.querySelector(`.${TABLE_CELL_CLASSNAME}[aria-rowindex="${ariaRow}"]`);
  }, [containerRef]);

  const getRightmostCell = useCallback((ariaRow: number) => {
    return maxBy(
      containerRef.current.querySelectorAll(`.${TABLE_CELL_CLASSNAME}[aria-rowindex="${ariaRow}"]`),
      (el: HTMLDivElement) => parseInt(el.getAttribute('aria-colindex'), 10)
    );
  }, [containerRef]);

  const getTopmostRenderedCell = useCallback((ariaColumn: number) => {
    return containerRef.current.querySelector(`.${BODY_CELL_CLASSNAME}[aria-colindex="${ariaColumn}"]`);
  }, [containerRef]);

  const getBottommostRenderedCell = useCallback((ariaColumn: number) => {
    return maxBy(
      containerRef.current.querySelectorAll(`.${BODY_CELL_CLASSNAME}[aria-colindex="${ariaColumn}"]`),
      (el: HTMLDivElement) => parseInt(el.getAttribute('aria-rowindex'), 10)
    );
  }, [containerRef]);

  const getTopmostVisibleCell = useCallback((ariaColumn: number) => {
    try {
      let topmostVisibleCell: HTMLDivElement;
      const bodyViewport = getBodyViewport();
      const bodyViewportRect = bodyViewport.getBoundingClientRect();
      const bodyViewportTop = bodyViewportRect.top;
      const columnCells: NodeListOf<HTMLDivElement> = containerRef.current.querySelectorAll(`.${BODY_CELL_CLASSNAME}[aria-colindex="${ariaColumn}"]`);
      for (let i = 0; i < columnCells.length; i += 1) {
        const cell: HTMLDivElement = columnCells[i];
        if (cell.getBoundingClientRect().top > bodyViewportTop) {
          topmostVisibleCell = cell;
          break;
        }
      }
      return topmostVisibleCell;
    } catch (e) {
      return null;
    }
  }, [containerRef, getBodyViewport]);

  const getFirstFocusableEl = useCallback((cell: HTMLDivElement): HTMLElement => {
    return cell.querySelector(FOCUSABLE_SELECTOR);
  }, []);

  const scrollToBottom = useCallback(() => {
    const bodyViewport = getBodyViewport();
    const centerColsClipper = getCenterColumnsClipper();
    bodyViewport.scrollTop = centerColsClipper ? centerColsClipper.scrollHeight : bodyViewport.scrollHeight;
  }, [getBodyViewport, getCenterColumnsClipper]);

  const scrollToTop = useCallback(() => {
    const bodyViewport = getBodyViewport();
    bodyViewport.scrollTop = 0;
  }, [getBodyViewport]);

  const scrollOnePage = useCallback((dir: 'up'|'down') => {
    const bodyViewport = getBodyViewport();
    const delta = dir === 'up' ? 50 - bodyViewport.clientHeight : bodyViewport.clientHeight - 50;
    bodyViewport.scrollTop += delta;
  }, [getBodyViewport]);

  /**
   * Main Key event handler
   */
  const keydownHandler = useCallback((e: KeyboardEvent) => {
    const tableHasFocus = containerRef.current.contains(document.activeElement);
    const closestCell: HTMLDivElement = document.activeElement.closest(`.${TABLE_CELL_CLASSNAME}`);

    // only apply these keyboard handlers if a table cell or something inside a table cell has focus
    if (!tableHasFocus || !closestCell) {
      return;
    }

    // get info about the closest cell element
    const cellElementIsFocused = closestCell === document.activeElement; // i.e., not something inside the cell
    const isHeaderCell = closestCell.classList.contains(HEADER_CELL_CLASSNAME);
    const ariaRow = parseInt(closestCell.getAttribute('aria-rowindex'), 10);
    const ariaColumn = parseInt(closestCell.getAttribute('aria-colindex'), 10);

    // determine focus based on which key was pressed
    let nextFocusElem: HTMLElement | (() => HTMLElement);
    let focusDelay: number = 0;

    switch (e.keyCode) {
      case KeyCodesTypes.ARROW_UP: {
        e.preventDefault();
        if (e[EXTRA_EVENT_KEY]) {
          scrollToTop();
          focusDelay = SCROLL_FOCUS_DELAY_MS;
          nextFocusElem = () => getTopmostRenderedCell(ariaColumn);
        } else {
          nextFocusElem = getUpCell(ariaRow, ariaColumn);
        }
        break;
      }

      case KeyCodesTypes.ARROW_DOWN: {
        e.preventDefault();
        if (e[EXTRA_EVENT_KEY]) {
          scrollToBottom();
          focusDelay = SCROLL_FOCUS_DELAY_MS;
          nextFocusElem = () => getBottommostRenderedCell(ariaColumn);
        } else {
          nextFocusElem = getDownCell(ariaRow, ariaColumn, isHeaderCell);
        }
        break;
      }

      case KeyCodesTypes.ARROW_LEFT: {
        e.preventDefault();
        nextFocusElem = e[EXTRA_EVENT_KEY] ? getLeftmostCell(ariaRow) : getLeftCell(ariaRow, ariaColumn);
        break;
      }

      case KeyCodesTypes.ARROW_RIGHT: {
        e.preventDefault();
        nextFocusElem = e[EXTRA_EVENT_KEY] ? getRightmostCell(ariaRow) : getRightCell(ariaRow, ariaColumn);
        break;
      }

      case KeyCodesTypes.ENTER: {
        if (cellElementIsFocused) {
          e.preventDefault();
          nextFocusElem = getFirstFocusableEl(closestCell);
        }
        break;
      }

      case KeyCodesTypes.ESCAPE: {
        e.preventDefault();
        nextFocusElem = closestCell;
        break;
      }

      case KeyCodesTypes.HOME: {
        e.preventDefault();
        nextFocusElem = getLeftmostCell(ariaRow);
        break;
      }

      case KeyCodesTypes.END: {
        e.preventDefault();
        nextFocusElem = getRightmostCell(ariaRow);
        break;
      }

      case KeyCodesTypes.PAGE_DOWN: {
        e.preventDefault();
        scrollOnePage('down');
        focusDelay = SCROLL_FOCUS_DELAY_MS;
        nextFocusElem = () => getTopmostVisibleCell(ariaColumn);
        break;
      }

      case KeyCodesTypes.PAGE_UP: {
        e.preventDefault();
        scrollOnePage('up');
        focusDelay = SCROLL_FOCUS_DELAY_MS;
        nextFocusElem = () => getTopmostVisibleCell(ariaColumn);
        break;
      }

      default:
    }

    setTimeout(() => {
      if (typeof nextFocusElem === 'function') {
        const el = nextFocusElem();
        el && el.focus();
      } else if (nextFocusElem) {
        (nextFocusElem as HTMLElement).focus();
      }
    }, focusDelay);
  }, [
    containerRef,
    getUpCell,
    getDownCell,
    getLeftCell,
    getRightCell,
    getLeftmostCell,
    getRightmostCell,
    getTopmostRenderedCell,
    getBottommostRenderedCell,
    getTopmostVisibleCell,
    getFirstFocusableEl,
    scrollToBottom,
    scrollToTop,
    scrollOnePage
  ]);

  /**
   * Effect to add the event listener
   */
  useEffect(() => {
    window.addEventListener('keydown', keydownHandler);
    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [keydownHandler]);
};
