import PageRatingJobStatus from 'models/pageRatingReport/pageRatingJobStatus';
import PageRatingReport from 'models/pageRatingReport/pageRatingReport';
import { PageRatingReportAction, PageRatingReportActionTypes } from './actions';

export interface PageRatingReportFeatureState {
  reportStatus: PageRatingJobStatus,
  report: PageRatingReport
}

export const defaultPageRatingReportState: PageRatingReportFeatureState = {
  reportStatus: null,
  report: null
};

export default function pageRatingReportReducer(
  state: PageRatingReportFeatureState = defaultPageRatingReportState,
  action: PageRatingReportAction
): PageRatingReportFeatureState {
  switch (action.type) {
    case PageRatingReportActionTypes.CREATE_REPORT:
      return {
        ...state,
        report: null
      };
    case PageRatingReportActionTypes.FETCH_STATUS:
      return {
        ...state,
      };
    case PageRatingReportActionTypes.RECEIVE_STATUS:
      return {
        ...state,
        reportStatus: action.payload.reportStatus,
      };
    case PageRatingReportActionTypes.DOWNLOAD_REPORT:
      return {
        ...state,
        report: null
      };
    case PageRatingReportActionTypes.RECEIVE_REPORT:
      return {
        ...state,
        reportStatus: {...state.reportStatus, reportWasDownloaded: true},
        report: action.payload.report
      };
    case PageRatingReportActionTypes.RESET_STATUS:
      return {
        ...defaultPageRatingReportState
      };
    default:
      return state;
  }
}
