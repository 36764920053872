import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Switch, Route, Redirect, generatePath } from 'react-router-dom';

import { RequestLabel } from 'constants/index';
import { RoutePathnames } from 'config/routePathnames';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getTopAccountId } from 'store/installation/selectors';
import { getLatestRequestInProgressByLabel } from 'store/requests/selectors';

import { AppMenuBar } from 'components/AppMenuBar';
import { FunctionMenuBar } from 'components/FunctionMenuBar';
import { AccountsCoursesManager } from 'components/AccountsCoursesManager';
import { SyncStatusManager } from 'components/SyncStatusManager';
import { CustomDragLayer } from 'components/CustomDragLayer';
import Loading from 'components/elements/Loading';
import Modals from 'components/Modals';

import { LayoutContainer, HeaderRegion, LoadingScreen, MainRegion, ToastCurtain } from './style';

interface ILayout {}

export const Layout: FC<ILayout> = memo(() => {
  const { t } = useTranslation();
  const loadingAppData: boolean = useSelectorWithProps(
    getLatestRequestInProgressByLabel,
    { label: RequestLabel.FETCH_INSTALLATION_DATA }
  );

  const topAccountId = useSelector(getTopAccountId);

  return (
    <>
      <h1 style={{ visibility: 'hidden', height: 0, margin: 0 }}>CourseBuilder</h1>
      <LayoutContainer loading={loadingAppData ? 1 : 0} className='layout-container'>
        <LoadingScreen loading={loadingAppData ? 1 : 0} tabIndex={-1} className='loading-screen'>
          <Loading message={`${t('loading')}...`} />
        </LoadingScreen>
        <HeaderRegion className='header-region'>
          <Switch>
            <Route
              path={[
                RoutePathnames.ACCOUNT_COURSES,
                RoutePathnames.ACCOUNTS,
                RoutePathnames.SYNC_STATUS,
                '*'
              ]}
              render={({ match }) => (
                <>
                  <AppMenuBar match={match} />
                  <FunctionMenuBar match={match} />
                </>
              )}
            />
          </Switch>
        </HeaderRegion>
        <MainRegion className='main-region'>
          {topAccountId && (
            <Switch>
              <Route exact path={RoutePathnames.HOME}>
                <Redirect path={RoutePathnames.HOME} to={generatePath(RoutePathnames.ACCOUNT_COURSES, { accountId: topAccountId })} />
              </Route>
              <Route exact path={RoutePathnames.ACCOUNTS} component={AccountsCoursesManager} />
              <Route exact path={RoutePathnames.ACCOUNT_COURSES} component={AccountsCoursesManager} />
              <Route exact path={RoutePathnames.SYNC_STATUS} component={SyncStatusManager} />
            </Switch>
          )}
        </MainRegion>
      </LayoutContainer>

      <CustomDragLayer />

      <ToastContainer />

      <ToastCurtain id='toast-curtain' />

      <Modals />
    </>
  );
});
