import { createGlobalStyle } from 'styled-components';
import { Popovers } from './popovers';
import { Toasts } from './toastsStyles';
import { Tabs } from './tabs';

/* eslint no-unused-expressions: 0 */
const GlobalStyle = createGlobalStyle`
  html{
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: 'Lato', 'sans-serif';
    color: #222;
  }
  *, *:before, *:after {
    /* outline:none; */
    text-decoration: none;
    box-sizing: border-box;
  }
  html, body {
    height: 100vh;
    width: 100vw;
  }
  body{
    margin:0;
    font-size: 1.4rem;
  }
  main{display:block}
  h1{font-size:2em;margin:.67em 0}
  hr{box-sizing:content-box;height:0;overflow:visible}
  pre{font-family:monospace,monospace;font-size:1em}
  a {
    background-color:transparent;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
  abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}
  b,strong{font-weight:bolder}
  code,kbd,samp{font-family:monospace,monospace;font-size:1em}
  small{font-size:80%}
  sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
  sub{bottom:-.25em}
  sup{top:-.5em}
  img{border-style:none}
  button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}
  button,input{overflow:visible}
  button,select{text-transform:none}
  [type=button],[type=reset],[type=submit],button{-webkit-appearance:button}
  [type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}
  [type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}
  fieldset{padding:.35em .75em .625em}
  legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}
  progress{vertical-align:baseline}
  textarea{overflow:auto}
  [type=checkbox],[type=radio]{box-sizing:border-box;padding:0}
  [type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
  [type=search]{-webkit-appearance:textfield;outline-offset:-2px}
  [type=search]::-webkit-search-decoration{-webkit-appearance:none}
  ::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
  details{display:block}
  summary{display:list-item}
  template{display:none}
  [hidden]{display:none}
  ul, li{list-style: none; padding: 0; margin: 0;}

  label {
    color: ${({ theme }) => theme.color.gray1};
    font: 400 1.2rem/1.2rem ${({ theme }) => theme.font.main};
    margin: 0 8px 4px 0;
  }
  input, select {
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.gray4};
    width: 100%;
    min-height: 2.5rem;
    padding: 2px 5px;

    &[type=datetime-local] {
      font-size: 1.3rem;
    }
  }

.toggle ~ div.collapsibleSubAccounts {
  height: auto;
  width: auto;
}
.collapsibleSubAccounts {
  height: auto;
  overflow: hidden;
  transition: height 0.25s ease 0s;
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 80%;
  margin: auto;
  opacity: 1;
  color: #000;
  display: flex;
  height: 100%;
  margin-bottom: 2px;
  position: relative;
  transition: all .4s ease;
  width: auto;
  padding-right:16px;

  .accountDescription{
    line-height: 19px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .TechnologyPresets{
    font-size: 17px;
    margin-top: 4px;
  }
}


.list {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-bottom: 2px;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  width: 100%;
}

.collapsibleSubAccounts .selection-checkbox{
  margin-left:48px
}
.switchLabel{
  display: inline-block;
  font-size: 1.4rem;
  color: #000;
}
.switchContainer{
  display: flex;
  align-items: center;
  margin: 8px 0px;
}

//

  ${Toasts}

  ${Popovers}

  ${Tabs}
`;

export default GlobalStyle;
