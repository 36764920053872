import { get } from 'lodash';

export default class SyncItemResult {
  syncStatus: string;
  message: string;
  code: string;
  errorsCount: number;
  timestamp: Date;

  constructor(json: any) {
    this.syncStatus = get(json, 'syncStatus');
    this.message = get(json, 'message');
    this.code = get(json, 'code');
    this.errorsCount = get(json, 'errorsCount');
    this.timestamp = get(json, 'timestamp') ? new Date(get(json, 'timestamp')) : null;
  }
}
