import { css } from 'styled-components';

export const Toasts = css`
  body {

    /** Used to define container behavior: width, position: fixed etc... **/
    .Toastify__toast-container {
      width: auto;
    }

    /** Used to define the position of the ToastContainer **/
    .Toastify__toast-container--top-left {}

    .Toastify__toast-container--top-center {}

    .Toastify__toast-container--top-right {}

    .Toastify__toast-container--bottom-left {}

    .Toastify__toast-container--bottom-center {}

    .Toastify__toast-container--bottom-right {}

    /** Classes for the displayed toast **/
    .Toastify__toast {
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
      width: 350px;
      min-height: 50px;
      padding: 15px;

      .buttons-container {
        justify-content: flex-start;
        margin-top: .7rem;
        .custom-toast-button {
          margin: 0;
        }
      }
      &.confirm-toast, &.large-toast{
        width: 500px;
        padding: 2rem;
        .buttons-container {
          justify-content: flex-end;
          margin-top: 2rem;
          .custom-toast-button {
            margin: 0 0 0 1em;
          }
        }
      }
      &.large-toast{
        max-width: 800px;
        width: 100vw !important;
        height: auto;
        min-height: 25vh;
        padding: 0;
        .content-container {
          max-height: 360px;
          overflow: auto;
          padding: 0 2rem; 
        }
        .buttons-container {
          padding: 2rem;
        }
      }

    }

    .Toastify__toast--rtl {}

    .Toastify__toast--dark {
      background: ${({ theme }) => theme.color.gray1};
    }

    .Toastify__toast--default {
      background: ${({ theme }) => theme.color.gray8};
      color: ${({ theme }) => theme.color.font};
    }

    .Toastify__toast--info {
      background: ${({ theme }) => theme.color.mainDarkActive};
    }

    .Toastify__toast--success {
      background: ${({ theme }) => theme.color.green};
    }

    .Toastify__toast--warning {
      background: ${({ theme }) => theme.color.lightYellow};
      color: ${({ theme }) => theme.color.font};
      .Toastify__progress-bar {
        background-color: ${({ theme }) => theme.color.font};
      }
    }

    .Toastify__toast--error {
      background: ${({ theme }) => theme.color.red};
    }

    .Toastify__toast-body {}

    /** Classes for the close button. Better use your own closeButton **/
    .Toastify__close-button {}

    .Toastify__close-button--default {}

    .Toastify__close-button>svg {}

    .Toastify__close-button:hover,
    .Toastify__close-button:focus {}

    /** Classes for the progress bar **/
    .Toastify__progress-bar {
      height: 1px;
    }

    .Toastify__progress-bar--animated {}

    .Toastify__progress-bar--controlled {}

    .Toastify__progress-bar--rtl {}

    .Toastify__progress-bar--default {}

    .Toastify__progress-bar--dark {}

    @keyframes customZoomIn {
      from {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
      }

      50% {
        opacity: 1;
      }
    }

    @keyframes customZoomOut {
      from {
        opacity: 1;
      }

      50% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
      }

      to {
        opacity: 0;
      }
    }
    
    .customZoomIn {
      animation-name: customZoomIn;
    }

    .customZoomOut {
      animation-name: customZoomOut;
    }
    
  }
  
`;
