import { of, concat, from, Observable, EMPTY } from 'rxjs';
import { mergeMap, switchMap, catchError, withLatestFrom, delay } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import { get, map as _map, some, filter as _filter } from 'lodash';

import { requestStart, requestSuccess, requestError } from 'store/requests/actions';
import { receiveCourses, receiveOriginalCourses } from 'store/courses/actions';

import { AppState } from 'store';
import AccountDto from 'models/dto/accountDto';
import AccountChildrenDto from 'models/dto/accountChildrenDto';
import CourseDto from 'models/dto/courseDto';
import Course from 'models/canvas/course';
import Account from 'models/canvas/account';
import * as accountsApi from './api';
import { AccountsActionTypes, FetchAccountAction, receiveAccounts, FetchAccountChildrenAction,
  FetchAccountSubAccountsAction, ReceiveAccountsAction, deleteAccounts, receiveOriginalAccounts } from './actions';
import { accountExistsInLmsAlready } from './selectors';
import { getNewAccountsToSync } from 'store/general/cross-selectors';
import { RequestLabel } from 'constants/index';

export const fetchAccountEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(AccountsActionTypes.FETCH_ACCOUNT),
  mergeMap((action: FetchAccountAction) => {
    const label = RequestLabel.FETCH_ACCOUNT(action.payload.accountId);
    const start = requestStart(label);
    const { requestUuid } = start.payload;
    return concat(
      of(start),
      from(accountsApi.fetchAccount(action.payload.accountId)).pipe(
        switchMap((accountDto: AccountDto) => concat(
          of(receiveOriginalAccounts([accountDto.toAccount()])),
          of(receiveAccounts([accountDto.toAccount()])),
          of(requestSuccess(requestUuid))
        )),
        catchError((error: Error) => of(requestError(requestUuid, error)))
      )
    );
  })
);

export const fetchAccountChildrenEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(AccountsActionTypes.FETCH_ACCOUNT_CHILDREN),
  mergeMap((action: FetchAccountChildrenAction) => {
    const label = RequestLabel.FETCH_ACCOUNT_CHILDREN(action.payload.accountId);
    const start = requestStart(label, action.payload.requestUuid);
    const { requestUuid } = start.payload;
    return concat(
      of(start),
      from(accountsApi.fetchAccountChildren(action.payload.accountId)).pipe(
        switchMap((children: AccountChildrenDto) => {
          const accounts: Account[] = get(children, 'accounts', []).map((dto: AccountDto) => dto.toAccount());
          const courses: Course[] = get(children, 'courses', []).map((dto: CourseDto) => dto.toCourse());
          return concat(
            of(receiveAccounts(accounts)),
            of(receiveOriginalAccounts(accounts)),
            of(receiveCourses(courses)),
            of(receiveOriginalCourses(courses)),
            of(requestSuccess(requestUuid))
          );
        }),
        catchError((error: Error) => of(requestError(requestUuid, error)))
      )
    );
  })
);

export const fetchAccountSubAccountsEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(AccountsActionTypes.FETCH_ACCOUNT_SUB_ACCOUNTS),
  mergeMap((action: FetchAccountSubAccountsAction) => {
    const label = RequestLabel.FETCH_ACCOUNT_SUB_ACCOUNTS(action.payload.accountId);
    const start = requestStart(label, action.payload.requestUuid);
    const { requestUuid } = start.payload;
    return concat(
      of(start),
      from(accountsApi.fetchAccountSubAccounts(action.payload.accountId)).pipe(
        switchMap((subAccounts: AccountDto[]) => {
          const accounts: Account[] = _map(subAccounts, (dto: AccountDto) => dto.toAccount());
          return concat(
            of(receiveAccounts(accounts)),
            of(receiveOriginalAccounts(accounts)),
            of(requestSuccess(requestUuid))
          );
        }),
        catchError((error: Error) => of(requestError(requestUuid, error)))
      )
    );
  })
);

// As we receive new accounts that have been successfully created, delete the associated temp accounts from the store
export const receiveAccountsEpic$ = (actions$: Observable<any>, state$: StateObservable<AppState>) => actions$.pipe(
  ofType(AccountsActionTypes.RECEIVE_ACCOUNTS),
  withLatestFrom(state$),
  mergeMap(([action, appState]: [ReceiveAccountsAction, AppState]) => {
    const tempAccounts = getNewAccountsToSync(appState);
    const existingReceivedAccounts = _filter(action.payload.accounts, accountExistsInLmsAlready);
    const tempsToDelete = _filter(
      tempAccounts,
      (tempAccount: Account) => {
        return some(existingReceivedAccounts, (a: Account) => tempAccount.id === get(a, '_meta.tempAccountId'));
      }
    );
    return get(tempsToDelete, 'length', 0) > 0
      ? of(deleteAccounts(_map(tempsToDelete, 'id'))).pipe(delay(200))
      : EMPTY;
  })
);
