import React, { FC, memo, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTopAccountId } from 'store/installation/selectors';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getLatestRequestInProgressByLabel } from 'store/requests/selectors';
import { getIsAccountsView } from 'store/router/selectors';
import { AccountFilter } from './AccountFilter';
import { AccountTreeItem } from './AccountTreeItem';
import { AccountDetailsPanel } from './AccountDetailsPanel';

import { Button, Loading } from 'components/elements';
import { HiddenKeyboardNav } from 'components/elements/HiddenKeyboardNav';

import { TreeContainer, AccountTreeContent } from './style';
import { usePrevious } from 'hooks/usePrevious';
import { RequestLabel } from 'constants/index';

interface IAccountTree {}

export const skipButtonId = 'skip-accounts-tree-button';
export const topButtonId = 'accounts-tree-top-button';

export const AccountTree: FC<IAccountTree> = memo(() => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>();
  const topAccountId = useSelector(getTopAccountId);
  const isAccountsView = useSelector(getIsAccountsView);
  const prevAccountsView = usePrevious(isAccountsView);
  const justSwitchedToAccountView = !prevAccountsView && isAccountsView;

  const [showPanel, setShowPanel] = useState(isAccountsView);

  const fetchingInitialData = useSelectorWithProps(
    getLatestRequestInProgressByLabel,
    { label: RequestLabel.FETCH_INSTALLATION_DATA }
  );

  const shouldShowLoading: boolean = fetchingInitialData || !topAccountId;

  // Show the view after a delay to avoid UI lag on route change
  useEffect(() => {
    let timer: number;

    if (justSwitchedToAccountView) {
      timer = window.setTimeout(() => {
        setShowPanel(true);
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [justSwitchedToAccountView, setShowPanel]);

  // Hides the account data panel
  useEffect(() => {
    if (!isAccountsView) {
      setShowPanel(false);
    }
  }, [isAccountsView, setShowPanel]);

  return (
    <TreeContainer
      className='account-tree-container'
      fullAccountView={isAccountsView}
      ref={containerRef}
      role='region'
      aria-label='Sub-accounts tree browser'
    >
      {shouldShowLoading && (
        <Loading message={`${t('loading')} ${t('subAccount_plural')}`} />
      )}

      {!shouldShowLoading && (
        <>
          <AccountTreeContent className='account-tree-content'>
            <header className='account-tree-header'>
              <h2>{t('account_plural')}</h2>
              <HiddenKeyboardNav>
                <Button as='a' href={`#${topButtonId}`} size='small' variant='link' id={skipButtonId}>
                  {t('skipToBottom')}
                </Button>
              </HiddenKeyboardNav>

              <AccountFilter />
            </header>
            <ul className='account-tree'>
              <AccountTreeItem accountId={topAccountId} depthLevel={0} />
            </ul>
            <HiddenKeyboardNav>
              <Button as='a' href={`#${skipButtonId}`} size='small' variant='link' id={topButtonId} aria-label='Back to Top'>
                {t('top')}
              </Button>
            </HiddenKeyboardNav>
          </AccountTreeContent>

          <AccountDetailsPanel show={showPanel} />
        </>
      )}
    </TreeContainer>
  );
});
