import React, { useCallback, SyntheticEvent } from 'react';
import { ModalProvider } from 'styled-react-modal';
import FocusLock from 'react-focus-lock';
import { get } from 'lodash';

import { useModalState } from 'hooks/useModalState';

import { Wrapper, Background } from './style';

interface IModalProps {
  children: any;
  handleClose: any;
  id: string;
  size?: string;
}

const Modal: React.FC<IModalProps> = ({ children, id, handleClose, size = 'medium' }) => {
  const modalState = useModalState(id);
  const isOpen = !!get(modalState, 'open');

  const handleBackgroundClick = useCallback((e: SyntheticEvent<Element, MouseEvent>) => {
    // Do not close modal. Prefer closing with provided action/cancel buttons.
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <ModalProvider backgroundComponent={Background}>
      <Wrapper
        isOpen
        onBackgroundClick={handleBackgroundClick}
        data-testid='modal-component'
        // @ts-ignore
        id={`modal-${id}`}
        role='dialog'
        aria-labelledby={`modal-${id}-title`}
        size={size}
      >
        <FocusLock returnFocus>
          {isOpen ? children : null}
        </FocusLock>
      </Wrapper>
    </ModalProvider>
  );
};

export default Modal;
