import { AxiosResponse } from 'axios';
import { map, get } from 'lodash';
import api from 'api';
import mockApi from 'api/mock';

import { API } from 'config';

import CourseTemplate from 'models/courseTemplate';

const customApi = API.useMock ? mockApi() : api();

export interface Templates {
  templates: CourseTemplate[]
}

export async function fetchTemplates(): Promise<Templates> {
  return customApi.get('/api/templates')
    .then((resp: AxiosResponse<Templates>) => {
      const data = get(resp, 'data', {});
      return {
        templates: map(data.templates, (accJson: CourseTemplate) => new CourseTemplate(accJson)),
      };
    });
}
