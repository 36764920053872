import React, { FC } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  SyncOrderStatusUnsynced,
  SyncOrderStatusPending,
  SyncOrderStatusActive,
  SyncOrderStatus
} from 'constants/index';

import { Icon } from './style';

interface ISyncOrderStatusIcon {
  status: SyncOrderStatus;
}

const SyncOrderStatusIcon: FC<ISyncOrderStatusIcon> = ({ status }) => {
  const renderIcon = () => {
    switch (status) {
      case SyncOrderStatusUnsynced.UNSYNCED:
        return (
          <FontAwesomeIcon
            className={cn('status-icon status-icon-unsynced')}
            icon={['fas', 'sync-alt']}
          />
        );
      case SyncOrderStatusActive.RUNNING:
        return (
          <FontAwesomeIcon
            className={cn('status-icon status-icon-running')}
            icon={['fas', 'sync-alt']}
            spin
          />
        );
      case SyncOrderStatusPending.PENDING:
        return (
          <FontAwesomeIcon
            className={cn('status-icon status-icon-pending')}
            icon={['fas', 'sync-alt']}
          />
        );
      default: break;
    }
  };

  return (
    <Icon>
      { renderIcon() }
    </Icon>
  );
};

export default SyncOrderStatusIcon;
