import { get, map } from 'lodash';
import Installation from 'models/installation';
import CourseTemplate from 'models/courseTemplate';
import CourseBuilderInstallationConfig from 'models/courseBuilderInstallationConfig';
import AccountDto from './accountDto';

export default class InstallationDto {
  installation: Installation;
  courseTemplates: CourseTemplate[];
  topAccount: AccountDto;
  subAccounts: AccountDto[];
  config: CourseBuilderInstallationConfig;
  lmsBaseUrl: string;

  constructor(json: any) {
    this.installation = new Installation(get(json, 'installation'));
    this.courseTemplates = map(
      get(json, 'courseTemplates'),
      (courseTemplateJson: any) => new CourseTemplate(courseTemplateJson)
    );
    this.topAccount = new AccountDto(get(json, 'topAccount'));
    this.subAccounts = map(
      get(json, 'subAccounts'),
      (accountDtoJson: any) => new AccountDto(accountDtoJson)
    );
    this.config = new CourseBuilderInstallationConfig(get(json, 'config'));
    this.lmsBaseUrl = get(json, 'lmsBaseUrl');
  }
}
