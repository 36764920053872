/**
 * Status returned from CB backend when creating or fetching status for a PageRatingJob
 */
export default class PageRatingJobStatus {
  /** Id generated by CB backend when pageRating Report Job is triggred */
  jobId: string;
  /** Status of report job returned by CB backend: 
   * success, error, running, pending, processing, cncelling, cancelled, complete, incomplete */
  status?: string;
  /** Boolean flag to represent if file has been downloaded previously:
   * if download has been triggered downloadedDate will be populated with a date record,
   * if not it will be null */
  reportWasDownloaded: boolean;
  /** Message from CB backend if error is encountered while processing report job */
  errorMessage?: string;

  constructor(json: any) {
    this.jobId = json?.jobId;
    this.status = json?.status;
    this.reportWasDownloaded = !!json?.downloadedDate;
    this.errorMessage = json?.errorMessage;
  }
}
