import styled, { css } from 'styled-components';

interface IFilter {
  hasResults: boolean
}

export const Filter = styled.div<IFilter>`
  ${({ theme, hasResults }) => css`
    .account-filter-input {
      max-width: 500px;
      position: relative;
      width: 100%;

      input {
        background: ${theme.color.white};
        border-radius: 4px;
        border: 2px solid ${theme.color.gray5};
        color: ${theme.color.font};
        display: block;
        font: 1.4rem/2.5rem ${theme.font.main};
        height: 30px;
        padding: 0 32px;
        transition: all .4s ease;
        width: 100%;

        &:focus {
          border: 2px solid ${theme.color.mainDark};
        }
      }

      .account-filter-icon,
      .account-filter-reset {
        align-items: center;
        display: flex;
        height: 100%;
        position: absolute;
        top: 0;
        transition: all .4s ease;
      }
      .account-filter-icon {
        color: ${theme.color.gray5};
        left: 10px;
      }
      .account-filter-reset {
        background: transparent;
        border: 0;
        color: ${theme.color.gray5};
        cursor: pointer;
        right: 8px;

        &:hover,
        &:focus {
          color: ${theme.color.mainDark};
        }
      }
    }

    .account-filter-list {
      margin-top: ${hasResults ? '20px' : 0};
      overflow: hidden;
    }

    .account-filter-result {
      background: ${theme.color.white};
      border-bottom: 2px solid ${theme.color.gray5};
      border-left: 3px solid ${theme.color.gray4};
      color: ${theme.color.font};
      cursor: pointer;
      padding: 4px 8px;
      transition: all .4s ease;

      &:last-child {
        border-bottom: none;
      }

      &:hover,
      &:focus {
        background: #f5f5fb;
        border-left: 3px solid ${theme.color.mainDark};
        color: ${theme.color.mainDark};
      }

      small {
        font-style: italic;
      }

      p {
        margin: 4px 0 0 0;
      }
    }
  `}
`;
