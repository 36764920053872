import React, { FC, memo, SyntheticEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ProgressBar } from 'components/elements';
import { ToggleTabListItem } from '../style';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IToggleTabItem {
  icon: IconProp,
  screenreaderText: string,
  active?: boolean,
  onClick?: (e: SyntheticEvent) => void,
  showProgress?: boolean,
  progressTotal?: number,
  progressSuccess?: number,
  progressError?: number,
  spinIcon?: boolean,
  testid?: string;
}

export const ToggleTabItem: FC<IToggleTabItem> = memo(({
  icon,
  screenreaderText,
  active,
  onClick,
  showProgress,
  progressTotal,
  progressSuccess,
  progressError,
  spinIcon,
  testid
}) => (
  <ToggleTabListItem
    className='toggle-tab-item'
    data-testid={testid}
    active={active}
  >
    { showProgress && (
    <ProgressBar
      total={progressTotal}
      success={progressSuccess}
      errors={progressError}
      fixed
    />
      )}

    <button className='toggle-tab-button' onClick={onClick} tabIndex={active ? -1 : 0} aria-label={screenreaderText}>
      <span className='toggle-tab-button-icon'>
        <FontAwesomeIcon
          icon={icon}
          spin={!!spinIcon}
        />
      </span>
    </button>
  </ToggleTabListItem>
));
