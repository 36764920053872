import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get, isEmpty, map } from 'lodash';

import { useModalState } from 'hooks/useModalState';
import Course from 'models/canvas/course';
import ModalModel from 'models/modal';

import { toggleModal } from 'store/modals/actions';
import { Button, Modal } from 'components/elements';
import { ModalHeading, SectionContent, SectionFooter } from 'components/general-styles';

import SourceCourse from './SourceCourse';
import { SourceCoursesContainer } from './style';
import { useFieldArray, useForm } from 'react-hook-form';
import { processCourseDuplicationForm } from 'store/courses/composite-actions';
import { ModalId } from 'constants/index';

import CourseNavSettings from 'models/courseNavSettings';
import CourseAuthoringSettings from 'models/courseAuthoringSettings';
import CoursePageRatingSettings from 'models/coursePageRatingSettings';

interface IDuplicateModalData {
  selectedCourses: Course[];
}

export interface IDuplicateCourseVals {
  accountId: string;
  courseCode: string;
  courseNavSettings: CourseNavSettings;
  courseAuthoringSettings: CourseAuthoringSettings;
  coursePageRatingSettings: CoursePageRatingSettings;
  endDate: Date;
  name: string;
  sisCourseId: string;
  sourceCourseId: string;
  startDate: Date;
  tags: string[];
  termId: string;
}

export interface ISourceCourseVals {
  sourceCourseId: string;
  duplicates: IDuplicateCourseVals[];
}

export interface ICourseDuplicationFormVals {
  sourceCourses: ISourceCourseVals[];
}

interface IDuplicateCourses {}

const DuplicateCourses: FC<IDuplicateCourses> = () => {
  const modalId = ModalId.DUPLICATE_COURSES;

  const dispatch = useDispatch();
  const store = useStore();
  const { t } = useTranslation();

  const modalState = useModalState<ModalModel<IDuplicateModalData>>(modalId);

  const { control, reset, getValues } = useForm<ICourseDuplicationFormVals>();

  const sourceCourseFieldArray = useFieldArray({
    control,
    name: 'sourceCourses',
  });

  const [numDuplicates, setNumDuplicates] = useState(0);
  const [submittingDuplicates, setSubmittingDuplicates] = useState<boolean>(false);
  const submitClickCount = useRef(0);

  // isolate this property as a stable dependency reference for the useEffect
  const sourceCourseAppend = useRef(sourceCourseFieldArray.append).current;

  const handleClose = () => {
    setNumDuplicates(0);
    dispatch(toggleModal(modalId, false, {}));
    reset();
  };

  const handleSubmit = () => {
    const formVals = getValues();
    processCourseDuplicationForm(formVals, store.dispatch, store.getState());
    handleClose();
  };

  /**
   * Due to browser lag we're triggering a state update only on click
   * Which will then be caught by the useEffect later and trigger the 
   * handleSubmit function.  This is so the browser updates the button
   * to disabled and showing the spinner before the handleSubmit method
   * causes the browser to lag.  
   */
  const handleSubmitClick = () => {
    submitClickCount.current = submitClickCount.current + 1; 
    setSubmittingDuplicates( true );
  };

  const handleRemoveSourceCourse = (index: number) => {
    sourceCourseFieldArray.remove(index);
  };

  // It fills the duplicated courses store with all the selected courses
  useEffect(() => {
    if (!modalState.open) return;
    const selectedCourses = get(modalState, 'data.selectedCourses', []);
    const newFields = map(selectedCourses, (course: Course) => ({ course }));
    sourceCourseAppend(newFields);
  }, [modalState, sourceCourseAppend]);

  // When submittingDuplicates state updates from click event trigger the 
  // handleSubmit method
  useEffect(() => {
    if (submittingDuplicates && submitClickCount.current === 1) {
      handleSubmit();
    }
  }, [submittingDuplicates, submitClickCount]);

  const noSourceCourses = isEmpty(sourceCourseFieldArray.fields);
  const noDuplicates = numDuplicates < 1;

  return (
    <Modal id={modalId} handleClose={handleClose} size='big'>
      <form>
        <SectionContent>
          <ModalHeading>
            <h2 id={`modal-${modalId}-title`}>
              {t('modalDuplicateCoursesTitle')}
            </h2>
            <p id={`modal-${modalId}-description`}>
              {t('modalDuplicateCoursesDescription')}
            </p>
          </ModalHeading>

          <SourceCoursesContainer className='source-courses-container'>
            {noSourceCourses && <p>{t('noCoursesSelected')}</p>}
            {map(
              sourceCourseFieldArray.fields,
              (field: { id: string; course: Course }, i: number) => (
                <React.Fragment key={field.id}>
                  {/* Keep this input as a placeholder field to ensure that this source course stays registered in the form vals,
                    even if it has no duplicate courses. This prevents bugs when removing a source course.  */}
                  <input
                    type='hidden'
                    name={`sourceCourses[${i}].sourceCourseId`}
                    ref={control.register()}
                    value={field.course?.id}
                  />
                  <SourceCourse
                    sourceCourse={field.course}
                    fieldPrefix={`sourceCourses[${i}]`}
                    modalControl={control}
                    setNumDuplicates={setNumDuplicates}
                    removeSourceCourse={() => handleRemoveSourceCourse(i)}
                  />
                </React.Fragment>
              )
            )}
          </SourceCoursesContainer>
        </SectionContent>
        <SectionFooter>
          <div />
          <div>
            <Button
              color='secondary'
              onClick={handleClose}
              testId='duplicate-courses-cancel-button'
            >
              {t('cancel')}
            </Button>
            <Button
              className='duplicate-courses-submit-button'
              disabled={noSourceCourses || noDuplicates}
              testId='duplicate-courses-submit-button'
              submitting={submittingDuplicates}
              onClick={handleSubmitClick}
              type='submit'
            >
              {t('duplicate')}
            </Button>
          </div>
        </SectionFooter>
      </form>
    </Modal>
  );
};

export default DuplicateCourses;
