import React, { FC } from 'react';
import { CellMessageWrapper } from './style';

interface ICellMessage {
  message: string;
}

export const CellMessage: FC<ICellMessage> = ({ message }) => {
  return (
    <CellMessageWrapper className='cell-message-wrapper'>
      {message}
    </CellMessageWrapper>
  );
};
