import { createSelector } from 'reselect';
import { get } from 'lodash';

import { AppState } from 'store';
import Installation from 'models/installation';
import CourseBuilderInstallationConfig from 'models/courseBuilderInstallationConfig';
import { InstallationFeatureState } from './reducer';

export const getInstallationFeature = (appState: AppState): InstallationFeatureState => get(appState, 'installation');

export const getInstallation = createSelector(
  getInstallationFeature,
  (featureState: InstallationFeatureState): Installation => get(featureState, 'item')
);

export const getInstallationConfig = createSelector(
  getInstallationFeature,
  (featureState: InstallationFeatureState): CourseBuilderInstallationConfig => get(featureState, 'config')
);

export const getLmsBaseUrl = createSelector(
  getInstallationFeature,
  (featureState: InstallationFeatureState): InstallationFeatureState['lmsBaseUrl'] => get(featureState, 'lmsBaseUrl')
);

export const getTopAccountId = createSelector(
  getInstallationFeature,
  (featureState: InstallationFeatureState): InstallationFeatureState['topAccountId'] => get(featureState, 'topAccountId')
);
