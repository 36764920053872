import { filter, values, orderBy, map, parseInt, isEqual,
  isFinite, divide, multiply, floor, zipObject, mapValues, flatMap, uniq, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from 'store';

import Course from 'models/canvas/course';
import Account from 'models/canvas/account';

import { getDescendentAccountsIdsOfAccount, PerAccountProps } from 'store/accounts/selectors';
import { getLmsBaseUrl } from 'store/installation/selectors';
import CourseMeta from 'models/courseMeta';
import { CoursesFeatureState, CoursesFetchStatus } from './reducer';
import { getSelectedRows, PerTableProps } from 'modules/data-table/store/data-table/selectors';
import { memoizeSelectorFactory } from 'store/selector-helpers';
import { CONSTANTS } from 'config';
import CourseDuplication from 'models/courseDuplication';

export type PerCourseProps = { courseId: Course['id'] };

export const getCoursesFeature = (appState: AppState) => appState?.courses;

export function courseHasNewTemplateSelected(course: Course): boolean {
  return !!course?._meta?.newCourseTemplateId && course?._meta?.newCourseTemplateId !== CONSTANTS.templates.emptyId;
}

export function courseExistsInLmsAlready(course: Course, originalCourse: Course): boolean {
  const courseId = course?.id;
  const hasOriginal = !!originalCourse;
  const tempIdDiffers = !!courseId && courseId !== course?._meta?.tempCourseId;
  return hasOriginal || tempIdDiffers;
}

export function courseIsLocked(course: Course, originalCourse: Course, isSyncing: boolean): boolean {
  return courseExistsInLmsAlready(course, originalCourse) || !!isSyncing;
}

export function courseIsUnsyncedDuplicate(course: Course): boolean {
  // test for existence of property that only exists on FE courses being staged for duplication
  const hasSourceCourseId = !!course?._meta?.courseDuplication?.originCourseId;
  return hasSourceCourseId;
}

export function courseHasNewCourseNavSettings(course: Course, originalCourse: Course): boolean {
  const currentSettings = course?._meta?.courseNavSettings;
  const originalSettings = originalCourse?._meta?.courseNavSettings;
  return !!currentSettings && !isEqual(currentSettings, originalSettings);
}

export function courseHasNewCourseAuthoringSettings(course: Course, originalCourse: Course): boolean {
  const currentSettings = course?._meta?.courseAuthoringSettings;
  const originalSettings = originalCourse?._meta?.courseAuthoringSettings;
  return !!currentSettings && !isEqual(currentSettings, originalSettings);
}

export function courseHasNewCoursePageRatingSettings(course: Course, originalCourse: Course): boolean {
  const currentSettings = course?._meta?.coursePageRatingSettings;
  const originalSettings = originalCourse?._meta?.coursePageRatingSettings;
  return !!currentSettings && !isEqual(currentSettings, originalSettings);
}

export function courseHasNewTags(course: Course, originalCourse: Course): boolean {
  const currentTags = course?._meta?.tags || [];
  const originalTags = originalCourse?._meta?.tags || [];
  return !isEqual(sortBy(currentTags), sortBy(originalTags));
}

export function courseHasNewCourseDuplication(course: Course, originalCourse: Course): boolean {
  const currentDuplicationData = course?._meta?.courseDuplication || new CourseDuplication({});
  const originalDuplicationData = originalCourse?._meta?.courseDuplication || new CourseDuplication({});
  return !isEqual(currentDuplicationData, originalDuplicationData);
}

export function courseIsUnsynced(course: Course, originalCourse: Course): boolean {
  return !courseExistsInLmsAlready(course, originalCourse)
    || courseHasNewTemplateSelected(course)
    || courseHasNewCourseNavSettings(course, originalCourse)
    || courseHasNewCourseAuthoringSettings(course, originalCourse)
    || courseHasNewCoursePageRatingSettings(course, originalCourse)
    || courseHasNewTags(course, originalCourse)
    || courseHasNewCourseDuplication(course, originalCourse);
}

export function buildCourseUrl(lmsBaseUrl: string, courseId: Course['id']): string {
  return `${lmsBaseUrl}/courses/${courseId}`;
}

export function buildCourseContentMigrationsUrl(lmsBaseUrl: string, courseId: Course['id']): string {
  return `${lmsBaseUrl}/courses/${courseId}/content_migrations`;
}

export function getCourseStartDate(course: Course): string|Date {
  return course?.start_at || course?.term?.start_at;
}

export function getCourseEndDate(course: Course): string|Date {
  return course?.end_at || course?.term?.end_at;
}

export const getCourseItems = createSelector(
  getCoursesFeature,
  (featureState: CoursesFeatureState): CoursesFeatureState['items'] => featureState?.items
);

export const getOriginalCourseItems = createSelector(
  getCoursesFeature,
  (featureState: CoursesFeatureState): CoursesFeatureState['originalItems'] => featureState?.originalItems
);

export const getAllCourses = createSelector(
  getCourseItems,
  (items: CoursesFeatureState['items']): Course[] => values(items)
);

export const getAllOriginalCourses = createSelector(
  getOriginalCourseItems,
  (items: CoursesFeatureState['originalItems']): Course[] => values(items)
);

export const getAllExistingCourses = createSelector(
  getAllCourses,
  getOriginalCourseItems,
  (allCourses: Course[], originals: CoursesFeatureState['originalItems']): Course[] => {
    return filter(
      allCourses,
      (c: Course) => {
        const original = originals?.[c.id];
        return courseExistsInLmsAlready(c, original);
      }
    );
  }
);

export const getNumberOfCourses = createSelector(
  getAllCourses,
  (allCourses: Course[]): number => allCourses?.length || 0
);

export const getSelectedCourses = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getSelectedRows(props),
  (selectedRows: Course[]): Course[] => selectedRows
));

export const getCourseById = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseItems,
  (courseItems: CoursesFeatureState['items']): Course => courseItems?.[props?.courseId]
));

export const getOriginalCourseById = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getOriginalCourseItems,
  (originalItems: CoursesFeatureState['originalItems']): Course => originalItems?.[props.courseId]
));

export const getCoursesByAccountId = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAllCourses,
  (courses: Course[]): Course[] => filter(
    courses,
    (course: Course) => course.account_id === props.accountId
  )
));

export const getDescendentCoursesOfAccount = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAllCourses,
  getDescendentAccountsIdsOfAccount(props),
  (courses: Course[], descendentAccountIds: Account['id'][]): Course[] => {
    // setting up a keyed object for speed in next step
    const keyedDescendentAccountIds = mapValues(zipObject(descendentAccountIds, []), () => true);
    return filter(
      courses,
      (course: Course) => course.account_id === props.accountId // child courses
        || !!keyedDescendentAccountIds[course.account_id] // grandchild & beyond
    );
  }
));

export const getDescendentCoursesOfAccountOrderedByNew = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getDescendentCoursesOfAccount(props),
  getOriginalCourseItems,
  (descendentCourses: Course[], originals: CoursesFeatureState['originalItems']): Course[] => {
    // Default sort to have new, unsynced courses on top, and then secondary sort by id int value (newest on top)
    const sortIterator1 = (c: Course) => {
      const orig = originals?.[c?.id];
      return courseExistsInLmsAlready(c, orig);
    };
    const sortIterator2 = (c: Course) => parseInt(c.id, 10);
    return orderBy(descendentCourses, [sortIterator1, sortIterator2], ['asc', 'desc']);
  }
));

export const getDescendentCoursesIdsOfAccount = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getDescendentCoursesOfAccount(props),
  (courses: Course[]): Course['id'][] => map(courses, 'id')
));

export const getTemplateIdForCourse = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseById(props),
  (course: Course): CourseMeta['courseTemplateId'] => course?._meta?.courseTemplateId
));

export const getCourseExistsInLmsAlready = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseById(props),
  getOriginalCourseById(props),
  (course: Course, originalCourse: Course): boolean => courseExistsInLmsAlready(course, originalCourse)
));

// Whether the course is a duplicate that may or may not have already been synced/created
export const getCourseIsUnsyncedDuplicate = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseById(props),
  (course: Course): boolean => courseIsUnsyncedDuplicate(course)
));

// Whether the course is a duplicate that hasn't been synced/created yet
export const getCourseIsNewDuplicate = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseIsUnsyncedDuplicate(props),
  getCourseExistsInLmsAlready(props),
  (isDuplicate: boolean, existsAlready: boolean): boolean => {
    return isDuplicate && !existsAlready;
  }
));

/** Whether the course has a different selected template than the one it loaded into the app with */
export const getCourseHasNewTemplateSelected = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseById(props),
  (course: Course): boolean => courseHasNewTemplateSelected(course)
));

/** Whether the course has a different nav settings than the one it loaded into the app with */
export const getCourseHasNewCourseNavSettings = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getCourseById(props),
  getOriginalCourseById(props),
  (course: Course, originalCourse: Course): boolean => courseHasNewCourseNavSettings(course, originalCourse)
));

export const getCoursesFetchStatus = createSelector(
  getCoursesFeature,
  (featureState: CoursesFeatureState): CoursesFetchStatus => featureState?.coursesFetch
);

export const getCoursesFetchInProgress = createSelector(
  getCoursesFetchStatus,
  (fetchStatus: CoursesFetchStatus): boolean => !!fetchStatus?.inProgress
);

export const getCoursesFetchNumPagesFetched = createSelector(
  getCoursesFetchStatus,
  (fetchStatus: CoursesFetchStatus): number => fetchStatus?.numPagesFetched
);

export const getCoursesFetchTotalPages = createSelector(
  getCoursesFetchStatus,
  (fetchStatus: CoursesFetchStatus): number => fetchStatus?.totalPages
);

export const getCoursesFetchProgressPercent = createSelector(
  getCoursesFetchNumPagesFetched,
  getCoursesFetchTotalPages,
  (numPagesFetched: number, totalPages: number): number => {
    if (!isFinite(numPagesFetched) || !isFinite(totalPages) || totalPages === 0) {
      return 0;
    }
    const percentage = multiply(
      divide(numPagesFetched, totalPages),
      100
    );
    return floor(percentage, 0);
  }
);

export const getCourseUrl = memoizeSelectorFactory((props: PerCourseProps) => createSelector(
  getLmsBaseUrl,
  (lmsBaseUrl: string) => buildCourseUrl(lmsBaseUrl, props.courseId)
));

export const getAutogenCount = createSelector(
  getCoursesFeature,
  (featureState: CoursesFeatureState): CoursesFeatureState['autogenCount'] => featureState?.autogenCount
);

export const getAllCourseTags = createSelector(
  getAllCourses,
  (courses: Course[]): string[] => {
    const tags: string[] = flatMap(courses, '_meta.tags');
    return sortBy(uniq(tags));
  }
);
