import { StateObservable, ofType } from 'redux-observable';
import { map as _map, filter as _filter, some } from 'lodash';
import * as actions from 'store/courses/actions';
import * as coursesApi from 'store/courses/api';
import { of, Observable, concat, from, EMPTY } from 'rxjs';
import { catchError, delay, flatMap, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import CanvasPageable from 'models/canvasPageable';
import Course from 'models/canvas/course';
import CourseDto from 'models/dto/courseDto';
import { AppState } from 'store';
import { courseExistsInLmsAlready, getOriginalCourseItems } from './selectors';
import { getNewCoursesToSync } from 'store/general/cross-selectors';
import { requestError, requestStart, requestSuccess } from 'store/requests/actions';
import { RequestLabel } from 'constants/index';
import Account from 'models/canvas/account';

const getCurrentProgress = (progress:number) => Math.round(Math.atan(progress) / (Math.PI / 2) * 100 * 1000) / 1000;

const respToCourses = (resp: CanvasPageable<CourseDto>): Course[] => _map(
  resp?.content,
  (courseDto: CourseDto) => courseDto.toCourse()
);

const fetchCoursesToObserver = async (observer: any, accountId: Account['id']) => {
  let currentPage = 1;
  let totalPages = 0;
  let progress = 5;
  let currentProgress = 0;
  const step = 0.3;
  let isLast = false;

  observer.next(
    actions.setCoursesFetchStatus({
      inProgress: true,
      numPagesFetched: progress,
      totalPages: 100,
    })
  );

  while (!isLast) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const resp = await coursesApi.getPaginatedCoursesForAccount(accountId, currentPage);

      observer.next(
        actions.setCoursesFetchStatus({
          inProgress: true,
          numPagesFetched: progress,
          totalPages: 100,
        })
      );

      observer.next(actions.receiveCourses(respToCourses(resp)));
      observer.next(actions.receiveOriginalCourses(respToCourses(resp)));

      currentPage += 1;
      totalPages = resp.totalPages;
      currentProgress += step;
      progress = getCurrentProgress(currentProgress);

      isLast = resp.last;
    } catch (e) {
      observer.error(e);
      return;
    }
  }

  observer.next(
    actions.setCoursesFetchStatus({
      inProgress: true,
      numPagesFetched: 100,
      totalPages: 100,
    })
  );

  setTimeout(() => {
    observer.next(
      actions.setCoursesFetchStatus({
        inProgress: false,
        numPagesFetched: 100,
        totalPages: 100,
      })
    );

    observer.complete();
  }, 250);
};

export const fetchPaginatedCoursesEpic$ = (
  action$: Observable<any>,
  state$: StateObservable<AppState>,
  // dependencies is a way to inject values used within the epic to make testing easier.
  // The real runtime value is assigned in store/index.ts in the `createEpicMiddleware` options
  dependencies: { injected_WithLatestFrom: typeof withLatestFrom }
) => action$.pipe(
  ofType(actions.CoursesActionTypes.FETCH_PAGINATED_COURSES),
  mergeMap((action: actions.FetchPaginatedCoursesAction) => {
    return new Observable((observer: any) => {
      fetchCoursesToObserver(observer, action.payload.accountId);
    }) as any;
  })
);

export const fetchCourseEpic$ = (actions$: Observable<any>, state$: StateObservable<AppState>) => actions$.pipe(
  ofType(actions.CoursesActionTypes.FETCH_COURSE),
  mergeMap((action: actions.FetchCourseAction) => {
    const label = RequestLabel.FETCH_COURSE(action.payload.courseId);
    const start = requestStart(label);
    const { requestUuid } = start.payload;
    return concat(
      of(start),
      from(coursesApi.getCourse(action.payload.courseId)).pipe(
        switchMap((courseDto: CourseDto) => {
          return concat(
            of(actions.receiveCourses([courseDto.toCourse()])),
            of(actions.receiveOriginalCourses([courseDto.toCourse()])),
            of(requestSuccess(requestUuid))
          );
        }),
        catchError((error: Error) => of(requestError(requestUuid, error)))
      )
    );
  })
);

// As we receive new courses that have been successfully created, delete the associated temp courses from the store
export const receiveCoursesEpic$ = (actions$: Observable<any>, state$: StateObservable<AppState>) => actions$.pipe(
  ofType(actions.CoursesActionTypes.RECEIVE_COURSES),
  withLatestFrom(state$),
  mergeMap(([action, appState]: [actions.ReceiveCoursesAction, AppState]) => {
    const tempCourses = getNewCoursesToSync(appState);
    const originalCourseItems = getOriginalCourseItems(appState);
    const existingReceivedCourses = _filter(action.payload.courses, (course: Course) => {
      const originalCourse: Course = originalCourseItems?.[course?.id];
      return courseExistsInLmsAlready(course, originalCourse);
    });
    const tempsToDelete = _filter(
      tempCourses,
      (tempCourse: Course) => {
        return some(existingReceivedCourses, (c: Course) => tempCourse.id === c?._meta?.tempCourseId);
      }
    );
    return (tempsToDelete?.length || 0) > 0
      ? of(actions.deleteCourses(_map(tempsToDelete, 'id'))).pipe(delay(200))
      : EMPTY;
  })
);
