import {
  ColumnDef, ColumnFilterConfig, ColumnSortConfig,
  ValueGetter, RowPositions, RowPosition, RowHeights, DataTableFilters,
  DataTableSorts,
  DataTableSelections,
  DataTableColumnIndices,
  ElementDimensions,
  RowsToRenderResult
} from '../types';
import { compact, concat, filter, findIndex, includes, keyBy, get,
  keys, map, max, min, orderBy, reduce, slice, some, toLower, values } from 'lodash';
import { RefObject } from 'react';

// Build a string key to identify a single table cell based on its row and column ids
export function buildCellKey(colId: string, rowId: string): string {
  return `${colId}-${rowId}`;
}

// Use one of the ColumnDef's configured value getters to get a value from the row item
export function getValue(
  row: any,
  colDef: ColumnDef,
  getterProp: 'sortValueGetter'|'filterValueGetter'|'cellValueGetter',
  allRows: any[]
): any {
  const valueGetter: ValueGetter = colDef?.[getterProp] || colDef?.cellValueGetter;
  if (typeof valueGetter === 'string') {
    // keep '_.get' here as the value getter properties allow a 'object.traversal.path' string
    return get(row, valueGetter);
  } if (typeof valueGetter === 'function') {
    return valueGetter(row, colDef, allRows);
  }
  return null;
}

// Apply the configured filters to the data set
export const filterRows = (
  data: any[],
  filters: DataTableFilters,
  columnDefs: ColumnDef[],
): any[] => {
  const colDefsMap = keyBy(columnDefs, 'columnId');
  const filtersToApply = compact(values(filters));
  return reduce(filtersToApply, (acc: any[], colFilter: ColumnFilterConfig) => {
    const { columnId, caseSensitive } = colFilter;
    return filter(acc, (row: any) => {
      const filterableValue = getValue(row, colDefsMap[columnId], 'filterValueGetter', data);
      return caseSensitive
        ? includes(filterableValue, colFilter.filterValue)
        : includes(toLower(filterableValue), toLower(colFilter.filterValue));
    });
  }, data);
};

// Apply the configured sorts to the current filtered data set.
// 'rowsToSort' should be the filtered data set
export const sortRows = (
  rowsToSort: any[], // rows with filters applied
  allRows: any[], // raw, unfiltered data set
  sorts: DataTableSorts,
  columnDefs: ColumnDef[],
): any[] => {
  const colDefsMap = keyBy(columnDefs, 'columnId');
  // if there are multiple sorts, apply them in order of when they were applied by the user (createdAt)
  const orderedSorts: ColumnSortConfig[] = orderBy(compact(values(sorts)), 'createdAt', 'asc');
  const iterators: ((row: any) => any)[] = map(orderedSorts, (s: ColumnSortConfig) => {
    return (row: any) => {
      const sortableValue = getValue(row, colDefsMap?.[s?.columnId], 'sortValueGetter', allRows);
      return sortableValue;
    };
  });
  const directions: ('asc' | 'desc')[] = map(orderedSorts, 'direction');
  return orderBy(rowsToSort, iterators, directions) as any[];
};

// Determine which rows to render to the DOM based on row/viewbox dimensions and scroll position
export function getRowsToRender(
  rows: any[],
  rowPositions: RowPositions,
  rowIdProperty: string,
  scrollTop: number,
  viewboxDimensions: ElementDimensions,
  defaultRowHeight: number,
): RowsToRenderResult {
  const scrollBottom = scrollTop + viewboxDimensions.height;
  // determine the index of the first row to render
  let firstInBoundIndex: number = findIndex(rows, (row: any, i: number) => {
    const rowId = row?.[rowIdProperty];
    const position: RowPosition = rowPositions?.[rowId] || {};
    const { height, top } = position;
    const bottom = (top || i * defaultRowHeight) + (height || defaultRowHeight);
    return bottom > scrollTop;
  }, 0);
  // Render a few extra rows before and after the visible ones for better scrolling experience
  const preBuffer = 5;
  const postBuffer = 20;

  // Max count of course rows that we can fit in display
  const maxCoursesinViewPort = viewboxDimensions.height/defaultRowHeight;

  // If there is no visible first element 
  // It is better to render a part of the list
  // Because the correct element will be rendered on next render
  firstInBoundIndex = firstInBoundIndex === -1 ? rows.length - 1 : firstInBoundIndex;
 
  // The course rows of the element for rendering
  const maxElementsForDisplaying = maxCoursesinViewPort + preBuffer + postBuffer;

  // If the first index with buffer would be negative, set to zero index
  const firstIndexToRender: number = max([firstInBoundIndex - preBuffer, 0]);

  // If the calculated last index is greater than the total course rows array length 
  // Set the last lastIndex to match the rows array length
  const lastIndexToRender: number = min([firstIndexToRender + maxElementsForDisplaying, (rows?.length || 1)]);

  const rowsToRender: any[] = rows.slice(firstIndexToRender, lastIndexToRender);
  
  // Return the rows plus some meta properties about which indices the rows are at
  return { firstIndexToRender, lastIndexToRender, rowsToRender };
}

// Calculate the sum total of all rows based on current row measurements
export function getTotalHeight(
  rowPositions: RowPositions,
  defaultRowHeight: number
): number {
  let totalHeight = 0;
  keys(rowPositions).forEach((rowId: string) => {
    totalHeight += rowPositions?.[rowId]?.height || defaultRowHeight;
  });
  return totalHeight;
}

// Based on row heights, determine the absolute-positioning 'top' position for each row
export function getRowPositions(
  rows: any[],
  rowHeights: RowHeights,
  rowIdProperty: string,
  defaultRowHeight: number
): RowPositions {
  const positions: any = {};
  let prevBottom: any = 0;
  rows.forEach((row: any, i: number) => {
    const rowId = row[rowIdProperty];
    const rowHeight = rowHeights[rowId] || { center: null, left: null };
    const height = Math.max(rowHeight.center, rowHeight.left);
    const top = prevBottom;
    const position: RowPosition = { top, left: 0, height, rowIndex: i };
    prevBottom = position.top + (position.height || defaultRowHeight);
    positions[rowId] = position;
  });
  return positions;
}

// Determine the ids of the selected rows that did not survive the current applied filters,
// so that we can deselect them once they're filtered out
export function getSelectedRowIdsNotInFilter(
  filteredRows: any[],
  selections: DataTableSelections,
  rowIdProperty: string,
): string[] {
  const someSelected = some(values(selections), (val: boolean) => !!val);
  if (!someSelected) {
    return [];
  }
  // Make a lookup object once so that the subsequent filter can happen faster
  const keyedFilteredRows: { [rowId: string]: any } = keyBy(filteredRows, (row: any) => row[rowIdProperty]);
  const rowIdsToDeselect = keys(selections).filter((rowId: string) => {
    return !!selections[rowId] && !keyedFilteredRows[rowId];
  });
  return rowIdsToDeselect;
}

// Unite the pinned / non-pinned columns in order to determine their
// apparent left-to-right index, for use in the aria-colindex attribute
export function getColumnIndices(
  pinnedLeftColumnDefs: ColumnDef[],
  centerColumnDefs: ColumnDef[]
): DataTableColumnIndices {
  const indices: DataTableColumnIndices = {};
  const columns = concat(pinnedLeftColumnDefs, centerColumnDefs);
  columns.forEach((c: ColumnDef, i: number) => {
    indices[c.columnId] = i;
  });
  return indices;
}

// When focusing on header cells, scroll the center columns viewport accordingly
// to match the currently focused cell so that the appropriate transform/translate
// can be applied to the header container, which doesn't truly scroll but is just
// kept artificially in sync with the center-cells scroll position
export function syncCenterScrollWithHeaderFocus(
  focusedHeader: HTMLDivElement,
  centerColumnsViewportRef: RefObject<HTMLDivElement>
): void {
  if (!focusedHeader || !centerColumnsViewportRef.current) { return; }

  const ariaColIndex = focusedHeader.getAttribute('aria-colindex');
  const firstColCell = centerColumnsViewportRef.current.querySelector(`[aria-colindex="${ariaColIndex}"]`);

  if (!firstColCell) { return; }

  const viewportRect = centerColumnsViewportRef.current.getBoundingClientRect();
  const headerRect = focusedHeader.getBoundingClientRect();
  const firstColCellRect = firstColCell.getBoundingClientRect();
  const firstColCellInView = firstColCellRect.left >= viewportRect.left && firstColCellRect.right <= viewportRect.right;

  if (!firstColCellInView) {
    firstColCell.scrollIntoView();
  }

  if (headerRect.left > viewportRect.left) {
    const headerLeftDistance = headerRect.left - viewportRect.left;
    const firstColLeftDistance = firstColCellRect.left - viewportRect.left;
    const delta = firstColLeftDistance > headerLeftDistance
      ? firstColLeftDistance - headerLeftDistance
      : headerLeftDistance - firstColLeftDistance;

    centerColumnsViewportRef.current.scrollLeft += delta;
  }
}
