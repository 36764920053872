import { FunctionBarAction, FunctionBarActionTypes } from './actions';

export interface FunctionBarFeatureState {
  expanded: boolean;
}

export const defaultFunctionBarState: FunctionBarFeatureState = {
  expanded: true
};

export const functionBarReducer = (
  state: FunctionBarFeatureState = defaultFunctionBarState,
  action: FunctionBarAction
): FunctionBarFeatureState => {
  switch (action.type) {
    case FunctionBarActionTypes.SET_EXPANDED: {
      return {
        ...state,
        expanded: !!action.payload.expanded
      };
    }
    default:
      return state;
  }
};
