import { useCallback, useEffect } from 'react';

/**
 * Hook that adds a debounce effect to the useEffect hook
 * Useful when you have to trigger debounced methods everytime a dep changes
 */
export const useDebouncedEffect = (effect: any, delay: any, deps: any) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};
