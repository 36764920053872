import React, { FC, ReactNode, useMemo } from 'react';
import { get, includes, isNumber, toLower } from 'lodash';
import { DragLayerMonitor, useDragLayer } from 'react-dnd';
import { DragLayerContainer, DragPreviewItemWrapper, DragPreviewItemInnerWrapper } from './style';
import { ItemTypes } from 'constants/index';
import { CoursesFeatureState } from 'store/courses/reducer';
import { useSelector } from 'react-redux';
import { getCourseItems } from 'store/courses/selectors';
import { AccountsFeatureState } from 'store/accounts/reducer';
import { getAccountItemsMap } from 'store/accounts/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface IDragLayer {}

const CUSTOM_PREVIEW_ITEM_TYPES = [
  ItemTypes.ACCOUNT,
  ItemTypes.COURSE
];

/**
 * Lets us render our own custom drag previews while dragging certain items.
 */
export const CustomDragLayer: FC<IDragLayer> = () => {
  const {
    currentOffset,
    initialOffset,
    isDragging,
    item,
    itemType
  } = useDragLayer((monitor: DragLayerMonitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const { t } = useTranslation();

  const courseItems: CoursesFeatureState['items'] = useSelector(getCourseItems);
  const accountItems: AccountsFeatureState['items'] = useSelector(getAccountItemsMap);

  const itemPreviewContent: ReactNode = useMemo(() => {
    const moveIcon = <FontAwesomeIcon className='move-icon' icon={['fas', 'arrows-alt']} />;

    switch (itemType) {
      case ItemTypes.COURSE: {
        const selectedRowIds = get(item, 'data.selectedRowIds', []);
        const draggedCourseId = get(item, 'data.draggedRowId');
        const draggedCourse = get(courseItems, draggedCourseId);
        const draggedRowIsSelected = get(item, 'data.draggedRowIsSelected');
        const draggedCourseName = get(draggedCourse, 'name');
        const movedItems = selectedRowIds.length > 1 && draggedRowIsSelected
          ? `${selectedRowIds.length} ${toLower(t('selectedCourses'))}`
          : <em className='course-name'>{draggedCourseName}</em>;
        return <>{moveIcon} {t('move')} {movedItems}</>;
      }
      case ItemTypes.ACCOUNT: {
        const accountId = get(item, 'data.accountId');
        const account = get(accountItems, accountId);
        const accountName = get(account, 'name');
        return <>{moveIcon} Move <em className='account-name'>{accountName}</em></>;
      }
      default:
        return null;
    }
  }, [item, itemType, courseItems, accountItems, t]);

  if (!isDragging || !includes(CUSTOM_PREVIEW_ITEM_TYPES, itemType)) {
    return null;
  }

  return (
    <DragLayerContainer className='custom-drag-layer'>
      <DragPreviewItemWrapper
        className='drag-preview-item-wrapper'
        // using style prop b/c controlling via style-component props caused jerky rendering
        style={{
          display: !initialOffset || !currentOffset ? 'none' : 'block',
          transform: !isNumber(get(currentOffset, 'x')) ? 'none' : `translate3d(${currentOffset.x + 5}px, ${currentOffset.y}px, 0)`
        }}
      >
        <DragPreviewItemInnerWrapper className='drag-preview-item-inner-wrapper'>
          {itemPreviewContent}
        </DragPreviewItemInnerWrapper>
      </DragPreviewItemWrapper>
    </DragLayerContainer>
  );
};
