import { ISyncSummary } from 'hooks/useSyncSummary';
import React, { FC } from 'react';
import { SyncChangesTableWrapper } from './style';

interface ISyncChanges {
  syncSummary?: ISyncSummary[];
}

const SyncChanges: FC<ISyncChanges> = ({ syncSummary }) => {
  return (
    <SyncChangesTableWrapper>
      <table className='syncChangesTable'>
        <thead>
          <tr className='syncChangesTableHeading'>
            <th>Item Name</th>
            <th>Item Type</th>
            <th>Change Description</th>
          </tr>
        </thead>
        <tbody>
          {syncSummary?.map((item: ISyncSummary) => {
            return (
              <tr key={item.id} className='syncChangesTableRow'>
                <td className='syncChangesTableRowCell'>{item.name}</td>
                <td className='syncChangesTableRowCell'>{item.type}</td>
                <td className='syncChangesTableRowCell'>
                  {item.desc}
                  {!!item.linkData && (
                    <a href={`${item.linkData.url}`} target='_blank'>
                      {item.linkData.name}
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </SyncChangesTableWrapper>
  );
};

export default SyncChanges;
