import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import Collapse from 'react-smooth-collapse';
import { Control, Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { CoursesSuffixes, COURSES_SUFFIXES } from 'config/index';
import { InstallationConfigKey } from 'constants/index';
import { useInstallationConfig } from 'hooks/useInstallationConfig';
import { getSuffix } from 'helpers/suffixes';
import { toast } from 'helpers/messages';

import { TermsFeatureState } from 'store/terms/reducer';
import { getTermItems } from 'store/terms/selectors';
import { getAllCourseTags } from 'store/courses/selectors';
import Course from 'models/canvas/course';
import EnrollmentTerm from 'models/canvas/enrollmentTerm';
import Account from 'models/canvas/account';
import CourseNavSettings from 'models/courseNavSettings';
import CourseAuthoringSettings from 'models/courseAuthoringSettings';
import CoursePageRatingSettings from 'models/coursePageRatingSettings';

import EnhancedNavSettings from 'components/elements/EnhancedNavSettings';
import EnhancedAuthoringSettings from 'components/elements/EnhancedAuthoringSettings';
import EnhancedPageRatingSettings from 'components/elements/EnhancedPageRatingSettings';
import { AccountSelect, Button, SelectionCheckbox } from 'components/elements';
import { DatePicker } from 'components/elements/Datepicker';
import { FieldSet } from 'components/general-styles';
import { TagsInput } from 'components/elements/TagsInput';
import { TermSelect } from 'components/elements/TermSelect';

import DuplicateCourse from './DuplicateCourse';
import { SourceCourseItem } from './style';

interface ISourceCourse {
  sourceCourse: Course,
  fieldPrefix: string,
  modalControl: Control,
  removeSourceCourse: () => void,
  setNumDuplicates: (fn: any) => void
}

export interface ISourceCourseSettingsFormVals {
  quantity: string,
  suffix: string,
  account: string,
  startDate: Date,
  endDate: Date,
  term: string,
  tags: string[],
  courseNavSettings: CourseNavSettings,
  courseAuthoringSettings: CourseAuthoringSettings,
  coursePageRatingSettings: CoursePageRatingSettings
}

export const maxCourses = 99;

const SourceCourse: React.FC<ISourceCourse> = ({
  sourceCourse,
  fieldPrefix,
  modalControl,
  removeSourceCourse,
  setNumDuplicates
}) => {
  const { t } = useTranslation();
  const allCourseTags = useSelector(getAllCourseTags);

  const hasNavigationFeature = useInstallationConfig(InstallationConfigKey.NAV_SETTINGS_FEATURE);
  const hasAuthoringFeature = useInstallationConfig(InstallationConfigKey.AUTHORING_FEATURE);
  const hasPageRatingFeature = useInstallationConfig(InstallationConfigKey.PAGE_RATING_FEATURE);

  const [courseMetaExpanded, setCourseMetaExpanded] = useState(true);
  const [creatingDuplicates, setCreatingDuplicates] = useState(false);
  const [remainingDuplicates, setRemainingDuplicates] = useState<number>(maxCourses);
  const duplicationWasClicked = useRef<boolean>(false);

  const defaultValues = {
    quantity: '0',
    suffix: CoursesSuffixes.NUMBERS,
    account: sourceCourse?.account_id,
    term: sourceCourse?.term?.id || null,
    startDate: sourceCourse?.start_at || sourceCourse?.term?.start_at || null,
    endDate: sourceCourse?.end_at || sourceCourse?.term?.end_at || null,
    courseAuthoringSettings: sourceCourse?._meta?.courseAuthoringSettings,
    courseNavSettings: sourceCourse?._meta?.courseNavSettings,
    coursePageRatingSettings: sourceCourse?._meta?.coursePageRatingSettings,
    tags: sourceCourse?._meta?.tags || []
  };

  // A separate, local form just for the duplication settings, applied to newly added duplicate courses
  const { control, register, watch, getValues, setValue, errors } = useForm<ISourceCourseSettingsFormVals>({
    mode: 'onChange',
    defaultValues: defaultValues
  });

  const navEnabledIsChecked = watch('courseNavSettings.enabled', true);
  const tagsAreIncluded = watch('tagsIncluded', true);

  const duplicatesFieldArray = useFieldArray({
    control: modalControl,
    name: `${fieldPrefix}.duplicates`
  });

  const termItems: TermsFeatureState['items'] = useSelector(getTermItems);

  const syncDatesWithTerm = (term: EnrollmentTerm) => {
    setValue('startDate', term?.start_at);
    setValue('endDate', term?.end_at);
  };

  const handleAddDuplicatedCourses = () => {
    const settings = getValues();
    const duplicateCourseQuantity: number = parseInt(settings.quantity);
    setRemainingDuplicates(prevRemaining => prevRemaining - duplicateCourseQuantity);
    const existingDuplicateQuantity = duplicatesFieldArray.fields.length;
    const newFields = Array.from({ length: duplicateCourseQuantity })
      .map((_: any, i: number) => {
        // If tags are not included remove tags from generated copies.
        if (!tagsAreIncluded) {
          settings.tags = [];
        }
        const suffix = getSuffix(settings.suffix, existingDuplicateQuantity + i);
        return { settings, suffix };
      });
    duplicatesFieldArray.append(newFields);
    setNumDuplicates((prev: number) => prev + newFields.length);
    // Wait to reset click count and duplication status to account for browser lag
    setTimeout(() => {
      setCreatingDuplicates(false);
      duplicationWasClicked.current = false;
    }, 500);
  };

  const handleRemoveSourceCourse = () => {
    toast.confirm(t('confirmRemoveItem'), removeSourceCourse);
  };

  const handleNewAccountCreate = (tempAccountId: string, newAccount: Account) => {
    setValue('account', tempAccountId);
  };

  const handleTermCreate = (tempTermId: string, newTerm: EnrollmentTerm) => {
    setValue('term', tempTermId);
    syncDatesWithTerm(newTerm);
  };

  /**
   * Due to browser lag we're triggering a state update only on click
   * Which will then be caught by the useEffect later and trigger the 
   * handleAddDuplicatedCourses function.  This is so the browser updates
   * the button to disabled and showing the spinner before the 
   * handleAddDuplicatedCourses method causes the browser to lag.
   * DuplicationWasClicked is only set when the currentDuplicates state is true
   * to avoid a race condition with the react state scheduler.
   */
  const handleAddClick = () => {
    if (!creatingDuplicates) {
      setCreatingDuplicates( true );
    } else {
      duplicationWasClicked.current = true;
    }
  };

  const settingsQuantityIsValid = (): boolean => {
    const quantity = Number(watch('quantity'));
    return quantity > 0 && quantity <= remainingDuplicates;
  };

  // When creatingDuplicates state updates from click event trigger the 
  // handleAddDuplicatedCourses method
  useEffect(() => {
    if ( creatingDuplicates && !duplicationWasClicked.current ) {
      duplicationWasClicked.current = true;
      handleAddDuplicatedCourses();
    }
  }, [creatingDuplicates]);

  return (
    <SourceCourseItem id={`source-course-${sourceCourse.id}-item`} className='source-course-item'>
      <div className='course-heading'>
        <div className='course-heading-description'>
          <h4>{t('source')}:</h4>
          <p>{sourceCourse?.name}</p>
          <p>{sourceCourse?.course_code}</p>
        </div>
        <div className='course-heading-actions'>
          <Button
            aria-label={t('remove')}
            variant='link'
            color='secondary'
            onClick={handleRemoveSourceCourse}
            id={`source-course-${sourceCourse.id}-remove-button`}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        </div>
      </div>

      <div className='course-settings'>
        <h4 className='course-settings-heading'>{t('settings')}:</h4>
        <div className='course-settings-fields'>
          <div className='course-settings-main'>
            <FieldSet className='fieldset-autosuffix' flex direction='column'>
              <label htmlFor={`source-course-${sourceCourse.id}-autosuffix`}>{t('autoSuffix')}</label>
              <select
                id={`source-course-${sourceCourse.id}-autosuffix`}
                name='suffix'
                ref={register}
                defaultValue={CoursesSuffixes.NUMBERS}
              >
                {map(COURSES_SUFFIXES, (sf: any) => (
                  <option key={sf.value} value={sf.value}>
                    {t(sf.name)}
                  </option>
                ))}
              </select>
            </FieldSet>
            <FieldSet className='fieldset-account' flex direction='column'>
              <label htmlFor={`source-course-${sourceCourse.id}-account`}>{t('destinationAccount')}</label>
              <Controller
                control={control}
                name='account'
                defaultValue={sourceCourse?.account_id}
                render={({ name, value, onChange }) => (
                  <AccountSelect
                    allowCreate
                    id={`source-course-${sourceCourse.id}-account`}
                    name={name}
                    defaultValue={value}
                    onChange={onChange}
                    onCreate={handleNewAccountCreate}
                    size='small'
                    value={value}
                  />
                )}
              />
            </FieldSet>
            <FieldSet className='fieldset-term' flex direction='column'>
              <label htmlFor={`source-course-${sourceCourse.id}-term`}>{t('destinationTerm')}</label>
              <Controller
                control={control}
                name='term'
                defaultValue={sourceCourse?.term?.id}
                render={({ name, onChange, value }) => (
                  <TermSelect
                    allowCreate
                    id={`source-course-${sourceCourse.id}-term`}
                    defaultValue={value}
                    name={name}
                    onChange={(selectedTermId: string) => {
                      syncDatesWithTerm(termItems?.[selectedTermId]);
                      onChange(selectedTermId);
                    }}
                    onCreate={handleTermCreate}
                    size='small'
                    value={value}
                  />
                )}
              />
            </FieldSet>
            <FieldSet className='fieldset-start-date' flex direction='column'>
              <label htmlFor={`source-course-${sourceCourse.id}-start-date`}>{t('startDate')}</label>
              <Controller
                control={control}
                defaultValue={sourceCourse?.start_at || sourceCourse?.term?.start_at || null}
                name='startDate'
                render={({ name, value, onBlur, onChange }) => (
                  <DatePicker
                    defaultValue={value}
                    id={`source-course-${sourceCourse.id}-start-date`}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('whenever')}
                    value={value}
                  />
                )}
              />
            </FieldSet>
            <FieldSet className='fieldset-end-date' flex direction='column'>
              <label htmlFor={`source-course-${sourceCourse.id}-end-date`}>{t('endDate')}</label>
              <Controller
                control={control}
                defaultValue={sourceCourse?.end_at || sourceCourse?.term?.end_at || null}
                name='endDate'
                render={({ name, value, onBlur, onChange }) => (
                  <DatePicker
                    defaultValue={value}
                    id={`source-course-${sourceCourse.id}-end-date`}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('whenever')}
                    value={value}
                  />
                )}
              />
            </FieldSet>
          </div>
          <div className={cn('course-settings-meta', { expanded: courseMetaExpanded })}>
            <Button
              className='course-settings-meta-expand-toggle'
              onClick={() => setCourseMetaExpanded((prev: boolean) => !prev)}
              variant='link'
            >
              {t('more')}
              <FontAwesomeIcon className='expand-icon' icon={['fas', 'chevron-down']} />
            </Button>
            <Collapse
              className='expand-container'
              expanded={courseMetaExpanded}
            >
              <div className='expand-container-inner'>

              <EnhancedAuthoringSettings
                  show={hasAuthoringFeature}
                  showTitle={false}
                  showLabel
                  control={control}
                  id={`source-course-${sourceCourse.id}`}
                  defaultValue={sourceCourse?._meta?.courseAuthoringSettings}
                />

                <EnhancedNavSettings
                  show={hasNavigationFeature}
                  showTitle={false}
                  showLabel
                  control={control}
                  defaultValue={sourceCourse?._meta?.courseNavSettings}
                  id={`source-course-${sourceCourse.id}`}
                  readOnlyHomeMenu={!navEnabledIsChecked}
                />                

                <EnhancedPageRatingSettings
                  show={hasPageRatingFeature}
                  showTitle={false}
                  showLabel
                  control={control}
                  id={`source-course-${sourceCourse.id}`}
                  defaultValue={sourceCourse?._meta?.coursePageRatingSettings}
                />

                <FieldSet className='fieldset-tags' flex direction='row'>
                  <Controller
                    control={control}
                    name='tagsIncluded'
                    defaultValue
                    render={({ name, value, onChange }) => (
                      <SelectionCheckbox
                        id={`source-course-${sourceCourse.id}-tags-include`}
                        name={name}
                        selected={value}
                        onClick={onChange}
                      >
                        {t('tagsInclude')}
                      </SelectionCheckbox>
                    )}
                  />
                  <label className='tags-with-check' htmlFor={`source-course-${sourceCourse.id}-tags`}>{t('tags')}
                    <Controller
                      control={control}
                      name='tags'
                      defaultValue={sourceCourse?._meta?.tags || []}
                      render={({ value, onChange, name }) => (
                        <TagsInput
                          id={`source-course-${sourceCourse.id}-tags`}
                          name={name}
                          onChange={onChange}
                          tabIndex={courseMetaExpanded ? 0 : -1}
                          tags={allCourseTags}
                          value={value}
                          disabled={!tagsAreIncluded}
                        />
                      )}
                    />
                  </label>
                </FieldSet>
              </div>
            </Collapse>
          </div>
          <div className='duplication-actions'>
            <FieldSet className='fieldset-quantity' flex direction='row'>
              <label htmlFor={`source-course-${sourceCourse.id}-quantity`}>{t('duplicate_plural')}</label>
              <input
                id={`source-course-${sourceCourse.id}-quantity`}
                name='quantity'
                type='number'
                min='0'
                max={`${remainingDuplicates}`}
                step='1'
                ref={register({
                  max: remainingDuplicates
                })}
                placeholder='0'
              />
            </FieldSet>
            <Button
              className='source-course-add-button'
              size='small'
              onClick={handleAddClick}
              id={`source-course-${sourceCourse.id}-add-button`}
              disabled={!settingsQuantityIsValid()}
              submitting={creatingDuplicates}
            >
              {t('add')}
            </Button>
            {errors.quantity && <p className='duplication-validation-error'>{t('modalDuplicationQuantityError')}</p>}
          </div>
        </div>
      </div>

      <ul className='duplicated-courses-container'>
        {
          map(duplicatesFieldArray.fields, (field: { id: string, settings: ISourceCourseSettingsFormVals, suffix: string }, dupIndex: number) => (
            <DuplicateCourse
              modalControl={modalControl}
              key={field.id}
              fieldPrefix={`${fieldPrefix}.duplicates[${dupIndex}]`}
              index={dupIndex}
              settings={field.settings}
              sourceCourse={sourceCourse}
              suffix={field.suffix}
              removeDuplicateCourse={() => {
                duplicatesFieldArray.remove(dupIndex);
                setNumDuplicates((prev: number) => prev - 1);
              }}
            />
          ))
        }
      </ul>
    </SourceCourseItem>
  );
};

export default SourceCourse;
