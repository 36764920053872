import { get } from 'lodash';

export default class StartEndDates {
  start_at: Date;
  end_at: Date;

  constructor(json: any) {
    this.start_at = get(json, 'start_at') ? new Date(json.start_at) : null;
    this.end_at = get(json, 'end_at') ? new Date(json.end_at) : null;
  }
}
