import React, { FC, ReactNode, createElement, useMemo, ComponentClass, FunctionComponent } from 'react';
import { ColumnDef, CellValue } from 'modules/data-table/types';
import { getValue } from 'modules/data-table/util';
import { DataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';
import cn from 'classnames';
import { isValidElementType } from 'react-is';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getDataTableData } from 'modules/data-table/store/data-table/selectors';
import { TABLE_CELL_CLASSNAME, BODY_CELL_CLASSNAME } from '../TableBody';

interface ITableCell {
  row: any;
  rowId: string;
  rowHeight: number;
  columnDef: ColumnDef;
  api: DataTableObservables;
  columnIndex: number;
  ariaRowIndex: number;
  tableId: string;
}

export const TableCell: FC<ITableCell> = ({
  row,
  columnDef,
  columnIndex,
  ariaRowIndex,
  tableId
}) => {
  const cellRenderer = columnDef?.cellRenderer;
  const className = columnDef?.className;
  const columnId = columnDef?.columnId;

  const data = useSelectorWithProps(getDataTableData, { tableId });

  const customContent: ReactNode = useMemo(() => {
    const value: CellValue = getValue(row, columnDef, 'cellValueGetter', data);
    let content: ReactNode = value;
    if (isValidElementType(cellRenderer)) {
      content = createElement(cellRenderer as FunctionComponent<any> | ComponentClass<any, any>, { tableId, value, row, columnDef, data });
    }
    return content;
  }, [row, columnDef, data, cellRenderer, tableId]);

  /* Aria column indices start at 1 */
  const ariaColumnIndex = columnIndex + 1;

  return (
    <div
      className={cn(TABLE_CELL_CLASSNAME, BODY_CELL_CLASSNAME, `column-${columnId}`, { [className]: !!className })}
      data-testid={`table-cell-${columnIndex}-${ariaRowIndex}`}
      role='gridcell'
      aria-colindex={ariaColumnIndex}
      aria-rowindex={ariaRowIndex}
      style={{
        width: columnDef.width,
        minWidth: columnDef.width,
        maxWidth: columnDef.width,
      }}
      tabIndex={0}
    >
      {customContent}
    </div>
  );
};
