import { AppMenuManagerReturn } from 'hooks/useAppMenuDropdownManager';
import { createContext } from 'react';

const detaultContextValue: AppMenuManagerReturn = {
  appMenu: [],
  collapseAll: () => {},
  toggleExpandItem: (id: string) => {},
  selectMenuItem: (id: string) => { return null; }
};

/**
 * Context to power the appMenu dropdowns
 * 
 * Returns {@link AppMenuManagerReturn}
 */
const AppMenuContext = createContext<AppMenuManagerReturn>(detaultContextValue);

export default AppMenuContext;
