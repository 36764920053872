import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import { FieldErrorsWrapper } from './style';
import { map, capitalize } from 'lodash';

interface IFieldErrors {
  errors: FieldError | FieldError[];
}

export const FieldErrors: FC<IFieldErrors> = ({ errors }) => {
  const isMultiple = Array.isArray(errors);

  if (!errors) {
    return <></>;
  }

  return (
    <FieldErrorsWrapper className='field-errors-wrapper'>
      {isMultiple && (
        <ul className='field-errors-list'>
          {map(errors, (err: FieldError, i: number) => (
            <li key={i} className='field-errors-list-item'>
              {capitalize(err.message)}
            </li>
          ))}
        </ul>
      )}
      {!isMultiple && capitalize((errors as FieldError)?.message)}
    </FieldErrorsWrapper>
  );
};
