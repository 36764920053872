import { EnrollmentType } from 'models/canvas/enrollmentTerm';
import StartEndDates from 'models/canvas/startEndDates';
import BaseSyncItemEntity from './baseSyncItemEntity';
import { mapValues } from 'lodash';

export default class SyncNewTerm extends BaseSyncItemEntity {
  syncNewTermId: string;
  tempTermId: string;
  name: string;
  sisTermId: string;
  startAt: Date;
  endAt: Date;
  overrides: Partial<Record<EnrollmentType, StartEndDates>>;
  termId: string;

  constructor(json: any) {
    super(json);
    this.syncNewTermId = json?.syncNewTermId;
    this.tempTermId = json?.tempTermId;
    this.name = json?.name;
    this.sisTermId = json?.sisTermId;
    this.startAt = json?.startAt ? new Date(json.startAt) : null;
    this.endAt = json?.endAt ? new Date(json.endAt) : null;
    this.overrides = mapValues(
      json?.overrides,
      (val: any) => new StartEndDates(val)
    );
    this.termId = json?.termId;
  }
}
