import styled, { css } from 'styled-components';

interface IAccountListItem {
  active: boolean;
  ancestorIsActive: boolean;
  canDrag: boolean;
  draggingOver: boolean;
  expanded: boolean;
  fullAccountView: boolean;
  hasChildren: boolean;
  isDragging: boolean;
  topAccount: boolean;
}

interface ITreeContainer {
  fullAccountView: boolean;
}

interface IAccountFilterControlContainer {}

export const TreeContainer = styled.section<ITreeContainer>`
  ${({ fullAccountView, theme }) => css`
    background: ${theme.color.gray6};
    display: flex;
    height: 100%;
    overflow: auto;
    transition: padding .4s ease;

    .account-tree-header {
      padding: ${fullAccountView ? 0 : '0 8px'};
      transition: padding .4s ease;

      h2 {
        font: 18px/20px 'Lato';
        margin: 16px 0 8px 0;
      }
    }

    .account-tree {
      list-style: none;
      margin: 16px 0 0 0;
      padding: 0;
      width: 100%;
      display: inline-table;
    }

    .account-details-panel {
      display: ${fullAccountView ? 'block' : 'none'};
      width: 20%;
    }

    .account-tree-content {
      padding: ${fullAccountView ? '8px 24px' : '8px 0'};
      width: 80%;
    }

  `}
`;

export const AccountTreeContent = styled.div`
  ${({ theme }) => css`
    flex: 1 1 auto;
    overflow: auto;
    transition: all .4s ease;
  `}
`;

export const AccountListItem = styled.li<IAccountListItem>`
  ${({ topAccount, hasChildren, ancestorIsActive, active, expanded, theme, fullAccountView, draggingOver, canDrag }) => css`
    width: 100%;

    > .account-item-wrapper {
      display: block;
      height: 100%;
      margin-bottom: 2px;
      position: relative;
      transition: all .4s ease;
      width: 100%;
      background: ${fullAccountView
        ? (active || ancestorIsActive ? theme.color.white : theme.color.gray7)
        : theme.color.gray8
      };
      border-left: 3px solid ${active ? theme.color.mainDark : theme.color.gray4 };

      .account-button {
        align-items: center;
        background: transparent;
        border: 2px solid ${draggingOver ? theme.color.orange : 'transparent'};
        color: ${theme.color.gray2};
        cursor: ${canDrag ? 'grab' : 'pointer'};
        display: flex;
        height: 2em;
        justify-content: space-between;
        padding: 0;
        transition: color .2s ease, border-color .1s ease;
        white-space: nowrap;
        width: 100%;
      }

      .account-item-description {
        display: flex;

        .account-icon {
          color: ${theme.color.gray3};
          margin: 0 0 0 8px;
          transition: color .2s ease;            

          &:nth-of-type(1) {
            margin: 0 8px 0 0;
          }
        }
      }
      
      .account-item-actions {
        display: ${fullAccountView ? 'flex' : 'none'};
        opacity: 0;
        transition: all .2s ease;
      }

      &:hover,
      &:focus-within {
        border-left: 3px solid ${theme.color.mainDark};
        
        .account-button {
          color: ${theme.color.mainDark};
        }

        .account-item-description {
          .account-icon {
            color: ${theme.color.mainDark};
          }
        }

        .account-item-actions {
          opacity: 1;
        }
      }

      > .bracket {
        flex-shrink: 0;
        height: 100%;
        opacity: ${fullAccountView ? 0 : 1};
        position: relative;
        width: 0.3em;
        z-index: 1;

        &:before {
          border-bottom: 1px solid ${theme.color.gray3};
          border-left: 1px solid ${theme.color.gray3};
          bottom: 50%;
          content: '';
          display: block;
          height: 1000vw;
          left: 0;
          position: absolute;
          width: .3em;
        }
        &:after {
          border-right: 1px solid ${theme.color.gray3};
          bottom: -16px;
          content: '';
          display: none;
          height: .7em;
          left: 14px;
          position: absolute;
          width: 1px;
        }
      }
    }

    ${topAccount && css`
      > .account-item-wrapper {
        .bracket {
          &:before {
            display: none;
          }
        }
      }
    `}

    ${hasChildren && expanded && css`
      > .account-item-wrapper {
        .bracket {
          &:after {
            display: block;
          }
        }
      }
    `}

    ${ancestorIsActive && css`
      .account-item-wrapper {
        .account-button {
          color: ${theme.color.font};
          .account-icon {
            color: ${theme.color.gray1};
          }
        }

        &:hover, &:focus-within {
          .account-button {
            color: ${theme.color.mainDark};
            .account-icon {
              color: ${theme.color.mainDark};
            }
          }
        }
      }
    `}

    ${active && css`
      > .account-item-wrapper {
        &:after {
          border-left: 3px solid ${theme.color.mainDark}!important;
          opacity: 1;
        }
        .account-button {
          color: ${theme.color.mainDark};
          
          .account-item-description { 
            .account-icon {
              color: ${theme.color.mainDark};
            }
          }
        }
      }
    `}
  `}
`;

export const AccountFilterControlContainer = styled.div<IAccountFilterControlContainer>`
`;
