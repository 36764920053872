import { of, concat, from, Observable } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';

import { requestStart, requestSuccess, requestError } from 'store/requests/actions';
import { AppState } from 'store';
import { TemplatesActionTypes, receiveTemplates } from './actions';
import * as templatesApi from './api';
import { RequestLabel } from 'constants/index';

export const fetchTemplatesEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(TemplatesActionTypes.FETCH_TEMPLATES),
  mergeMap(() => {
    const label = RequestLabel.FETCH_TEMPLATES;
    const start = requestStart(label);
    const { requestUuid } = start.payload;
    return concat(
      of(start),
      from(templatesApi.fetchTemplates()).pipe(
        switchMap((data: templatesApi.Templates) => {
          const templates = data?.templates || [];
          return concat(
            of(receiveTemplates(templates)),
            of(requestSuccess(requestUuid))
          );
        }),
        catchError((error: Error) => of(requestError(requestUuid, error)))
      )
    );
  })
);
