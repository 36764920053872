import { UAParser } from 'ua-parser-js';
import { get, includes } from 'lodash';

const userAgentParser = new UAParser();
const operatingSystem = userAgentParser.getOS();
const osName = get(operatingSystem, 'name');

export const ENV = process.env.NODE_ENV;

export const IS_DEV = ENV === 'development';

export const API = {
  url: process.env.REACT_APP_API_BASE_URL,
  cors: '',
  frontEndOrigin: process.env.REACT_APP_FRONT_END_ORIGIN,
  useMock: String(process.env.REACT_APP_API_USE_MOCK) === 'true',
};

export const GA = {
  id: ''
};

export const CONSTANTS = {
  templates: {
    emptyId: 'no-template'
  }
};

export const SHORTCUTS = {
  extraKeys: ['ctrlKey', 'altKey'] as (keyof KeyboardEvent)[],
  extraKeysAria: 'Control+Alt'
};

export const USER_AGENT = {
  operatingSystem,
  isMac: includes(osName, 'Mac OS') || includes(osName, 'iOS')
};

export const SYNC_PAGINATION = {
  perPage: 10
};

export enum CoursesSuffixes {
  NONE = 'NONE',
  LETTERS = 'LETTERS',
  NUMBERS = 'NUMBERS',
}

export const COURSES_SUFFIXES = [
  { value: CoursesSuffixes.NONE, name: 'none' },
  { value: CoursesSuffixes.LETTERS, name: 'suffixLetter' },
  { value: CoursesSuffixes.NUMBERS, name: 'suffixNumber' }
];
