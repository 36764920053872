import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { UseFormMethods } from 'react-hook-form/dist/types';

import { CONSTANTS } from 'config';
import CourseTemplate from 'models/courseTemplate';
import { getAllTemplates } from 'store/templates/selectors';
import { Fieldset, Legend } from 'components/elements/Input/style';

import TemplateItem from './TemplateListItem';

import { List } from './style';

export interface ITemplateListProps {
  label: string;
  name: string;
  noFrameWorkSelectedOption: boolean;
  register: UseFormMethods['register'];
}

/**
 * Displays a list of available templates to pick from and apply to new courses
 *
 */
const TemplateList: React.FC<ITemplateListProps> = ({ label, name, noFrameWorkSelectedOption, register }) => {
  
  const { t } = useTranslation();
  
  const templates = useSelector(getAllTemplates);

  const emptyTemplate = new CourseTemplate({
    courseTemplateId: CONSTANTS.templates.emptyId,
    name: t('noTemplateSelected')
  });

  const templatesArray = noFrameWorkSelectedOption ?  [emptyTemplate, ...templates] : [...templates];

  return (
    <>
      {
        !isEmpty(templates) && (
          <>
            <Fieldset data-testid={label} ><Legend>{label}</Legend>
              <List className='templates-list' id='templates-list'>
                {
                  templatesArray.map((template: CourseTemplate) => (
                    <TemplateItem
                      key={`template-item-${template.courseTemplateId}`}
                      template={template}
                      name={name}
                      register={register}
                    />
                  ))
                }
              </List>
            </Fieldset>
          </>
        )
      }
    </>
  );
};

export default TemplateList;
