import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CustomButton, ICustomButtonProps } from './style';

interface IButtonProps extends ICustomButtonProps {
}

export const defaultButtonProps: Partial<IButtonProps> = {
  color: 'primary',
  size: 'big',
  type: 'button',
  variant: 'filled',
  rotate: 0,
  as: 'button'
};

const Button: React.ForwardRefRenderFunction<
  // Ref Type
  HTMLButtonElement | HTMLAnchorElement,
  // Props Type (accepts own IButtonProps and ...otherProps belonging to <button> or <a>)
  IButtonProps & (
    React.ButtonHTMLAttributes<HTMLButtonElement>
    | React.AnchorHTMLAttributes<HTMLAnchorElement>
  )> = ({
  ariaLabel,
  children,
  className,
  color = defaultButtonProps.color,
  disabled,
  href,
  id,
  italic,
  onClick,
  rotate = defaultButtonProps.rotate,
  size = defaultButtonProps.size,
  submitting,
  target,
  type = defaultButtonProps.type,
  underline,
  variant = defaultButtonProps.variant,
  as = defaultButtonProps.as, // part of StyledComponents, to render a different element but use the same styles
  testId,
  ...otherProps
}, ref: any) => (
  <CustomButton
    aria-label={ariaLabel}
    as={as}
    className={className}
    color={color}
    data-testid={testId}
    data-color={color}
    data-size={size}
    data-submitting={submitting}
    data-underline={underline}
    data-variant={variant}
    disabled={disabled || submitting}
    href={href}
    id={id}
    italic={italic}
    onClick={onClick}
    ref={ref}
    rotate={rotate}
    size={size}
    target={target}
    type={type}
    underline={underline}
    variant={variant}
    {...otherProps}
  >
    {
      submitting
        ? (
          <>
            <FontAwesomeIcon className='spinner-icon' icon={['fas', 'spinner']} spin />
            { children }
          </>
        )
        : children
      }
  </CustomButton>
  );

export default React.memo(React.forwardRef(Button));
