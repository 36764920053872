import styled, { css } from 'styled-components';

interface IPanel {
  show: boolean;
}

export const Panel = styled.aside<IPanel>`
  ${({ theme, show }) => css`
    background: ${theme.color.white};
    display: ${show ? 'block' : 'none'};
    overflow: hidden;
    transition: width .3s ease;
    width: ${show ? '20%' : 0};

    .account-detail {
      opacity: ${show ? 1 : 0};
      transition: opacity .3s ease .3s;

      header {
        border-bottom: 1px solid ${theme.color.gray6};

        h3 {
          font: 400 24px/25px Lato;
          margin: 0;
          padding: 16px;
        }
      }

      dl {
        margin: 0;
        padding: 16px;

        div {
          display: block;
          margin-bottom: 13px;
        }

        dt { text-align: left; font-weight: 600 }
        dd { text-align: left }
        dt, dd {
          flex: 1;
          margin: 0;
          padding: 0;
        }

        p {
          margin: 0;
          font: 400 14px/25px Lato;
          width: 50%;
        }
      }
    }
  `}
`;
