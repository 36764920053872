import { AppMenuClasses } from 'components/AppMenuBar/AppMenuDropdownItem';
import AppMenuDropdownConfig from 'models/appMenuDropdownConfig';
import { useCallback, useEffect, useReducer } from 'react';

/**
 * Configuring actions and types for appMenuDropdownManager
 */
 enum DropDownActions {
  COLLAPSE_ALL = 'collapse_all',
  TOGGLE_EXPAND_ITEM = 'toggle_expand_item'
}

interface CollapseAllDropdownAction {
  type: typeof DropDownActions.COLLAPSE_ALL
}

interface ExpandDropdownItemAction {
  type: typeof DropDownActions.TOGGLE_EXPAND_ITEM,
  payload: { itemId: string }
}

export type DropDownAction =
  | CollapseAllDropdownAction
  | ExpandDropdownItemAction;

export interface AppMenuManagerReturn {
  /** updated state of the appMenu */
  appMenu: AppMenuDropdownConfig[];
  /** handler to collapse all menuItems */
  collapseAll: () => void;
  /** handler to toggled expand passed id and collapses sibling items */
  toggleExpandItem: (id: string) => void;
  /** selector to get appMenuItem from Id */
  selectMenuItem: (id: string) => AppMenuDropdownConfig;
}


/**
 * Recursively parses through items in config object to return all nested items as collapsed
 * @param menuItem  - original AppMenuDropdownConfig
 * @returns @see {@link AppMenuDropdownConfig} with isExpanded set to false
 */
const collapseItem = (menuItem: AppMenuDropdownConfig): AppMenuDropdownConfig => {
  let updatedItem: AppMenuDropdownConfig = new AppMenuDropdownConfig({...menuItem, isExpanded: false});
  if (!!updatedItem?.items) {
    updatedItem.items = updatedItem.items.map(item => collapseItem(item));
  }
  return updatedItem;
};

/**
 * Recursively searches appMenuItems and sets target id to new expanded state and parent items to isExpanded = true
 * @param id - id of the appMenuItem to find
 * @param menuItems - array of AppMenuDropdownConfigs to search
 * @param newState - boolean to signify if item should be expanded or not
 * @returns @see {@link AppMenuDropdownConfig} with new isExpanded state
 */
const expandItemChain = (id: string, menuItems: AppMenuDropdownConfig[], newState: boolean): AppMenuDropdownConfig[] => {
  const updatedMenuItems = [...menuItems];
  const itemIndex = menuItems.findIndex(item => id.includes(item.id));
  if (itemIndex !== -1) {
    if ( id === updatedMenuItems[itemIndex].id) {
      updatedMenuItems[itemIndex] = new AppMenuDropdownConfig({...updatedMenuItems[itemIndex], isExpanded: newState});
      return updatedMenuItems;
    } else if (id.includes(updatedMenuItems[itemIndex].id) && !!updatedMenuItems[itemIndex].items) {
      // Parent objects will be expanded regardless of item's new expanded state
      updatedMenuItems[itemIndex] = new AppMenuDropdownConfig({...updatedMenuItems[itemIndex], isExpanded: true});
      updatedMenuItems[itemIndex].items = expandItemChain(id, updatedMenuItems[itemIndex].items, newState);
      return updatedMenuItems;
    }
  }
  return updatedMenuItems;
};

/**
 * Recursively search the appMenuItems for matching id, returns null if id isn't found
 * @param id - id of the appMenuItem to find
 * @param menuItems - array of AppMenuDropdownConfigs to search
 * @returns @see {@link AppMenuDropdownConfig} | null 
 */
const findItem = (id: string, menuItems: AppMenuDropdownConfig[]): AppMenuDropdownConfig => {
  const foundItem = menuItems.find(menuItem => id.includes(menuItem.id));
  if (!foundItem) {
    return null;
  } else if ( id === foundItem.id ) {
    return foundItem;
  } else if (id.includes(foundItem.id) && !!foundItem.items) {
    return findItem(id, foundItem.items);
  } else {
    return null;
  }
};

/**
 * Helper to identify if a sting of classes matches the appMenu
 * @param classListString String returned from HTMLElement.classList.value
 * @returns boolean 
 */
const hasMenuClass = (classListString: string): boolean => {
  return Object.values(AppMenuClasses).some(className => classListString.includes(className));
};

/**
 * Recursively checks parent elements to determine if selected element exists within the App Menu
 * @param element - HTMLElement of the element to check
 * @returns boolean - true if element is within App Menu, false once the main App container is found
 */
const isMenuItem = (element: HTMLElement): boolean => {
  const classListString = element?.classList.value || '';
  const rootContainerClass = 'cb-app-container';
  const isRootElement = !element.parentElement;
  if (classListString.includes(rootContainerClass) || isRootElement) {
    // If the element is at the app root, or the dom root it's not in the app menu
    return false;
  } else if ( hasMenuClass(classListString) ) {
    // If the element has one of the app menu classes it's in the app menu
    return true;
  } else {
    // If not at the root and doesn't have a menu class check the parent element
    return isMenuItem(element.parentElement);
  }
};



/**
 * Manages the state of the AppMenuContext
 * @param initialMenu - takes orginal configuration of appMenu
 * @returns @see {@link AppMenuManagerReturn}
 */
export const useAppMenuDropdownManager = (initialMenu: AppMenuDropdownConfig[]): AppMenuManagerReturn => {
  const [appMenu, dispatch] = useReducer((state: AppMenuDropdownConfig[], action: DropDownAction) => {
    switch (action.type) {
      case DropDownActions.COLLAPSE_ALL:
        return state.map(menuItem => collapseItem(menuItem));
      case DropDownActions.TOGGLE_EXPAND_ITEM:
        // Get inverse boolean of current item's expanded state
        const newToggleState = !findItem(action.payload.itemId, state).isExpanded;
        // Reset state to be fully collapsed so sibling dropdown items will be closed when opening a dropdown item
        const collapsedState = state.map(menuItem => collapseItem(menuItem));
        return expandItemChain(action.payload.itemId, collapsedState, newToggleState);
      default:
        return state;
    }
  }, initialMenu);

  const collapseAll = useCallback((): void => {
    dispatch({type: DropDownActions.COLLAPSE_ALL});
  }, []);

  const toggleExpandItem = useCallback((id: string): void => {
    dispatch({type: DropDownActions.TOGGLE_EXPAND_ITEM, payload: { itemId: id }});
  }, []);

  const selectMenuItem = useCallback((id: string): AppMenuDropdownConfig => {
    return findItem(id, appMenu);
  }, [appMenu]);

  const menuIsOpen = (): boolean => {
    return appMenu.some(menuItem => menuItem.isExpanded);
  };

  // Applying Event listeners to check if clicked item is in the app menu
  useEffect(() => {
    const handler = (event: MouseEvent | TouchEvent) => {
      // Only check menu tree if menu is open
      if (menuIsOpen()) {
        const target = event.target as HTMLElement;
        if (!isMenuItem(target)) {
          // If clicked item is not within the app menu then close the app menu
          dispatch({type: DropDownActions.COLLAPSE_ALL});
        }
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [appMenu]);

  return { appMenu, collapseAll, toggleExpandItem, selectMenuItem };
};
