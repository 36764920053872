import Account from 'models/canvas/account';
import AccountMeta from 'models/accountMeta';
import { getUuid } from 'helpers';

/** TYPES */

export enum AccountsActionTypes {
  FETCH_ACCOUNT = 'accounts/FETCH_ACCOUNT',
  FETCH_ACCOUNT_CHILDREN = 'accounts/FETCH_ACCOUNT_CHILDREN',
  FETCH_ACCOUNT_SUB_ACCOUNTS = 'accounts/FETCH_ACCOUNT_SUB_ACCOUNTS',
  RECEIVE_ACCOUNTS = 'accounts/RECEIVE_ACCOUNTS',
  UPDATE_ACCOUNTS = 'accounts/UPDATE_ACCOUNTS',
  DELETE_ACCOUNTS = 'accounts/DELETE_ACCOUNTS',
  UPDATE_ACCOUNT_META = 'accounts/UPDATE_ACCOUNT_META',
  RESET_ACCOUNTS = 'accounts/RESET_ACCOUNTS',
  RECEIVE_ORIGINAL_ACCOUNTS = 'accounts/RECEIVE_ORIGINAL_ACCOUNTS'
}

export interface FetchAccountAction {
  type: typeof AccountsActionTypes.FETCH_ACCOUNT;
  payload: { accountId: Account['id'] };
}

export interface ReceiveAccountsAction {
  type: typeof AccountsActionTypes.RECEIVE_ACCOUNTS;
  payload: { accounts: Account[] };
}

export interface FetchAccountChildrenAction {
  type: typeof AccountsActionTypes.FETCH_ACCOUNT_CHILDREN;
  payload: { accountId: Account['id'], requestUuid: string };
}

export interface FetchAccountSubAccountsAction {
  type: typeof AccountsActionTypes.FETCH_ACCOUNT_SUB_ACCOUNTS;
  payload: { accountId: Account['id'], requestUuid: string };
}

export interface UpdateAccountsAction {
  type: typeof AccountsActionTypes.UPDATE_ACCOUNTS;
  payload: { accountIds: Account['id'][]; update: Partial<Account> };
}

export interface DeleteAccountsAction {
  type: typeof AccountsActionTypes.DELETE_ACCOUNTS;
  payload: { accountIds: Account['id'][]; };
}

export interface UpdateAccountMetaAction {
  type: typeof AccountsActionTypes.UPDATE_ACCOUNT_META;
  payload: { accountId: Account['id'], update: Partial<AccountMeta> }
}

export interface ResetAccountsAction {
  type: typeof AccountsActionTypes.RESET_ACCOUNTS;
}

export interface ReceiveOriginalAccountsAction {
  type: typeof AccountsActionTypes.RECEIVE_ORIGINAL_ACCOUNTS;
  payload: { accounts: Account[] };
}

export type AccountsAction =
  | FetchAccountAction
  | ReceiveAccountsAction
  | FetchAccountChildrenAction
  | UpdateAccountsAction
  | DeleteAccountsAction
  | UpdateAccountMetaAction
  | ResetAccountsAction
  | ReceiveOriginalAccountsAction;

/** ACTION CREATORS */

export const fetchAccount = (accountId: Account['id']): FetchAccountAction => ({
  type: AccountsActionTypes.FETCH_ACCOUNT,
  payload: { accountId }
});

export const receiveAccounts = (accounts: Account[]): ReceiveAccountsAction => ({
  type: AccountsActionTypes.RECEIVE_ACCOUNTS,
  payload: { accounts }
});

export const fetchAccountChildren = (accountId: Account['id']): FetchAccountChildrenAction => ({
  type: AccountsActionTypes.FETCH_ACCOUNT_CHILDREN,
  payload: { accountId, requestUuid: getUuid() }
});

export const fetchAccountSubAccounts = (accountId: Account['id']): FetchAccountSubAccountsAction => ({
  type: AccountsActionTypes.FETCH_ACCOUNT_SUB_ACCOUNTS,
  payload: { accountId, requestUuid: getUuid() }
});

export const updateAccounts = (accountIds: Account['id'][], update: Partial<Account>): UpdateAccountsAction => ({
  type: AccountsActionTypes.UPDATE_ACCOUNTS,
  payload: { accountIds, update }
});

export const deleteAccounts = (accountIds: Account['id'][]): DeleteAccountsAction => ({
  type: AccountsActionTypes.DELETE_ACCOUNTS,
  payload: { accountIds }
});

export const updateAccountMeta = (accountId: Account['id'], update: Partial<AccountMeta>): UpdateAccountMetaAction => ({
  type: AccountsActionTypes.UPDATE_ACCOUNT_META,
  payload: { accountId, update }
});

export const resetAccounts = (): ResetAccountsAction => ({
  type: AccountsActionTypes.RESET_ACCOUNTS
});

export const receiveOriginalAccounts = (accounts: Account[]): ReceiveOriginalAccountsAction => ({
  type: AccountsActionTypes.RECEIVE_ORIGINAL_ACCOUNTS,
  payload: { accounts }
});
