import styled from 'styled-components';

export const SyncStatusCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  .sync-icon {
    color: ${({ theme }) => theme.color.orange};
    &.syncing {
      color: ${({ theme }) => theme.color.green};
    }
  }
`;
