import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Wrapper, Message, ILoadingProps } from './style';

const Loading = React.memo((
  { message }: ILoadingProps
) => (
  <Wrapper data-testid='loading-component'>
    <Message>{ message }</Message>
    <FontAwesomeIcon icon={['fas', 'spinner']} spin />
  </Wrapper>
));

export default Loading;
