import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from 'react-smooth-collapse';
import { filter, includes } from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SyncItemStatusError } from 'constants/index';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getAllItemsForSyncOrder, getSyncOrderDto, getSyncOrderProgress,
  syncItemIsAccount, syncItemIsCourse, syncItemIsTerm } from 'store/syncOrders/selectors';

import { Button, ProgressBar } from 'components/elements';
import { SyncOrderDetails } from './SyncOrderDetails';
import { SyncJobItem, Description, Data } from './style';
import BaseSyncItemEntity from 'models/syncOrder/baseSyncItemEntity';

interface ISyncOrderStatusProps {
  syncOrderId: string,
  type?: 'active' | 'finished'
}

export const SyncOrderItem: React.FC<ISyncOrderStatusProps> = React.memo(({ syncOrderId, type = 'finished' }) => {
  const syncOrderDto = useSelectorWithProps(getSyncOrderDto, { syncOrderId });
  const allItems: BaseSyncItemEntity[] = useSelectorWithProps(getAllItemsForSyncOrder, { syncOrderId });
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const progress = useSelectorWithProps(
    getSyncOrderProgress,
    { syncOrderIds: [syncOrderId] }
  );

  const termItems: BaseSyncItemEntity[] = useMemo(() => {
    return filter(allItems, (item: BaseSyncItemEntity) => syncItemIsTerm(item));
  }, [allItems]);

  const accountItems: BaseSyncItemEntity[] = useMemo(() => {
    return filter(allItems, (item: BaseSyncItemEntity) => syncItemIsAccount(item));
  }, [allItems]);

  const courseItems: BaseSyncItemEntity[] = useMemo(() => {
    return filter(allItems, (item: BaseSyncItemEntity) => syncItemIsCourse(item));
  }, [allItems]);

  const errorItems: BaseSyncItemEntity[] = useMemo(() => {
    return filter(allItems, (item: BaseSyncItemEntity) => {
      return includes(SyncItemStatusError, item?.syncItemResult?.syncStatus);
    });
  }, [allItems]);

  return (
    <SyncJobItem className={`sync-job-item sync-job-item-${type}`} key={syncOrderId}>
      <Description>
        <Data>
          <p>{moment(syncOrderDto?.syncOrder?.createdAt).format('ddd. LLL')}</p>
          {!!termItems.length && (
            <p>{t('termWithCount', { count: termItems.length })}</p>
          )}
          {!!accountItems.length && (
            <p>{t('accountWithCount', { count: accountItems.length })}</p>
          )}
          {!!courseItems.length && (
            <p>{t('courseWithCount', { count: courseItems.length })}</p>
          )}
          {!!errorItems.length && (
            <p className='error'>{t('errorWithCount', { count: errorItems.length })}</p>
          )}
          <Button
            variant='link'
            color='secondary'
            onClick={() => setExpanded(prev => !prev)}
            rotate={expanded ? 90 : 0}
            ariaLabel={`${expanded ? 'Hide' : 'Show'} Sync Job Details`}
          >
            <FontAwesomeIcon icon={['fas', 'angle-right']} />
          </Button>
        </Data>
      </Description>

      {
        type === 'active' && (
          <ProgressBar
            total={progress.total}
            success={progress.success}
            errors={progress.error}
            size='medium'
          />
        )
      }

      <Collapse
        id={`sync-order-${syncOrderId}`}
        expanded={expanded}
        heightTransition='.4s ease'
        className='sync-item-collapse'
      >
        <SyncOrderDetails syncOrderId={syncOrderDto?.syncOrder?.syncOrderId} />
      </Collapse>
    </SyncJobItem>
  );
});
