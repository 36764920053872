import { get } from 'lodash';
import { getUuid } from 'helpers';
import BaseSyncAccount from './baseSyncAccount';
export default class SyncExistingAccount extends BaseSyncAccount {  
  syncExistingAccountId: string;  

  constructor(json: any) {
    super(json);    
    this.syncExistingAccountId = get(json, 'syncExistingAccountId', getUuid());    
  }
}
