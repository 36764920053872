import { createStore, combineReducers, Store, applyMiddleware, compose, Middleware } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { values } from 'lodash';
import * as operators from 'rxjs/operators';
import { connectRouter, RouterState, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';

import * as config from '../config';
import * as accountEpics from './accounts/epics';
import * as templateEpics from './templates/epics';
import * as installationEpics from './installation/epics';
import * as syncOrderEpics from './syncOrders/epics';
import * as pageRatingReportEpics from './pageRatingReport/epics';
import * as courseEpics from './courses/epics';
import * as termEpics from './terms/epic';
import * as requestEpics from './requests/epics';

import modalsReducer, { ModalsFeatureState, defaultModalsFeatureState } from './modals/reducer';
import templatesReducer, { TemplatesFeatureState, defaultTemplatesFeatureState } from './templates/reducer';
import accountsReducer, { AccountsFeatureState, defaultAccountsState } from './accounts/reducer';
import coursesReducer, { CoursesFeatureState, defaultCoursesFeatureState } from './courses/reducer';
import requestsReducer, { RequestsFeatureState, defaultRequestsFeatureState } from './requests/reducer';
import installationReducer, { InstallationFeatureState, defaultInstallationState } from './installation/reducer';
import syncOrderReducer, { SyncOrderFeatureState, defaultSyncOrderState } from './syncOrders/reducer';
import { FunctionBarFeatureState, functionBarReducer, defaultFunctionBarState } from './function-bar/reducer';
import { AccountTreeFeatureState, accountTreeReducer, defaultAccountTreeState } from './account-tree/reducer';
import { DataTableFeatureState, dataTableReducer, defaultDataTableFeatureState } from 'modules/data-table/store/data-table/reducer';
import termsReducer, { TermsFeatureState, defaultTermsFeatureState } from './terms/reducer';
import pageRatingReportReducer, { defaultPageRatingReportState, PageRatingReportFeatureState } from './pageRatingReport/reducer';

const isDev = config.ENV === 'development';
const isTest = config.ENV === 'test';

export const browserHistory = isTest ? createMemoryHistory() : createBrowserHistory();

export interface AppState {
  accountTree: AccountTreeFeatureState;
  accounts: AccountsFeatureState;
  courses: CoursesFeatureState;
  dataTable: DataTableFeatureState;
  functionBar: FunctionBarFeatureState;
  installation: InstallationFeatureState;
  modals: ModalsFeatureState;
  requests: RequestsFeatureState;
  router: RouterState;
  syncOrders: SyncOrderFeatureState;
  pageRatingReport: PageRatingReportFeatureState;
  templates: TemplatesFeatureState;
  terms: TermsFeatureState;
}

export const createRootReducer = (history: any) => combineReducers<AppState>({
  router: connectRouter(history),
  installation: installationReducer,
  requests: requestsReducer,
  accounts: accountsReducer,
  courses: coursesReducer,
  templates: templatesReducer,
  modals: modalsReducer,
  syncOrders: syncOrderReducer,
  pageRatingReport: pageRatingReportReducer,
  functionBar: functionBarReducer,
  accountTree: accountTreeReducer,
  dataTable: dataTableReducer,
  terms: termsReducer,
});

export const defaultAppState: AppState = {
  // must leave undefined, this 3rd-party store feature handles its own default state
  router: undefined,
  installation: defaultInstallationState,
  requests: defaultRequestsFeatureState,
  accounts: defaultAccountsState,
  courses: defaultCoursesFeatureState,
  templates: defaultTemplatesFeatureState,
  modals: defaultModalsFeatureState,
  syncOrders: defaultSyncOrderState,
  pageRatingReport: defaultPageRatingReportState,
  functionBar: defaultFunctionBarState,
  accountTree: defaultAccountTreeState,
  dataTable: defaultDataTableFeatureState,
  terms: defaultTermsFeatureState,
};

const rootEpic = combineEpics(
  ...values(accountEpics),
  ...values(templateEpics),
  ...values(installationEpics),
  ...values(syncOrderEpics),
  ...values(pageRatingReportEpics),
  ...values(courseEpics),
  ...values(termEpics),
  ...values(requestEpics)
);

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    injected_WithLatestFrom: operators.withLatestFrom
  }
});

const prodMiddlewares: Middleware[] = [
  epicMiddleware,
  routerMiddleware(browserHistory)
];

let enhancer = compose(
  applyMiddleware(
    ...prodMiddlewares
  )
);

if (isDev) {
  const logger = createLogger({
    collapsed: true,
  });

  if (logger) {
    enhancer = composeWithDevTools(
      applyMiddleware(
        ...prodMiddlewares,
        // logger
      )
    );
  }
}

export const store: Store<AppState> = createStore(
  createRootReducer(browserHistory),
  enhancer
);

// call this after 'createStore'
epicMiddleware.run(rootEpic);
