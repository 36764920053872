import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { USER_AGENT } from 'config';
import { toggleModal } from 'store/modals/actions';
import { Modal, Button } from 'components/elements';
import { ModalHeading, SectionContent, SectionFooter } from 'components/general-styles';
import { TableWrapper } from './style';
import { ModalId } from 'constants/index';

interface IDataTableKeyboardInfo {}

const EXTRA_KEY = USER_AGENT.isMac ? 'Option' : 'Alt';

const DataTableKeyboardInfo: FC<IDataTableKeyboardInfo> = () => {
  const modalId = ModalId.DATA_TABLE_KEYBOARD_INFO;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClose = useCallback(() => {
    dispatch(toggleModal(modalId, false, {}));
  }, [dispatch, modalId]);

  return (
    <Modal id={modalId} handleClose={handleClose}>
      <SectionContent>
        <ModalHeading>
          <h2>{t('modalKeyboardTitle')}</h2>
          <p>
            {t('modalKeyboardDescription')}
            <br />
            <a id='keyboard-nav-top' href='#keyboard-nav-bottom'>{t('skipToBottom')}</a>
          </p>
        </ModalHeading>
        <TableWrapper>
          <table>
            <caption>{t('modalKeyboardTableCaption')}</caption>
            <thead>
              <tr>
                <th scope='col'>{t('key')}</th>
                <th scope='col'>{t('action')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><kbd>{t('upArrowKey')}</kbd></td>
                <td>{t('actionUpArrow')}</td>
              </tr>
              <tr>
                <td><kbd>{t('downArrowKey')}</kbd></td>
                <td>{t('actionDownArrow')}</td>
              </tr>
              <tr>
                <td><kbd>{t('leftArrowKey')}</kbd></td>
                <td>{t('actionLeftArrow')}</td>
              </tr>
              <tr>
                <td><kbd>{t('rightArrowKey')}</kbd></td>
                <td>{t('actionRightArrow')}</td>
              </tr>
              <tr>
                <td><kbd>{EXTRA_KEY} + {t('upArrowKey')}</kbd></td>
                <td>{t('actionAltUpArrow')}</td>
              </tr>
              <tr>
                <td><kbd>{EXTRA_KEY} + {t('downArrowKey')}</kbd></td>
                <td>{t('actionAltDownArrow')}</td>
              </tr>
              <tr>
                <td><kbd>{EXTRA_KEY} + {t('leftArrowKey')}</kbd> (or <kbd>Home</kbd>)</td>
                <td>{t('actionAltLeftArrow')}</td>
              </tr>
              <tr>
                <td><kbd>{EXTRA_KEY} + {t('rightArrowKey')}</kbd> (or <kbd>End</kbd>)</td>
                <td>{t('actionAltRightArrow')}</td>
              </tr>
              <tr>
                <td><kbd>{t('pageUpKey')}</kbd></td>
                <td>{t('actionPageDown')}</td>
              </tr>
              <tr>
                <td><kbd>{t('pageDownKey')}</kbd></td>
                <td>{t('actionPageUp')}</td>
              </tr>
              <tr>
                <td><kbd>{t('enterKey')}</kbd></td>
                <td>{t('actionEnter')}</td>
              </tr>
              <tr>
                <td><kbd>{t('escapeKey')}</kbd></td>
                <td>{t('actionEscape')}</td>
              </tr>
              <tr>
                <td><kbd>{t('spaceKey')}</kbd> or <kbd>{t('enterKey')}</kbd></td>
                <td>{t('actionSpaceEnter')}</td>
              </tr>
              <tr>
                <td><kbd>{t('tabKey')}</kbd></td>
                <td>{t('actionTab')}</td>
              </tr>
              <tr>
                <td><kbd>Shift + {t('tabKey')}</kbd></td>
                <td>{t('actionShiftTab')}</td>
              </tr>
            </tbody>
          </table>
          <a id='keyboard-nav-bottom' href='#keyboard-nav-top' aria-label='Back to Top'>Top</a>
        </TableWrapper>
      </SectionContent>
      <SectionFooter>
        <div />
        <div>
          <Button
            color='primary'
            onClick={handleClose}
            ariaLabel='Ok -- close modal'
          >
            Ok
          </Button>
        </div>
      </SectionFooter>
    </Modal>
  );
};

export default React.memo(DataTableKeyboardInfo);
