import { get } from 'lodash';

export default class Installation {
  installationId: string;
  ltiId: string;
  ltiAppName: string;
  consumerId: string;
  lmsId: string;
  serviceTierId: string;
  launchDomain: string;
  config: {
    [configKey: string]: any
  };

  constructor(json: any) {
    this.installationId = get(json, 'installationId');
    this.ltiId = get(json, 'ltiId');
    this.ltiAppName = get(json, 'ltiAppName');
    this.consumerId = get(json, 'consumerId');
    this.lmsId = get(json, 'lmsId');
    this.serviceTierId = get(json, 'serviceTierId');
    this.launchDomain = get(json, 'launchDomain');
    this.config = get(json, 'overrideLtiConfig', {});
  }
}
