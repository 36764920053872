import Installation from 'models/installation';
import CourseBuilderInstallationConfig from 'models/courseBuilderInstallationConfig';
import Account from 'models/canvas/account';

/** TYPES */

export enum InstallationActionTypes {
  FETCH_INSTALLATION_DATA = 'installation/FETCH_INSTALLATION_DATA',
  RECEIVE_INSTALLATION = 'installation/RECEIVE_INSTALLATION',
  RECEIVE_CONFIG = 'installation/RECEIVE_CONFIG',
  SET_LMS_BASE_URL = 'installation/SET_LMS_BASE_URL',
  SET_TOP_ACCOUNT_ID = 'installation/SET_TOP_ACCOUNT_ID'
}

export interface FetchInstallationDataAction {
  type: typeof InstallationActionTypes.FETCH_INSTALLATION_DATA;
}

export interface ReceiveInstallationAction {
  type: typeof InstallationActionTypes.RECEIVE_INSTALLATION;
  payload: { installation: Installation };
}

export interface ReceiveConfigAction {
  type: typeof InstallationActionTypes.RECEIVE_CONFIG;
  payload: { config: CourseBuilderInstallationConfig };
}

export interface SetLmsBaseUrlAction {
  type: typeof InstallationActionTypes.SET_LMS_BASE_URL;
  payload: { lmsBaseUrl: string };
}

export interface SetTopAccountIdAction {
  type: typeof InstallationActionTypes.SET_TOP_ACCOUNT_ID;
  payload: { topAccountId: Account['id'] };
}

export type InstallationAction =
  | FetchInstallationDataAction
  | ReceiveInstallationAction
  | ReceiveConfigAction
  | SetLmsBaseUrlAction
  | SetTopAccountIdAction;

/** ACTION CREATORS */

export const fetchInstallationData = (): FetchInstallationDataAction => ({
  type: InstallationActionTypes.FETCH_INSTALLATION_DATA
});

export const receiveInstallation = (installation: Installation): ReceiveInstallationAction => ({
  type: InstallationActionTypes.RECEIVE_INSTALLATION,
  payload: { installation }
});

export const receiveConfig = (config: CourseBuilderInstallationConfig): ReceiveConfigAction => ({
  type: InstallationActionTypes.RECEIVE_CONFIG,
  payload: { config }
});

export const setLmsBaseUrl = (lmsBaseUrl: string): SetLmsBaseUrlAction => ({
  type: InstallationActionTypes.SET_LMS_BASE_URL,
  payload: { lmsBaseUrl }
});

export const setTopAccountId = (topAccountId: Account['id']): SetTopAccountIdAction => ({
  type: InstallationActionTypes.SET_TOP_ACCOUNT_ID,
  payload: { topAccountId }
});
