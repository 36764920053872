/** TYPES  */

export enum ModalsActionTypes {
  TOGGLE_MODAL = 'modals/TOGGLE_MODAL'
}

export interface ToggleModalAction {
  type: typeof ModalsActionTypes.TOGGLE_MODAL;
  payload: { id: string, data: { [key: string]: any }, open: boolean };
}

export type ModalsAction =
  | ToggleModalAction;

/** ACTION CREATORS */

export const toggleModal = (id: string, open: boolean, data: any): ToggleModalAction => ({
  type: ModalsActionTypes.TOGGLE_MODAL,
  payload: { id, open, data }
});
