import { createSelector } from 'reselect';
import { get } from 'lodash';
import { AppState } from 'store';
import Modal from 'models/modal';
import { ModalsFeatureState, defaultModalState } from './reducer';
import { memoizeSelectorFactory } from 'store/selector-helpers';

export type PerModalProps = { modalId: string };

export const getModalsFeature = (appState: AppState): ModalsFeatureState => get(appState, 'modals');

export const getModalById = memoizeSelectorFactory((props: PerModalProps) => createSelector(
  getModalsFeature,
  (featureState: ModalsFeatureState): Modal => get(featureState, `${props.modalId}`, defaultModalState)
));
