import styled, { css } from 'styled-components';
import { srOnly } from 'styles/classes';
import { pulseAnimation } from 'styles/keyframes';

interface ILabel {
  disabled?: boolean;
}

interface ICustomCheckboxContainer {
  selected: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
}

const sideLength = '1.6rem';

export const Wrapper = styled.div``;

export const CheckboxInput = styled.input`
  ${srOnly}
`;

export const IndeterminateIcon = styled.span`
  font-weight: 800;
  display: inline-block;
  margin-top: -2px;
`;

export const Label = styled.label<ILabel>`
  color: ${({ theme }) => theme.color.font};
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  margin: 0;
  pointer-events: all;
  position: relative;
  user-select: none;

  ${({ disabled, theme }) => disabled && css`
    color: ${theme.color.gray4}
  `}
`;

export const ScreenReaderLabelText = styled.span`
  ${srOnly}
`;

export const CustomCheckboxContainer = styled.button<ICustomCheckboxContainer & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  align-items: center;
  background-color: ${({ theme, selected, disabled }) => {
    if (disabled) {
      return theme.color.gray6;
    }
    if (selected) {
      return theme.color.mainDark;
    }
    return theme.color.gray7;
  }};
  border: 1px solid ${({ theme }) => theme.color.gray4};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  justify-content: center;
  margin-right: 8px;
  max-height: ${sideLength};
  overflow: hidden;
  padding: 0;
  width: ${sideLength};
  height: ${sideLength};
  color: ${({ theme }) => theme.color.white};

  &.style-radio {
    background-color: ${({ theme, selected, disabled }) => (selected && !disabled ? theme.color.white : theme.color.gray7)};
    border-radius: 50%;
    font-size: .8rem;
    width: ${sideLength + 3};
    height: ${sideLength + 3};
    color: ${({ theme }) => theme.color.mainDark};
    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.color.mainDark};
    }
  }

  > .icon-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    font-size: 1.2rem;
    height: ${sideLength};
    width: ${sideLength};
    overflow: hidden;
  }

  ${Label}:hover &,
  ${Label}:focus &,
  ${Label}:focus-within & {
    ${pulseAnimation}
    border-color: ${({ theme }) => theme.color.mainDark};
  }
  
`;
