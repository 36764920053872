import React, { useCallback, SyntheticEvent } from 'react';
import cn from 'classnames';
import { Label, CheckboxInput, CustomCheckboxContainer, IndeterminateIcon } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ISelectionCheckboxProps {
  id: string;
  name?: string;
  selected?: boolean;
  onClick?: (checked: boolean) => void;
  children?: any;
  indeterminate?: boolean; // whether to show the mixed/partial-selection state
  checkboxStyle?: 'checkbox'|'radio';
  readOnly?: boolean;
  dataTip?: string;
  dataFor?: string;
}

const SelectionCheckbox: React.FC<ISelectionCheckboxProps> = React.memo(({
  id,
  name,
  selected,
  onClick,
  children,
  indeterminate,
  checkboxStyle = 'checkbox',
  readOnly = false,
  dataTip,
  dataFor,
}) => {
  /** Invoke the handler with the new selected value */
  const handleCustomCheckboxClick = useCallback(() => {
    if (onClick) {
      onClick(!selected);
    }
  }, [onClick, selected]);

  /** Prevent any label click from causing a selection toggle
   * (otherwise collides with edit-in-place click on label text) */
  const handleLabelClick = useCallback((e: SyntheticEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const icon = indeterminate
      ? <IndeterminateIcon id='indeterminateIcon'>&mdash;</IndeterminateIcon>
      : <FontAwesomeIcon icon={['fas', checkboxStyle === 'radio' ? 'circle' : 'check']} />;

  const ariaChecked = indeterminate
    ? 'mixed'
    : (selected ? 'true' : 'false');

  return (
    <>
      <Label
        className={cn('selection-checkbox', { selected, readOnly })}
        htmlFor={id}
        onClick={handleLabelClick}
        disabled={readOnly}
        
      >
        <CheckboxInput 
          type='checkbox' 
          name={name} 
          id={id} 
          checked={selected} 
          tabIndex={-1} 
          data-testid={name} 
          readOnly 
          data-tip={dataTip}
          data-for={dataFor} />

        <CustomCheckboxContainer
          className={`selection-checkbox-button style-${checkboxStyle}`}
          id={`${id}-checkbox-button`}
          selected={selected}
          disabled={readOnly}
          onClick={handleCustomCheckboxClick}
          type='button'
          tabIndex={0}
          role='checkbox'
          aria-labelledby={`${id}-button-label`}
          aria-checked={ariaChecked}
          data-testid={`${id}-button`}
        >
          <span className='icon-wrapper' data-tip={dataTip} data-for={dataFor}>
            {icon}
          </span>
        </CustomCheckboxContainer>

        <label 
          id={`${id}-button-label`} 
          className='selection-checkbox-label'>
            {children}
        </label>
      </Label>
    </>
  );
});

export default SelectionCheckbox;
