import { v4 as uuid } from 'uuid';

/**
 * generates a random string of letters of a given length
 */
export const generateRandomString = (length: number) =>
  Array.from({ length })
    .map(() => Math.random().toString(36).charAt(2))
    .join('');

/**
 * generates an unique id
 */
export const getUuid = () => uuid();

/**
 * generates an ISO date
 */
export const getDateISOString = (): string => new Date().toISOString();