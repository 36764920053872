import styled from 'styled-components';

export const List = styled.div`
  background: ${({ theme }) => theme.color.gray8};
  border: 1px solid ${({ theme }) => theme.color.gray6};
  margin: 0;

  .template-description-collapse {
    background: ${({ theme }) => theme.color.white};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray6};
    overflow: hidden;
    height: 0;
    padding: 0;
    margin: 0;

    div {
      padding: 16px 16px 16px 56px
    }
  }
`;

export const Item = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray6};
  display: flex;
  height: 40px;

  &:last-child {
    border: none;
  }
`;
export const Check = styled.div`
  border-right: 1px solid ${({ theme }) => theme.color.gray6};
  height: 40px;
  margin-right: 16px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Description = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;

  & > svg {
    color: ${({ theme }) => theme.color.main};
  }
`;

export const Name = styled.div`
  display: flex;
  flex-grow: 1;

  && p {
    font: 400 1.4rem/2.5rem ${({ theme }) => theme.font.main};
    margin: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center
`;
