import styled from 'styled-components';

interface ISyncStatusHeaderCellWrapper {
  desc: boolean;
}

export const SyncStatusHeaderCellWrapper = styled.div<ISyncStatusHeaderCellWrapper>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 80%;
  .sync-sort-button {
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    .sync-icon {
      margin-bottom: 3px;
      color: ${({ theme }) => theme.color.font};
    }
    .sort-icon {
      font-size: 1.2rem;
      transition: transform .2s ease;
      transform: ${({ desc }) => (desc ? 'rotate(180deg)' : 'none')};
    }
  }
`;
