import { DataTableConfig, ColumnSortConfig, ColumnFilterConfig, ColumnDef } from 'modules/data-table/types';

export const defaultDataTableConfig: DataTableConfig = {
  columnDefs: [],
  rowIdProperty: 'id',
  maxSimultaneousSorts: 1,
  defaultRowHeight: 40,
};

export const defaultColumnSortConfig: Partial<ColumnSortConfig> = {
  direction: 'asc'
};

export const defaultColumnFilterConfig: Partial<ColumnFilterConfig> = {
  caseSensitive: false
};

export const defaultColumnDef: Partial<ColumnDef> = {
  sortable: true,
  filterable: true,
  defaultHidden: false,
  toggleable: true
};
