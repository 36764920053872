import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { SyncItemStatusCompleted, SyncItemStatusPending, SyncItemStatusActive, SyncItemStatusError, ISyncTypeData, SyncItemStatus, SyncItemStatusUnsynced } from 'constants/index';

import BaseSyncItemEntity from 'models/syncOrder/baseSyncItemEntity';
import { getSyncItemTypeData } from 'store/syncOrders/selectors';

interface ISyncItemDetail {
  syncItem: BaseSyncItemEntity;
}

export const SyncItemDetail: React.FC<ISyncItemDetail> = React.memo(({ syncItem }) => {
  const { t } = useTranslation();
  const status: SyncItemStatus = syncItem?.syncItemResult?.syncStatus as SyncItemStatus;
  const { isNew }: ISyncTypeData = getSyncItemTypeData(syncItem);

  const details: Record<SyncItemStatus, ReactNode> = {
    [SyncItemStatusCompleted.SUCCESS]: isNew ? t('successfullyCreated') : t('successfullySynced'),
    [SyncItemStatusPending.PENDING]: '-',
    [SyncItemStatusActive.RUNNING]: '-',
    [SyncItemStatusUnsynced.UNSYNCED]: '-',
    [SyncItemStatusError.ERROR]: <span className='error'>{t('errorProcessing')}</span>,
    [SyncItemStatusError.PARENT_ACCOUNT_NOT_FOUND]: <span className='error'>{t('errorParentAccount')}</span>,
    [SyncItemStatusError.APPLY_COURSE_TEMPLATE_FAILED]: <span className='error'>{t('errorApplyingTemplate')}</span>,
    [SyncItemStatusError.COURSE_COPY_FAILED]: <span className='error'>{t('errorCopyingCourseContent')}</span>
  };

  return (
    <>
      {details[status]}
    </>
  );
});
