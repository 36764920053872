import React, { FC, SyntheticEvent, useCallback, useRef, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { DataTableObservables } from 'modules/data-table/hooks/useDataTableObservables';
import { useObservable } from 'rxjs-hooks';
import AutoSizer from 'react-virtualized-auto-sizer';
import { TableRow } from '../TableRow';
import { syncCenterScrollWithHeaderFocus } from 'modules/data-table/util';
import cn from 'classnames';
import { HorizontalScroller } from '../HorizontalScroller';

interface ITableBody {
  api: DataTableObservables;
  dragItemType?: string;
  hasCustomDragItemRenderer?: boolean;
  rowCanDrag?: (row: any) => boolean;
  tableId: string;
}

// classnames that other files depend on
export const TABLE_BODY_VIEWPORT_CLASSNAME = 'table-body-viewport';
export const CENTER_COLUMNS_CLIPPER_CLASSNAME = 'center-columns-clipper';
export const TABLE_CELL_CLASSNAME = '--data-table-cell';
export const BODY_CELL_CLASSNAME = '--data-table-body-cell';
export const HEADER_CELL_CLASSNAME = '--data-table-header-cell';
export const ROW_CLASSNAME = '--data-table-row';

export const TableBody: FC<ITableBody> = ({
  api,
  dragItemType,
  hasCustomDragItemRenderer,
  rowCanDrag,
  tableId,
}) => {
  const centerColumnsViewportRef = useRef<HTMLDivElement>();
  const horizontalScrollViewportRef = useRef<HTMLDivElement>();
  const bodyViewportRef = useRef<HTMLDivElement>();

  const selections = useObservable(() => api.selections$, {});
  const defaultRowHeight = useObservable(() => api.defaultRowHeight$);
  const rowIdProperty = useObservable(() => api.rowIdProperty$, 'id');
  const pinnedLeftColumnDefs = useObservable(() => api.pinnedLeftColumnDefs$, []);
  const centerColumnDefs = useObservable(() => api.centerColumnDefs$, []);
  const columnVisibility = useObservable(() => api.columnVisibility$, {});

  const columnIndices = useObservable(() => api.columnIndices$, {});
  const totalHeight = useObservable(() => api.totalHeight$);
  const totalPinnedLeftWidth = useObservable(() => api.totalPinnedLeftWidth$);
  const totalCenterWidth = useObservable(() => api.totalCenterWidth$);
  const rowsToRender = useObservable(() => api.rowsToRender$, []);
  const rowPositions = useObservable(() => api.rowPositions$, {});
  const rowHeights = useObservable(() => api.rowHeights$, {});
  const bodyViewportScrollPosition = useObservable(() => api.bodyViewportScrollPosition$, { top: 0, left: 0 });
  const centerColumnsScrollPosition = useObservable(() => api.centerColumnsScrollPosition$, { top: 0, left: 0 });
  const focusedHeader = useObservable(() => api.focusedHeaderCell$);

  const debouncedViewboxUpdate = useMemo(() => debounce((height: number, width: number) => {
    api.viewboxDimensions$.next({ height, width });
  }, 500), [api]);

  const handleViewportResize = useCallback(({ height, width }: any) => {
    debouncedViewboxUpdate(height, width);
  }, [debouncedViewboxUpdate]);

  const handleBodyViewportScroll = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    api.bodyViewportScrollPosition$.next({ top: e.currentTarget.scrollTop, left: e.currentTarget.scrollLeft });
  }, [api]);

  const handleCenterColumnsScroll = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    api.centerColumnsScrollPosition$.next({ top: e.currentTarget.scrollTop, left: e.currentTarget.scrollLeft });
    horizontalScrollViewportRef.current.scrollLeft = e.currentTarget.scrollLeft;
  }, [api, horizontalScrollViewportRef]);

  const handleHorizontalScrollViewportScroll = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    centerColumnsViewportRef.current.scrollLeft = e.currentTarget.scrollLeft;
  }, [centerColumnsViewportRef]);

  useEffect(() => {
    syncCenterScrollWithHeaderFocus(focusedHeader, centerColumnsViewportRef);
  }, [focusedHeader, centerColumnsViewportRef]);

  const centerColumnsIsScrolled = centerColumnsScrollPosition.left > 5;

  // don't memoize this since the ref dependency would not change
  const scrollbarWidth = bodyViewportRef.current
    ? bodyViewportRef.current.offsetWidth - bodyViewportRef.current.clientWidth
    : 0;

  return (
    <div
      className={cn('table-body-container')}
      data-testid='table-body-container'
      role='presentation'
    >
      {rowsToRender.length < 1 && (
        <div className='no-rows-message'>No rows to display</div>
      )}
      <AutoSizer onResize={handleViewportResize}>
        {({ width, height }) => (
          <div
            className='table-body-viewport-wrapper'
            style={{
              height,
              maxHeight: totalHeight + scrollbarWidth,
              width,
              maxWidth: width,
              minWidth: width,
            }}
          >
            <div
              className={cn(TABLE_BODY_VIEWPORT_CLASSNAME, { 'center-scrolled': centerColumnsIsScrolled })}
              ref={bodyViewportRef}
              role='presentation'
              onScroll={handleBodyViewportScroll}
            >
              <div
                className='pinned-left-columns'
                role='rowgroup'
                style={{
                  height: totalHeight,
                  width: totalPinnedLeftWidth,
                  maxWidth: totalPinnedLeftWidth,
                  minWidth: totalPinnedLeftWidth,
                  flexBasis: totalPinnedLeftWidth,
                }}
              >
                {rowsToRender.map((row: any) => {
                  const rowId = row?.[rowIdProperty];
                  const canDrag = typeof rowCanDrag === 'function' ? rowCanDrag(row) : false;
                  return (
                    <TableRow
                      key={`${tableId}-${rowId}`}
                      container='left'
                      tableId={tableId}
                      scrollTop={bodyViewportScrollPosition.top}
                      row={row}
                      api={api}
                      rowPositions={rowPositions}
                      columnVisibility={columnVisibility}
                      rowId={rowId}
                      columnsToInclude={pinnedLeftColumnDefs}
                      rowHeights={rowHeights}
                      selections={selections}
                      defaultRowHeight={defaultRowHeight}
                      columnIndices={columnIndices}
                      dragItemType={dragItemType}
                      canDrag={canDrag}
                      hasCustomDragItemRenderer={hasCustomDragItemRenderer}
                      minWidth={totalPinnedLeftWidth}
                    />
                  );
                })}
              </div>
              <div
                className={CENTER_COLUMNS_CLIPPER_CLASSNAME}
                role='presentation'
                style={{ height: totalHeight }}
              >
                <div
                  className='center-columns-viewport'
                  role='presentation'
                  onScroll={handleCenterColumnsScroll}
                  ref={centerColumnsViewportRef}
                  style={{
                    height: `calc(100% + ${scrollbarWidth}px)`
                  }}
                >
                  <div
                    className='center-columns'
                    role='rowgroup'
                    style={{ width: totalCenterWidth, minWidth: totalCenterWidth, maxWidth: totalCenterWidth }}
                  >
                    {rowsToRender.map((row: any) => {
                      const rowId = row?.[rowIdProperty];
                      const canDrag = typeof rowCanDrag === 'function' ? rowCanDrag(row) : false;
                      return (
                        <TableRow
                          key={`${tableId}-${rowId}`}
                          container='center'
                          tableId={tableId}
                          scrollTop={bodyViewportScrollPosition.top}
                          row={row}
                          api={api}
                          rowPositions={rowPositions}
                          columnVisibility={columnVisibility}
                          rowId={rowId}
                          columnsToInclude={centerColumnDefs}
                          rowHeights={rowHeights}
                          selections={selections}
                          defaultRowHeight={defaultRowHeight}
                          columnIndices={columnIndices}
                          dragItemType={dragItemType}
                          canDrag={canDrag}
                          hasCustomDragItemRenderer={hasCustomDragItemRenderer}
                          minWidth={width - totalPinnedLeftWidth - scrollbarWidth}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {rowsToRender.length > 0 && (
              <HorizontalScroller
                ref={horizontalScrollViewportRef}
                onScroll={handleHorizontalScrollViewportScroll}
                scrollbarWidth={scrollbarWidth}
                totalCenterWidth={totalCenterWidth}
                totalPinnedLeftWidth={totalPinnedLeftWidth}
              />
            )}
          </div>
        )}
      </AutoSizer>
    </div>
  );
};
