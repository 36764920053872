import BaseSyncCourse from './baseSyncCourse';

export default class SyncNewDupCourse extends BaseSyncCourse {
  syncNewDupCourseId: string;
  tempCourseId: string;
  accountId: string;
  tempAccountId: string;
  enrollmentTermId: string;
  tempEnrollmentTermId: string;
  startDate: Date;
  endDate: Date;
  sourceCourseId: string;
  restrictEnrollmentsToCourseDates: boolean;

  constructor(json: any) {
    super(json);
    this.syncNewDupCourseId = json?.syncNewDupCourseId;
    this.tempCourseId = json?.tempCourseId;
    this.accountId = json?.accountId;
    this.tempAccountId = json?.tempAccountId;
    this.enrollmentTermId = json?.enrollmentTermId ? String(json?.enrollmentTermId) : null;
    this.tempEnrollmentTermId = json?.tempEnrollmentTermId;
    this.startDate = json?.startDate ? new Date(json.startDate) : null;
    this.endDate = json?.endDate ? new Date(json.endDate) : null;
    this.sourceCourseId = json?.sourceCourseId;
    this.restrictEnrollmentsToCourseDates = json?.restrictEnrollmentsToCourseDates;
  }
}
