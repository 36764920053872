import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { Provider as StoreProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { ThemeProvider } from 'styled-components';

// Theme
import GlobalStyle from 'styles/global';
import DefaultTheme from 'styles/themes/default';
import 'styles/sass-imports.scss';

// Translations
import './i18n';

// Fonts
import 'lato-font';

// Icons
import 'icons';

import { store, browserHistory } from 'store';
import App from './components/app';

ReactModal.setAppElement('body');

ReactDOM.render(
  <StoreProvider store={store}>
    <ConnectedRouter history={browserHistory}>
      <ThemeProvider theme={DefaultTheme}>
        <DndProvider backend={Backend}>
          <GlobalStyle theme={DefaultTheme} />
          {/* This Suspense is needed for i18next translation */}
          <Suspense fallback={null}>
            <App />
          </Suspense>
        </DndProvider>
      </ThemeProvider>
    </ConnectedRouter>
  </StoreProvider>,
  document.getElementById('root')
);
