import { filter, map, includes } from 'lodash';
import { TFunction } from 'i18next';
import { Dispatch } from 'redux';
import { AppState } from 'store';
import { getActiveAccountId } from 'store/account-tree/selectors';
import { triggerDelete } from 'store/accounts/composite-actions';
import { getDescendentAccountsIdsOfAccount } from 'store/accounts/selectors';
import { getAccountIsLocked, getCourseLockedMap } from 'store/general/cross-selectors';
import { getCourseExistsInLmsAlready, getDescendentCoursesIdsOfAccount, getSelectedCourses } from 'store/courses/selectors';
import { toast } from './messages';
import { deleteCourses, receiveCourses } from 'store/courses/actions';
import Course from 'models/canvas/course';
import { COURSES_LIST_TABLE_ID } from 'components/AccountsCoursesManager/CoursesList/CoursesListTable';
import Account from 'models/canvas/account';
import { toggleModal } from 'store/modals/actions';
import { ModalId } from 'constants/index';

/**
 * Method called from function menu to delete the active account. It only deletes the account if it is not locked
 */
export function handleDeleteAccount(dispatch: Dispatch, appState: AppState, t: TFunction): void {
  const accountId: Account['id'] = getActiveAccountId(appState);
  const locked: boolean = getAccountIsLocked({ accountId })(appState);

  const descendentCoursesIds: Course['id'][] = getDescendentCoursesIdsOfAccount({ accountId })(appState);
  const descendentAccountIds: Account['id'][] = getDescendentAccountsIdsOfAccount({ accountId })(appState);

  if (locked) {
    toast.error('Locked Account');
    return;
  }

  triggerDelete(
    accountId,
    descendentCoursesIds,
    descendentAccountIds,
    t,
    appState
  )(dispatch);
}

/**
 * Method called from function menu to delete the selected courses. It only deletes the courses if they are not locked
 */
export function handleDeleteCourses(dispatch: Dispatch, appState: AppState, t: TFunction): void {
  const courseLockedMap = getCourseLockedMap(appState);

  const selectedCourses: Course[] = getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID })(appState);
  const unlockedCourses = filter(selectedCourses, (c: Course) => {
    const locked = !!courseLockedMap?.[c.id];
    return !locked;
  });
  const unlockedCourseIds = map(unlockedCourses, 'id');
  const lockedCoursesSelected: boolean = selectedCourses.length > unlockedCourses.length;

  const undo = () => dispatch(receiveCourses(unlockedCourses));

  const onConfirm = () => {
    dispatch(deleteCourses(unlockedCourseIds));
    toast.successWithUndo(t('toastDeletedItemsWithCount', { count: unlockedCourseIds.length }), undo);
  };

  if (lockedCoursesSelected) {
    toast.confirm(t('confirmDeleteOnlyUnlockedItems'), onConfirm);
  } if (!lockedCoursesSelected) {
    toast.confirm(t('confirmDeleteSelectedItems'), onConfirm);
  }
}

export function handleDuplicateCourses(dispatch: Dispatch, appState: AppState, t: TFunction): void {
  const selectedCourses = getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID })(appState);
  const newCourses = filter(selectedCourses, (c: Course) => {
    return !getCourseExistsInLmsAlready({ courseId: c.id })(appState);
  });
  const hasNewCourses = (newCourses?.length || 0) > 0;

  const onConfirm = () => {
    const existingCourses = filter(selectedCourses, (c: Course) => !includes(newCourses, c));
    dispatch(toggleModal(ModalId.DUPLICATE_COURSES, true, { selectedCourses: existingCourses }));
  };

  if (hasNewCourses) {
    toast.confirm(t('confirmDuplicateOnlyExistingCourses'), onConfirm, undefined, undefined, t('confirmDuplicateOnlyExistingCoursesTitle'), undefined, undefined, undefined, true);
  } else {
    onConfirm();
  }
}

export function handleBulkCourseSettings(dispatch: Dispatch, appState: AppState, t: TFunction): void {
  const selectedCourses: Course[] = getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID })(appState);

  dispatch(toggleModal(ModalId.BULK_COURSE_SETTINGS, true, { courses: selectedCourses }));
}

export function handleImportCourse(dispatch: Dispatch, appState: AppState, t: TFunction): void {
  const selectedCourses: Course[] = getSelectedCourses({ tableId: COURSES_LIST_TABLE_ID })(appState);
  
  const newCourses: Course[] = filter(selectedCourses, (c: Course) => {
    return !getCourseExistsInLmsAlready({ courseId: c.id })(appState);
  });

  const hasNewCourses = (newCourses?.length || 0) > 0;
 
  const onConfirm = () => {
    const existingCourses = filter(selectedCourses, (c: Course) => !includes(newCourses, c));
    const existingCourseIds = map(existingCourses, 'id');
    dispatch(toggleModal(ModalId.IMPORT_COURSE, true, { targetCourseIds: existingCourseIds }));
  };

  //only unsync courses are seleceted
  if (selectedCourses.length === newCourses.length) {
    toast.confirm(t('confirmImportUnsuccsesful'), ()=>{}, undefined, undefined, t('confirmImportUnsyncedTitle'), undefined, undefined, undefined, false);
  } else if (hasNewCourses) {
    toast.confirm(t('confirmImportOnlyIntoExistingCourses'), onConfirm, undefined, undefined, t('confirmImportUnsyncedTitle'), undefined, t('importExistingCourses'), undefined, true);
  } else {
    onConfirm();
  }
}
