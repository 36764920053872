import React, { useState, useEffect, useRef, useCallback } from 'react';
import { trim } from 'lodash';
import { useKeyPress } from 'hooks/useKeyPress';
import { useElementWidth } from 'hooks/useElementWidth';

import { Wrapper, Placeholder, InlineInput } from './style';
import { KeyCodesTypes } from 'constants/index';
import { SrOnlyText } from 'components/general-styles';
import { useClickOutside } from 'hooks/useClickOutside';
import { useTranslation } from 'react-i18next';

export interface IEditableProps {
  text: string;
  onSetText: any;
}

/**
 * A component that allows the user to edit editable text.
 * The user clicks the text, and it becomes a text input. They
 * finish editing, and it invokes the callback and displays the new text.
 */
const EditableTextInput: React.FC<IEditableProps> = ({ text, onSetText }) => {
  const { t } = useTranslation();

  const [inputActive, setInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(text);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const enter = useKeyPress(KeyCodesTypes.ENTER);
  const esc = useKeyPress(KeyCodesTypes.ESCAPE);

  const inputWidth = useElementWidth(inputValue, '500 1.4rem/1.8rem "Lato"');

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (inputActive && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [inputActive, inputRef]);

  // Triggers the onSetText callback
  const setText = useCallback((newValue: string) => {
    const shouldMaintainText = !newValue || newValue === trim(text);
    setInputActive(false);
    onSetText(shouldMaintainText ? trim(text) : newValue);
    setInputValue(shouldMaintainText ? trim(text) : newValue);
  }, [text, onSetText, setInputValue, setInputActive]);

  useEffect(() => {
    if (!inputActive || document.activeElement !== inputRef.current) { return; }

    // If the user presses the ENTER key and the current input value changed
    // it saves the value
    if (enter && inputValue !== text) {
      setText(trim(inputValue));
      setTimeout(() => buttonRef.current.focus(), 100);
    }

    // If the users presses the ESC key, it reset the input value
    if (esc) {
      setInputValue(text);
      setInputActive(false);
      buttonRef.current.focus();
    }
  }, [enter, esc, inputActive, inputValue, onSetText, text, setText, buttonRef]);

  // Saves the current value when the user clicks outside of the component
  useClickOutside(wrapperRef, () => {
    if (inputActive) {
      setText(trim(inputValue));
    }
  });

  const handleBlur = useCallback(() => {
    if (enter || esc || !inputActive) { return; }
    setText(inputValue);
  }, [enter, esc, setText, inputValue, inputActive]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, [setInputValue]);

  const handlePlaceholderClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setInputActive(true);
    e.stopPropagation();
  }, [setInputActive]);

  return (
    <Wrapper ref={wrapperRef}>
      <Placeholder
        className='editable-input-placeholder'
        onClick={handlePlaceholderClick}
        ref={buttonRef}
        role='button'
        tabIndex={inputActive ? -1 : 0}
        visible={inputActive}
      >
        {text}
        <SrOnlyText>&nbsp;({t('clickTextToEdit')})</SrOnlyText>
      </Placeholder>
      <InlineInput
        className='editable-inline-input'
        onChange={handleChange}
        onBlur={handleBlur}
        ref={inputRef}
        style={{ width: `${inputWidth}px` }}
        tabIndex={inputActive ? 0 : -1}
        value={inputValue}
        visible={inputActive}
      />
    </Wrapper>
  );
};

export default EditableTextInput;
