import React, { FC, useCallback, SyntheticEvent, ReactText, useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SyncOrderDto from 'models/dto/syncOrderDto';
import { AppState } from 'store';
import { getHasChangesToSync, getCurrentSyncOrderDto } from 'store/general/cross-selectors';
import { createSyncOrder } from 'store/syncOrders/actions';
import Button from '../Button';
import { AnyAction, Store } from 'redux';
import { toast } from 'helpers/messages';
import { RequestLabel } from 'constants/index';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getLatestRequestInProgressByLabel } from 'store/requests/selectors';
import SyncChanges from 'components/Modals/SyncChanges';
import useSyncSummary from 'hooks/useSyncSummary';

interface ISyncChangesButton {}

/**
 * Button in charge of triggering the sync request
 */
export const SyncChangesButton: FC<ISyncChangesButton> = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store: Store<AppState, AnyAction> = useStore();
  const [syncOrderDto, setsyncOrderDto] = useState<SyncOrderDto>(null);
  
  const {syncArray} = useSyncSummary(syncOrderDto);
  
  const hasUnsyncedChanges = useSelector(getHasChangesToSync);

  const creatingSyncOrder = useSelectorWithProps(
    getLatestRequestInProgressByLabel,
    { label: RequestLabel.CREATE_SYNC_ORDER }
  );

  const submitting = creatingSyncOrder;
  const shouldDisable = !hasUnsyncedChanges || submitting;

  const onConfirm = useCallback((toastId: ReactText) => {
    const appState = store.getState();
    const curretnSyncOrderDto: SyncOrderDto = getCurrentSyncOrderDto(appState);
    dispatch(createSyncOrder(curretnSyncOrderDto));
  }, [dispatch, store]);

  useEffect(()=> {
      if (!syncOrderDto) {
        return;
      }

    toast.confirm((
      <>
        <div style={{ marginBottom: 10 }}>{t('confirmSyncChangesDescription')}</div>
        <SyncChanges syncSummary={syncArray}/>
      </>), onConfirm, (()=> setsyncOrderDto(null)), undefined, t('confirmSyncChanges'), 'large-toast', t('startSync'), t('goBack'));
  }, [syncOrderDto]);


  const handleSyncChangesClick = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    const appState = store.getState();
    const newSyncOrderDto: SyncOrderDto = getCurrentSyncOrderDto(appState);
    setsyncOrderDto(newSyncOrderDto);
  }, [store]);

  return (
    <Button
      className='sync-changes-button'
      color='primary'
      disabled={shouldDisable}
      onClick={handleSyncChangesClick}
      submitting={submitting}
      testId='sync-changes-button'
      ariaLabel={
        shouldDisable
          ? t('noSyncChanges')
          : t('syncChanges')
      }
    >
      {submitting && `${t('startingSync')}...`}
      {!submitting && (
        <>
          <FontAwesomeIcon className='sync-icon' icon={['fas', 'sync-alt']} />
          {shouldDisable
            ? t('noSyncChanges')
            : t('syncChanges')}
        </>
      )}
    </Button>
  );
};
