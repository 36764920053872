import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      queryStringParams: { v: new Date().getTime().toString() } // prevents cache
    },
    fallbackLng: 'en',
    react: {
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
