import styled from 'styled-components';

export const ContentInnerWrapper = styled.div`
  margin-top: -10px;

  .import-course-header {
    margin-bottom: .5rem;

    .expand-target-courses-button {
      display: inline-block;
      .expand-icon {
        transform: rotate(-90deg);
  
        &.expanded {
          transform: rotate(0deg);
        }
      }
    }
  }

  .import-course-data {
    margin-bottom: 1rem;

    .course-icon {
      margin: 0 .5em 0 0;
    }
    .sis-id {
      margin-left: 2.5rem;
    }
    .term-name {
      margin-left: .5em;
    }
    .empty-message {
      color: ${({ theme }) => theme.color.gray2};
      font-style: italic;
      font-weight: normal;
    }
  }

  .selected-source-course {
    font-size: 1.4rem;
    margin: 0 0 1em 0;
  }

  .selected-target-courses {
    margin: 0 0 1em 0;

    .target-courses-list {
      font-weight: normal;
      margin: .5em 1em 0 0em;
      max-height: 190px;
      overflow-y: scroll;
    }
  }

  .table-container {
    height: 500px;

    .select-course-table-wrapper {
      .--data-table-row {
        &.selected, &.odd.selected {
          background: ${({ theme }) => theme.color.tableSelectedRow};
        }
      }
    }
  }
`;
