import { get } from 'lodash';

export default class CanvasUpdateJobResult {
  jobId: string;
  status: string;
  canvasApiErrorMessage: string;
  errorMessage: string;
  startTime: Date;
  endTime: Date;

  constructor(json: any) {
    this.jobId = get(json, 'jobId');
    this.status = get(json, 'status');
    this.canvasApiErrorMessage = get(json, 'canvasApiErrorMessage');
    this.errorMessage = get(json, 'errorMessage');
    this.startTime = get(json, 'startTime') ? new Date(get(json, 'startTime')) : null;
    this.endTime = get(json, 'endTime') ? new Date(get(json, 'endTime')) : null;
  }
}
