import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import moment from 'moment';

import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { getAccountById, getDescendentAccountsIdsOfAccount } from 'store/accounts/selectors';
import { getActiveAccount } from 'store/account-tree/selectors';
import { getDescendentCoursesIdsOfAccount } from 'store/courses/selectors';

import { Panel } from './style';

interface IAccountDetailsPanel {
  show: boolean;
}

const DATE_FORMAT = 'MMM D, YYYY h:mm A';

// Shows a sidebar pabnel with data about the selected account
export const AccountDetailsPanel: React.FC<IAccountDetailsPanel> = React.memo(({ show }) => {
  const { t } = useTranslation();
  const activeAccount = useSelector(getActiveAccount);

  const subAccountIds = useSelectorWithProps(
    getDescendentAccountsIdsOfAccount,
    { accountId: get(activeAccount, 'id') }
  );
  const subCoursesIds = useSelectorWithProps(
    getDescendentCoursesIdsOfAccount,
    { accountId: get(activeAccount, 'id') }
  );
  const parentAccount = useSelectorWithProps(
    getAccountById,
    { accountId: get(activeAccount, 'parent_account_id') }
  );

  return (
    <Panel className='account-details-panel' show={show}>
      <div className='account-detail'>
        <header>
          <h3>{t('accountDetails')}</h3>
        </header>

        <dl>
          {
            !!get(activeAccount, 'name') && (
              <div className='account-name-wrapper'>
                <dt>{t('name')}</dt>
                <dd>{get(activeAccount, 'name')}</dd>
              </div>
            )
          }
          {
            !!get(activeAccount, '_meta.createdAt') && (
              <div className='account-created-wrapper'>
                <dt>{t('created')}</dt>
                <dd>{moment(get(activeAccount, '_meta.createdAt')).format(DATE_FORMAT)}</dd>
              </div>
            )
          }
          {
            !!get(activeAccount, '_meta.modifiedAt') && (
              <div className='account-modified-wrapper'>
                <dt>{t('lastModified')}</dt>
                <dd>{moment(get(activeAccount, '_meta.modifiedAt')).format(DATE_FORMAT)}</dd>
              </div>
            )
          }
          {
            !!get(parentAccount, 'name') && (
              <div className='account-parent-wrapper'>
                <dt>{t('location')}</dt>
                <dd>{get(parentAccount, 'name')}</dd>
              </div>
            )
          }
          <div className='account-sub-accounts-wrapper'>
            <dt>{t('subAccount_plural')}</dt>
            <dd>{get(subAccountIds, 'length', 0)}</dd>
          </div>
          <div className='account-courses-wrapper'>
            <dt>{t('course_plural')}</dt>
            <dd>{get(subCoursesIds, 'length', 0)}</dd>
          </div>
        </dl>
      </div>
    </Panel>
  );
});
