import { map } from 'lodash';

import SyncOrder from 'models/syncOrder/syncOrder';
import SyncNewAccount from 'models/syncOrder/syncNewAccount';
import SyncNewCourse from 'models/syncOrder/syncNewCourse';
import SyncExistingCourse from 'models/syncOrder/syncExistingCourse';
import SyncNewDupCourse from 'models/syncOrder/syncNewDupCourse';
import SyncExistingDupCourse from 'models/syncOrder/syncExistingDupCourse';
import SyncNewTerm from 'models/syncOrder/syncNewTerm';
import SyncExistingAccount from 'models/syncOrder/syncExistingAccount';

export default class SyncOrderDto {
  syncOrder: SyncOrder;
  syncNewAccounts: SyncNewAccount[];
  syncNewTerms: SyncNewTerm[];
  syncNewCourses: SyncNewCourse[];
  syncExistingCourses: SyncExistingCourse[];
  syncNewDupCourses: SyncNewDupCourse[];
  syncExistingDupCourses: SyncExistingDupCourse[];
  syncExistingAccounts: SyncExistingAccount[];

  constructor(json: any) {
    this.syncOrder = new SyncOrder(json?.syncOrder);
    this.syncNewAccounts = map(
      json?.syncNewAccounts,
      (syncNewAccountJson: any) => new SyncNewAccount(syncNewAccountJson)
    );
    this.syncExistingAccounts = map(
      json?.syncExistingAccounts,
      (syncExistingAccount: any) => new SyncExistingAccount(syncExistingAccount)
    );
    this.syncNewTerms = map(
      json?.syncNewTerms,
      (syncNewTermJson: any) => new SyncNewTerm(syncNewTermJson)
    );
    this.syncNewCourses = map(
      json?.syncNewCourses,
      (syncNewCourseJson: any) => new SyncNewCourse(syncNewCourseJson)
    );
    this.syncExistingCourses = map(
      json?.syncExistingCourses,
      (syncExistingCourseJson: any) => new SyncExistingCourse(syncExistingCourseJson)
    );
    this.syncNewDupCourses = map(
      json?.syncNewDupCourses,
      (syncNewDupCourseJson: any) => new SyncNewDupCourse(syncNewDupCourseJson)
    );
    this.syncExistingDupCourses = map(
      json?.syncExistingDupCourses,
      (syncExistingDupCourseJson: any) => new SyncExistingDupCourse(syncExistingDupCourseJson)
    );
  }
}
