import { createSelector } from 'reselect';
import { get, values, find, filter, orderBy, flatMapDeep, concat, map, trim, toLower, compact, reverse } from 'lodash';
import { AppState } from 'store';
import Account from 'models/canvas/account';
import { AccountsFeatureState } from './reducer';
import { getLmsBaseUrl } from 'store/installation/selectors';
import { memoizeSelectorFactory } from '../selector-helpers';
import { isEqual } from 'lodash';

export type PerAccountProps = { accountId: Account['id'] };
export const getAccountsFeature = (appState: AppState) => appState.accounts;

// separating 'isLocked' function in case the definition expands
export function accountExistsInLmsAlready(account: Account): boolean {
  const accountId = get(account, 'id');
  return !!accountId && accountId !== get(account, '_meta.tempAccountId');
}

export function accountIsLocked(account: Account, isSyncing: boolean): boolean {
  return accountExistsInLmsAlready(account) || isSyncing;
}

export function accountIsUnsynced(account: Account, originalAccount:Account): boolean {
  return !accountExistsInLmsAlready(account) || 
  (accountExistsInLmsAlready(account) && !isEqual(account?._meta, originalAccount?._meta)); 
}

export function buildAccountUrl(lmsBaseUrl: string, accountId: Account['id']): string {
  return `${lmsBaseUrl}/accounts/${accountId}`;
}

export function getParentAccount(accounts: Account[], accountId: Account['id']): Account {
  return find(accounts, (acc: Account) => acc.id === accountId);
}

export function buildAncestorMap(
  accountsFeature: AccountsFeatureState,
  accounts: Account[]
): Record<Account['id'], Account['id'][]> {
  const ancestorMap: Record<string, string[]> = {};
  const getAccountAncestors = (accountId: string) => {
    const parentAccountId = get(accountsFeature, `items.${accountId}.parent_account_id`);
    const parentAccount = get(accountsFeature, `items.${parentAccountId}`);
    const ancestorIds: string[] = parentAccount
      ? getAccountAncestors(parentAccountId) // recursive call
      : []; // base case
    return concat(parentAccountId, ancestorIds);
  };
  accounts.forEach((a: Account) => {
    // reverse so top ancestor comes first, nearest ancestor comes last
    ancestorMap[a.id] = reverse(getAccountAncestors(a.id));
  });
  return ancestorMap;
}

export const getEditableInputOfAccount = (accountId: Account['id']): HTMLButtonElement => document.querySelector(`#account-${accountId}-tree-item .editable-input-placeholder`);

export const getAccountItemsMap = createSelector(
  (state: AppState) => state.accounts,
  (featureState: AccountsFeatureState): AccountsFeatureState['items'] => get(featureState, 'items')
);

export const getOriginalAccountsMap = createSelector(
  (state: AppState) => {return state.accounts;},
  (featureState: AccountsFeatureState): AccountsFeatureState['originalItems'] => get(featureState, 'originalItems')
);

export const getAllAccounts = createSelector(
  getAccountsFeature,
  (featureState: AccountsFeatureState): Account[] => values(featureState.items)
);

export const getAllAccountsSortedAlphabetically = createSelector(
  getAccountsFeature,
  (featureState: AccountsFeatureState): Account[] => orderBy(
    values(featureState.items),
    [(account: Account) => toLower(trim(get(account, 'name')))],
    ['asc']
  )
);

export const getAccountById = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAccountItemsMap,
  (items: AccountsFeatureState['items']) => {
    return get(items, props.accountId);
  }
));

export const getOriginalAccountById = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getOriginalAccountsMap,
  (items: AccountsFeatureState['originalItems']) => get(items, props.accountId)
));

export const getAccountSubAccounts = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAllAccounts,
  (accounts: Account[]): Account[] => filter(
    accounts,
    (account: Account) => account.parent_account_id === props.accountId
  )
));

// Get the subset of the account sub-accounts that already exist in Canvas
export const getAccountExistingSubAccounts = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAccountSubAccounts(props),
  (accountSubAccounts: Account[]): Account[] => {
    const filtered: Account[] = filter(accountSubAccounts, accountExistsInLmsAlready);
    const ordered: Account[] = orderBy(
      filtered,
      [
        (account: Account) => toLower(trim(get(account, 'name')))
      ],
      ['asc']
    );
    return ordered;
  }
));

// Get the subset of the account sub-accounts that have not yet been synced to Canvas
// NOTE: we don't sort these to avoid 'jumping/reordering' UI when user edits fields
export const getAccountUnsortedNewSubAccounts = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAccountSubAccounts(props),
  (accountSubAccounts: Account[]): Account[] => filter(
    accountSubAccounts,
    (subAccount: Account): boolean => !accountExistsInLmsAlready(subAccount)
  )
));

// Recursive to get all descendent account ids (not just child sub-accounts)
export const getDescendentAccountsIdsOfAccount = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAllAccounts,
  (accounts: Account[]): Account['id'][] => {
    // Recursive function to walk the directory tree
    const getDescendentSubAccounts = (accountId: Account['id']) => {
      const childSubAccounts = filter(accounts, (a: Account) => a.parent_account_id === accountId);
      const hasChildSubAccounts = get(childSubAccounts, 'length', 0) > 0;
      const descendentAccountsOfChildAccounts = hasChildSubAccounts
        ? flatMapDeep(childSubAccounts, (a: Account) => getDescendentSubAccounts(a.id)) // recursive call
        : []; // base case
      return concat(childSubAccounts, descendentAccountsOfChildAccounts);
    };

    // Trigger the recursive function
    const descendentSubAccounts: Account[] = getDescendentSubAccounts(props.accountId);

    // Ids only, please
    return map(descendentSubAccounts, 'id');
  }
));

export const getAccountAncestorMap = createSelector(
  getAccountsFeature,
  getAllAccounts,
  (accountsFeature: AccountsFeatureState, accounts: Account[]): Record<Account['id'], Account['id'][]> => {
    return buildAncestorMap(accountsFeature, accounts);
  }
);

export const getAccountAncestors = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAccountsFeature,
  getAccountAncestorMap,
  (accountsFeature: AccountsFeatureState, ancestorMap: Record<string, string[]>): Account[] => {
    const accountIds: string[] = get(ancestorMap, props.accountId, []);
    const accounts = map(accountIds, (accountId: string) => get(accountsFeature, `items.${accountId}`));
    return compact(accounts);
  }
));

export const getAccountExistsInLmsAlready = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAccountById(props),
  (account: Account): boolean => accountExistsInLmsAlready(account)
));

export const getAccountUrl = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getLmsBaseUrl,
  (lmsBaseUrl: string): string => buildAccountUrl(lmsBaseUrl, props.accountId)
));

export const getNewAccountsToSync = createSelector(
  getAllAccounts,
  (accounts: Account[]): Account[] => filter(
    accounts,
    (account: Account): boolean => {
      const existsInLmsAlready = accountExistsInLmsAlready(account);
      return !existsInLmsAlready;
    }
  )
);

export const getAccountIsUnsynced = memoizeSelectorFactory((props: PerAccountProps) => createSelector(
  getAccountById(props),
  getOriginalAccountById(props),
  (account: Account, originalAccount:Account): boolean => accountIsUnsynced(account, originalAccount)
));
