import styled, { css } from 'styled-components';
import { CustomButton } from 'components/elements/Button/style';

interface IPopoverContentProps {
  open: boolean;
}

export const PopoverContent = styled.div<IPopoverContentProps>`
  background-color: ${({ theme }) => theme.color.black};
  border-radius: 8px;
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.main};
  padding: 8px;

  p {
    margin: 8px;
  }

  li {
    margin: 0 0 8px;

    &:last-child {
      margin: 0;
    }

    ${CustomButton} {
      display: block;
      font-size: 1.4rem;
      margin: 0;
      padding-left: 0;
    }
  }

  ${({ open }) => !open && css`
    pointer-events: none;
  `}
`;
