import { keyBy, reduce, get, omitBy, includes } from 'lodash';

import Course from 'models/canvas/course';
import CourseMeta from 'models/courseMeta';
import { AccountsAction, AccountsActionTypes } from 'store/accounts/actions';
import { CoursesAction, CoursesActionTypes } from './actions';

export interface CoursesFetchStatus {
  inProgress: boolean;
  numPagesFetched?: number;
  totalPages?: number;
}

export interface CoursesFeatureState {
  autogenCount: number;
  coursesFetch: CoursesFetchStatus;
  items: Record<Course['id'], Course>;
  originalItems: Record<Course['id'], Course>;
}

export const defaultCoursesFeatureState: CoursesFeatureState = {
  autogenCount: 0,
  coursesFetch: {
    inProgress: false
  },
  items: {},
  originalItems: {},
};

/**
 * { key = courseId: value = Course }
 */
interface BulkUpdateObj {
  [key: string]: Course;
}

export default function coursesReducer(
  state: CoursesFeatureState = defaultCoursesFeatureState,
  action: CoursesAction | AccountsAction
): CoursesFeatureState {
  switch (action.type) {
    case CoursesActionTypes.RECEIVE_COURSES: {
      return {
        ...state,
        items: {
          ...state.items,
          ...keyBy(action.payload.courses, 'id')
        }
      };
    }

    case CoursesActionTypes.RECEIVE_ORIGINAL_COURSES: {
      return {
        ...state,
        originalItems: {
          ...state.originalItems,
          ...keyBy(action.payload.courses, 'id')
        }
      };
    }

    case CoursesActionTypes.UPDATE_COURSES: {
      const { courseIds, update } = action.payload;
      const bulkUpdateObj: BulkUpdateObj = reduce(
        courseIds,
        (acc: BulkUpdateObj, courseId: Course['id']) => {
          const currentCourseState = get(state, `items.${courseId}`, {});
          const newCourseState: Course = new Course({ ...currentCourseState, ...update });
          return {
            ...acc,
            [courseId]: newCourseState
          };
        },
        {}
      );
      return {
        ...state,
        items: {
          ...state.items,
          ...bulkUpdateObj
        }
      };
    }

    case CoursesActionTypes.UPDATE_COURSE_META: {
      const { courseId, update } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [courseId]: new Course({
            ...get(state, `items.${courseId}`, {}),
            _meta: {
              ...get(state, `items.${courseId}._meta`, {}),
              ...update
            }
          })
        }
      };
    }

    case CoursesActionTypes.UPDATE_COURSE_METAS: {
      const { courseIds, update } = action.payload;
      const bulkUpdateObj = reduce(
        courseIds,
        (acc: BulkUpdateObj, courseId: Course['id']) => {
          const currentCourseState = get(state, `items.${courseId}`, {});
          const currentCourseMetaState = get(currentCourseState, '_meta', {});
          const newMeta = { ...currentCourseMetaState, ...update };

          return {
            ...acc,
            [courseId]: new Course({
              ...currentCourseState,
              _meta: newMeta
            })
          };
        },
        {}
      );
      return {
        ...state,
        items: {
          ...state.items,
          ...bulkUpdateObj
        }
      };
    }

    case CoursesActionTypes.UPDATE_COURSES_METAS: {
      const { updates } = action.payload;
      const bulkUpdateObj = reduce(
        updates,
        (acc: BulkUpdateObj, meta: Partial<CourseMeta>) => {
          const currentCourseState = get(state, `items.${meta.courseId}`, {});
          const currentCourseMetaState = get(currentCourseState, '_meta', {});
          const newMeta = { ...currentCourseMetaState, ...meta };
          return {
            ...acc,
            [meta.courseId]: new Course({
              ...currentCourseState,
              _meta: newMeta
            })
          };
        }, {}
      );

      return {
        ...state,
        items: {
          ...state.items,
          ...bulkUpdateObj
        }
      };
    }

    case CoursesActionTypes.APPLY_TEMPLATES: {
      const { courseIds, courseTemplateId } = action.payload;
      const bulkUpdateObj: BulkUpdateObj = reduce(
        courseIds,
        (acc: BulkUpdateObj, courseId: Course['id']) => {
          const currentCourseState = get(state, `items.${courseId}`, {});
          const currentCourseMeta = get(currentCourseState, '_meta', {});
          const newCourseState = { ...currentCourseState, _meta: { ...currentCourseMeta, newCourseTemplateId: courseTemplateId } };
          return { ...acc, [courseId]: newCourseState };
        },
        {}
      );
      return {
        ...state,
        items: {
          ...state.items,
          ...bulkUpdateObj
        }
      };
    }

    case CoursesActionTypes.DELETE_COURSES: {
      const { courseIds } = action.payload;
      const itemsWithDeletions: CoursesFeatureState['items'] = omitBy(
        state.items,
        (course: Course) => includes(courseIds, course.id)
      );
      return {
        ...state,
        items: {
          ...itemsWithDeletions
        }
      };
    }

    case CoursesActionTypes.RESET_COURSES: {
      return { ...defaultCoursesFeatureState };
    }

    case AccountsActionTypes.DELETE_ACCOUNTS: {
      const { accountIds } = action.payload;

      const itemsAfterDeletions: CoursesFeatureState['items'] = omitBy(
        state.items,
        (course: Course) => includes(accountIds, course.account_id)
      );
      return {
        ...state,
        items: itemsAfterDeletions
      };
    }

    case CoursesActionTypes.SET_COURSES_FETCH_STATUS: {
      return {
        ...state,
        coursesFetch: { ...action.payload }
      };
    }

    case CoursesActionTypes.SET_AUTOGEN_COUNTER: {
      return {
        ...state,
        autogenCount: action.payload.count
      };
    }

    default:
      return state;
  }
}
