import React, { FC, memo, ReactElement } from 'react';
import { IResizableColumn } from './ResizableColumn';
import { SplitResizableLayoutContainer } from './style';

interface ISplitResizableLayout {
  children: ReactElement<FC<IResizableColumn>>[];
}

export const SplitResizableLayout: FC<ISplitResizableLayout> = memo(({
  children
}) => {
  return (
    <SplitResizableLayoutContainer className='split-resizable-layout-container'>
      {children}
    </SplitResizableLayoutContainer>
  );
});
