import { get, toString, mapValues } from 'lodash';
import EnrollmentTermMeta from 'models/enrollmentTermMeta';
import StartEndDates from './startEndDates';

export enum EnrollmentType {
  StudentEnrollment = 'StudentEnrollment',
  TeacherEnrollment = 'TeacherEnrollment',
  TaEnrollment = 'TaEnrollment',
  DesignerEnrollment = 'DesignerEnrollment'
}

export default class EnrollmentTerm {
  id: string;
  sis_term_id: string;
  sis_import_id: string;
  name: string;
  start_at: Date;
  end_at: Date;
  workflow_state: string;
  overrides: Partial<Record<EnrollmentType, StartEndDates>>;
  _meta: EnrollmentTermMeta;

  constructor(json: any) {
    this.id = toString(get(json, 'id'));
    this.sis_term_id = get(json, 'sis_term_id');
    this.sis_import_id = toString(get(json, 'sis_import_id'));
    this.name = get(json, 'name');
    this.start_at = get(json, 'start_at') ? new Date(get(json, 'start_at')) : null;
    this.end_at = get(json, 'end_at') ? new Date(get(json, 'end_at')) : null;
    this.workflow_state = get(json, 'workflow_state');
    this.overrides = mapValues(get(json, 'overrides'), (val: any) => {
      return new StartEndDates(val);
    });
    this._meta = new EnrollmentTermMeta(get(json, '_meta'));
  }
}
