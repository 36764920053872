import { get, toString } from 'lodash';
import DefaultBulkCourseSettings from 'models/defaultBulkCourseSettings';
import BaseSyncItemEntity from './baseSyncItemEntity';

export default class BaseSyncAccount extends BaseSyncItemEntity {
  name: string;
  accountId: string;
  parentAccountId: string;
  defaultCourseBulkSettings: DefaultBulkCourseSettings;

  constructor(json: any) {
    super(json);
    this.name = toString(get(json, 'name'));
    this.accountId = toString(get(json, 'accountId'));
    this.parentAccountId = get(json, 'parentAccountId'); 
    this.defaultCourseBulkSettings = get(json, 'defaultCourseBulkSettings', null);
  }
}
