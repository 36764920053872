import { createSelector } from 'reselect';
import { DataTableFeatureState, DataTableState, defaultDataTableState } from './reducer';
import { filter, sumBy, indexOf, keys, map, keyBy } from 'lodash';
import { DataTableSelections, DataTableSorts, DataTableFilters, ColumnDef, DataTableConfig, DataTableColumnVisibility, DataTableColumnIndices } from 'modules/data-table/types';
import * as util from '../../util';
import { memoizeSelectorFactory } from 'store/selector-helpers';
import { defaultDataTableConfig } from 'modules/data-table/defaults';

export type PerTableProps = { tableId: string };

export const getDataTableFeature = (appState: any): DataTableFeatureState => appState.dataTable;

export const getDataTable = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableFeature,
  (featureState: DataTableFeatureState): DataTableState => featureState?.tables?.[props?.tableId] || defaultDataTableState
));

export const getDataTableConfig = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTable(props),
  (table: DataTableState): DataTableConfig => table?.config || defaultDataTableConfig
));

export const getRowIdProperty = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableConfig(props),
  (config: DataTableConfig): string => config?.rowIdProperty || 'id'
));

export const getDataTableColumnVisibility = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTable(props),
  (table: DataTableState): DataTableColumnVisibility => table?.columnVisibility || {}
));

export const getDataTableData = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableFeature,
  (featureState: DataTableFeatureState): any[] => featureState?.data?.[props?.tableId] || []
));

export const getColumnDefs = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableConfig(props),
  (config: DataTableConfig): ColumnDef[] => config?.columnDefs || []
));

export const getPinnedLeftColumnDefs = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getColumnDefs(props),
  (columnDefs: ColumnDef[]): ColumnDef[] => filter(
    columnDefs,
    (c: ColumnDef) => !!c?.pinLeft
  )
));

export const getCenterColumnDefs = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getColumnDefs(props),
  (columnDefs: ColumnDef[]): ColumnDef[] => filter(
    columnDefs,
    (c: ColumnDef) => !c?.pinLeft
  )
));

export const getColumnIndices = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getPinnedLeftColumnDefs(props),
  getCenterColumnDefs(props),
  (leftColumnDefs: ColumnDef[], centerColumnDefs: ColumnDef[]): DataTableColumnIndices => {
    return util.getColumnIndices(leftColumnDefs, centerColumnDefs);
  }
));

export const getDataTableSelections = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTable(props),
  (table: DataTableState): DataTableSelections => table?.selections || {}
));

export const getDataTableSorts = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTable(props),
  (table: DataTableState): DataTableSorts => table?.sorts || {}
));

export const getDataTableFilters = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTable(props),
  (table: DataTableState): DataTableFilters => table?.filters || {}
));

export const getFilteredRows = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableData(props),
  getDataTableFilters(props),
  getColumnDefs(props),
  (data: any[], filters: DataTableFilters, columnDefs: ColumnDef[]): any[] => {
    return util.filterRows(data, filters, columnDefs);
  }
));

export const getSortedFilteredRows = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableData(props),
  getFilteredRows(props),
  getDataTableSorts(props),
  getColumnDefs(props),
  (data: any[], filteredRows: any[], sorts: DataTableSorts, columnDefs: ColumnDef[]): any[] => {
    const result = util.sortRows(filteredRows, data, sorts, columnDefs);
    return result;
  }
));

export const getSelectedRowIds = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableSelections(props),
  (selections: DataTableSelections): string[] => filter(
    keys(selections),
    (rowId: string) => !!selections[rowId]
  )
));

export const getSelectedRows = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getSelectedRowIds(props),
  getSortedFilteredRows(props),
  getRowIdProperty(props),
  (selectedIds: string[], rows: any[], rowIdProperty: string): any[] => {
    const keyedRows = keyBy(rows, rowIdProperty);
    return map(selectedIds, (id: string) => keyedRows?.[id]);
  }
));

export const getDefaultRowHeight = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableConfig(props),
  (config: DataTableConfig): number => config?.defaultRowHeight
));

export const getVisibleColumnDefs = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getDataTableColumnVisibility(props),
  getColumnDefs(props),
  (visibility: DataTableColumnVisibility, columnDefs: ColumnDef[]): ColumnDef[] => filter(
    columnDefs,
    (c: ColumnDef) => !!visibility?.[c?.columnId]
  )
));

export const getVisibleLeftColumnDefs = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getVisibleColumnDefs(props),
  (columnDefs: ColumnDef[]): ColumnDef[] => filter(columnDefs, (c: ColumnDef) => !!c?.pinLeft)
));

export const getVisibleCenterColumnDefs = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getVisibleColumnDefs(props),
  (columnDefs: ColumnDef[]): ColumnDef[] => filter(columnDefs, (c: ColumnDef) => !c?.pinLeft)
));

export const getTotalPinnedLeftWidth = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getVisibleLeftColumnDefs(props),
  (columnDefs: ColumnDef[]): number => sumBy(columnDefs, 'width')
));

export const getTotalCenterWidth = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getVisibleCenterColumnDefs(props),
  (columnDefs: ColumnDef[]): number => sumBy(columnDefs, 'width')
));

export const getToggleableColumnDefs = memoizeSelectorFactory((props: PerTableProps) => createSelector(
  getColumnDefs(props),
  (columnDefs: ColumnDef[]): ColumnDef[] => filter(columnDefs, (c: ColumnDef) => !!c?.toggleable)
));

// Get the array of ids between the provided row and the previous selected row.
// Used when shift+click selecting a row to select all rows between this row and the prev. selected row.
// We call this on demand, so no need to memoize
export const getShiftSelectionRangeForRow = (props: { tableId: string, selectedRow: any }) => createSelector(
  getSortedFilteredRows({ tableId: props.tableId }),
  getDataTableSelections({ tableId: props.tableId }),
  getRowIdProperty({ tableId: props.tableId }),
  (rows: any[], selections: DataTableSelections, rowIdProperty: string): string[] => {
    const indexOfSelectedRow = indexOf(rows, props.selectedRow);
    const rowIdsToSelect: string[] = [];

    if (indexOfSelectedRow < 0) { return []; }

    // move backwards through the rows until reaching the nearest selected row (or else first row)
    for (let i = indexOfSelectedRow - 1; i >= 0; i -= 1) {
      const row = rows[i];
      const rowId = row[rowIdProperty];
      const selected = !!selections[rowId];
      if (rowId && !selected) {
        rowIdsToSelect.push(rowId);
      } else {
        break;
      }
    }

    return rowIdsToSelect;
  }
);
