import { AxiosResponse } from 'axios';
import api from 'api';
import mockApi from 'api/mock';
import CanvasPageable from 'models/canvasPageable';
import EnrollmentTermDto from 'models/dto/enrollmentTermDto';
import { API } from 'config';

const customApi = API.useMock ? mockApi() : api();
const include = 'include%5B%5D=overrides';

export async function fetchTermsPage(
  pageNum: number
): Promise<CanvasPageable<EnrollmentTermDto>> {
  return customApi.get(`/api/terms?page=${pageNum}&size=100&${include}`)
    .then((resp: AxiosResponse<CanvasPageable<EnrollmentTermDto>>) => {
      return new CanvasPageable<EnrollmentTermDto>(resp.data, EnrollmentTermDto);
    });
}
