import Modal from 'models/modal';
import { ModalsAction, ModalsActionTypes } from './actions';

export interface ModalsFeatureState {
  [modalId: string]: Modal
}

export const defaultModalsFeatureState: ModalsFeatureState = {
};

export const defaultModalState: Modal = {
  open: false,
  data: {}
};

export default function modalsReducer(
  state: ModalsFeatureState = defaultModalsFeatureState,
  action: ModalsAction
): ModalsFeatureState {
  switch (action.type) {
    case ModalsActionTypes.TOGGLE_MODAL: {
      const { id, data, open } = action.payload;
      return {
        ...state,
        [id]: { ...defaultModalState, open, data }
      };
    }
    default:
      return state;
  }
}
