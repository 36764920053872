import styled, { css } from 'styled-components';

interface IAppMenuContainer {}

export const AppMenuContainer = styled.nav<IAppMenuContainer>`
  ${({ theme }) => css`
    &.app-menu-container {
      u {
        text-decoration: underline;
      }
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-start;
      padding: 0 1rem;

      & > ul {
        display: flex;
        height: 100%;
      }

      .app-menu-item, .app-submenu-item {
        position: relative;
      }

      .app-menu-item {
        align-items: center;
        display: flex;
        font-size: 1.4rem;
        height: 100%;
        padding: 0;
        transition: background-color .2s ease;
        &:hover > button {
          background: ${theme.color.mainLightHighlight};
          color: ${theme.color.white};
        }
        & > button[aria-expanded="true"] {
          background: ${theme.color.white};
          color: ${theme.color.font};
        }
      }

      .app-submenu-item {
        min-width: 20rem;
        &:hover > button {
          background: ${theme.color.mainLight};
        }
        & > button[aria-expanded="true"] {
          background: ${theme.color.gray5};
        }
        &:after {
          content: '>';
          position: absolute;
          top: 0.5rem;
          right: 1rem;
          pointer-events: none;
        }
      }

      .dropdown-toggle {
        display: inline-flex;
        height: 100%;
        width: 100%;
        align-items: center;
        background: transparent;
        border: 0;
        padding: .5rem 1rem;
        &:after {
          display: none;
        }
      }
      button.dropdown-toggle {
        font-size: 1.4rem;
      }

      .app-menu-dropdown, .app-menu-subcomponent {
        border-radius: 0;
        padding: 1rem 0;
        position: absolute;
        top: 0;
        left: 100%;
        background: ${theme.color.white};
        z-index: 1000;
        box-shadow: ${theme.shadow.container}
      }
      .app-menu-item > .app-menu-dropdown {
        top: 100%;
        left: 0;
      }

      .app-menu-subcomponent {
        padding: 1.5rem;
      }
    }
  `}
`;
