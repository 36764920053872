import React from 'react';
import PageRatingReportWidget from 'components/AppMenuBar/widgets/PageRatingReportWidget';
import { IAppMenuDropdownItem } from 'models/appMenuDropdownConfig';

/**
 * Configuration for appMenuDropdown
 * Note: title value should be key of translation for i18next
 */
export const appMenuItems: IAppMenuDropdownItem[] = [
  {
    title: 'export',
    items: [
      {
      title: 'pageRatingData',
      component: <PageRatingReportWidget />
      }
    ]
  }
];