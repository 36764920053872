import { useState, useEffect } from 'react';

/** Hook that keeps track of an element width */
export const useElementWidth = (inputValue:string, font:string): number => {
  const [width, setWidth] = useState(0);

  // Everytime the value or font of the element changes, it calculates the new width
  useEffect(() => {
    const element: HTMLCanvasElement = document.createElement('canvas');
    const context: CanvasRenderingContext2D = element.getContext('2d');
    if (context) context.font = font;
    const newWidth: number = context?.measureText?.(inputValue)?.width || width;
    setWidth(newWidth);
  }, [inputValue, font]);

  return width;
};
