import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { createRatingReport, downloadPageRatingReport, fetchPageRatingReportStatus, resetPageRatingReportStatus } from 'store/pageRatingReport/actions';
import { toast } from 'helpers/messages';
import { getPageRatingReport, getPageRatingReportStatus } from 'store/pageRatingReport/selectors';
import { PageRatingReportStatus } from 'constants/index';
import PageRatingJobStatus from 'models/pageRatingReport/pageRatingJobStatus';
import PageRatingReport from 'models/pageRatingReport/pageRatingReport';
import { downloadAsCsv } from 'helpers/downloads';
import AppMenuContext from 'components/AppMenuBar/context/appMenuContext';
import { Button } from 'components/elements';
import { PageRatingReportContainer } from './style';
import { getTopAccountId } from 'store/installation/selectors';

/**
 * Widget to allow retrieval of PageRating Report
 */
const PageRatingReportWidget: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { collapseAll } = useContext(AppMenuContext);

  const [statusBarContent, setStatusBarContent] = useState(<></>);

  const accountId: string = useSelector(getTopAccountId);

  const pageRatingReportStatus: PageRatingJobStatus = useSelector(getPageRatingReportStatus);
  const pageRatingReport: PageRatingReport = useSelector(getPageRatingReport);

  // Page Rating Report Statuses
  const reportIsRunning = !!pageRatingReportStatus && pageRatingReportStatus?.status !== PageRatingReportStatus.SUCCESS
  && pageRatingReportStatus?.status !== PageRatingReportStatus.ERROR;

  const noReportRunning = !pageRatingReportStatus || !pageRatingReportStatus?.jobId;

  const reportReadyForDownload = pageRatingReportStatus?.status === PageRatingReportStatus.SUCCESS 
  && !pageRatingReportStatus?.reportWasDownloaded;

  const downloadIsReady = !!pageRatingReport?.filename && pageRatingReportStatus?.status === PageRatingReportStatus.SUCCESS;

  const reportWasDownloaded = pageRatingReportStatus?.reportWasDownloaded;

  const reportHasError = pageRatingReportStatus?.status === PageRatingReportStatus.ERROR;

  /** 
   * Trigger the request to the backend to start compiling the page rating report
   * 
   * Collapse App dropdown menu
   */
  const createReportHandler = () => {
    dispatch(createRatingReport(accountId));
    toast.success(t('pageRatingToast'));
    collapseAll();
  };

  /** 
   * Trigger the request to the backend to stream the pageRatingReport result
   * 
   * Update the statusBar to inform user the export is in process
   */
  const downloadReportHandler = () => {
    dispatch(downloadPageRatingReport());
    setStatusBarContent(<p>{t('fetchingDownload')}</p>);
  };

  /** Defining the elements that will populate the statusBar */
  const initiateButton = <Button 
      className='pageRatingWidget-button pageRatingWidget-button-initiate'
      color='primary'
      onClick={createReportHandler}
      ariaLabel={t('initiateNewCollection')}
    >
      <FontAwesomeIcon icon={['fas', 'play']} />{t('initiateNewCollection')}
    </Button>;

  const downloadButton = <Button 
      className='pageRatingWidget-button pageRatingWidget-button-download'
      color='primary'
      onClick={downloadReportHandler}
      ariaLabel={t('download')}
    >
      <FontAwesomeIcon icon={['fas', 'download']} />{t('download')}
    </Button>;

  const pullingStatusDisplay = <p className='pageRatingWidget-status-pulling'>
      <FontAwesomeIcon icon={['fas', 'sync-alt']} spin />{t('pageRatingExportPulling')}
    </p>;

  const errorDisplay = <>
      <p className='pageRatingWidget-status-error'>{t('pageRatingError')}</p>
      {initiateButton}
    </>;

  /** Determine what status content to display in the statusBar based on status record */
  const populateStatusBarContent = () => {
    if (noReportRunning || reportWasDownloaded) {
      // If no report has been triggered or if the report was already downloaded
      setStatusBarContent(initiateButton);
    } else if (reportReadyForDownload) {
      // If the report has been completed and hasn't been downloaded yet
      setStatusBarContent(downloadButton);
    } else if (reportIsRunning) {
      // If the report job has been started but the backend job is still in progress
      setStatusBarContent(pullingStatusDisplay);
    } else if (reportHasError) {
      setStatusBarContent(errorDisplay);
    }
  };

  /** 
   * If a pageRating Report Job has started and it's last status was running, 
   * Fetch the latest status on component load
   */
  useEffect(() => {
    if (reportIsRunning) {
      dispatch(fetchPageRatingReportStatus());
    }
    populateStatusBarContent();
  }, []);

  /** Watch for updates to the report status and display appropriet content */
  useEffect(() => {
    populateStatusBarContent();
  }, [pageRatingReportStatus]);

  /** Watch for received pageRatingReport and trigger file download */
  useEffect(() => {
    if (downloadIsReady) {
      downloadAsCsv(pageRatingReport.filename, pageRatingReport.reportContent);
      dispatch(resetPageRatingReportStatus());
      collapseAll();
    }
  }, [pageRatingReportStatus, pageRatingReport]);

  return (
    <PageRatingReportContainer className='pageRatingWidget-container' >
      <h3>{t('generateDownloadLink')}</h3>
      <p>{t('pageRatingExportDescription')}</p>
      <div className='pageRatingWidget-statusBar'>
        {statusBarContent}
      </div>
    </PageRatingReportContainer>
  );
};

export default PageRatingReportWidget;
