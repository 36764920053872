import { get } from 'lodash';

export default class SourceCourseMeta {
    courseCode: string;
    id: string;
    name: string;
    
  constructor(json: any) {
    this.id = get(json, 'id');
    this.name = get(json, 'name');
    this.courseCode = get(json, 'course_code');
  }
}