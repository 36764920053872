import { of, concat, from, Observable, EMPTY } from 'rxjs';
import { map as _map } from 'lodash';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';

import { AppState } from 'store';
import { requestStart, requestSuccess, requestError } from 'store/requests/actions';
import { TermsActionTypes, receiveTerms, FetchTermsPageAction, fetchTermsPage, receiveOriginalTerms } from './actions';

import * as termsApi from './api';
import CanvasPageable from 'models/canvasPageable';
import EnrollmentTermDto from 'models/dto/enrollmentTermDto';
import EnrollmentTerm from 'models/canvas/enrollmentTerm';

export const fetchTermPagesEpic$ = (action$: Observable<any>, state$: StateObservable<AppState>) => action$.pipe(
  ofType(TermsActionTypes.FETCH_TERMS_PAGE),
  mergeMap((action: FetchTermsPageAction) => {
    const label = 'fetch_terms';
    const start = requestStart(label);
    const { requestUuid } = start.payload;
    return concat(
      of(start),
      from(termsApi.fetchTermsPage(action.payload.page)).pipe(
        switchMap((page: CanvasPageable<EnrollmentTermDto>) => {
          const dtos = page.content;
          const terms: EnrollmentTerm[] = _map(dtos, (dto: EnrollmentTermDto) => dto.toEnrollmentTerm());
          const hasNextPage = !page.last;
          const nextPageAction = hasNextPage ? of(fetchTermsPage(page.number + 1)) : EMPTY;
          return concat(
            of(receiveTerms(terms)),
            of(receiveOriginalTerms(terms)),
            of(requestSuccess(requestUuid)),
            nextPageAction
          );
        }),
        catchError((error: Error) => of(requestError(requestUuid, error)))
      )
    );
  })
);
