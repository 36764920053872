import { get } from 'lodash';

export default class CourseDuplication {
  originCourseId: string; // used only on FE
  originMetaId: string;
  newCopy: boolean;
  createdAt: Date;

  constructor(json: any) {
    this.originCourseId = get(json, 'originCourseId');
    this.originMetaId = get(json, 'originMetaId');
    this.newCopy = !!get(json, 'newCopy');
    this.createdAt = get(json, 'createdAt') ? new Date(get(json, 'createdAt')) : null;
  }
}
