import MongoPageable from 'models/mongoPageable';
import SyncOrder from 'models/syncOrder/syncOrder';
import SyncOrderDto from 'models/dto/syncOrderDto';

/** TYPES */
export enum SyncOrderActionTypes {
  START_SYNC_STATUS_POLLS = 'syncOrders/START_SYNC_STATUS_POLLS',
  FETCH_SYNC_ORDERS = 'syncOrders/FETCH_SYNC_ORDERS',
  FETCH_SYNC_ORDER = 'syncOrders/FETCH_SYNC_ORDER',
  RECEIVE_SYNC_ORDER_DTOS = 'syncOrders/RECEIVE_SYNC_ORDER_DTOS',
  RECEIVE_SYNC_ORDER_PAGEABLE_INFO = 'syncOrders/RECEIVE_SYNC_ORDER_PAGEABLE_INFO',
  CREATE_SYNC_ORDER = 'syncOrders/CREATE_SYNC_ORDER',
  CLEAR_SYNC_ORDER = 'syncOrders/CLEAR_SYNC_ORDER'
}

export interface StartSyncStatusPollsAction {
  type: typeof SyncOrderActionTypes.START_SYNC_STATUS_POLLS;
}

export interface FetchSyncOrdersAction {
  type: typeof SyncOrderActionTypes.FETCH_SYNC_ORDERS;
  payload: { page: number }
}

export interface FetchSyncOrderAction {
  type: typeof SyncOrderActionTypes.FETCH_SYNC_ORDER;
  payload: { syncOrderId: SyncOrder['syncOrderId'] };
}

export interface ReceiveSyncOrderDtosAction {
  type: typeof SyncOrderActionTypes.RECEIVE_SYNC_ORDER_DTOS;
  payload: { syncOrderDtos: SyncOrderDto[] };
}

export interface ReceiveSyncOrderPageableInfoAction {
  type: typeof SyncOrderActionTypes.RECEIVE_SYNC_ORDER_PAGEABLE_INFO;
  payload: { mongoPageable: MongoPageable<SyncOrderDto> };
}

export interface CreateSyncOrderAction {
  type: typeof SyncOrderActionTypes.CREATE_SYNC_ORDER;
  payload: { syncOrderDto: SyncOrderDto };
}

export interface ClearSyncOrderAction {
  type: typeof SyncOrderActionTypes.CLEAR_SYNC_ORDER;
  payload: { syncOrderIds: SyncOrder['syncOrderId'][] }
}

export type SyncOrderAction =
  | StartSyncStatusPollsAction
  | FetchSyncOrdersAction
  | FetchSyncOrderAction
  | ReceiveSyncOrderDtosAction
  | ReceiveSyncOrderPageableInfoAction
  | CreateSyncOrderAction
  | ClearSyncOrderAction;

/** ACTION CREATORS */
export const startSyncStatusPolls = () => ({
  type: SyncOrderActionTypes.START_SYNC_STATUS_POLLS
});

export const fetchSyncOrders = (page: number): FetchSyncOrdersAction => ({
  type: SyncOrderActionTypes.FETCH_SYNC_ORDERS,
  payload: { page }
});

export const fetchSyncOrder = (syncOrderId: SyncOrder['syncOrderId']): FetchSyncOrderAction => ({
  type: SyncOrderActionTypes.FETCH_SYNC_ORDER,
  payload: { syncOrderId }
});

export const receiveSyncOrderDtos = (syncOrderDtos: SyncOrderDto[]): ReceiveSyncOrderDtosAction => ({
  type: SyncOrderActionTypes.RECEIVE_SYNC_ORDER_DTOS,
  payload: { syncOrderDtos }
});

export const receiveSyncOrderPageableInfo = (mongoPageable: MongoPageable<SyncOrderDto>): ReceiveSyncOrderPageableInfoAction => ({
  type: SyncOrderActionTypes.RECEIVE_SYNC_ORDER_PAGEABLE_INFO,
  payload: { mongoPageable }
});

export const createSyncOrder = (syncOrderDto: SyncOrderDto): CreateSyncOrderAction => ({
  type: SyncOrderActionTypes.CREATE_SYNC_ORDER,
  payload: { syncOrderDto }
});

export const clearSyncOrder = (syncOrderIds: SyncOrder['syncOrderId'][]): ClearSyncOrderAction => ({
  type: SyncOrderActionTypes.CLEAR_SYNC_ORDER,
  payload: { syncOrderIds }
});
