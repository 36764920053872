import { keyBy, omit } from 'lodash';

import EnrollmentTerm from 'models/canvas/enrollmentTerm';
import { TermsAction, TermsActionTypes } from './actions';

export interface TermsFeatureState {
  items: Record<EnrollmentTerm['id'], EnrollmentTerm>;
  originalItems: Record<EnrollmentTerm['id'], EnrollmentTerm>;
}

export const defaultTermsFeatureState: TermsFeatureState = {
  items: {},
  originalItems: {}
};

export default function termsReducer(
  state: TermsFeatureState = defaultTermsFeatureState,
  action: TermsAction
): TermsFeatureState {
  switch (action.type) {
    case TermsActionTypes.RECEIVE_TERMS: {
      return {
        ...state,
        items: {
          ...state.items,
          ...keyBy(action.payload.terms, 'id')
        }
      };
    }

    case TermsActionTypes.RECEIVE_ORIGINAL_TERMS: {
      return {
        ...state,
        originalItems: {
          ...state.originalItems,
          ...keyBy(action.payload.terms, 'id')
        }
      };
    }

    case TermsActionTypes.DELETE_TERMS: {
      return {
        ...state,
        items: omit(state.items, action.payload.ids)
      };
    }

    default:
      return state;
  }
}
