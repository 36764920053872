// hook from https://usehooks.com/useOnClickOutside/
import { useEffect, RefObject } from 'react';
import { some } from 'lodash';

// Hook thats detects clicks outside the given element
export const useClickOutside = (
  ref: RefObject<any> | RefObject<any>[],
  handler: (e: Event) => void
) => {
  useEffect(
    () => {
      const listener = (event: Event) => {
        if (Array.isArray(ref)) {
          if (some(ref, (r: RefObject<any>) => r.current && r.current.contains(event.target))) {
            return;
          }
        }
        // Do nothing if clicking ref's element or descendent elements
        if (!(ref as RefObject<any>).current || (ref as RefObject<any>).current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  );
};
