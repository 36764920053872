import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { cloneDeep, split, isFunction } from 'lodash';
import { Popover } from '../Popover';
import DayPicker, { DayPickerProps } from 'react-day-picker';
import { Button } from '..';
import { useTranslation } from 'react-i18next';
import { DatePickerPopoverWrapper, DatePickerWrapper } from './style';
import { useClickOutside } from 'hooks/useClickOutside';
import { OverlayProps } from 'react-bootstrap/Overlay';
import cn from 'classnames';
import { DATETIME_FORMAT_COMMA, TIME_INPUT_FORMAT } from 'constants/index';
import { isLikeClick } from 'helpers/events';

interface IDatePicker {
  className?: string;
  clearable?: boolean;
  dateDisplayFormat?: string;
  defaultValue?: Date;
  disabled?: boolean;
  disabledDays?: DayPickerProps['disabledDays'];
  id?: string;
  maxTime?: string;
  minTime?: string;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (newVal: Date) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onPopoverClose?: () => void;
  onPopoverOpen?: () => void;
  placeholder?: string;
  placement?: OverlayProps['placement'];
  readOnly?: boolean;
  selectTime?: boolean;
  timeFormat?: string;
  value: Date;
}

export const DatePicker: FC<IDatePicker> = ({
  className,
  clearable = true,
  dateDisplayFormat = DATETIME_FORMAT_COMMA,
  defaultValue,
  disabled,
  disabledDays,
  id,
  maxTime,
  minTime,
  name,
  onBlur,
  onChange,
  onFocus,
  onPopoverClose,
  onPopoverOpen,
  placeholder,
  placement = 'auto',
  readOnly = false,
  selectTime = true,
  timeFormat = TIME_INPUT_FORMAT,
  value,
}) => {
  const { t } = useTranslation();
  const originalDefaultValue = useRef(defaultValue);
  const mainInputRef = useRef<HTMLInputElement>(null);
  const popoverWrapperRef = useRef<HTMLDivElement>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(originalDefaultValue.current);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const formattedDate = selectedDate ? moment(selectedDate).format(dateDisplayFormat) : '';
  const formattedTime = selectedDate ? moment(selectedDate).format(timeFormat) : '';

  const handleTriggerClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowPopover(true);
    isFunction(onPopoverOpen) && onPopoverOpen();
  }, [setShowPopover, onPopoverOpen]);

  const handleDayClick = useCallback((date: Date) => {
    let newDate = date;
    if (selectedDate) {
      // only change the y/m/d part of the current value
      newDate = cloneDeep(selectedDate);
      newDate.setFullYear(date.getFullYear());
      newDate.setMonth(date.getMonth());
      newDate.setDate(date.getDate());
    } else {
      // use the selected date at 12am as the new value
      newDate.setHours(0);
      newDate.setMinutes(0);
      newDate.setSeconds(0);
    }
    onChange(newDate);
  }, [selectedDate, onChange]);

  const handleTimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const [newHour, newMinute] = split(e.target.value, ':');
    const newDate: Date = selectedDate ? cloneDeep(selectedDate) : new Date();
    newDate.setHours(parseInt(newHour, 10) || 0);
    newDate.setMinutes(parseInt(newMinute, 10) || 0);
    onChange(newDate);
  }, [selectedDate, onChange]);

  const handleClearClick = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const handleCloseClick = useCallback(() => {
    setShowPopover(false);
    isFunction(onPopoverClose) && onPopoverClose();
  }, [setShowPopover, onPopoverClose]);

  useEffect(() => {
    setSelectedDate(value);
  }, [value, setSelectedDate]);

  useClickOutside(popoverWrapperRef, () => {
    setShowPopover(false);
    isFunction(onPopoverClose) && onPopoverClose();
  });

  return (
    <>
      <DatePickerWrapper className='date-picker-wrapper'>
        <input
          className={cn('date-picker-display-input', { [className]: !!className, readOnly })}
          data-testid='date-picker-display-input'
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          value={formattedDate}
          /** Note about applying readonly attr to this display input vs. this whole Datepicker field being set to readOnly=true: */
          /** This el always technically has readonly attribute to enforce the datepicker popup usage. This 'input' el is for formatted date display only, */
          /** but if the Datepicker component prop is set to readOnly from within a form context, then setting the value, even via datepicker popup, shouldn't be allowed and */
          /** should be styled as though it's disabled. (the functional distinction btw disabled and readOnly is important, that's why we need both props for this component) */
          readOnly
          ref={mainInputRef}
          tabIndex={0}
        />
        <Button
          aria-haspopup
          ariaLabel={t('edit')}
          className='date-picker-trigger-button'
          color='secondary'
          disabled={disabled || readOnly}
          id={`${id}-date-picker-button`}
          onClick={handleTriggerClick}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (isLikeClick(e)) {
              e.stopPropagation();
              handleTriggerClick(e as any);
            }
          }}
          size='small'
          testId='date-picker-trigger-button'
        >
          <FontAwesomeIcon className='calendar-icon' icon={['fas', 'calendar']} />
        </Button>
      </DatePickerWrapper>

      <Popover
        autoFocus={false}
        placement={placement}
        returnFocus={true}
        show={!disabled && showPopover}
        targetRef={mainInputRef}
      >
        <DatePickerPopoverWrapper
          className='date-picker-popover-wrapper'
          ref={popoverWrapperRef}
        >
          <div className='top-buttons-container'>
            {clearable && (
              <Button
                className='clear-button'
                onClick={handleClearClick}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (isLikeClick(e)) {
                    e.stopPropagation();
                    handleClearClick();
                  }
                }}
                size='small'
                testId='datetime-popover-clear-button'
                variant='link'
              >
                {t('clear')}
              </Button>
            )}
            <Button
              ariaLabel={t('close')}
              className='close-button'
              onClick={handleCloseClick}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (isLikeClick(e)) {
                  e.stopPropagation();
                  handleCloseClick();
                }
              }}
              size='small'
              testId='datetime-popover-close-button'
              variant='link'
            >
              <FontAwesomeIcon className='close-icon' icon={['fas', 'times']} />
            </Button>
          </div>
          <div className='calendar-time-container'>
            <div className='calendar-wrapper'>
              <DayPicker
                onDayClick={handleDayClick}
                disabledDays={disabledDays}
                initialMonth={defaultValue}
                selectedDays={selectedDate || undefined}
              />
            </div>
            {selectTime && (
              <div className='time-wrapper'>
                <label htmlFor={`${id}-time`}>{t('time')}</label>
                <input
                  id={`${id}-time`}
                  max={maxTime}
                  min={minTime}
                  onChange={handleTimeChange}
                  type='time'
                  value={formattedTime}
                />
              </div>
            )}
          </div>
        </DatePickerPopoverWrapper>
      </Popover>
    </>
  );
};
