import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from 'react-smooth-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CONSTANTS } from 'config';
import CourseTemplate from 'models/courseTemplate';

import { Button, RadioButton } from 'components/elements';

import { Item, Check, Description, Name, Actions } from './style';
import { UseFormMethods } from 'react-hook-form';

export interface ITemplateItemProps {
  template: CourseTemplate;
  name: string;
  register: UseFormMethods['register'];
}

const TemplateItem: React.FC<ITemplateItemProps> = ({ template, name, register }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const isEmpty = template.courseTemplateId === CONSTANTS.templates.emptyId;

  const displayName = template?.displayName || template?.name;

  return (
    <>
      <Item key={template.courseTemplateId} data-testid='template-item'>
        <Check>
          <RadioButton
            label={displayName}
            id={`template-${template.courseTemplateId}`}
            name={name}
            register={register}
            value={template?.courseTemplateId}
          />
        </Check>

        <Description>
          <Name>
            <p>{displayName}</p>
          </Name>

          {
            !isEmpty && (
              <Actions>
                <Button
                  className='.'
                  variant='link'
                  color='secondary'
                  onClick={() => setExpanded(prev => !prev)}
                  rotate={expanded ? 90 : 0}
                  ariaLabel={`${expanded ? 'Hide' : 'Show'} Details`}
                >
                  {t('details')}
                  <FontAwesomeIcon icon={['fas', 'angle-right']} />
                </Button>
              </Actions>
            )
          }
        </Description>
      </Item>
      {
        !isEmpty && (
          <Collapse
            id={`template-${template.courseTemplateId}`}
            className='template-description-collapse'
            heightTransition='.4s ease'
            expanded={expanded}
          >
            <div>
              <p>{template.description}</p>
            </div>
          </Collapse>
      )
}
    </>
  );
};

export default TemplateItem;
