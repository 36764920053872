import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trim } from 'lodash';
import Course from 'models/canvas/course';
import { buildCourseUrl, getCourseExistsInLmsAlready } from 'store/courses/selectors';
import { getCourseIsLocked } from 'store/general/cross-selectors';
import { getLmsBaseUrl } from 'store/installation/selectors';
import { updateCourses } from 'store/courses/actions';
import { NameCellWrapper } from './style';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { EditSingleValueButton } from 'components/elements/EditSingleValueButton';

interface INameCell {
  row: Course;
}

export const NameCell: FC<INameCell> = ({
  row
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const lmsBaseUrl = useSelector(getLmsBaseUrl);
  const name = row?.name;
  const courseId = row?.id;
  const locked = useSelectorWithProps(getCourseIsLocked, { courseId });
  const existsAlready = useSelectorWithProps(getCourseExistsInLmsAlready, { courseId });
  const url = buildCourseUrl(lmsBaseUrl, courseId);

  return (
    <NameCellWrapper className='name-cell-wrapper'>
      <EditSingleValueButton
        canEdit={!locked}
        currentValue={row?.name}
        isValid={(val: string) => !!trim(val)}
        labelText={t('name')}
        onUpdate={(newName: string) => {
          dispatch(updateCourses([courseId], { name: newName }));
        }}
        type='text'
      >
        {locked && (
          <span className='lock-icon-wrapper'>
            <FontAwesomeIcon className='lock-icon' icon={['fas', 'lock']} />
          </span>
        )}
        {existsAlready && (
          <a className='course-external-link' href={url} target='_blank' rel='noopener noreferrer' title={t('openInNewTab')}>{name}</a>
        )}
        {!existsAlready && name}
      </EditSingleValueButton>
    </NameCellWrapper>
  );
};
