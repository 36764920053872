import React, { useState, useCallback, SyntheticEvent, ReactNode, useContext, ReactNodeArray, Ref, useEffect } from 'react';
import { Popover, ArrowContainer, PopoverProps, PopoverPosition, PopoverState } from 'react-tiny-popover';
import { isBoolean, omit, assign } from 'lodash';
import { ThemeContext } from 'styled-components';
import FocusLock from 'react-focus-lock';

import { KeyCodesTypes } from 'constants/index';
import { useKeyPress } from 'hooks/useKeyPress';

import IconButton from 'components/elements/IconButton';
import { CustomButton, ICustomButtonProps } from 'components/elements/Button/style';
import { PopoverContent } from './style';

export interface IPopoverButtonProps extends ICustomButtonProps {
  children: ReactNode | ReactNodeArray;
  containerStyle?: PopoverProps['containerStyle'];
  contentDestination?: HTMLElement;
  popoverContent: PopoverProps['content'];
  preferredPosition?: PopoverPosition[];
  forceOpen?: boolean;
  isIconButton?: boolean;
  testId?: string;
}

const defaultContainerStyle: PopoverProps['containerStyle'] = {
  zIndex: '100',
  maxWidth: '30rem',
  fontSize: '1.3rem'
};

/**
 * A button that opens/closes content in a 'popover' when clicked.
 */
const PopoverButton: React.ForwardRefRenderFunction<HTMLDivElement, IPopoverButtonProps> = (
  props: IPopoverButtonProps,
  // forwarded ref
  ref: Ref<HTMLDivElement>
) => {
  const [popoverIsOpen, setPopoverOpen] = useState(false);
  const themeContext = useContext(ThemeContext);
  const esc = useKeyPress(KeyCodesTypes.ESCAPE);

  // Destructure some PopoverButton-specific props
  const { children, contentDestination, popoverContent, containerStyle, preferredPosition, forceOpen, onClick, testId, isIconButton } = props;

  // Props to pass down to lower-level Button element
  const buttonProps = omit(props, ['children', 'popoverContent', 'preferredPosition', 'forceOpen', 'onClick']);

  const handleButtonClick = useCallback((e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    // First be sure to toggle the popover, then run any passed-in onClick handler
    setPopoverOpen(!popoverIsOpen);
    if (onClick) {
      onClick(e);
    }
  }, [popoverIsOpen]);

  const handlePopoverClickOutside = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  // Close popover after clicking something inside, and then run any passed-in onPopoverClickInside handler
  const handlePopoverClickInside = useCallback((e: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setPopoverOpen(false);
  }, []);

  useEffect(() => {
    // Prefer a forced state possibly passed via props over the click-driven internal state
    if (isBoolean(forceOpen)) {
      setPopoverOpen(forceOpen as boolean);
    }
  }, [forceOpen]);

  useEffect(() => {
    if (esc) {
      setPopoverOpen(false);
    }
  }, [esc]);

  const ButtonComponent = isIconButton ? IconButton : CustomButton;

  const style = assign({}, defaultContainerStyle, containerStyle);

  return (
    <Popover
      isOpen={popoverIsOpen}
      onClickOutside={handlePopoverClickOutside}
      padding={0}
      containerStyle={style}
      positions={ ['top']}
      content={(popoverInfo: PopoverState) => (
        <ArrowContainer
          position={popoverInfo.position}
          childRect={popoverInfo.childRect}
          popoverRect={popoverInfo.popoverRect}
          arrowColor={themeContext.color.black}
          arrowSize={10}
          arrowStyle={{}}
        >
          <PopoverContent
            onClick={handlePopoverClickInside}
            open={popoverIsOpen}
          >
            <FocusLock returnFocus>
              {popoverContent}
            </FocusLock>
          </PopoverContent>
        </ArrowContainer>
      )}
    >
      <div ref={ref}>
        <ButtonComponent
          aria-haspopup
          aria-expanded={popoverIsOpen}
          aria-label='Open dropdown'
          color='secondary'
          onClick={handleButtonClick}
          testId={testId}
          {...buttonProps}
        >
          {children}
        </ButtonComponent>
      </div>
    </Popover>
  );
};

export default React.forwardRef(PopoverButton);
