import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { useEffect, useRef } from 'react';
import { BehaviorSubject, Observable } from 'rxjs';

export function useSelectorWithPropsObservable<T = any>(
  selectorWithProps: (props: any) => (appState: any) => T,
  selectorProps: any,
): Observable<T> {
  const val = useSelectorWithProps(selectorWithProps, selectorProps);
  const obs$ = useRef(new BehaviorSubject<T>(val)).current;

  useEffect(() => {
    obs$.next(val);
  }, [val, obs$]);

  useEffect(() => {
    return () => obs$.unsubscribe();
  }, [obs$]);

  return obs$;
}
